import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';


var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll; 

class Prereq extends React.Component{

    state = {
        topics: [],
        selected_topics: [],
        saved: null,
        loading: false,
        save_message: ""
    }

    fetchAllTopics = async () => {
        this.setState({loading: true});
        const response = await kopi.get('/api/topics/prereq-topics', {
            params: {userId: this.props.currentUser}
        });    
        // console.log(response.data[1]);
        this.setState({loading: false, topics: response.data[0], selected_topics: response.data[1]});
    }

    selectTopic(topic) {
        let selectArray = [];
        this.state.selected_topics.forEach(function(t){
            selectArray.push(t.id);
        });
        if (selectArray.includes(topic.id)) {
            this.setState({selected_topics: this.state.selected_topics.filter((t, i) => t.id !== topic.id)});
        } else {
                this.setState({selected_topics: [...this.state.selected_topics, topic]});
            }
        }

    updateTopics = async () => {
            this.setState({loading: true});
            kopi.put('/api/topics/prereq-topics', {
                userId: this.props.currentUser,
                topic_rank_ids: this.state.selected_topics.map(selected_topic=>selected_topic.id)
            });  
            let save_message  = "Saved!" 
            this.setState({loading: false, saved: true, save_message});
            this.scrollToTop();
    }

    componentDidMount() {
        this.fetchAllTopics();
    }

    showTopics() {
        if (this.state.topics){
            let selectArray = [];
            this.state.selected_topics.forEach(function(topic){
                selectArray.push(topic.id);
            });
            return (
                <div className="row"> {this.state.topics.map(topic => {
                    if (selectArray.includes(topic.id)) {
                        return (
                            <div className="col-md-6">
                                <div onClick={() => this.selectTopic(topic)} key={topic.id} className="cursor-pointer alert alert-info">
                                    {topic.title}
                                </div>
                            </div>
                            ); 
                    } else {
                        return (
                            <div className="col-md-6">
                                <div onClick={() => this.selectTopic(topic)} key={topic.id} className="cursor-pointer alert alert-dark">
                                    {topic.title}
                                </div>
                            </div>
                        );
                    }
                })
            } </div>
            );
        } else {
            return <small>Loading</small>
        }
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    render(){
        let savedText;
        
        if(this.state.saved){
            savedText =  <div className="alert alert-light" role="alert">
                            {this.state.save_message}
                            <br/>
                        </div>
        }

        if(this.state.loading){
           return <div className="">
                    {savedText}
                    <br/>
                    <div align="center"> 
                    <br/> 
                    <div className="spinner-border text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <br/>   <br/> 
                    </div>                    
                    <button className="btn btn-info btn-block">Update</button>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>;
        } else {
                return <div className="">
                    <br/>
                    {savedText}
                    <button onClick={this.updateTopics} className="btn btn-info btn-block">Update</button>
                    <br/>
                    <p className="lead">
                        Let Joni know which topics you have covered in school so that he can provide you with the most suitable questions.
                    </p>

                    <p className="lead">
                    <small>You only need to do this once.</small>
                    </p>
                    
                    {this.showTopics()}
                    <button onClick={this.updateTopics} className="btn btn-info btn-block">Update</button>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>;
            }
        }
    }


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};
    
export default connect(mapStateToProps, {})(Prereq);