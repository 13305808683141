import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history';

const ConfirmStudyPlan = (props) => {

    let bare_weekly_goal, recommended_weekly_goal, stretch_weekly_goal;
    let fortnightly_topic_inserts;
    
    let average_parts_to_mastery;

    if (props.topics[0].level === 1 || props.topics[0].level  === 2){
        average_parts_to_mastery = 36; //70
    } else {
        average_parts_to_mastery = 50; //105
    }

    let total_parts_to_mastery = props.sorted_topics.length * average_parts_to_mastery
    let num_of_days_from_now = Math.floor((new Date(props.endDate).getTime()-new Date(Date.now()).getTime())/(3600000 * 24));
    let num_of_weeks_from_now = Math.floor(num_of_days_from_now / 7);

    if (total_parts_to_mastery/(num_of_weeks_from_now-1)< 18){ 
        bare_weekly_goal = 8;
        recommended_weekly_goal = 18;
        stretch_weekly_goal = 25;
    } else {
        bare_weekly_goal = Math.ceil(total_parts_to_mastery/(num_of_weeks_from_now-1)*(8/18));
        recommended_weekly_goal = Math.ceil(total_parts_to_mastery/(num_of_weeks_from_now-1));
        stretch_weekly_goal = Math.ceil(total_parts_to_mastery/(num_of_weeks_from_now-1)*(25/18));
    }

    if (props.sorted_topics.length/(num_of_weeks_from_now - 1) <= 0.5){
        fortnightly_topic_inserts = '1';
    } else if (props.sorted_topics.length/(num_of_weeks_from_now - 1) <= 1.5){
        fortnightly_topic_inserts = '1-2';
    } else {
        fortnightly_topic_inserts = '2';
    }

    const renderEndDate = () => {
        return <h3 className="dark-txt">
            You have <strong>{num_of_days_from_now} days</strong> before <strong>{String(props.endDate).split(`${new Date(Date.now()).getYear() + 1900}`)[0]}</strong> to master the following <strong> {props.selected_topics.length} topics</strong>.
        </h3>
    }

    const renderTopics = () => {
        return <ul><small> {props.selected_topics.map(topic => {
            return <li>{topic.title}</li>
        })} </small> </ul>
    }

    const renderGoalPlanning = () => {
        return <div class="alert alert-light" role="alert">
            <small><strong>WEEKLY GOALS</strong></small> <br/>
            Bare Minimum: {bare_weekly_goal} question parts<br/>
            <strong>Recommended: {recommended_weekly_goal} question parts</strong><br/>
            Stretch: {stretch_weekly_goal} question parts<br />
        </div>
    }

    const renderTopicPlanning = () => {
        switch (fortnightly_topic_inserts) {
            case '1':
                return "Topics are added progressively. Every 2 weeks, Joni will add 1 new topic to your practice stack."
            case '1-2':
                return "Topics are added progressively. Every 2 weeks, Joni will add 1 or 2 new topics to your practice stack."
            case '2':
                return "Topics are added progressively. Every 2 weeks, Joni will add 2 new topics to your practice stack."
            default:
                return "Oops. Something went wrong."
        }
    }

    const renderEncouragement = () => {
        return <div class="alert alert-info" role="alert">
            Consistently achieve your goals to get special rewards and more importantly, <strong>master what you want to learn!</strong>
        </div>
    }

    const renderSubmit = () => {
        return <nav class="navbar fixed-bottom navbar-light bg-white">
            <button onClick={props.onSubmit} className="btn btn-block btn-blue">Back To Home</button>
        </nav>
    }

    return <div className="container padding-top">
        <style></style>
        {renderEndDate()}
        <br />
        {renderTopics()}
        <hr />
        {renderGoalPlanning()}
        <br/> 
        <div align="center">
        <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560546592/Joni%20Assets/pro_bono.png" className="img-fluid" style={{maxHeight: "16vh"}}/> <br/>
        </div> <br/> 
        {renderTopicPlanning()}
        <br/> <br/>
        <div align="center">
        <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560546592/Joni%20Assets/top-prizes.png" className="img-fluid" style={{maxHeight: "16vh"}}/> <br/>
        </div> <br/>
        {renderEncouragement()}
   
        {renderSubmit()}
    </div>
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(ConfirmStudyPlan);
