import React, {useState} from 'react';

const SimilarQuestionsToggle = (props) => {
    
    const [want_similar_question, wantSimilarQuestion] = useState(false);

    const toggleSimilarQuestion = () => {
        props.toggleSimilarQuestion(!want_similar_question);
        wantSimilarQuestion(!want_similar_question);
    }
    
    const renderAttemptSimilarQuestions = () => {
    
        if (props.dark_mode) {
            return <div align="center" >
            <small 
            className={`${want_similar_question ? 'cursor-pointer badge-selected-dark' : 'cursor-pointer badge-faded-dark'}`} 
            onClick={()=>toggleSimilarQuestion(!want_similar_question)}
            for="similar">
                I would like to try a similar question soon
            </small>
            <hr/>
            </div>
        } else {
            return <div align="center" >
            <small 
            className={`${want_similar_question ? 'cursor-pointer badge-selected' : 'cursor-pointer badge-faded'}`} 
            onClick={()=>toggleSimilarQuestion(!want_similar_question)}
            for="similar">
                I would like to try a similar question soon
            </small>
            <hr/>
       </div>
        }
       
    }
   

    if (props.marked_question) {
        if (props.answer_feedback.is_correct){
            return  <></>
        } else {
            return <div>
                {renderAttemptSimilarQuestions()}
            </div>
        }
    } else {
        return  <></>
    }
   

}

export default SimilarQuestionsToggle;


