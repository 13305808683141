import React from 'react'; 
import {InlineTex} from 'react-tex';

const TextField = (props) => {

  const renderAnswerContext = () => {
    let seperator = "\${1,2}";
    if (props.current_part.prefix || props.current_part.suffix){
      let prefix = "";
      let suffix ="";

      if (props.current_part.prefix) prefix = props.current_part.prefix
      if (props.current_part.suffix) suffix = props.current_part.suffix
    
      return  <InlineTex texSeperator={seperator} texContent={prefix  + " _____ " + suffix} />     
    } else {
      return <></>
    }
  }

    const renderPrefixUnits = () => {
      if (props.current_part.units){
        let seperator = "\${1,2}";
        let prefix;
          if (props.current_part.units === "\$") {
            prefix = <div className="input-group-prepend">
              <span className="input-group-text"><InlineTex texSeperator={seperator} texContent={props.current_part.units} /> </span>
            </div>
          } else {
            prefix = <></>
          }      
          return prefix;  
      } else {
        return <></>
      }
     
    }

    const renderSuffixUnits = () => {
      if (props.current_part.units){
        let seperator = "\${1,2}";
        let suffix;
        if (props.current_part.units === "\$") {
          suffix = <></>
        } else {
          suffix = <div className="input-group-append">
                    <span className="input-group-text"><InlineTex texSeperator={seperator} texContent={props.current_part.units} /> </span>
                  </div>
        }      
        return suffix;  
      } else {
        return <></>
      }
    }

    const renderInputField = () => {
      return <div align='left'>
        <small>Answer: </small>
      <div className="input-group mb-3" style={{ maxWidth: "41vw"}}>
        {renderPrefixUnits()}
        <input value={props.attempt} onChange={(e)=>{props.updateAttempt(e.target.value)}} type="text" className="form-control" />
        {renderSuffixUnits()}
      </div>
      </div>
  }

    return <div className="padding-top answer-form">
        {renderAnswerContext()}
        {renderInputField()}
    </div>

}


export default TextField;

