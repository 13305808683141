import { SIGN_IN, SIGN_OUT, SIGN_IN_GOOGLE, UPDATE_ACCOUNT, UPDATE_SUBSCRIPTION_STATUS} from './types';
import kopi from '../apis/kopi';
import history from '../history';

// Onboarding


// Authentication 


// export const validateVersion = ({ version_number }) => (dispatch) => {
//     dispatch({ type: VALIDATE_VERSION, payload: version_number });
// };

export const signOut = (message) => {
    history.push({pathname: '/', state: {message: message}});
    return {
        type: SIGN_OUT
    };
};


export const updateAccount = ({ name, secondary }) => (dispatch) => {
    dispatch({ type: UPDATE_ACCOUNT, payload: { name, secondary } });
    history.push('/')
}

export const updateSubscriptionStatus = ({subscription_tier}) => (dispatch) => {
    dispatch({type : UPDATE_SUBSCRIPTION_STATUS, payload: {subscription_tier} });
}


export const signInWithGoogle = ({ _id, name, secondary, email }) => (dispatch) => { // remember that both dispatch and getState are passed to reducer
    dispatch({ type: SIGN_IN_GOOGLE, payload: { _id, name, secondary, email } });
    history.push('/');
};

export const signIn = formValues => async (dispatch) => { // remember that both dispatch and getState are passed to reducer
    const response = await kopi.post('/login', {
        username: formValues.username.toLowerCase(), password: formValues.password
    });
    if (response.data.msg === "Invalid Login") {
        history.push({ pathname: '/login', state: { wrongInfo: true } })
        return
    } else if (response.data.msg === 'Please verify your email'){
        history.push({ pathname: '/login', state: { verifyEmail: true } })
        return
    }else {
        dispatch({ type: SIGN_IN, payload: response.data });
        window.location.reload('/');
    }
};

export const signUp = formValues => async (dispatch) => { // remember that both dispatch and getState are passed to reducer
    const response = await kopi.post('/register', { parent_id: formValues.parent_id, prototype_id: formValues.prototype_id, username: formValues.username.toLowerCase(), password: formValues.password, name: formValues.name, secondary: formValues.secondary });
    if (response.data.error) {
        return "Username Already Exists"
    } else if (response.data.msg == 'Username Already Exists') {
        return "Username Already Exists"
    } else if (response.data) {
        dispatch({ type: SIGN_IN, payload: response.data });
        history.push('/onboard');
    } else {
        return "Error"
    }
};

export const signUpTrial = formValues => async (dispatch) => { // remember that both dispatch and getState are passed to reducer
    const response = await kopi.post('/trial', { parent_id: formValues.parent_id, prototype_id: formValues.prototype_id, username: formValues.username.toLowerCase(), password: formValues.password, name: formValues.name, secondary: formValues.secondary });
    if (response.data.error) {
        return "Username Already Exists"
    } else if (response.data.msg == 'Username Already Exists') {
        return "Username Already Exists"
    } else if (response.data) {
        dispatch({ type: SIGN_IN, payload: response.data });
        history.push('/onboard');
    } else {
        return "Error"
    }
};

export const signUpFreeTrial = formValues => async (dispatch) => { // remember that both dispatch and getState are passed to reducer
    const response = await kopi.post('/registerfreetrial', { username: formValues.email.toLowerCase(), password: formValues.password, name: formValues.name, secondary: formValues.level });
    if (response.data.error) {
        return window.alert("Unknown Error")
    } else if (response.data.msg == 'Username Already Exists') {
        return window.alert("Username Already Exists")
    } else if (response.data) {
        history.push('/verifyemail');
    } else {
        return "Error"
    }
};



// Create Question

// Answer Question

// Training

// export const fetchTrainingSet = (currentUser) => async (dispatch) => { // remember that both dispatch and getState are passed to reducer
//     const response = await kopi.get('/api/training_sets/start',{
//         params: {user: currentUser}
//     });    
//     dispatch({type: FETCH_TRAINING_SET, payload: response.data});
//     history.push('/training');
// };