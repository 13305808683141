

import React from 'react'; 

import SkyLight from 'react-skylight';

import kopi from '../../../../apis/kopi';

import {connect} from 'react-redux';

class AskQuestion extends React.Component {
    render(){
        return <></>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(AskQuestion);