import React from 'react'; 
import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

const QuestionHeader = props => {

    const renderHeaderText = (question) => {
        let seperator = "\${1,2}";
        if (question.header_txt && question.header_txt.startsWith("\\begin")) {
            return <div style={{overflowX: "auto", overflowY:"auto"}}>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Tex texContent={question.header_txt} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        } else {
            return <div><InlineTex texSeperator={seperator} texContent={question.header_txt} /></div>
        }
    }

    const renderHeaderImage = (question) => {
        if (question.header_img) {
           return <div align="center"><br/><img className="img-fluid" src={question.header_img} /><br/></div>
        } else {
            return <></>
        }
    }

    const renderLine = (header_txt, header_img) => {
        if (header_txt || header_img) {
            return <hr/>
        } else {
            return <></>
        }
    }

    if (props.marked_question && props.solution_view === "Solution") {
        return <></>
    } else if (props.all_parts.length == 0){
        return <div>
            {renderHeaderText(props.current_part)}
            {renderHeaderImage(props.current_part)}
            {renderLine(props.current_part.header_txt, props.current_part.header_img)}
        </div>
    } else {
        return <div>
             {renderHeaderText(props.all_parts[0])}
             {renderHeaderImage(props.all_parts[0])}
            {renderLine(props.all_parts[0].header_txt, props.all_parts[0].header_img)}
        </div>
    }
    
}

export default QuestionHeader;


  