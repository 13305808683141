import {SIGN_IN, SIGN_OUT, SIGN_IN_GOOGLE, UPDATE_ACCOUNT, UPDATE_SUBSCRIPTION_STATUS} from '../actions/types'; 

const INITIAL_STATE = {
    isSignedIn: null,
    currentUser: localStorage.getItem('currentUser'),
    secondary: localStorage.getItem('secondary'),
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email'),
    tier: localStorage.getItem('tier'),
    dateJoined: localStorage.getItem('dateJoined'),
    subscription_tier: localStorage.getItem('subscription_tier'),
};

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case UPDATE_ACCOUNT:
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('secondary', action.payload.secondary);
            return {...state, isSignedIn: true, name: action.payload.name, secondary: action.payload.secondary};
        case UPDATE_SUBSCRIPTION_STATUS:
            localStorage.setItem('subscription_tier', action.payload.subscription_tier);
            return {...state, subscription_tier: action.payload.subscription_tier}
        case SIGN_IN_GOOGLE:
            localStorage.setItem('currentUser', action.payload._id);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('secondary', action.payload.secondary);
            localStorage.setItem('email', action.payload.email);
            return {...state, isSignedIn: true, currentUser: action.payload._id, name: action.payload.name, secondary: action.payload.secondary, email: action.payload.email};
        case SIGN_IN:
            localStorage.setItem('currentUser', action.payload._id);
            localStorage.setItem('name', action.payload.name);
            localStorage.setItem('secondary', action.payload.secondary);
            localStorage.setItem('email', action.payload.email);
            localStorage.setItem('tier', action.payload.tier);
            localStorage.setItem('dateJoined', action.payload.dateJoined);
            localStorage.setItem('subscription_tier', action.payload.subscription_tier);
            return {...state, isSignedIn: true, currentUser: action.payload._id, name: action.payload.name, secondary: action.payload.secondary, email: action.payload.email, tier: action.payload.tier, dateJoined: action.payload.dateJoined, subscription_tier: action.payload.subscription_tier};
        case SIGN_OUT:
            localStorage.removeItem('currentUser');
            localStorage.removeItem('name');
            localStorage.removeItem('secondary');
            localStorage.removeItem('email');
            localStorage.removeItem('tier');
            localStorage.removeItem('subscription_tier')
            return  {...state, isSignedIn: false, currentUser: null, name: null, secondary: null, email: null, tier: null, dateJoined: null, subscription_tier: null};
        default: 
            if (!state.currentUser) {
                return state;
            } else{
                return {...state, isSignedIn: true};
            }
    }
};