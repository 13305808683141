import React from 'react';
import { connect } from 'react-redux';

class Privacy extends React.Component {

 
render(){
  return (
    <div className="container-fluid">

<br/>
<h1>Privacy Policy</h1>
<h2>1.	Introduction </h2>
<p>1.1.	Joni.ai Pte Ltd (hereinafter referred by/as “Joni.ai”, “we”, “us” or “our”) respects your privacy and ask that you read this privacy policy (the “Policy”) carefully to understand how we will use and protect your personal information.  This Policy applies to all information we collect:
</p>
<ol>
<li>on https://mentor.joni.ai and its subdomains (collectively referred to as “Website”);</li>
<li>visitors to our Website;</li>
<li>members subscribed to and accessing our Services.</li>
</ol>
<p>1.2.	This Policy is governed by and construed in accordance with the laws of Singapore.</p>
<p>1.3.	Our Site may contain links to other websites. This privacy policy only applies to this website so when you link to other websites you should read their respective privacy policies.
</p>
<h2>2.	Types of Information Collected</h2>
<p>We will collect personal information about you only when you give us your explicit consent. We may also collect personal information without consent through (but not limited to) your correspondence with us. Some of this data will be aggregated or statistical, which means that we will not be able to identify you individually. When you visit our Website, we may monitor your use of our Website through cookies and similar tracking devices. This information helps us to build a profile of our users so as to improve our Services. Please refer to the section on “Our Use of Cookies” below.
</p>
<h3>2.1.	Personal Information</h3>
<p>“Personal information” is information that can be used to identify you as an individual or to relate to an identifiable individual. This may include (but not limited to) your name, date of birth, education details, email address, zip code/postal address, telephone number, bank and credit card details. Such “personal information” may be collected from you through our Website and any subdomain that we operate. We may also collect your personal information offline through phone calls with a sales representative, or when you contact our customer service. Information from external sources may also be obtained in order to improve our ability in providing relevant marketing, offers, and services to you. This information may be taken from public databases, marketing partners, social media platforms, as well as from other third parties.
</p>
<h3>2.2.	IP Address</h3>
<p>Your “IP address” is automatically assigned to your computer by your Internet Service Provider. When accessing the Site, your IP Address may be recognised and recorded in our server log files, along with the time of visit and page(s) that you visited on the Site. This is standard practice and is done automatically on many websites and other services. IP addresses help us to calculate the Site’s user traffic, identify server issues and administer the Site.
</p>
<h3>2.3.	Other Information</h3>
<p>“Other Information” is any information that does not identify you as an individual, or relate to any identifiable individual. This might include browser and device information, information collected through cookies, email tracking, pixel tags and/or other technologies; other information provided by you. This information collected is for us to facilitate the user experience and operations of the Site and other purposes. 
</p>
<p>We make use of third party web analytics such as Google Analytics to collect “Other Information”. For more information about Google Analytics, or to opt out of Google Analytics, you may go to tools.google.com/dlpage/gaoptout
</p>
<p>Your personal data might be collected by us or 3rd parties, if we choose to employ. “Other Information” can also be collected through your browser or device, cookies and other similar technologies, email or aggregating information.
</p>
<h2>3.	Storage of Collected Information</h2>
<p>3.1.	Information mentioned in the section “TYPES OF INFORMATION COLLECTED” may be retained as long as it serves the purpose of collection. We do not store information for use for other purposes that have not been notified to a concerned individual. You may refer to the section “USAGE OF COLLECTED INFORMATION” to understand our use of personal information. We will ensure that your personal information will be erased or destroy when we no longer require it. Documents containing your personal information will also be destroyed when it is no longer required. These documents will then be irretrievable or inaccessible to our organization.
</p>
<p>Information collected may also be retained for legal or business purposes.</p>
<p>This includes situations where:</p>
<ol>
<li>personal data is required for ongoing legal procedures involving the organisation;
</li>
<li>personal data is necessary to comply with the organization’s obligations under relevant laws and regulations, international/regional/bilateral standards which require the retention of personal data;
</li>
<li>personal data is required for the organisation to carry out its business operations, such as the generation of annual reports
</li></ol>
<h2>4.	Usage of Collected Information</h2>
<p>When you register with us, we may use the relevant information for the following:</p>
<ol>
<li>sending you newsletters, contact you about products and services we think may be of interest to you.</li>
<li>sending you emails regarding updates on the Site</li>
<li>communication with you and to provide customer services</li>
<li>to personalise your experience on our Site</li>
<li>when we deem necessary or appropriate under applicable law</li>
<ul>
<li>for compliance with legal processes,</li>
<li>to respond to government authorities,</li>
<li>for the enforcement of our Terms of Use and/or Terms and Conditions,</li>
<li>to protect our operations,</li>
<li>to protect our rights, privacy, safety or property and/or those of yours, and/or</li>
<li>to allow us to pursue available remedies or limit the damages that we may sustain</li>
</ul>
</ol>
<p>If you do not wish that we use this information, please send an email to info@joni.ai.</p>
<h2>5.	Our use of Cookies</h2>
<p>5.1.	A cookie is a small piece of information that is placed on your computer when you visit certain websites. When we refer to “cookies” we include other technologies with similar purposes, such as tags and identifiers. Find out more about the use of cookies on http://www.cookiecentral.com/.
</p>
<p>5.2.	We use the following types of cookie, as will your browser:
</p>
<ol>
<li>Service cookies that help us to make our Website work as efficiently as possible; remember your registration and login details; remember your settings preferences; to detect what device you are using and adapt how we present our Services according to the screen size of that device; and meter the number of pages you view for the purpose of administering subscriptions to our Services.
</li>
</ol>
<p>5.3.	We use cookies for various purposes, including (but not limited to):</p>
<ol>
<li>to identify you as a user on our Site and stores your preferences and information that you may have previously provided;
</li>
<li>allow us to better understand what you are doing on our services and provide us with information on page and service popularity;
</li>
<li>to help us to better understand you and allow us to provide users with advertising that we believe are relevant to them, based on your information provided;
</li>
<li>credit card information and other financial data required to process payments when using our services may be collected and stored by third party payment processors or our own payment processors. Third party payment processors may provide us with a cookie or similar technology that enables you to make additional purchases using the payment information you have already provided.
</li>
</ol>
<p>5.4.	We may have third party partners from time to time, but we will have no access to third party cookies and third party organisations have no access to ours. The third party organisations that place cookies, including your browser (such as Google) and the third party companies who pay for advertising and analytics services using this information, will have their own privacy policies.
</p>
<h2>6.	How your information is protected</h2>
<p>We are committed to maintaining the security of personal information. We have put in place appropriate security procedures and technical and organisational measures to safeguard your personal information to prevent loss, misapplication, unauthorised access or disclosure of information.
</p>
<p>
Sensitive information like passwords and credit card information are encrypted using encryption technology such as (SSL) which may be stored in Singapore and overseas. However, it has to be noted that no data security measures can promise 100% security at any given time. Our systems and communication networks through which you access our services may experience security breaches and failures which are beyond our reasonable control.
</p>
<p>We also restrict access to information about you to Joni.ai employees who require the information in order to respond to your inquiries and requests. Employees who misuse your information will subject to strict disciplinary actions.
</p>
<h2>7.	Maintaining accurate Information</h2>
<p>
To serve you to the best our abilities, we require that you ensure the accuracy of your personal information on our Website. If any of the information that you have provided to Joni.ai changes, or if you wish to cancel your registration, please let us know by sending an email to info@joni.ai. We may also ask you to correct information we think is inaccurate, and you may also ask us to remove information which is inaccurate.
</p>
<h2>8.	Non-disclosure</h2>
<p>We do not sell, trade, or otherwise transfer your personally identifiable information to third parties. However, your information may be handed to other trusted third parties who assist us in operating our Website, conducting our business, or servicing you, and in such cases they will be keeping this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, protect ours and others’ rights, property, or safety, or respond to claims that any content violates the rights of third parties. Disclosure of information may also be done to satisfy any applicable law, regulation, legal process or governmental requests. Non-personally identifiable visitor information may also be provided to other parties for marketing, advertising, or other uses.
</p>
<h2>9.	Changes to the Policy</h2>
<p>
Should we change our privacy policy, we will post the changes on this webpage and we reserve the right to make modifications to this privacy policy at any time. Unless stated otherwise, all modifications made shall automatically take effect upon posting onto this webpage and shall annul any earlier versions of privacy policies. You agree to review these privacy policies through our Website regularly to ensure that you are informed of any modifications. By your continued access and/or use of our Service after the modifications constitute your acceptance of the latest privacy policy in entirety
</p>
<h2>10.	 Changes to Business Administration</h2>
<p>
Should Joni.ai’s business be sold or integrated with another business, your personal information may be disclosed to, stored and used by the new owners and any party who require such information to ensure that you continue to enjoy the Services that Joni.ai provided.
</p>
<p>
This privacy policy is about you and your information privacy. We welcome your views about the policy should it enable us to serve you better. If you would like to contact us with any queries or comments please send an email to info@join.ai.
</p>


    </div>
  );
}
}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Privacy);