import React from 'react'; 
import {connect} from 'react-redux';

import MasteryUpdate from './MasteryUpdate'

const MasteriesUpdate = props => {
    if (props.updated_masteries){ // loop through 1st mastery array for index
            return props.updated_masteries.map((mastery,index) => {
                return <span><div>
                 <MasteryUpdate key={index} updatedMastery={mastery} />
                 </div> <br/> </span>
              });
        } else {
            
            return <span></span>
        }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(MasteriesUpdate);

