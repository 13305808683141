import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history';
import kopi from '../../../apis/kopi';

import QuestionCard from './QuestionCard';
import UploadWorking from './UploadWorking';

import {Link} from 'react-router-dom';

class ResultSlip extends React.Component {

    state = {student_id: null, name: "", attempts: [], submitted_working: false, session_completed: false, require_working: true, position: 0, question: []}

    componentDidMount = async () => {
        let student_id = window.location.pathname.split("/slip/results/").pop()
        let response = await kopi.get('/api/exit_slip/result_slip', {
            params: {
                student_id
            }
        })
        this.setState({submitted_working: response.data.working_uploaded, question: response.data.question, student_id, name: response.data.name, attempts: response.data.attempts, session_completed: response.data.session_completed, require_working: response.data.require_working})
    }

    initState = async () => {
        let student_id = window.location.pathname.split("/slip/results/").pop()
        let response = await kopi.get('/api/exit_slip/result_slip', {
            params: {
                student_id
            }
        })
        this.setState({submitted_working: response.data.working_uploaded, question: response.data.question, position: response.data.position, student_id, name: response.data.name, attempts: response.data.attempts, session_completed: response.data.session_completed, require_working: response.data.require_working})
    }
    
    renderQuestion = () => {
        if (this.state.attempts.length > 0) {
            return <QuestionCard question={this.state.question} attempts={this.state.attempts} />
        } else {
            return <></>
        }
    }

    submitWorking = () => {
        this.setState({submitted_working: true})
    }
        
    renderUploadWorking = () => {
         return <UploadWorking student_id={this.state.student_id} submitWorking={this.submitWorking}  />
    }

    renderWaiting = () => {
        return <div>
                <p>You can fetch your results after your teacher ends the session.</p>
                <button class="btn btn-block btn-info" onClick={()=>this.initState()}>Fetch Results</button>
            </div>
    }

    renderContent = () => {
        if ((!this.state.session_completed && !this.state.require_working) || (!this.state.session_completed && this.state.submitted_working)) {
            return this.renderWaiting()
        } else {
            return this.state.submitted_working || !this.state.require_working ? <> Score: {this.state.attempts.filter(attempt=>attempt.correct).length}/{this.state.attempts.length} <br/> {this.renderQuestion()} </> : this.renderUploadWorking() 
        }
    }

    renderEncouragement = () => {
        if (this.state.attempts.filter(attempt=>attempt.correct).length/this.state.attempts.length >= 0.5) {
            return <>Well done</>
        } else {
            return <>Good try</>
        }
    }

    renderPosition = () => {
        if (this.state.position == 0) return
        return <div>
            <h5>Hooray! You came in at {this.state.position} place!</h5>
        </div>
    }
    
    render() {
        return <div className="" align="left">
            <div class="lead container" align="center">
                <img style={{height: "120px", marginBottom: "15px"}} class="img img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561025325/Joni%20Assets/jonihappy.png" />
                <h4>{this.renderEncouragement()}, {this.state.name}!</h4>
                {this.renderPosition()}
                {this.renderContent()}
            </div>

        </div>
    }
}




export default ResultSlip;

