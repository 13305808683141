import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';

import Goal from './Goal';
import GoalDetails from './Goal/GoalDetails';

import history from '../../../history'
import { signOut } from '../../../actions';
import { ga } from 'react-ga';


class Goals extends React.Component {

    state = { goals: [], selected_goal: null }

    deletePlan = async () => {
        let response = await kopi.get("/api/study_plans/all", {
            params: { userId: this.props.currentUser }
        })
        this.setState({ goals: response.data, selected_goal: null })
    }

    componentDidMount = () => {
        this.fetchGoals();
    }

    selectGoal = (selected_goal) => {
        this.setState({ selected_goal });
    }

    fetchGoals = async () => {
        let response = await kopi.get("/api/study_plans/all", {
            params: { userId: this.props.currentUser }
        })

        // id: study_plan._id,
        // has_concluded: study_plan.has_concluded,
        // reviewed: study_plan.reviewed,
        // end_date: study_plan.end_date,
        // bare_goal: study_plan.bare_goal,
        // recommended_goal: study_plan.recommended_goal,
        // stretch_goal: study_plan.stretch_goal,
        // topics_left: study_plan.topics_left.map(topic => topic.title),
        // topics_practicing: study_plan.topics_practicing.map(topic => topic.title),
        // study_weeks: study_plan.study_weeks

        this.setState({ goals: response.data })
    }

    renderGoals = () => {
        if (this.state.goals.length === 0 || !this.state.goals.find(goal => !goal.reviewed)) {
            return <div className="padding-top">
                <button onClick={() => { history.push('/createplan') }} className="btn btn-block btn-orange">Create Study Plan</button> <br /><br />
                {this.state.goals.map(goal => {
                    return <Goal selectGoal={this.selectGoal} key={goal.id} goal={goal} />
                })}
            </div>
        } else {
            return this.state.goals.map(goal => {
                return <Goal selectGoal={this.selectGoal} key={goal.id} goal={goal} />
            })
        }
    }

    renderGold = () => {
        let gold_trophy_count = 0;
        this.state.goals.forEach(goal => {
            goal.study_weeks.forEach(study_week => {
                if (study_week.parts_done >= goal.stretch_goal) gold_trophy_count++;
            })
        })
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_gold.png" /><br />
            <strong>x{gold_trophy_count}</strong>
        </div>;
    }

    renderSilver = () => {
        let silver_trophy_count = 0;
        this.state.goals.forEach(goal => {
            goal.study_weeks.forEach(study_week => {
                if (study_week.parts_done >= goal.recommended_goal) silver_trophy_count++;
            })
        })


        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1566396152/Joni%20Assets/trophy_silver-removebg-preview.png" /> <br />
            <strong>x{silver_trophy_count}</strong>
        </div>;
    }

    renderBronze = () => {
        let bronze_trophy_count = 0;
        this.state.goals.forEach(goal => {
            goal.study_weeks.forEach(study_week => {
                if (study_week.parts_done >= goal.bare_goal) bronze_trophy_count++;
            })
        })
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_bronze.png" /><br />
            <strong>x{bronze_trophy_count}</strong>
        </div>;
    }

    render() {
        if (this.state.selected_goal) {
            return <div>
                <br />
                <div class="container"><button onClick={() => this.setState({ selected_goal: null })} className="btn btn-dark btn-block"><i class="fas fa-arrow-left"></i>&nbsp;Back</button></div>
                <br />
                <GoalDetails key={this.state.selected_goal.id} deletePlan={this.deletePlan} goal={this.state.selected_goal} />
            </div>
        } else {
            return <div className="container padding-bottom"><div align="center"><br />
            <h4 className='header-font'>My Study Plans</h4>
            <br />
            </div>
                <div className="row">
                    <div className="col">
                        {this.renderBronze()}
                    </div>
                    <div className="col">
                        {this.renderSilver()}
                    </div>
                    <div className="col">
                        {this.renderGold()}
                    </div>
                </div>
                <br />
                {this.renderGoals()}
            </div>
        }
    }
}



const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { signOut })(Goals);