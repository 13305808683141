import React from 'react';
import kopi from '../../apis/kopi'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../history.js';
import queryString from "query-string";
import { signUp } from '../../actions';

class Activate extends React.Component {

    // url = `https://app.joni.ai/activate?parent_id=${this.props.currentUser}&prototype_id=${this.state.selected_prototype_id}`
    state = { loading: false, name: "", password: "", username: "", secondary: "", parent_id: "", prototype_id: "", error: false, activated: false, account_already_exists: false }

    componentDidMount = async () => {
        this.setState({loading: true})
        var query = queryString.parse(this.props.location.search);
        let response;  
        const { parent_id, prototype_id } = query;
        if (parent_id && prototype_id) {
            response = await kopi.get('/api/auth/activate', {
            params: {
                parent_id,
                prototype_id
            }
            })

            if (response.data.already_exists) {
                this.setState({ account_already_exists: true, activated: true, loading: false });
                return;
            }
    
            if (response.data.prototype) {
                const { name, secondary } = response.data.prototype
                this.setState({ name, secondary, parent_id, prototype_id, activated: true, loading: false });
            }
        } else {
            return history.push('/')
        }
        
        
    }

    onSubmit = async () => {
        this.setState({ loading: true });
        const formValues = { parent_id: this.state.parent_id, prototype_id: this.state.prototype_id, username: this.state.username, password: this.state.password, name: this.state.name, secondary: this.state.secondary }
        let response = await this.props.signUp(formValues)
        this.setState({ loading: false, error: true });
    }

    passValidation = (username) => {
        return /\s/.test(username)
    }

    renderSubmit = () => {
        if (this.state.loading){ return <>
            <br />
            <strong>This might take up to 15 seconds. You will be redirected when Joni is done setting up your account.</strong>
            <button className="btn btn-dark btn-block button-text-small"> Activating Account </button>
        </>} else if (/\s/.test(this.state.username)){
            return <button style={{backgroundColor:'grey', color: 'darkgrey'}} className="btn btn-blue btn-block button-text-small"> Activate Account </button>
        } else {
        return <button onClick={this.onSubmit} className="btn btn-blue btn-block button-text-small"> Activate Account </button>
        }
    }

    render() {

        const renderError = () => {
            if (/\s/.test(this.state.username)){
                return <small style={{color:'red'}}>Usernames with spaces are not allowed!</small>
            }
        }

        if (this.state.loading && !this.state.activated) {
            return <div className="login-centralise">
                <div className="container padding-bottom" align="center">
                Loading...
            </div>
            </div>
        }

        if (!this.state.activated) {
            return <div className="login-centralise">
                <div className="container padding-bottom" align="center">
                    {this.state.error ? <div class="alert alert-danger">{this.state.error}</div> : ""}
                    <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" className="img img-fluid" style={{ maxHeight: "20vh" }} />
                    <br /><br />
                    <br />
                    <h3>Oops, you've yet to start a subscription for this account!</h3>
                </div>
            </div>

        } else if (this.state.account_already_exists) {
            return <div className="login-centralise">
                <div className="container padding-bottom" align="center">
                    {this.state.error ? <div class="alert alert-danger">{this.state.error}</div> : ""}
                    <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" className="img img-fluid" style={{ maxHeight: "20vh" }} />
                    <br /><br />
                    <br />
                    <h3>Wait a minute!</h3> 
                    <h5>This account has already been activated.</h5>
                    <button onClick={() => history.push("/")} class="btn btn-blue btn-block button-text-small">Click here to login</button>
                </div>
            </div>
        }



        return <div className="login-centralise">
            <div className="container padding-bottom" align="center">
                {this.state.error ? <div class="alert alert-danger">This username has been taken!</div> : ""}
                <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" className="img img-fluid" style={{ maxHeight: "20vh" }} />
                <br /><br />
                <br />
                <div class="form-group">
                    <input disabled id="name" value={this.state.name} type="text" className="form-control" placeholder="Name" />
                </div>
                <div class="form-group">
                    <input disabled id="secondary" value={"Secondary " + this.state.secondary} type="text" className="form-control" placeholder="Secondary" />
                </div>
                {renderError()}
                <div class="form-group">
                    <input id="username" onChange={(e) => { this.setState({ username: e.target.value }) }} value={this.state.username} type="text" className="form-control" placeholder="Username" />
                </div>
                <div class="form-group">
                    <input id="password" onChange={(e) => { this.setState({ password: e.target.value }) }} value={this.state.password} type="password" className="form-control" placeholder="Password" />
                </div>
                {this.renderSubmit()}
            </div>
        </div>
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { signUp })(Activate);

