import React from 'react'; 

import kopi from '../../../apis/kopi'

import {connect} from 'react-redux';

import imageCompression from 'browser-image-compression';


import history from '../../../history'
class UploadWorking extends React.Component {

    state = {file: null, info: {}, loading: false, images_uploaded: 0, urls: [], percent: ""};

    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
      }


    // componentDidMount = () => {

    //     function pageon(){return window.location.pathname}
    //     function referrer(){return document.referrer}
    //     function previousSites(){return history.length}
    
    //     function browserName(){return navigator.appCodeName}
    //     function browserEngine(){return navigator.product}
    //     function browserVersion1a(){return navigator.appVersion}
    //     function browserVersion1b(){return navigator.userAgent}
    //     function browserLanguage(){return navigator.language}
    //     function browserOnline(){return navigator.onLine}
    //     function  browserPlatform(){return navigator.platform}
    //     function  javaEnabled(){return navigator.javaEnabled()}
    //     function  dataCookiesEnabled(){return navigator.cookieEnabled}
    //     function  dataCookies1(){return document.cookie}
    //     function  dataCookies2(){return decodeURIComponent(document.cookie.split(";"))}
    //     function  dataStorage(){return localStorage}
    
    //     function sizeScreenW(){return window.screen.width}
    //     function sizeScreenH(){return window.screen.height}
    //     function sizeDocW(){return document.width}
    //     function  sizeDocH(){return document.height}
    //     function  sizeInW(){return window.innerWidth}
    //     function  sizeInH(){return window.innerHeight}
    //     function  sizeAvailW(){return window.screen.availWidth}
    //     function  sizeAvailH(){return window.screen.availHeight}
    //     function  scrColorDepth(){return window.screen.colorDepth}
    //     function  scrPixelDepth(){return window.screen.pixelDepth}
        
    
    //     // function  latitude(){return position.coords.latitude}
    //     // function  longitude(){return position.coords.longitude}
    //     // function  accuracy(){return position.coords.accuracy}
    //     // function  altitude(){return position.coords.altitude}
    //     // function  altitudeAccuracy(){return position.coords.altitudeAccuracy}
    //     // function  heading(){return position.coords.heading}
    //     // function  speed(){return position.coords.speed}
    //     // function  timestamp(){return position.timestamp}
        
    //     let info={
    //         pageOn: pageon(),
    //         referrer: referrer(),
    //         previousSites: previousSites(),
    //         browserName: browserName(),
    //         browserEngine: browserEngine(),
    //         browserVersion1a: browserVersion1a(),
    //         browserVersion1b: browserVersion1b(),
    //         browserLanguage: browserLanguage(),
    //         browserOnline: browserOnline(),
    //         browserPlatform: browserPlatform(),
    //         javaEnabled: javaEnabled(),
    //         dataCookiesEnabled: dataCookiesEnabled(),
    //         dataCookies2: dataCookies2(),
    //         dataCookies1: dataCookies1(),
    //         dataStorage: dataStorage(),
    //         sizeScreenW: sizeScreenW(),
    //         sizeScreenH: sizeScreenH(),
    //         sizeDocW: sizeDocW(),
    //         sizeDocH: sizeDocH(),
    //         sizeInW: sizeInW(),
    //         sizeInH: sizeInH(),
    //         sizeAvailW: sizeAvailW(),
    //         sizeAvailH: sizeAvailH(),
    //         scrColorDepth: scrColorDepth(),
    //         scrPixelDepth: scrPixelDepth(),
    //         // latitude: latitude(),
    //         // longitude: longitude(),
    //         // accuracy:accuracy(),
    //         // altitude:altitude(),
    //         // altitudeAccuracy: altitudeAccuracy(),
    //         // heading:heading(),
    //         // speed:speed(),
    //         // timestamp:timestamp()
    //     };
    //     console.log(info)
    //     this.setState({info})
    // }

      sendWorking = async () => {
        if (this.fileInput.current.files.length == 0) {
            window.alert("You have to upload your workings!")
            return;
        }
        this.setState({loading: true})
        const formData = new FormData();
        var options = {
            maxSizeMB: 1,
        }
        const compressedFile = await imageCompression(this.fileInput.current.files[0], options);
        formData.append('image',compressedFile);
        formData.append('student_id',this.props.student_id);

        const updatePercent = (percentCompleted) => {
            this.setState({percent: percentCompleted})
        }

        // formData.append('info',this.state.info);
        const config = {
            onUploadProgress: (progressEvent)=> {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                updatePercent(percentCompleted);
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };
        kopi.post("/api/exit_slip/upload_working",formData,config)
            .then((response) => {
                this.setState({urls: [...this.state.urls, response.data.Location], loading: false, images_uploaded: this.state.images_uploaded+1});
            }).catch((error) => {
        });
    }

    renderImages = () => {
        return this.state.urls.map(url=><div><img class="img-fluid" src={url} /></div>)
    }

    render(){

        if (this.state.loading){
            return <div align="center">
            <br/>
         <input onChange={this.sendWorking} className="form-control-file col-6" type='file' ref={this.fileInput}  />
         <br/>
         {this.state.images_uploaded} image(s) submitted <br/> <br/>
         <h4>Uploading...</h4> <br/>
         {this.state.percent}%
         <br/><br/>
         { this.state.images_uploaded > 0 ? <button class="btn btn-md btn-warning" onClick={this.props.submitWorking}> Finished Uploading </button> : "" }
         <hr/>
         {this.renderImages()}  
        </div>
        } 

      return <div align="center">
            <br/>
         <input className="form-control-file col-6" type='file' ref={this.fileInput}  />
         <br/>
         {this.state.images_uploaded} image(s) submitted <br/> <br/>
         <button class="btn btn-md btn-dark" onClick={this.sendWorking}> Upload Solution </button>
         <br/><br/>
         { this.state.images_uploaded > 0 ? <button class="btn btn-md btn-warning" onClick={this.props.submitWorking}> Finished Uploading </button> : "" }
         <hr/>
         {this.renderImages()} 
         
      </div>
      
    }

}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(UploadWorking);

