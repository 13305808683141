import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../apis/kopi';
import Footer from '../Footer';

import Question from './Question';


import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

class AttemptedQuestions extends React.Component{
    // type: 0, type: 1
    state = {type: 0, pageNumber: 1, training_set: [], selected_qn: null, Yscroll_position: 0}

    componentDidMount = async () => {
        const response = await kopi.get('/api/library/history', {
            params: {userId: this.props.currentUser, pageNumber: this.state.pageNumber, trainingSetId: this.props.training_set._id}
        });    
        // console.log(response.data)
        this.setState({training_set: response.data})
    }

    componentDidUpdate = () => {
        window.scrollTo(0, this.state.Yscroll_position)
    }

    viewQuestion = (selected_qn) => {
        this.setState({type: 1, selected_qn: selected_qn, Yscroll_position: window.pageYOffset});
    }

    closeQuestion = () => {
        this.setState({type: 0, selected_qn: null});
    }

    renderList = () => {
        let seperator = "\${1,2}";
        let image = <span></span>;
        let header_txt;
        let qn_txt;
        let topics;
        let index;
        if (this.state.training_set.length) {
            return this.state.training_set.map(question => {
                image = <span></span>;
                topics = "";

                question.topics.forEach(function(topic){
                    topics += topic.title + '\n';
                });

                if (question.header_img !== " "){
                    image= <span><img src={question.header_img} className="img-fluid" /><br/></span>
                } 
                if (question.header_txt && question.header_txt.startsWith("\\begin")){
                    header_txt = <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={question.header_txt} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>    
                } else {
                    header_txt = <div><InlineTex texSeperator={seperator} texContent={question.header_txt} /></div>
                }
                if (question.qn_txt && question.qn_txt.startsWith("\\begin")){
                    // console.log("identified as begin")
                    qn_txt = <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={question.qn_txt} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>    
                } else {
                    qn_txt = <div><InlineTex texSeperator={seperator} texContent={question.qn_txt} /></div>
                }

                function nextChar(c, index) {
                    return String.fromCharCode(c.charCodeAt(0) + index);
                }

                let relevant_attempt = this.props.training_set.attempts.find(attempt=>attempt.question == question._id)

                let is_correct = relevant_attempt && relevant_attempt.correct ? <small>✅</small> : <small></small>
                is_correct = relevant_attempt && !relevant_attempt.correct ? <small>❌</small> : is_correct

                let res = relevant_attempt ? <span style={{color: 'pink'}}>{relevant_attempt.student_ans}</span> : ""
                if (!question.prev_qn){
                    if (question.nxt_qn){
                        index=0;
                        return (
                            <div key={question._id}>
                                <small className="topic-subtitle">{topics}</small>
                                {header_txt}<br/>
                                {image}
                                ({nextChar("a", index)}) {is_correct}
                                {qn_txt}
                            </div>
                        ); 
                    } else {
                        return (
                            <div key={question._id}>
                                <small className="topic-subtitle">{topics}</small>
                                {header_txt}<br/>
                                {image}
                                {qn_txt} <br/>
                                {is_correct} <br/>  <br/>
                                <button onClick={() => this.viewQuestion(question)} className="btn btn-block btn-orange">View Solution</button>
                                <hr/>
                            </div>
                        ); 
                    }
                } else {
                    if (question.nxt_qn) {
                        index++;
                        return (
                            <div key={question._id}>
                            <span>({nextChar("a", index)})</span> {is_correct}
                                {image}
                                {qn_txt}
                            </div>
                        ); 
                    } else {
                        index++;
                        return (
                            <div key={question._id}>
                               <span>({nextChar("a", index)})</span> {is_correct}
                                {image}
                                {qn_txt} <br/>
                                <button onClick={() => this.viewQuestion(question)} className="btn btn-block btn-orange">View Solution</button>
                                <hr/>
                            </div>
                        ); 
                    }
                }
            });
        } else {
            return <span></span>
        }
    }

    render(){
        if (this.state.type === 0){
            return <div>
                {this.renderList()}
                <br/><br/><br/><br/><br/><br/>
            </div>;
        } else {
            return <div>
                <Question attempts={this.props.training_set.attempts} closeQuestion={this.closeQuestion} question={this.state.selected_qn} />
                <br/><br/><br/><br/><br/><br/>
            </div>;
        }
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(AttemptedQuestions);