import React from 'react'; 


import QuestionHeader from './QuestionHeader/index.js';
import QuestionPart from './QuestionPart/index.js';
import AnswerField from './AnswerField/index.js';
import SolutionField from './SolutionField/index.js';
import SelectToggle from './SelectToggle/index.js';
import SimilarQuestionsToggle from './SelectToggle/SimilarQuestionsToggle.js';

import {connect} from 'react-redux';

class QuestionCard extends React.Component {

    state = {selected_part: this.props.question[0]};

    selectPart = (selected_part) => { 
      this.setState({selected_part: selected_part});
    }

    render(){

      return <div style={{height: "60vh"}} className="card question-card shadow-sm p-3 mb-5 bg-white rounded">
          <div className="card-body question-card-content dark-blue-text" style={{overflowY: "auto", fontSize: "1em"}}>
              <div class="top-bar-parts">
                <div align="center" style={{width: "100%"}}>
                  <SelectToggle attempts={this.props.attempts} selectPart={this.selectPart} selected_part={this.state.selected_part} all_parts={this.props.question} />
                </div>
              </div>
              <div align="left">
                <QuestionHeader selected_part={this.props.question[0]} all_parts={this.props.question} />
                <QuestionPart selected_part={this.state.selected_part} />
              </div>
              <SolutionField attempts={this.props.attempts} selected_part={this.state.selected_part} />
              <br/>
          </div>
      </div>
      
    }

}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(QuestionCard);

