import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';
import Footer from '../../Footer';
import AttemptedQuestions from './AttemptedQuestions';

import SingleQuestion from './SingleQuestion';
import MultiPartQuestion from './MultiPartQuestion';
// import StudentAttempt from './StudentAttempt';


const Question = props => {

    if (props.question.nxt_qn || props.question.prev_qn){
        return <span>
        <button className="btn btn-block btn-info" onClick={props.closeQuestion}>Back</button>
        <br/>
        <MultiPartQuestion attempts={props.attempts} seed_question={props.question} />
        </span>
    } else {
        return <span>
        <button className="btn btn-block btn-info" onClick={props.closeQuestion}>Back</button>
        <br/>
        <SingleQuestion attempt={props.attempts.find(attempt=>attempt.question==props.question._id)} question={props.question} />
        </span>
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Question);