import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { signOut } from '../actions';

import history from '../history';

class Footer extends React.Component{

  dashboard = () => {
    if (window.location.pathname === '/dashboard' || window.location.pathname === '/daily'){
      return <Link className="nav-link  nav-active fas fa-home" to="/dashboard"></Link>
    } else {
      return <Link className="nav-link fas fa-home" to="/dashboard"></Link>
    }
  }

  library = () => {
    if (window.location.pathname === '/library'){
       return <Link className="nav-link  nav-active fas fa-book-open" to="/library"></Link>
    } else {
      return <Link className="nav-link fas fa-book-open" to="/library"></Link>
    }
  }

  brian = () => {
    if (window.location.pathname === '/brain'){
      return <Link className="nav-link  nav-active-dark fas fa-brain" to="/brain"></Link>
    } else {
      return <Link className="nav-link fas fa-brain" to="/brain"></Link>
    }
  }

  settings = () => {
    if (window.location.pathname === '/settings'){
      return <Link className="nav-link nav-active fas fa-user-circle" to="/settings"></Link>
    } else {
      return <Link className="nav-link fas fa-user-circle" to="/settings"></Link>
    }
  }

  render(){
    if (this.props.isSignedIn && window.location.pathname === '/brain'){
        return <nav className="navbar fixed-bottom navbar-light navbar-padding-bottom-dark bg-dark">
        {this.dashboard()}
        {this.library()}
        {this.brian()}
        {this.settings()}
      </nav>
    } else if (this.props.isSignedIn){
      return <nav className="navbar fixed-bottom navbar-light navbar-padding-bottom bg-light">
      {this.dashboard()}
      {this.library()}
      {this.brian()}
      {this.settings()}
    </nav> 
    } else {
        return <>
        </>
    }
  }
}

const mapStateToProps = state => {
  return {
      name: state.auth.name,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, {signOut})(Footer);