import React from 'react';

const PartSelector = props => {

        const nextChar = (c, index) => {
            return String.fromCharCode(c.charCodeAt(0) + index);
        }
        
        let navigation = props.all_parts.map((qn, index) => {
            let attempt = props.attempts.find(attempt=> attempt.question._id == qn.id)
            if (qn.id === props.selected_part.id) {
                if (attempt && attempt.correct) {
                    return <li className="nav-link nav-item" key={index + 1} onClick={() => props.selectPart(qn)}>
                    <button style={{background: "#388E3C"}} className="btn btn-part btn-sm btn-xs"><strong style={{color: 'white'}}>({nextChar('a',index)})</strong></button>
                </li>
                } else {
                    return <li className="nav-link nav-item" key={index + 1} onClick={() => props.selectPart(qn)}>
                    <button style={{background: "#AD1457"}}  className="btn btn-danger btn-sm btn-xs"><strong style={{color: 'white'}}>({nextChar('a',index)})</strong></button>
                </li>
                }
            } else {
                if (attempt && attempt.correct) {
                return <li className="nav-link nav-item" key={index + 1} onClick={() => props.selectPart(qn)}>
                    <button style={{background: "#7bed9f"}} className="btn btn-light btn-sm btn-xs">
                        <strong style={{color: 'white'}}>({nextChar('a',index)})</strong>
                    </button>
                </li>
                } else {
                    return <li className="nav-link nav-item" key={index + 1} onClick={() => props.selectPart(qn)}>
                    <button style={{background: "#F06292"}} className="btn btn-warning btn-sm btn-xs">
                        <strong style={{color: 'white'}}>({nextChar('a',index)})</strong>
                    </button>
                </li>  
                }
            }
        })

        return <ul className="nav justify-content-center">
                    {navigation}
                </ul>
         
    }

    export default PartSelector;
