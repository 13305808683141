import React from 'react'; 
import {connect} from 'react-redux';

import {InlineTex} from 'react-tex';

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

class MasteryUpdate extends React.Component {

    state = {mastery_bar: 0}

    componentDidMount = () => {

        if(this.props.updatedMastery.difference<=0){
            let mastery_bar;
            if (this.props.updatedMastery.difference < 0 && this.props.updatedMastery.prev_rank !== this.props.updatedMastery.current_rank){ // downrank
                mastery_bar = 50;
            } else if (this.props.updatedMastery.skill_after < 30){ // downrank
                mastery_bar = 50;
            } else {
                if (60<=this.props.updatedMastery.skill_after<=89){
                   mastery_bar = 50 + (15-this.props.updatedMastery.till_next)/15*50;
                }else{
                    mastery_bar = 50 + (10-this.props.updatedMastery.till_next)/10*50;
                }
            }
            this.setState({mastery_bar: mastery_bar});
            } else if (this.props.updatedMastery.difference>0){
            setTimeout(() => { 
            let mastery_bar;
            if (this.props.updatedMastery.difference < 0 && this.props.updatedMastery.prev_rank !== this.props.updatedMastery.current_rank){ // downrank
                mastery_bar = 50;
            } else if (this.props.updatedMastery.skill_after < 30){ // downrank
                mastery_bar = 50;
            } else {
                if (60<=this.props.updatedMastery.skill_after<=89){
                   mastery_bar = 50 + (15-this.props.updatedMastery.till_next)/15*50 - 8;
                }else{
                    mastery_bar = 50 + (10-this.props.updatedMastery.till_next)/10*50 - 8;
                }
            }
                this.setState({mastery_bar: mastery_bar});
            },
            200);
        }
    }

    componentWillUnmount = () => {
        this.setState({mastery_skill: 0});
    }

    formatNumber (number) {
        if(number % 1 == 0){
            return number
        } else {
            return number.toFixed(2)
        }
    }

    render(){
        let seperator = "\${1,2}";

        let skill_difference, skill_difference_text;

        if (this.props.updatedMastery.difference < 0) {
            skill_difference = <h2 className="no-bottom-margin">{this.formatNumber(this.props.updatedMastery.difference)}</h2>
        } else {
            skill_difference = <h2 className="no-bottom-margin"> +{this.formatNumber(this.props.updatedMastery.difference)}</h2>
        }
         
        skill_difference_text = <span>{this.formatNumber(this.props.updatedMastery.till_next)} points to<br/>{this.props.updatedMastery.nxt_rank}</span>
        
        if (this.props.updatedMastery.prev_rank !== this.props.updatedMastery.current_rank){
            skill_difference = <span>
                    <img src={this.props.updatedMastery.prev_rank_img} className="figure-img img-fluid rank-element" />
                        <i className="fas fa-arrow-right rank-element"></i>
                    <img src={this.props.updatedMastery.current_rank_img} className="figure-img img-fluid rank-element" />
                </span>
            if (this.props.updatedMastery.skill_after < this.props.updatedMastery.skill_before) {
                skill_difference_text = <span>
                    <i class="fas fa-angle-double-down down-rank"></i>&nbsp;{this.props.updatedMastery.current_rank}
                </span>
            } else {
                skill_difference_text = <span>
                    <i class="fas fa-angle-double-up up-rank"></i>&nbsp;{this.props.updatedMastery.current_rank}
                </span>
            }
        }
        return <div>
            <div className="pull-to-bottom row">
                    <div align="left" className="col-7 no-padding">
                        <div style={{paddingBottom: "0.7em"}}>
                        <span style={{height: "3.05em", overflow: "auto"}} className="learning-objective"><InlineTex texSeperator={seperator} texContent={this.props.updatedMastery.objective} /> <br/> <br/> <br/> </span>
                        </div>
            <ProgressBar
        percent={this.state.mastery_bar}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        >
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width="30"
              src={this.props.updatedMastery.before_current_rank_img}
            />
          )}
        </Step>
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width="30"
              src={this.props.updatedMastery.current_rank_img}            
            />
          )}
        </Step>
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            <img
              style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
              width="30"
              src={this.props.updatedMastery.nxt_rank_img} 
            />
          )}
        </Step>
        </ProgressBar>
        </div>
                    <div align="right" className="col-5 no-padding">
                        {skill_difference}
                        <span className="learning-objective">{skill_difference_text}</span>
                    </div>
                    <br/>
                </div>
        </div>;
    }

   
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(MasteryUpdate);

