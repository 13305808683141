import React from 'react';
import ReactDOM from 'react-dom';

class YouTube extends React.Component {

    state = { loading: true }

    myRef = React.createRef();

    componentDidMount = () => {
        this.myRef.current.addEventListener('load', this.hiderSpinner);
        // this.refs.iframe.getDOMNode().addEventListener('load', this.hiderSpinner);
    }

    hiderSpinner = () => {
        this.setState({ loading: false });
    }

    render() {
        return <div align="center"><span style={this.props.dark_mode ? {color:'#A0A0A0'} : {}}>{this.state.loading ? "Loading" : ""}</span><iframe title='YouTube' ref={this.myRef} width="100%" height="auto" src={this.props.src} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
    }
}

export default YouTube;