import React from 'react';
import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history'; 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class SelectDate extends React.Component {

    componentDidMount = () => {
        document.getElementsByTagName("input")[0].setAttribute("readonly", "readonly");
    }
    
     renderDatePicker = () => {
            let num_of_days_from_now = Math.ceil((new Date(this.props.endDate).getTime()-new Date(Date.now()).getTime())/(3600000 * 24))
            let earliest_date =new Date(Date.now());
            earliest_date = earliest_date.setDate(earliest_date.getDate() + 30);

        return ( 
        <>
        
        <div className="container dark-txt padding-top">
        <i className="fas fa-chevron-left fa-2x cursor-pointer" aria-hidden="true" style={{color:"#354B80"}} onClick={()=>{history.push('/dashboard')}}></i>

        <h3>Let <strong>Joni</strong> guide you down the path to success!</h3>

        <hr/> 

        <p className="lead">
            Begin with the end in mind. Start by choosing an end date for your forward study plan.
        </p>
        <p className="lead">
            This could be the date of a quiz, an exam or anything else.
        </p>
        
        <DatePicker className="btn btn-orange btn-block"
            selected={this.props.endDate}
            onChange={this.props.selectDate}
            minDate = {earliest_date}
            placeholderText={`Today's Date: ${new Date(Date.now()).getDate() + '/' + (new Date(Date.now()).getMonth() + 1)}`}
            withPortal
        /> 
        <small>This date must be at least be 30 days from today.</small>

        <br/>
        <br/>

        { this.props.endDate ?
            <>
            <div className="alert alert-info">
                <h3><small>That's</small> {num_of_days_from_now} days <small>from now!</small></h3>
            </div></>
            : <></>
        }
 
        </div>
        </>
        )
    }

   renderSubmit =() => {
        return <nav class="navbar fixed-bottom navbar-light bg-white">
        <button onClick={this.props.onSubmit} className="btn btn-block btn-blue">Continue</button>
      </nav>
    }

    render(){
        return <>
        {this.renderDatePicker()}
        {this.renderSubmit()}
        </>
    }
    
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(SelectDate);


//
// 