

import React from 'react'; 

import SkyLight from 'react-skylight';

import kopi from '../../../../apis/kopi';

import {connect} from 'react-redux';

import Dock from 'react-dock';

import ChangeDifficulty from './ChangeDifficulty';

class AskEmotion extends React.Component {

    state={selected_feedback: null, change_difficulty_prompt: false, loading: false, prompt_text: "", feedbacks: [], interactive_prompt_id: null}

    componentDidMount = async () => {
        let response = await kopi.get('/api/interactions/get_interactive_prompt', {
            params: {prompt_type: "Emotion"}
        });
        if (!response) return;
        // console.log(response.data);

        this.setState({prompt_text: response.data.prompt_text, feedbacks: response.data.feedbacks, interactive_prompt_id: response.data.id});
    }

    renderTextPrompt = () => <h5> {this.state.prompt_text}</h5>

    renderEmotionInputPanel = () => {

        const renderJoniEmoticon = (emoji) => {
            switch(emoji) {
                case "😁" :
                    return <img style={{height: "10vh"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/emotion_happy.png" />
                
                case "😢" :
                    return <img style={{height: "10vh"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/emotion_sad.png" />
                
                case "😲" :
                    return <img style={{height: "10vh"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/emotion_anger.png" />

                case "🤢": 
                    return <img style={{height: "10vh"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/emotion_disgust.png" />
            }
        }

        if (this.state.change_difficulty_prompt){
            return <h1>{this.state.selected_feedback}</h1>
        } else {
            return <div className="">
            { this.state.feedbacks.map(feedback=>{
                return <div className="cursor-pointer" onClick={()=>this.selectEmotionalResponse(feedback)}>
                    {renderJoniEmoticon(feedback)}
                    <br/><br/>
                </div>
            }) }
            </div>
        }
    }    

    selectEmotionalResponse = async (selected_feedback) => {

            // console.log(selected_feedback);
            
            let new_mood = await kopi.post('/api/interactions/submit_interaction', {
                userId: this.props.currentUser,
                interaction_type: "Emotion",
                interactive_prompt_id: this.state.interactive_prompt_id,
                selected_feedback
            });
    
            if (this.props.correct_answers % 6 === 0 && this.props.answer_feedback.is_correct){
                this.setState({selected_feedback, change_difficulty_prompt: true, loading: false});
            } else if (this.props.wrong_answers % 3 === 0 && !this.props.answer_feedback.is_correct){
                if (new_mood.data.score < 50){
                    this.setState({selected_feedback, change_difficulty_prompt: true, loading: false});
                } else {
                    this.endInteraction();
                }
            } else {
                this.endInteraction();
            }
    } 


    endInteraction = () => {
        this.props.endInteraction();
    }

    render(){
        return <div className="dark-blue-text">
            {this.renderTextPrompt()}
            <hr/>
            {this.renderEmotionInputPanel()}
            {this.state.change_difficulty_prompt ? <ChangeDifficulty endInteraction={this.endInteraction} increase_difficulty={this.props.answer_feedback.is_correct} /> : "" }
            </div>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(AskEmotion);