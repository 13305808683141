import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { updateSubscriptionStatus } from '../../actions';
import kopi from '../../apis/kopi';
import history from '../../history';
import Footer from '../Footer';
import Switch from "react-switch";
import Ticker from '../StudyPlan/Ticker';
import { validateVersion } from '../../actions';


class Dashboard extends React.Component {

    state = { mood: "null", mode: "Normal", mode_checked: false, practice_status: "Not Started" };

    componentDidMount = async () => {

        if (!this.props.isSignedIn || !this.props.currentUser) {
            return history.push('/')
        } else {

            let subscription_tier = await kopi.get('/api/joni/subscription_tier', {
                params: { userId: this.props.currentUser }
            })

            this.props.updateSubscriptionStatus({
                subscription_tier: subscription_tier.data.subscription_tier
            })
            if (this.props.isSignedIn && this.props.currentUser && this.props.tier == 0 && Math.floor((new Date(Date.now()).getTime() - new Date(this.props.dateJoined).getTime()) / (3600000 * 24)) >= 7){
                return history.push('/reactivate')
            } else if (this.props.isSignedIn && this.props.currentUser && this.props.subscription_tier === 'No Active Subscription'){
                return history.push('/reactivate')
            } else {
                let dailyMood = await kopi.get('/api/joni/has_daily_mood', {
                    params: { userId: this.props.currentUser }
                })
            
                if (!dailyMood.data[0]) {
                    return history.push("/daily");
                } else {
                    this.setState({ mood: dailyMood.data[0].score, practice_status: dailyMood.data[1] })
                }

        } 
        }  
    }

    toggleEasyMode = () => {
        if (this.state.mode === "Normal") {
            this.setState({ mode: "Easy", mode_checked: true });
        } else {
            this.setState({ mode: "Normal", mode_checked: false });
        }
    }

    toggleHardMode = () => {
        if (this.state.mode === "Normal") {
            this.setState({ mode: "Hard", mode_checked: true });
        } else {
            this.setState({ mode: "Normal", mode_checked: false });
        }
    }

    renderMode = () => {
        if (this.state.mode === "Easy") {
            return <span>Easy Mode</span>
        } else if (this.state.mode === "Hard") {
            return <span>Hard Mode</span>
        } else {
            return <span>Normal Mode</span>
        }
    }

    renderImage() {
        if (this.state.mood <= -50) {
            return "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006990/Joni%20Assets/answer_wrong.png"
        } else if (this.state.mood >= 50) {
            return "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png"
        } else {
            return "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540509/Joni%20Assets/joni-cute.png"
        }
    }


    renderMessage() {
        if (this.state.mood <= -50) {
            return "I am sorry you are feeling this way. Shall we still do some practice?"
        } else if (this.state.mood >= 50) {
            return "Awesome! Let's get started, shall we?"
        } else {
            return "Alright, let's do some practices and improve your day!"
        }
    }

    startTraining = () => {
        history.push("/training");
    }

    renderContent() {
        if (this.state.mood === "null") {
            return <div className="container padding-top" align="center">
                <br />
                <img className="img-fluid big-joni" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540509/Joni%20Assets/joni-cute.png" />
            </div>
        } else {
            if (this.state.mood <= -50) {  // dude's sad
                return <div className="container padding-top" align="center">
                    {/* <div className="row"> */}
                    <br />
                    {/* <div className="col-md-4"> */}
                    <img className="img-fluid big-joni" src={this.renderImage()} />
                    <br />
                    <p className='lead'>{this.renderMessage()}</p>
                    <br />

                    <span onClick={() => history.push(
                        {
                            pathname: '/training',
                            state: { mode: this.state.mode }
                        })} className="btn btn-lg btn-blue btn-rounded"> {this.state.practice_status === 'Not Started' ? 'Start Now' : 'Resume Session'}
                    </span>
                    <br />
                    <label>
                        <small>{this.renderMode()}</small><br />
                        <Switch 
                            height={20} 
                            onColor={"#FFD472"} 
                            uncheckedIcon={false} 
                            checkedIcon={false} 
                            onChange={this.toggleEasyMode} 
                            checked={this.state.mode_checked} 
                        />

                    </label>

                    {/* </div> */}
                    {/* <div className="col-md-8"> */}

                    {/* </div> */}
                    <br /><br /><br /><br /><br />
                    {/* </div> */}
                    <Footer />
                </div>
            } else if (this.state.mood >= 50) {
                return <div className="container padding-top" align="center">
                    {/* <div className="row"> */}
                    <br />
                    {/* <div className="col-md-4"> */}
                    <img className="img-fluid big-joni" src={this.renderImage()} />
                    <br />
                    <p className='lead'>{this.renderMessage()}</p>
                    <br />

                    <span onClick={() => history.push(
                        {
                            pathname: '/training',
                            state: { mode: this.state.mode }
                        })} className="btn btn-lg btn-blue btn-rounded"> {this.state.practice_status === 'Not Started' ? 'Start Now' : 'Resume Session'}
                    </span>
                    <br />
                    <label>
                        <small>{this.renderMode()}</small><br />
                        <Switch height={20} onColor={"#FFD472"} uncheckedIcon={false} checkedIcon={false} onChange={this.toggleHardMode} checked={this.state.mode_checked} />
                    </label>
                    {/* </div> */}
                    {/* <div className="col-md-8"> */}

                    {/* </div> */}
                    <br /><br /><br /><br /><br />
                    {/* </div> */}
                    <Footer />
                </div>
            } else {
                return <div className="container padding-top" align="center">
                    <br />
                    <img className="img-fluid big-joni" src={this.renderImage()} />
                    <br />
                    <p className='lead'>{this.renderMessage()}</p>
                    <br />
                    <span onClick={() => history.push(
                        {
                            pathname: '/training',
                            state: { mode: this.state.mode }
                        })} className="btn btn-lg btn-blue btn-rounded"> {this.state.practice_status === 'Not Started' ? 'Start Now' : 'Resume Session'}
                    </span> <br />
                    <br /><br /><br /><br /><br />
                    <Footer />
                </div>
            }
        }
    }
    render() {
        return <>
            <Ticker key={1} attempted_since_refresh={0} />
            {this.renderContent()}
        </>
    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        name: state.auth.name,
        isSignedIn: state.auth.isSignedIn,
        tier: state.auth.tier,
        dateJoined: state.auth.dateJoined,
        subscription_tier: state.auth.subscription_tier,
        // version: state.version.version
    };
};

export default connect(mapStateToProps, {updateSubscriptionStatus   })(Dashboard);