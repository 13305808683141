import React from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import Settings from './Settings'
import { NONAME } from 'dns';

class OnboardSettings extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      run: true,
      steps: [
        {
          content: "Welcome to the Profile settings page.",
          placement: 'center',
          target: 'body',
          disableOverlayClose: true,
          hideCloseButton: true,
        },
        {
          content: "Click 'Config'.",
          placement: 'to  p',
          target: '.nav-item:nth-child(3)',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: true,
          spotlightClicks: true,
        },
        {
          content: "Select Indicate Learnt Topics",
          target: '.btn-dark',
          placement: 'top',
          disableOverlayClose: true,
          hideCloseButton: true,
          spotlightClicks: true,
          hideFooter: true,
          hideBackButton: true,
        },  
        {
          content: "Select the topics that you have already learnt in school. This will help us give you more suitable questions.",
          target: 'body',
          placement: 'center',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
          
        },
        {
          content: "",
          target: 'body',
          placement: 'center',
          hideCloseButton: true,
          hideBackButton: true,
          disableOverlay: true,
          hideFooter: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          styles:{
            options:{
              backgroundColor: "none",
              beaconSize:0,
              zIndex: -1,
            }
          },
        },
        {
          content: "Awesome! Now we just need you to select the topics you're interested in learning using our app. Last step, we promise!",
          target: 'body',
          placement: 'center',
          hideCloseButton: true,
          hideBackButton: true,
          disableOverlayClose: true,
            
        },
        
        {
          content: "Let's go back to the first Topics page.",
          target: '.nav-item:first-child',
          placement: 'top',
          hideCloseButton: true,
          hideBackButton: true,
          hideFooter: true,
          spotlightClicks: true,
          disableOverlayClose: true,
        },
        {
          content: "Select the topics that you want to start with right now, and press Update once you're done.",
          target: 'body',
          placement: 'center',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
          
        },
        {
          content: "",
          target: 'body',
          placement: 'center',
          hideCloseButton: true,
          hideBackButton: true,
          disableOverlay: true,
          hideFooter: true,
          spotlightClicks: true,
          disableOverlayClose: true,
          styles:{
            options:{
              backgroundColor: "none",
              beaconSize:0,
              zIndex: -1,
            }
          }
        },
        {
          title: "Great job!",
          content: "And we're done! It's time to embark on your journey, my dear disciple!",
          target: 'body',
          placement: 'center',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
          locale: {last:"Let's go!"}
        }
      ],
      stepIndex: 0,
      atConfig: false,
      learntTopics: false,
      contTour: false,
      backTopics: false,
      atTopics: false,
    };
  }

  toggleConfig=()=>{
    const { run, atConfig, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 1 ? false : run,
      atConfig: !atConfig,
      stepIndex: stepIndex === 1 ? 2 : stepIndex,
    });
  }

  learningTopics=()=>{
    const { run, learntTopics, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 2 ? false : run,
      learntTopics: !learntTopics,
      stepIndex: stepIndex === 2 ? 3 : stepIndex,
    });
  }

  continueTour=()=>{
    const { run, contTour, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 4 ? false : run,
      contTour: !contTour,
      stepIndex: stepIndex === 4 ? 5 : stepIndex,
    });
  }

  goTopics=()=>{
    const { run, atTopics, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 6 ? false : run,
      atTopics: !atTopics,
      stepIndex: stepIndex === 6 ? 7 : stepIndex,
    });
  }

  toggleTopics=()=>{
    const { run, backTopics, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 8 ? false : run,
      backTopics: !backTopics,
      stepIndex: stepIndex === 8 ? 9 : stepIndex,
    });
  }


  handleJoyrideCallback = data => {
    const { atConfig, learntTopics, contTour, atTopics, backTopics } = this.state;
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({ run: false });
      this.props.changeParent(4);
    }
    else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (atConfig && index===1){
        setTimeout(() => {
          this.setState({ run: true });
        }, 400);
      }
      else if (atConfig && index===2) {
        this.setState({
          run: false,
          atConfig: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else if(learntTopics && index===2){
        setTimeout(() => {
          this.setState({ run: true });
        }, 400);
      }else if (learntTopics && index===3) {
        this.setState({
          run: false,
          learntTopics: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else if(contTour && index===4){
        setTimeout(() => {
          this.setState({ run: true });
        }, 400);
      }else if (contTour && index===5) {
        this.setState({
          run: false,
          contTour: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else if(atTopics && index===6){
        setTimeout(() => {
          this.setState({ run: true });
        }, 400);
      }else if (atTopics && index===7) {
        this.setState({
          run: false,
          atTopics: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else if(backTopics && index===8){
        setTimeout(() => {
          this.setState({ run: true });
        }, 400);
      }else if (backTopics && index===9) {
        this.setState({
          run: false,
          backTopics: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else{
        this.setState({
          atConfig: false,
          learntTopics: false,
          stepIndex,
        });
      }

      // console.log(this.state.stepIndex)
    }
    console.groupCollapsed(type);
    // console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };


  renderBackground=()=>{
      return <Settings goTopics={this.goTopics} toggleTopics={this.toggleTopics} toggleConfig={this.toggleConfig} learningTopics={this.learningTopics} continueTour={this.continueTour} />
  }
  
  render () {
    const { run, steps, stepIndex } = this.state;
    // const { breakpoint } = this.props;
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          steps={steps}
          continuous
          scrollToFirstStep
          showProgress = {false}
          showSkipButton={false}
          stepIndex={stepIndex}
          run={true}
          styles={{
            options: {
              primaryColor: '#354B80',
              zIndex: 99999999999999,
            }
          }}
        />
      
      {this.renderBackground()}
      </div>
    );
  }
}

export default OnboardSettings;