import React from 'react';
import {connect} from 'react-redux';
import SkyLight from 'react-skylight';
// import {Link} from 'react-router-dom';


class Timer extends React.Component {

    state = {seconds_elapsed: 0}
    timer;

    componentDidMount = () => {
        this.timer = setInterval(()=>{ 
            this.setState({seconds_elapsed: this.state.seconds_elapsed+1})
        }, 
        1000);
    }

    componentWillUnmount = () => {
         clearInterval(this.timer);
    }

    doSomething = () => {
        switch(this.state.seconds_elapsed){
            case 15: 
                this.props.completeCooldown();
                break;
            case 420:
                this.props.showBenevolence();
                this.motivate.show();
                break;
            default: 
        }
        return this.state.seconds_elapsed
    }

    renderCooldownButton = () => {
        if(this.state.seconds_elapsed <= 15 && this.props.question_type == "self"){
            return  <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                        <button onClick={()=>{}} className="btn btn-block btn-black btn-not-rounded">Attempt in {15-this.state.seconds_elapsed} seconds</button>
                    </nav>
        } else {
            return <></>
        }
    }

    showMotivation = () => {
        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return  <SkyLight dialogStyles={style} hideOnOverlayClicked ref={ref => this.motivate = ref} >
         <div className="container" align="center">
            <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540509/Joni%20Assets/joni-cute.png" alt="joni reminder" style={{ maxHeight: "400px", maxWidth: "200px", marginBottom: "10px"}}/> <br/>
            <b className='dark-blue-text'>Don't give up,<br/> my young disciple!</b>    
        </div>
      </SkyLight>

    }


    render = () => {
        if (this.props.no_more_questions || this.props.daily_limit_hit) return <></>
        this.doSomething()
        
            return <>
            {this.renderCooldownButton()}
            {this.showMotivation()}
        </>
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(Timer);

 