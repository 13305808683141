import React from 'react'; 
import kopi from '../../../../apis/kopi';
import {connect} from 'react-redux';


class PauseMenu extends React.Component {

    state = {loading: false, reporting_question: false, problem: "", report_text: ""}

    onEndSession = () => {
        kopi.put('/api/joni/update_onboarding', {
            data: {user_id: this.props.currentUser}
        })
        this.props.changeParent(2);
    }

    render() {
            if (this.state.loading){
                return <div className="lead">
                        <div align="center"> 
                            <div className="spinner-border text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                            <hr/>
                            <div align="center">
                                <button onClick={this.onEndSession} className="btn btn-block btn-danger">End Session</button>
                            </div>
                        </div>
            } else {
                    let session_timing;
                    let session_duration = this.props.time_elapsed;
                    let session_h = parseInt(session_duration/60);
                    let session_min = parseInt(session_duration%60);
                    if (session_h >= 1) {
                        session_timing = <span>{session_h}h{session_min}min</span>
                    } else {
                        session_timing = <span>{session_min}min</span>
                    }
        
                    return <div className="lead">
                                Correct: {this.props.correct_answers} <br/>
                                Wrong: {this.props.wrong_answers} <br/>
                                Duration: {session_timing} <br/> 
                                <hr/>
                                <div align="center">
                {this.props.no_more_questions ? "" : <button onClick={()=>this.props.pauseSession(false)} className="btn btn-block btn-info">Resume Session</button> }
                                    <button onClick={this.onEndSession} className="btn btn-block btn-danger">End Session</button>
                                </div>
                                <br/>
                                {this.props.no_more_questions ? "" : <div className="report-question" align="center">
                                <small  >Report this question</small>
                                </div>}
                            </div>
                }
            
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
  };
  
  export default connect(mapStateToProps, {})(PauseMenu);
  
  
  
