import React from 'react'; 
import {connect} from 'react-redux';
import kopi from '../../../apis/kopi';
import history from '../../../history'; 
import Switch from 'react-switch';

class PauseMenu extends React.Component {

    state = {loading: false, reporting_question: false, problem: "", report_text: ""}

    onEndSession = async () => {
        this.setState({loading: true})
        await kopi.put('/api/training_sets/end', {
            userId: this.props.currentUser
        });
        history.push("/dashboard");
    }

    reportQuestion = (toggle) => {
        this.setState({reporting_question: toggle, problem: ""})
    }

    gotProblem = (issue) => {
        this.setState({problem: issue});
    }

    renderProblem = ()=>{
        if (this.state.reporting_question) {
            if (this.state.problem !== "") {
                if (this.state.problem === "Others"){
                    return <div align="center">
                    <p className=""><b>{this.state.problem}</b></p>
                    <textarea placeholder="Type your concerns here." className="textarea-grey" value={this.state.report_text} onChange={e=>{this.setState({report_text: e.target.value})}} />
                    <br/><br/>
                    <button onClick={()=>{
                        kopi.post("/api/authors/flag/student-flags-question", {
                            qnId: this.props.current_part.id,
                            userId: this.props.currentUser,
                            subject: this.state.problem,
                            details: this.state.report_text
                        })
                        this.setState({reporting_question: false, report_text: ""})
                    }} className="btn btn-sm btn-dark">Send Report</button>
                    <br/>                    <br/>
                    <div className="report-question" align="center">
                        <small className='cursor-pointer' onClick={()=>this.reportQuestion(false)}><u>I changed my mind</u></small>
                    </div>
                </div>
                } else {
                    return <div align="center">
                    <p className=""><b>{this.state.problem}</b></p>
                    <button onClick={()=>{
                        kopi.post("/api/authors/flag/student-flags-question", {
                            qnId: this.props.current_part.id,
                            userId: this.props.currentUser,
                            subject: this.state.problem,
                            details: this.state.report_text
                        })
                        this.setState({reporting_question: false, report_text: ""})
                    }} className="btn btn-sm btn-dark">Send Report</button>
                    <br/>                    <br/>
                    <div className="report-question" align="center">
                        <small className='cursor-pointer' onClick={()=>this.reportQuestion(false)}><u>I changed my mind</u></small>
                    </div>
                </div>
                }
            } else {
                return <div align="">
                    <button onClick={()=>this.gotProblem("The Question Is Wrong")} className="btn btn-block btn-light btn-bottom-margin">The Question Is Wrong</button>
                    <button onClick={()=>this.gotProblem("The Solution Is Wrong")} className="btn btn-block btn-light btn-bottom-margin">The Solution Is Wrong</button>
                    <button onClick={()=>this.gotProblem("Copyright Violation")} className="btn btn-block btn-light btn-bottom-margin">Copyright Violation</button>
                    <button onClick={()=>this.gotProblem("Others")} className="btn btn-block btn-light btn-bottom-margin">Others</button>
                    <br/>
                    <div className="report-question" align="center">
                        <small onClick={()=>this.reportQuestion(false)}><u>I changed my mind</u></small>
                    </div>
                </div>
            }
        } else if (this.state.problem !== "") {
            return <div align="center">
                <img className="img-fluid" style={{width: "25vw"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1558434761/Joni%20Assets/Screenshot_2019-05-21_at_6.32.02_PM.png" /> <br/>
                <small className="grey-text"><strong>JONI IS GRATEFUL</strong></small>
                <div className="bottom-margin-tiny">
                    <small className="very-small-text">We're working on it!</small>
                </div>
                <button className="btn btn-block btn-dark" onClick={()=>{this.setState({problem: ""})}}>Back</button>
            </div>
        } else {
            return <></>
        }

    }

    render() {

            if (this.state.loading){
                return <div className="lead">
                        <div align="center"> 
                                     
                            <div className="spinner-border text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>

                        </div>
            } else {
                if (this.state.reporting_question || this.state.problem !== "") {
                    return <div className="lead">
                               {this.renderProblem()}
                            </div>
                } else {
                    let session_timing;
                    let session_duration = this.props.time_elapsed;
                    let session_h = parseInt(session_duration/60);
                    let session_min = parseInt(session_duration%60);
                    if (session_h >= 1) {
                        session_timing = <span>{session_h}h{session_min}min</span>
                    } else {
                        session_timing = <span>{session_min}min</span>
                    }
        
                    return <div className="lead">
                                Correct: {this.props.correct_answers} <br/>
                                Wrong: {this.props.wrong_answers} <br/>
                                Duration: {session_timing} <br/> 
                                <hr/>
                                <div align="center">
                {this.props.no_more_questions || this.props.daily_limit_hit ? "" : <button onClick={()=>this.props.pauseSession(false)} className="btn btn-block btn-info">Resume Session</button> }
                                    <button onClick={this.onEndSession} className="btn btn-block btn-danger">End Session</button>
                                </div>
                                <br/>
                                {this.props.no_more_questions || this.props.daily_limit_hit ? "" : <div className="report-question" align="center">
                                    
                                <small className='cursor-pointer' onClick={()=>this.reportQuestion(true)}><u>Report this question</u></small>
                                </div>}
                            </div>
                }
            }
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(PauseMenu);
