

import React from 'react'; 

import {connect} from 'react-redux';

import SkyLight from 'react-skylight';

import kopi from '../../../apis/kopi';

import Dock from 'react-dock';

import AskEmotion from'./AskEmotion/index.js';
import AskQuestion from'./AskQuestion/index.js';
import TellQuote from './TellQuote/index.js';




class JoniIntervention extends React.Component {

    state={tell_joni: false, activated: false, probability: null}

    tellJoni = () => {
        this.interact.show();
    }

    ignoreJoni = (interaction_type) => {
        kopi.post("/api/interactions/ignore_joni", {
            interaction_type,
            userId: this.props.currentUser
        })
        this.setState({tell_joni: true})
    }

    componentDidMount=()=>{
        setTimeout(()=>{ 
            this.setState({activated: true})
         }, 1000);
    }

    endInteraction=()=>{
        this.interact.hide();
    }

    renderInteraction = (interaction_type) => {
             
        let interaction;

        if (this.state.tell_joni) {
            switch (interaction_type) {
            
                case "Emotion": 
                    interaction = <AskEmotion endInteraction={this.endInteraction} correct_answers={this.props.correct_answers} wrong_answers={this.props.wrong_answers} answer_feedback={this.props.answer_feedback} />
                    break; 
    
                case "Question": 
                    interaction = <AskQuestion endInteraction={this.endInteraction} correct_answers={this.props.correct_answers} wrong_answers={this.props.wrong_answers} answer_feedback={this.props.answer_feedback} />
                    break;
    
    
                case "Quote": 
                    interaction = <TellQuote endInteraction={this.endInteraction} correct_answers={this.props.correct_answers} wrong_answers={this.props.wrong_answers} answer_feedback={this.props.answer_feedback} />
                    break;

                 default:
                    interaction = "lalala"
    
            }
        } else {
            interaction = <></>
        }

        return interaction;
    }

    renderInteractiveModal = (interaction_type) => {
        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return <SkyLight
        dialogStyles={style}
         hideOnOverlayClicked 
         ref={ref => this.interact = ref}
         afterOpen={()=>this.setState({tell_joni: true, activated: false})}
         afterClose={()=>this.setState({tell_joni: false})}
         >
         <div className="container" align="center">
         {this.renderInteraction(interaction_type)}
        </div>
      </SkyLight>
    }

    renderJoniBeckons = (interaction_type) => {

        const dockStyle = this.props.dark_mode ? 
          {
            background: '#ffcc80',
            maxHeight: "20vh",
            paddingLeft: "2vw",
          }
        : {
            background: 'rgb(255, 212, 114)',
            maxHeight: "20vh",
            paddingLeft: "2vw",
        }

        let message;
        let call_to_action;

        switch (interaction_type){

            case "Emotion": 
                message = "How are you feeling, young padawan?";
                call_to_action = "Tell Joni";
                break; 

            case "Quote": 
                message = "Joni wishes to bestow words of wisdom upon you!";
                call_to_action = "Enlighten me!";
                break;
        }

            return <Dock dockStyle={dockStyle} position='top' isVisible={this.state.activated && this.state.tell_joni===false} dimMode={"none"}>
            <br/>
                    <div style={{display: "flex", flexDirection: "row", paddingRight: "10px"}}>
                    <div>
                        <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006990/Joni%20Assets/dropdown_joni.png" className="img-fluid" style={{maxHeight: "15vh", marginRight: "7vw"}} /> 
                    </div>
                    <div >
                        <strong>{message}</strong><br/>
                        <button onClick={()=>this.tellJoni()} style={{backgroundColor: "#b5954c", color: "white"}} className="btn btn-sm">{call_to_action}</button>
                        <button onClick={()=>this.ignoreJoni(interaction_type)}  style={{color: "grey", fontSize: "0.7em"}} className="btn btn-sm">Not now, Joni</button>
                    </div>
                    </div>
         
            </Dock>
    }

    render(){
    
        if (this.props.trigger === "Before Attempt") {
            let interaction_type;
            let will_joni_intervene = this.props.will_joni_intervene;
            if (will_joni_intervene) {
                // let interaction_types = ["Question", "Quote"];
                // let interaction_type = interaction_types[Math.floor(Math.random() * interaction_types.length)];
                interaction_type = "Quote";
                return <>
                    {this.renderJoniBeckons(interaction_type)}
                    {this.renderInteractiveModal(interaction_type)}
                </>
            } else {
                return <></>
            }
        } else { // After Attempt
            let interaction_type;
            // console.log("this.props.correct_answers")
            // console.log(this.props.correct_answers)

            // console.log("this.props.wrong_answers")
            // console.log(this.props.wrong_answers)

            // console.log("this.props.answer_feedback.is_correct")
            // console.log(this.props.answer_feedback.is_correct)

            // console.log("this.props.correct_answers % 6 === 0  && this.props.answer_feedback.is_correct", this.props.correct_answers % 6 === 0  && this.props.answer_feedback.is_correct)
            if ((this.props.correct_answers % 6 === 0  && this.props.answer_feedback.is_correct) || (this.props.wrong_answers % 3 === 0 && !this.props.answer_feedback.is_correct)) {
                interaction_type = "Emotion";
                return <>
                    {this.renderJoniBeckons(interaction_type)}
                    {this.renderInteractiveModal(interaction_type)}
                </>
            } else {
                return <></>
            }
        }
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(JoniIntervention);

