import React from 'react';
import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../../history'; 


const SelectByLevel = (props) => {

    const renderTopicPicker = () => {
        return <div className="container">
            <style></style>

            {props.prereq_topics.map(topic=>{
                  if(props.selected_topics.includes(topic)){
                    return <div onClick={()=>{props.selectTopic(topic)}} class="alert alert-info cursor-pointer" role="alert">
                    {topic.title}
                  </div>
                  } else {
                    return <div onClick={()=>{props.selectTopic(topic)}} class="alert alert-light cursor-pointer" role="alert">
                    {topic.title}
                  </div>
                  }
                })
            }
        </div>
    };

    const renderSubmit = () => {
        return <nav class="navbar fixed-bottom navbar-light bg-white">
        <button onClick={props.onSubmit} className="btn btn-block btn-blue">Continue</button>
      </nav>
    }

    return <>
        {renderTopicPicker()}
        {renderSubmit()}
        </>
    
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(SelectByLevel);


//
// 