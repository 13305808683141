import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../../../apis/kopi';

import Goal from './Goal';
import GoalDetails from './Goal/GoalDetails';

import history from '../../../../../history'
import { signOut } from '../../../../../actions';


class Goals extends React.Component{

    state = {goals: [], selected_goal: null} 

    deletePlan = async () => {
        let response = await kopi.get("/api/study_plans/all", {
            params: {userId: this.props.currentUser}
        })
        this.setState({goals: response.data, selected_goal: null})    
    }

    componentDidMount = () => {
        this.fetchGoals();
    }

    selectGoal = (selected_goal) => {
        this.setState({selected_goal});
    }

    fetchGoals = async () => {
        let response = await kopi.get("/api/study_plans/all", {
            params: {userId: this.props.currentUser}
        })
        this.setState({goals: response.data})
    } 

    renderGoals = () => {
        if (this.state.goals.length === 0){
            return <div className="padding-top">
                <button onClick={()=>{history.push('/createplan')}} className="btn btn-block btn-orange">Create Study Plan</button>
            </div>
        } else {
            return this.state.goals.map(goal=>{
                return <Goal selectGoal={this.selectGoal} key={goal.id} goal={goal} />
           })
        }
    }

    render(){
        if (this.state.selected_goal){
            return <GoalDetails key={this.state.selected_goal.id} deletePlan={this.deletePlan} goal={this.state.selected_goal} />
        } else {
            return <div className="container padding-bottom">{this.renderGoals()}</div>
        }
    }
}



const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, {signOut})(Goals);