import React from 'react';
import { connect } from 'react-redux';

class TopicsRankUp extends React.Component {

    /* 
       [{
           title: topic_title
           new_rank: rank,
           new_rank_image: rank_img,
           old_rank: rank,
           old_rank_image: rank_img,
       }]
   */

    state = {
        animation: false,
        animation_complete: false
    };

    timer;

    componentDidMount = async () => {
        this.timer = setTimeout(() => {
            this.timer = setTimeout(() => {
                this.setState({ animation_complete: true })
            },
                3000);
            this.setState({ animation: true })
        },
            1000);
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer);
    }

    renderContent = () => {
        if (!this.state.animation) {
            return <></>
        }

        return <><div className="slide-up">
            <div className="slide-up content">
                <img className="img-fluid" style={{ height: "20vh" }} src={this.props.updated_topic_ranks ? this.props.updated_topic_ranks[0].new_rank_image : null} alt='' />
            </div>
        </div>
            <div className="text-fade">
                <strong> 👍 Rank Up! </strong> <br/>
                <h3> {this.props.updated_topic_ranks ? this.props.updated_topic_ranks[0].title : ''} </h3>
                <h5> {this.props.updated_topic_ranks ? this.props.updated_topic_ranks[0].new_rank : ''} </h5>
            </div>
            <br />
            <div className="text-fade">
                {this.state.animation_complete ? <button onClick={this.props.closeTopicRankModal} class="btn btn-sm btn-blue btn-rounded">Keep up the good work!</button> : ""}
            </div>
        </>
    }

    render = () => {

        const style = {
            color: 'white',
            position: "fixed", /* Stay in place */
            paddingTop: "20vh",
            zIndex: 9999999999999999999, /* Sit on top */
            left: 0,
            top: 0,
            width: '100%', /* Full width */
            height: '100%', /* Full height */
            overflow: "auto" /* Enable scroll if needed */
        }
        return <div style={style}><div align="center" className="container">
            {this.renderContent()}
        </div></div>
    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(TopicsRankUp);