import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import kopi from '../../apis/kopi';
import TopicBar from './TopicBar'
import LOBar from './LOBar'
import Footer from '../Footer';
import history from '../../history';

class ProfileShow extends React.Component {
    state = { studentTopicRanks: [], studentLORanks: [], pageNum: 0, selectedTopic: null, loading: true, order: 1, sort: ''}
    // pageNum: 0 => topic masteries
    // pageNum: 1 => LO masteries

    componentDidMount = async () => {

        if (!this.props.isSignedIn || !this.props.currentUser){
            return history.push('/')
        }

        document.body.style.background = "#121212";

        const response = await kopi.get(`/api/library/profiles`, {
            params: {userId: this.props.currentUser}
        })
        // console.log(response.data)
        const { LORanks, topicRanks } = response.data  // masteries refers to LO masteries
        this.setState( { loading: false, studentTopicRanks: topicRanks, studentLORanks: LORanks } )
    }

    componentWillUnmount = () => {
        document.body.style.background = null ;
    }

    onTopicClick = (topic) => {
        // this.setState({pageNum:1, selectedTopic: topic})
    }

    onBackClick = () => {
        this.setState({pageNum:0})
    }

    onClickSort = () => {
        this.setState({
                        studentTopicRanks: this.state.studentTopicRanks.sort((a, b) => (this.state.order*(b.skill-a.skill))), 
                        order: this.state.order * -1,
                        sort: this.state.sort === "fas fa-sort-down cursor-pointer" ? "fas fa-sort-up cursor-pointer" : "fas fa-sort-down cursor-pointer"
                    }) //descending order
        
    }

    render() {
        if (this.state.loading){
            
            return <div style={{backgroundColor: "#121212", height:'100vh'}} align="center" >
            <br/> <br/> <br/>
            <div style={{backgroundColor: "#121212"}} className="spinner-border text-secondary" role="status">
                 <span style={{backgroundColor: "#121212"}} className="sr-only">Loading...</span>
            </div>
            <Footer/>
            </div>
        } else {
            if (this.state.pageNum === 0){
                return (
                    <div style={{backgroundColor:'#121212'}} className='container padding-top container-padding-bottom' align="left">
                        <div align="center">
                            <h3 onClick={this.onClickSort} style={{color:'#FFFFFF'}} className='header-font cursor-pointer' >My Topics Mastery <i style={{color:'#3076A3'}} className={this.state.sort ? this.state.sort : "fas fa-sort cursor-pointer"}></i></h3>
                        </div>
                        <div className="row">
                            {this.state.studentTopicRanks.map(rank => {
                                return <div className="col-md-6" >
                                    <div className="container" style={{backgroundColor:'#2C2C2C', borderRadius:'15px', paddingLeft:'20px', paddingRight:'20px'}}>
                                        <TopicBar key={rank._id} rank={rank} onTopicClick={this.onTopicClick} />
                                    </div>
                                </div>
                            })}
                        </div>
                        <Footer/>
                    </div>
                )
            } else {
                // console.log(this.state.studentLORanks)
                return (
                    <div className='container padding-top container-padding-bottom' align="left">
                        <button onClick={this.onBackClick} className="btn btn-block btn-info"> Back </button>
                        <br/>
                        <div align="center">
                            <strong>{this.state.selectedTopic.title}</strong>
                        </div>
                        <div className="row">
                        {this.state.studentLORanks.filter(LO => {
                            if (LO.objective){
                                return LO.objective.topic === this.state.selectedTopic._id
                            } else {
                                return false
                            }}).map(rank => {
                                return <div className="col-md-6">
                                <div className="container">
                                <LOBar key={rank._id} title={rank.objective.title} skill={rank.skill} />
                                </div>
                            </div>
                            })}
                        <Footer/>
                    </div>
                </div>
                )
            }
        }
    }
}



const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(ProfileShow);