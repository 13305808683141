import React from 'react'; 

import {InlineTex} from 'react-tex';

const MCQField = (props) => {
    
    let seperator = "\${1,2}";


    const renderOptions = () => {

        let options = [];
        options.push(props.current_part.ans)
        options.push(props.current_part.ans_alt1);
        options.push(props.current_part.ans_alt2);
        options.push(props.current_part.ans_alt3);


        return options.map((option,index)=>{
            if (props.attempt === option){
                return <div key={index} className="alert alert-info">
                <InlineTex texSeperator={seperator} texContent={option} />
                </div>
            } else {
                return <div key={index} onClick={()=>props.updateAttempt(option)} className="alert alert-light">
                    <InlineTex texSeperator={seperator} texContent={option} />
                </div>
            }
            
        });

    }

    return <div className="padding-top answer-form">
        {renderOptions()}
    </div> 
  
}

export default MCQField;

