import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history';

import kopi from '../../../apis/kopi';
import { ShakeHorizontal } from 'reshake'


import {Link} from 'react-router-dom';

import socketIOClient from "socket.io-client";

import QuestionCard from './QuestionCard'
import MasteriesUpdate from './JoniModal/MasteriesUpdate'
import JoniModal from './JoniModal'; 


let socket;

class Session extends React.Component {

    state = {
        // endpoint: "https://k8sapi.joni.ai/api",
        // endpoint: "http://localhost:3000", 
        endpoint: "https://k8sapi.joni.ai",

        question: null,
        attempted_questions: [],
        my_attempts: [],
        current_part_index: 0,

        answer_feedback: null,
        updated_masteries: null,
        marked_question: null,
        error: false,
        concluded: false,
        pause: true,
        loading: false,
        show_secret_number: true,
        secret_number: ""
    };  

    timer;

    UNSAFE_componentWillMount = async () => {
        const { endpoint } = this.state;
        socket = socketIOClient(endpoint, {path: '/apisocket/socket.io'});
        window.addEventListener('beforeunload', this.leavePractice, false);
        socket.on("exitSlip", ()=>this.updateQuestion())
        socket.on("fetchQuestion", ()=>this.updateQuestion())
        socket.on("attendanceSlip", ()=>socket.emit("reportAttendance", {name: this.props.name, session_id: this.props.session_id}))
        socket.on('kennaKick', name =>{
            if (name === this.props.name) history.push("/slip/kicked/"+this.props.invite_code)
        })
        socket.emit("joinSlip", this.props.session_id);
        // socket.on("endSession", (data)=>{this.setState({concluded: data})})
    }


    componentDidMount = async () => {
        this.timer = setInterval(
             () => {
                socket.emit("joinSlip", this.props.session_id)
             },
             5000
           );
        
        socket.emit("reportAttendance", {name: this.props.name, session_id: this.props.session_id})
        this.updateQuestion();
     }

    takeAttendance = () => {
        socket.emit("takeAttendance", {session_id: this.props.session_id, user_id: this.props.currentUser});
    }

    updateQuestion = async () => {
        if (!this.state.loading) this.setState({loading: true})
        let response = await kopi.get('/api/exit_slip/fetch_question', {
            params: {
                name: this.props.name,
                secret: this.props.secret,
                session_id: this.props.session_id
            }
        })

        if (response.data.msg && response.data.msg=="Done") {
            history.push('/slip/results/' + response.data.student_id)
            // this.setState({loading: false,pause: true, concluded: true})
            return;
        }

        if (!response || response.data.error) {
            this.setState({loading: false,pause: true})
            return;
        };

        if (this.state.question) {
            if (response.data.question[0].id === this.state.question[0].id) {
                if (response.data.question.length == response.data.current_part_index){
                    this.setState({loading: false,pause: true})
                    return;
                }
            }
        }

        this.setState({loading: false, question: response.data.question, current_part_index: response.data.current_part_index, error: false, answer_feedback: null, updated_masteries: null, marked_question: null, concluded: false, pause: false})
    }

    leavePractice = () => {
        // socket.emit("leavePractice", {session_id: this.props.session_id, user_id: this.props.currentUser});
    }

    componentWillUnmount = () => {
        // this.leavePractice();
        clearInterval(this.timer);
        socket.close();
    }

    renderAnswerFeedbackModal = () => {
        if (this.state.updated_masteries && this.state.answer_feedback) {
            let content = <MasteriesUpdate updated_masteries={this.state.updated_masteries} />;
            let title = this.state.answer_feedback.is_correct ? "Great Job!" : "Oh Dear!"
            let subtitle = this.state.answer_feedback.is_correct ? "Joni is proud of you!" : "You can do better!"
            return <JoniModal is_happy={this.state.answer_feedback.is_correct} title={title} subtitle={subtitle} content={content} />
        } else {
            return <></>
        }
    }

    renderPracticeComponents = () => {
            return <>
                {this.renderAnswerFeedbackModal()}
                <div className="padding-top">
                    {this.renderQuestionCard()}
                </div>
            </>
        }

    renderQuestionCard = () => {
        if (this.state.current_part_index !== null && this.state.question && this.state.current_part_index < this.state.question.length && !this.state.pause) {
            return <>
            <QuestionCard key={this.state.question[0].id + this.state.current_part_index} question={this.state.question} current_part_index={this.state.current_part_index} marked_question={this.state.marked_question} showing_modal={this.state.updated_masteries ? true : false} onSubmit={this.onSubmit} answer_feedback={this.state.answer_feedback} />
            </>
        } else {
            return <div style={{padding:'5px'}}>
                <h3>Get ready my young disciple!</h3> <br/>Please prepare your writing materials and calculator while waiting for the question to be released.
                <br/><br/>
                <button class="btn btn-info btn-lg" onClick={this.updateQuestion}> Refresh </button>
            </div>
        }
    }
    

    onSubmit = async (action, value) => {
        
        switch(action){
            case "attempt_current_question":
                    this.setState({loading: true})
                    let response1 = await kopi.post('/api/exit_slip/submit', {
                    name: this.props.name,
                    secret: this.props.secret,
                    session_id: this.props.session_id,
                    question_id: this.state.question[this.state.current_part_index].id,
                    answer: value,
                    });

                    this.updateQuestion()
                    
                    break; 

            case "fetch_next_question":
                this.updateQuestion()
                break;
            
        }


    }

    renderError =() => {
        return <div> Get ready my young disciple! Please prepare your writing materials and calculator while waiting for the question to be released.
        <button class="btn btn-info btn-lg" onClick={this.updateQuestion}> Fetch Again </button></div>
    }

    render() {
        if (this.state.loading) {
            return <div marginTop='20vh'> <ShakeHorizontal fixed h={10} v={2} r={10}>
                <img style={{maxWidth: "40vw"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561025325/Joni%20Assets/jonihappy.png" class="img img-fluid" />
            </ShakeHorizontal> </div>
        } else if (this.state.show_secret_number) {
            return <div class="container">
            <div style={{marginTop: "20vh"}}/> 
                <ShakeHorizontal fixed h={10} v={2} r={10}>
                    <img style={{maxWidth: "40vw"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561025325/Joni%20Assets/jonihappy.png" class="img img-fluid" />
                </ShakeHorizontal> <br/>
                Your secret number is
                <h1>{this.props.secret}</h1>
                <hr/>
                Write this code down somewhere. You can use it to resume the session if you get disconnected!
                <br/> <br/> 
                <button onClick={()=>this.setState({show_secret_number: false})} class="btn btn-light btn-block">Okay I've written it down!</button>
            </div>
       
        } else if (!this.state.error && !this.state.pause) {
            return <div align="center">
            {this.renderPracticeComponents()}  
            </div>
        } else {
            return this.renderError()
        }
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(Session);


//
// 