import React from 'react';
import kopi from '../../apis/kopi'
import { connect } from 'react-redux';
import { signIn } from '../../actions';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import history from '../../history.js';
import '../Joni.css';
import Sparkle from 'react-sparkle'

class Auth extends React.Component {

  state = {
    username: "",
    password: "",
    sign_in_with_username: true,
    logging_in: false,
    forgot_password: false,
    password_reset_sent: false
  }

  renderError = () => {
    if (this.props.location.state) {
      if (this.props.location.state.wrongInfo) {
        this.setState({ logging_in: false });
        return "Wrong Username/Password"
      } else if (this.props.location.state.verifyEmail) {
        this.setState({ logging_in: false });
        return "Please verify your email"
      } else {
        return <></>
      }
    } else {
      return <></>
    }
  }

  componentDidMount = async () => {

    if (this.props.secondary == 0 || this.props.secondary == "0") {
      history.push('/setup')
      return;
    }

    if (this.props.currentUser) {
      let response = await kopi.get('/api/joni/check_onboarding', {
        params: { user_id: this.props.currentUser }
      })

      if (response.data.onboarding == 0) {
        history.push('/onboard')
        return
      } else if (response.data.onboarding < 3) {
        history.push(`/onboardstart:${response.data.onboarding}`)
        return;
      }
      if (this.props.currentUser && response.data.onboarding) {
        history.push('/dashboard');
      } else {
        window.scrollTo(0, 0);
      }
    }

  }

  loginUser = () => {
    this.setState({ logging_in: true })
    this.props.signIn({
      username: this.state.username.toLowerCase(),
      password: this.state.password,
    });

  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.props.signIn({
        username: this.state.username.toLowerCase(),
        password: this.state.password,
      });
    }
  }

  renderusernameSignIn = () => {
    if (this.state.sign_in_with_username) {
      return <> <small className="error-messages">{this.renderError()}</small>
        <br />
        <div class="form-group">
          <input id="username" onChange={(e) => { this.setState({ username: e.target.value }) }} value={this.state.username} type="username" className="form-control" placeholder="Username" />
        </div>
        <div class="form-group">
          <input id="password" onChange={(e) => { this.setState({ password: e.target.value }) }} value={this.state.password} type="password" className="form-control" placeholder="Password" onKeyDown={this._handleKeyDown} />
        </div>
        <button onClick={this.loginUser} className="btn btn-blue btn-block button-text-small"> Login </button>
        <br />
        <a style={{ color: 'black' }} href="https://www.joni.ai/pricing"><small>I want to create an account for my child.</small></a> <br />
        <small className='cursor-pointer' onClick={() => this.setState({ forgot_password: true })} style={{ color: 'black' }} ><strong>I forgot my password.</strong></small><br/>
        <small>{this.state.logging_in ? 'Logging you in...' : ''}</small>
      </>
    } else {
      return <p onClick={() => this.setState({ sign_in_with_username: true })}>Sign in with username</p>
    }
  }

  renderForgotPassword = () => {
    const iForgotMyPasword = () => {
      if (this.state.username && this.state.username != '') {
        kopi.post('/api/auth/forgot_password', {
          username: this.state.username
        })
        this.setState({ password_reset_sent: true })
      }
    }

    if (this.state.password_reset_sent) {
      return <div id="error">
        A password reset link has been sent to you via email.
      <br /><br />
        <button onClick={() => this.setState({ forgot_password: false })} className="btn btn-blue btn-block button-text-small"> Back to Login </button>
      </div>
    } else {
      return <div id="error">
        A password reset link will be sent to you via email.
        <br /><br />
        <div class="form-group">
          <input id="username" onChange={(e) => { this.setState({ username: e.target.value }) }} value={this.state.username} type="username" className="form-control" placeholder="Username" />
        </div>
        <button onClick={iForgotMyPasword} className="btn btn-blue btn-block button-text-small"> Get Password Reset Link </button>
        <br />

        <small className='cursor-pointer' onClick={() => this.setState({ forgot_password: false })} style={{ color: 'black' }} ><strong>I remembered my password!</strong></small>

      </div>
    }
  }

  render() {
    return (
      <div style={{ height: '100%', overflow: "hidden" }}>
        <div className="login-centralise">

          <div className="container padding-bottom" align="center">
            {this.props.location && this.props.location.state && this.props.location.state.message ? <span>{this.props.location.state.message}</span> : ''}
            <div style={{ position: 'relative' }}>
              <br /><br />
              <h1>Hi! I'm Joni.</h1> <br />
              <img src='https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png' className="img img-fluid" style={{ maxHeight: "25vh" }} />
              <br />
              {/* <Sparkle color={'random'} count={8}/> */}
            </div>
            <div>

              <br />
              {this.state.forgot_password ? this.renderForgotPassword() : this.renderusernameSignIn()}
            </div>

          </div>
          {this.props.showiOSPrompt()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    isSignedIn: state.auth.isSignedIn,
    secondary: state.auth.secondary
  };
};

export default connect(mapStateToProps, { signIn })(Auth);
