import React from 'react';
// import {connect} from 'react-redux';
// import kopi from '../../../apis/kopi';
// import axios from 'axios';
// import history from '../../../../history'; 

import { VelocityTransitionGroup } from 'velocity-react';
import { ShakeHorizontal } from 'reshake';
import UIfx from 'uifx';
import rankUpAudio from '../../../my-sounds/Game Success.mp3'

import TopicsRankUp from './TopicsRankUp.js';

require('velocity-animate');
require('velocity-animate/velocity.ui');

const rankUp = new UIfx(
    rankUpAudio,
    {
        volume: 1.0,
        throttleMs: 50
    }
)


class JoniModal extends React.Component {
    state = { render: false, closed_topic_rank_modal: false }

    componentDidMount = () => {
        this.setState({ render: true });
    }

    renderJoni = () => {
        return this.props.is_happy ? <img style={{ maxHeight: "40vh", maxWidth: "60vw", marginBottom: "-2vh" }} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561025325/Joni%20Assets/jonihappy.png" className="img-fluid" /> : <img style={{ maxHeight: "50vh", maxWidth: "60vw", marginBottom: "-2vh" }} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540542/Joni%20Assets/answer_wrong.png" className="img-fluid" />
    }

    renderTopicRankUp = () => {
        const closeTopicRankModal = () => {
            this.setState({ closed_topic_rank_modal: true })
        }
        if (this.state.closed_topic_rank_modal || !this.props.updated_topic_ranks || this.props.updated_topic_ranks.length === 0) {
            return <></>
        } else {
            if (!this.state.closed_topic_rank_modal) rankUp.play()
            return <><div id="overlay-darker"></div><TopicsRankUp closeTopicRankModal={closeTopicRankModal} updated_topic_ranks={this.props.updated_topic_ranks} /></>
        }
    }

    render() {
        // return 
        return <> {this.renderTopicRankUp()}
        <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div align="center" className="test">
                    {/* <ShakeHorizontal h={10} v={2} r={10}> */}
                    <VelocityTransitionGroup enter={{ animation: "slideDown" }} leave={{ animation: "slideUp" }}>
                        {this.state.render ?
                            this.renderJoni()
                            : undefined}
                    </VelocityTransitionGroup>
                    {/* </ShakeHorizontal>  */}
                </div>

                <div class="modal-content" style={{ overflow: "auto" }}>
                    <div align="center">
                        <h4 onClick={() => { this.setState({ render: !this.state.render }) }} class="modal-title dark-blue-text"><strong>{this.props.title}</strong></h4>
                        <small class="dark-blue-text">{this.props.subtitle}</small>

                    </div>

                    <div class="modal-body">
                        {this.props.content}
                    </div>
                </div>
            </div>
        </div> </>
    }
}

export default JoniModal;

