import React from 'react';
import {connect} from 'react-redux';
import kopi from '../../apis/kopi';

import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

class MultiPartQuestion extends React.Component {

    state={multiparts: null, selected_question: null, prev_question: null, attempted: true, loaded: false, Yscroll_position: 0}

    componentDidMount = async () => {
        const response = await kopi.get('/api/library/multipart_question', {
            params: {seedQnId: this.props.seed_question._id}
        });
        
        this.setState({multiparts: response.data, selected_question: this.props.seed_question})
    }

    selectQuestion = (qn) => {
        if (qn === this.state.selected_question) return;
        this.setState({ prev_question: this.state.selected_question, selected_question: qn, loaded: false, Yscroll_position: window.pageYOffset })
    }

    componentDidUpdate = async () => {  
        
        if (this.state.selected_question !== this.state.prev_question){
            this.setState({ prev_question: this.state.selected_question, loaded: true})
        }
    
        if (this.state.selected_question !== this.state.prev_question){
            this.setState({attempted: true, prev_question: this.state.selected_question})
        }

        window.scrollTo(0, this.state.Yscroll_position)

    }


    render() {


        // console.log(this.state.multiparts)
        // console.log('select', this.state.selected_question)

        let seperator = "\${1,2}";

        if(this.state.multiparts){
            function nextChar(c, index) {
                return String.fromCharCode(c.charCodeAt(0) + index);
            }
            let navigation = this.state.multiparts.map((qn, index) => {
                if (qn._id === this.state.selected_question._id) {
                    return <li className="nav-item" key={qn._id} onClick={() => this.selectQuestion(qn)}>
                        <strong className='cursor-pointer'>({nextChar('a',index)})</strong>
                    </li>
                } else {
                    return <li className="nav-item" key={qn._id} onClick={() => this.selectQuestion(qn)}>
                                    <strong className='cursor-pointer' style={{color: '#c7c8c9'}}>({nextChar('a',index)})</strong>
                           </li>
                }
            })

            let header_txt;
            let header_img;

            let qn_img;

            if (this.state.multiparts[0].header_txt && this.state.multiparts[0].header_txt.startsWith("\\begin")) {
                // console.log("identified as begin")
                header_txt = <div className="table-responsive">
                    <table className="table">
                        <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={this.state.multiparts[0].header_txt} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            } else {
                header_txt = <div><InlineTex texSeperator={seperator} texContent={this.state.multiparts[0].header_txt} /></div>
            }

            if (this.state.multiparts[0].header_img){
                header_img = <div>
                    <br />
                    <img className="img-fluid" src={this.state.multiparts[0].header_img} alt="lol" />
                </div>
            } 

            if (this.state.selected_question.qn_img){
                qn_img = <div>
                    <br />
                    <img className="img-fluid" src={this.state.selected_question.qn_img} alt="lol" />
                </div>
            } 

            let qn_txt;
            if (this.state.selected_question.qn_txt && this.state.selected_question.qn_txt.startsWith("\\begin")){
                // console.log("identified as begin")
                qn_txt = <div className="table-responsive">
                    <table className="table">
                        <tbody>
                        <tr>
                            <td>
                                <Tex texContent={this.state.selected_question.qn_txt} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>    
            } else {
                qn_txt = <div><InlineTex texSeperator={seperator} texContent={this.state.selected_question.qn_txt} /></div>
            }

            let sol_txt;
            let sol_img;
            let ans

       

            if (!this.state.loaded){
                return <span></span>
            } else {
                if (!this.state.attempted){
                    sol_txt = <span></span>
                    sol_img = <span></span>
                    ans = <span></span>
                } else {
                    // console.log(this.state.selected_question)
                    if ((this.state.selected_question && this.state.selected_question.sol_img === ' ') || !this.state.selected_question.sol_img){
                        sol_img = <span></span>
                    } else {
                        sol_img = <div>
                            <br />
                            <img className="img-fluid" src={this.state.selected_question.sol_img} alt="lol" />
                        </div>
                    }
        
                    if (this.state.selected_question.sol_txt && this.state.selected_question.sol_txt.startsWith("\\begin")){
                        // console.log("identified as begin")
                        sol_txt = <div className="table-responsive">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <td>
                                        <Tex texContent={this.state.selected_question.sol_txt} />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>    
                    } else {
                        sol_txt = <div><InlineTex texSeperator={seperator} texContent={this.state.selected_question.sol_txt} /></div>
                    }

                    const renderAnswerLatex = () => {
                        let seperator = "\${1,2}";
                        let content1 =  this.state.selected_question.units == '\$' ? "$" : ""
                        let content2 = this.state.selected_question.ans
                        let content3 = this.state.selected_question.units && this.state.selected_question.units !== '\$' ? this.state.selected_question.units : ""
                        return <InlineTex texSeperator={seperator} texContent={content1 + content2 + content3} />     
                    }
        

                    if (this.state.selected_question.units === "\$"){
                        ans = <div className="alert alert-success">
                            {renderAnswerLatex()}
                        </div>
                    } else {
                        ans = <div className="alert alert-success">
                            {renderAnswerLatex()}
                        </div>
                    }
                }

                const renderAnswerContext = () => {

                    let seperator = "\${1,2}";
                    if (this.state.selected_question.prefix || this.state.selected_question.suffix){
                        let prefix = this.state.selected_question.prefix  ? this.state.selected_question.prefix : ""
                        
                        let suffix = this.state.selected_question.suffix  ? this.state.selected_question.suffix : ""
                      return  <div>
                        <InlineTex texSeperator={seperator} texContent={prefix  + " _____ " + suffix} />     
                      <br/>
                      </div>
                    } else {
                      return <></>
                    }
                }

                const renderLatex = (content) => {
                    let seperator = "\${1,2}";
                    let content1 =  this.state.selected_question.units == '\$' ? "$" : ""
                    let content2 = content
                    let content3 = this.state.selected_question.units && this.state.selected_question.units !== '\$' ? this.state.selected_question.units : ""
                    return <InlineTex texSeperator={seperator} texContent={content1 + content2 + content3} />     
                }

                const renderText = (value) => {
                    const renderEmoji = (value) => {
                        if (value == 0) {
                            return '😢'
                        } else if (value == 33) {
                            return '😪'
                        } else if (value == 66) {
                            return '🙂'
                        } else {
                            return '😀'
                        }
                    }
            
                    const renderStatus = (value) => {
                        if (value == 0) {
                            return "I don't know where to begin"
                        } else if (value == 33) {
                            return 'I am able to make some progress'
                        } else if (value == 66) {
                            return 'I almost got it'
                        } else {
                            return "It's perfect!"
                        }
                    }
            
                    return <div align="center">
                            <h1>{renderEmoji(value)}</h1>                
                            <p>{renderStatus(value)}</p>
                        </div>
            
            
                   
                } 

                // let student_ans;

                // if (this.props.attempts) {
                //     let attempt = this.props.attempts.find(attempt=>attempt.question==this.state.selected_question._id)
                //     if (attempt && !attempt.correct) {
                //         student_ans = <div className="alert alert-danger">
                //             {renderLatex(attempt.student_ans)}
                //         </div>    
                //     }
                  
                // }

                // if (this.state.selected_question.qn_type == "self" && this.props.attempts) {
                //     let attempt = this.props.attempts.find(attempt=>attempt.question==this.state.selected_question._id)

                //     if (attempt) {
                //         student_ans =  <div style={{background: "#e7f6ff" , marginTop: "2vh", paddingTop: "1vh", paddingBottom: '5vh', boxShadow: "0 3px 6px rgba(255,212,114,0.05), 0 1.5px 3px rgba(255,212,114,0.05)"
                //     }} class='card'>
                //             <div class="card-content">
                //             <div align="center" style={{paddingBottom: '3vh'}}></div>
                //             {renderText(attempt.student_ans)}
                //             </div>
                //         </div>
    
                //         ans = ""
                //     } else {
                //         student_ans = ""
                //         ans = ""
                //     }
                // }


    
                if (this.state.selected_question.units === "\$"){
                    
                    return <span>
                        {header_txt}
                        {header_img}
                        <br />
                        <ul className="nav nav-pills nav-fill">
                            {navigation}
                        </ul>
                        <br />
                        {qn_img}
                        {qn_txt}
                        {renderAnswerContext()}
                        <br/>
                        {ans}
                        {/* {student_ans} */}
                        {sol_txt}
                        {sol_img}
                    </span> 
                } else {
                    return <span>
                    {header_txt}
                    {header_img}
                    <br />
                    <ul className="nav nav-pills nav-fill">
                        {navigation}
                    </ul>
                    <br />
                    {qn_img}
                    {qn_txt}
                    {renderAnswerContext()}
                    <br/>
                    {ans}
                    {/* {student_ans} */}
                    {sol_txt}
                    {sol_img}
                </span>
                }
            }
            
        } else {
            return <span></span>
        }

    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(MultiPartQuestion);

