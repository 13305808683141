import React from 'react';
import {connect} from 'react-redux';
import OnboardPart2 from './OnboardPart2';
import history from '../../../history'

class OnboardStart extends React.Component{
  state = {stageKey: null}

  static getDerivedStateFromProps(props,state){
    const { stageKey } = props.match.params

    if (stageKey && stageKey !== state.stageKey) {
      return {
        stageKey: Number(stageKey[1])
      }
    };

    return null;
  }

  componentDidMount = () => {
    if (!this.props.isSignedIn || !this.props.currentUser){
      return history.push('/')
    }
    if (this.state.stageKey && this.state.stageKey === 0) {
      history.push('/onboard') 
    } 
  }

render(){
      return <div>
        <OnboardPart2 stageKey={this.state.stageKey}/>
      </div>
    }
}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(OnboardStart);
