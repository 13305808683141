import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';
import history from '../../../history';
import Switch from 'react-switch';
import SkyLight from 'react-skylight';
import { myFirestore, myStorage } from '../../../apis/MyFirebase'
import * as linkify from 'linkifyjs';
import Linkify from 'linkifyjs/react';
import imageCompression from 'browser-image-compression';

class AskJoni extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            chatInput: '',
            chatContent: '',
            orientation: 0,
            mobileView: window.matchMedia('screen and (max-width: 768px)').matches,
            read: true,
            showing: false,
        }

        this.groupChatId = null
        this.removeListener = null
        this.listMessage = []
        this.currentPeerUser = '5d4c0e84fb5d5e68a1e4efcb' //shellsnail
        this.initiateChat = true
        this.currentPhotoFile = null
    }

    convertUnixToSG = (unixTimeStamp) => {

        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        var date = new Date(unixTimeStamp * 1000);
        // Hours part from the timestamp
        var hours = date.getHours();
        // Minutes part from the timestamp
        var minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        var seconds = "0" + date.getSeconds();

        // Will display time in 10:30:23 format
        var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return formattedTime
    }

    askJoni = () => {
        this.chat.show();
    }

    endChat = () => {
        this.chat.hide();
    }

    componentDidUpdate = () => {
        this.scrollToBottom()
    }

    componentDidMount = () => {
        window.addEventListener("orientationchange", function () {
            this.setState({ orientation: window.orientation, mobileView: window.matchMedia('screen and (max-width: 768px)').matches })
        }.bind(this), false);

        this.getListHistory();
    }

    getListHistory = () => {
        if (this.removeListener) {
            this.removeListener()
        }
        this.listMessage.length = 0
        this.setState({ loading: true })

        this.groupChatId = this.props.currentUser + this.currentPeerUser

        // Get history and listen new data added
        this.removeListener = myFirestore
            .collection('messages')
            .doc(this.groupChatId)
            .collection(this.groupChatId)
            .onSnapshot(
                snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type === 'added') {
                            this.listMessage.push(change.doc.data())
                            if (change.doc.data().idFrom !== this.props.currentUser) this.setState({ read: false })
                        }
                    })
                    this.setState({ loading: false })
                },
                err => {
                    console.log(err.toString())
                }
            )
    }

    sendChat = (input, type) => {
        const timestamp = Date.now().toString()

        const itemMessage = {
            idFrom: this.props.currentUser,
            idTo: this.currentPeerUser,
            timestamp: timestamp,
            content: input.trim(),
            question: this.props.question ? this.props.question.id : '',
            type: type
        }

        this.setState({ chatInput: '' })


        console.log(this.groupChatId)
        if (itemMessage.content !== '') {
            myFirestore
                .collection('messages')
                .doc(this.groupChatId)
                .collection(this.groupChatId)
                .doc(timestamp)
                .set(itemMessage)
                .then(() => {
                    if (this.props.question && this.initiateChat !== this.props.question.id) {
                        const response = kopi.post('/student-chat', {
                            userId: this.props.currentUser,
                            chatId: this.groupChatId,
                            content: itemMessage.content,
                            qnId: this.props.question.id
                        })
                        this.initiateChat = this.props.question.id
                    }
                })
                .catch(err => {
                    console.log(err.toString())
                })
        }
    }

    onChoosePhoto = event => {
        if (event.target.files && event.target.files[0]) {
            this.setState({ loading: true })
            this.currentPhotoFile = event.target.files[0]
            // Check this file is an image?
            const prefixFiletype = event.target.files[0].type.toString()
            if (prefixFiletype.indexOf('image/') === 0) {
                this.uploadPhoto()
            } else {
                this.setState({ loading: false })
                window.alert('This file is not an image')
            }
        } else {
            this.setState({ loading: false })
        }
    }

    uploadPhoto = async () => {
        if (this.currentPhotoFile) {
            const timestamp = Date.now().toString()

            var options = {
                maxSizeMB: 1,
            }

            const compressedFile = await imageCompression(this.currentPhotoFile, options);

            const uploadTask = myStorage
                .ref()
                .child(timestamp)
                .put(compressedFile)

            uploadTask.on(
                'state_changed',
                null,
                err => {
                    this.setState({ loading: false })
                    window.alert(err.message)
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        this.setState({ loading: false })
                        this.sendChat(downloadURL, 'image')
                        this.scrollToBottom()
                    })
                }
            )
        } else {
            this.setState({ loading: false })
            window.alert('File is null')
        }
    }

    updateChatContent = () => {
        if (this.listMessage.length > 0) {
            let viewListMessage = []
            this.listMessage.forEach((item, index) => {
                if (item.idFrom === this.props.currentUser) {
                    // Item right (my message)
                    if (item.type === 'text') {
                        viewListMessage.push(
                            <div class="mine messages">
                                <div className="" key={item.timestamp}>
                                    <div className="message"><Linkify options={{ className: 'orange-links' }}>{item.content}</Linkify></div>
                                </div>
                            </div>
                        )
                    } else if (item.type === 'image') {
                        viewListMessage.push(

                            <div className="mine messages" key={item.timestamp}>

                                <img
                                    className="message"
                                    src={item.content}
                                    alt="content message"
                                    style={{ maxWidth: '100%' }}
                                />
                            </div>
                        )
                    }
                } else {
                    if (item.type === 'text') {
                        viewListMessage.push(
                            <div class="yours messages">
                                <div className="" key={item.timestamp}>
                                    <div className="message"><Linkify>{item.content}</Linkify></div>
                                </div>
                            </div>
                        )
                    } else if (item.type === 'image') {
                        viewListMessage.push(
                            <div className="yours messages" key={item.timestamp}>
                                <img
                                    className="message"
                                    src={item.content}
                                    alt="content message"
                                    style={{ maxWidth: '100%' }}
                                />
                            </div>
                        )
                    }

                }

            })
            return viewListMessage
        } else {
            return <div className="">
                <span className="">Ask and we will get back to you soonest!</span>

            </div>
        }


    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault()
            this.sendChat(this.state.chatInput, 'text')
        }
    }

    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({})
        }
    }

    renderChat = () => {
        let myDialog;
        let myOverlay = {};
        let chatDisplay;
        let chatInput;
        let chatButton;


        if (this.state.mobileView) {

            myDialog = {
                backgroundColor: '#354B80',
                color: '#ffffff',
                width: '90vw',
                top: '10vh',
                left: '5vw',
                zIndex: '6000',
                marginTop: '0',
                marginLeft: '0',
            };

            chatDisplay = { overflow: this.state.showing ? 'scroll' : 'hidden', height: '45vh', marginBottom: '5%', backgroundColor: '#e7f6ff', padding: '0', borderRadius: '3px', color: 'black', paddingBottom: '2%', paddingTop: '3%' }
            chatInput = { resize: 'none', width: '100%', marginTop: '3%', backgroundColor: 'white', borderRadius: '3px' }
            chatButton = { width: '90%', borderColor: '#ffd372', backgroundColor: '#ffd372', borderRadius: '5px', }

        } else {
            myDialog = {
                backgroundColor: '#354B80',
                color: '#ffffff',
                top: '5vh',
                left: '68vw',
                marginTop: '0',
                marginLeft: '0',
                width: '28vw',
                height: '75vh',
                zIndex: '6000',
            };

            myOverlay = {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: '000000'
            };

            chatDisplay = { overflow: this.state.showing ? 'scroll' : 'hidden', height: '90%', marginBottom: '3%', backgroundColor: '#e7f6ff', paddingLeft: '0', paddingRight: '0', borderRadius: '3px', color: 'black', paddingBottom: '2%', paddingTop: '3%' }
            chatInput = { resize: 'none', width: '100%', marginTop: '2%', backgroundColor: 'white', borderRadius: '3px' }
            chatButton = { width: '90%', borderColor: '#ffd372', backgroundColor: '#ffd372', borderRadius: '5px', }

        }

        if (this.state.loading) {
            return <SkyLight
                hideOnOverlayClicked
                dialogStyles={myDialog}
                overlayStyles={myOverlay}
                ref={ref => this.chat = ref}
                beforeOpen={() => this.setState({ read: true, showing: true })}
                afterClose={() => this.setState({ read: true, showing: false })}
                transitionDuration={0}
            >
                <div style={{ height: '80%', paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }} className="container" align="center">
                    <h2>Ask Joni</h2>
                    <div style={chatDisplay} className='container'>
                        Loading...
                 </div>

                </div>
                <div style={{ height: '20%' }}>
                    <textarea
                        style={chatInput} name='chat-input' rows='3' resize='none' type='text' placeholder='Ask anything!'
                        onChange={(e) => { this.setState({ chatInput: e.target.value }) }} value={this.state.chatInput}
                        onKeyDown={this._handleKeyDown}
                    >
                    </textarea>
                    <div>
                        <button onClick={() => this.sendChat(this.state.chatInput, 'text')} style={chatButton} className='btn-sm btn-light cursor-pointer'>Send</button>
                        <i onClick={() => this.refInput.click()} style={{ width: '10%', padding: '1vmax' }} className="fas fa-camera"></i>
                        <input
                            ref={el => {
                                this.refInput = el
                            }}
                            accept="image/*"
                            className='viewInputGallery'
                            type="file"
                            onChange={this.onChoosePhoto}
                        />
                    </div>
                </div>
            </SkyLight>
        }

        return <SkyLight
            hideOnOverlayClicked
            dialogStyles={myDialog}
            overlayStyles={myOverlay}
            ref={ref => this.chat = ref}
            afterOpen={() => this.setState({ read: true, showing: true })}
            afterClose={() => this.setState({ read: true, showing: false })}
            transitionDuration={0}
        >
            <div style={{ height: '80%', paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }} className="container" align="center">
                <h2 className='header-font'>Ask Joni</h2>
                <div style={chatDisplay} className='container'>
                    <div className='chat' style={{ listStyleType: 'none', margin: '0', padding: '0', textAlign: 'left', marginLeft: '2px' }}>{this.updateChatContent()}</div>
                    <div
                        ref={el => {
                            this.messagesEnd = el
                        }}
                    />
                </div>

            </div>
            <div style={{ height: '20%' }}>
                <textarea
                    style={chatInput} name='chat-input' rows='3' resize='none' type='text' placeholder='Ask anything!'
                    onChange={(e) => { this.setState({ chatInput: e.target.value }) }} value={this.state.chatInput}
                    onKeyDown={this._handleKeyDown}
                >
                </textarea>
                <div>
                    <button onClick={() => this.sendChat(this.state.chatInput, 'text')} style={chatButton} className='btn-sm btn-light cursor-pointer'>Send</button>
                    <i onClick={() => this.refInput.click()} style={{ width: '10%', padding: '1vmax' }} className="fas fa-camera"></i>
                    <input
                        ref={el => {
                            this.refInput = el
                        }}
                        accept="image/*"
                        className='viewInputGallery'
                        type="file"
                        onChange={this.onChoosePhoto}
                    />
                </div>
            </div>
        </SkyLight>
    }

    render() {
        const style = {
            position: 'fixed',
            bottom: '10vh',
            right: '0',
            maxWidth: '150px',
            width: '15vmax'
        }

        let notificationStyle;

        if (this.state.mobileView) {
            notificationStyle = {
                position: 'fixed',
                bottom: '18vmax',
                right: '20vmin',
                fontSize: '2vmax'
            }

        } else {
            notificationStyle = {
                position: 'fixed',
                bottom: '20vmin',
                right: '11vmin',
                fontSize: '2vmin'
            }
        }

        if (this.state.orientation === 0) {
            return <>

                {this.renderChat()}
                <div style={{ overflow: 'visible' }}>

                    <img className='cursor-pointer' onClick={() => this.askJoni()} style={style} alt='' src='dropdown-joni.png' ></img>
                    <span style={notificationStyle} className='badge badge-pill badge-danger'>
                        {!this.state.read ? '!' : ''}</span>
                </div>
            </>
        } else
            return <></>


    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(AskJoni);
