import React from 'react';
import OnboardDashboard1 from './OnboardDashboard1';
import OnboardQuestions from './OnboardQuestions';
import OnboardDashboard2 from './OnboardDashboard2';
import OnboardSettings from './OnboardSettings';
import history from '../../../history'


class OnboardPart2 extends React.Component{
  state = { step: 0 };

  static getDerivedStateFromProps(props, state){
    if (state.step >= 4) {
      return null
    }

    if (props.stageKey && props.stageKey > state.step) {
      if (props.stageKey === 1) {
        return null
      } else if (props.stageKey === 2) {
        return {
          step: 2,
        }
      } else if (props.stageKey === 3) {
        return {
          step: 3,
        }
      } else {
        return null
      }
    };

    return null;
  }

  changeParent = (value) => {
    this.setState({step: value});
  }

  renderContent(){
    if (this.state.step===0){
      return <OnboardDashboard1 changeParent={this.changeParent} />;
    } else if(this.state.step===1){
      return <OnboardQuestions changeParent={this.changeParent} />;
    } else if(this.state.step===2){
      return <OnboardDashboard2 changeParent={this.changeParent} />;
    } else if(this.state.step===3){
      return <OnboardSettings changeParent={this.changeParent} />;
    }else if (this.state.step===4){
      history.push('/');
    }
  }

  render(){
    return <>
      {this.renderContent()}
    </>
  }
}

export default OnboardPart2;