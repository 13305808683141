import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';

import { signOut } from '../../../actions';

class Topics extends React.Component {

    state = {
        level: this.props.secondary >= 4 ? 3 : this.props.secondary,
        show_all_topics: false,
        topics: null,
        selected_topics: [],
        saved: null,
        loading: false,
        save_message: "",
        // level: 
    }

    fetchAllTopics = async () => {
        this.setState({ loading: true });
        const response = await kopi.get('/api/topics/settings', {
            params: { userId: this.props.currentUser }
        });
        this.setState({ loading: false, topics: response.data[0], selected_topics: response.data[1] });
    }

    selectTopic(topic) {
        let selectArray = [];
        this.state.selected_topics.forEach(function (t) {
            selectArray.push(t._id);
        });
        if (selectArray.includes(topic._id)) {
            this.setState({ selected_topics: this.state.selected_topics.filter((t, i) => t._id !== topic._id) });
        } else {
            this.setState({ selected_topics: [...this.state.selected_topics, topic] });
        }
    }

    updateTopics = async () => {
        if (this.state.selected_topics.length !== 0) {
            this.setState({ loading: true });
            const response2 = await kopi.put('/api/topics/update', {
                userId: this.props.currentUser,
                selected_topics: this.state.selected_topics
            });
            let save_message = response2.data[2] ? "Topics saved! Your study plan and homework have been paused." : "Topics saved!"
            await this.setState({ loading: false, saved: true, save_message });
        } else {
            window.alert("You need to select at least one topic!")
        }
    }

    componentDidMount() {
        this.fetchAllTopics();
    }

    renderSelector = () => {

        let options = [1, 2, 3];

        const renderCount = (level) => {
            const count = this.state.selected_topics.filter(topic => topic.level === level).length
            if (count > 0){
                return <span className="badge badge-pill badge-info">{count}</span>
            }
        }

        const selected_style = { fontWeight: 'bold' }
        return <div align="center" class='row'>
            {options.map(level => {
                if (level == 3) {
                    return <div class="col">
                        <span className='cursor-pointer' key={level} style={this.state.level == level ? selected_style : {}} onClick={() => this.setState({ level: level })}> Upper Sec {renderCount(level)}</span>
                    </div>
                } else {
                    return <div class="col">
                <span className='cursor-pointer' key={level}  style={this.state.level == level ? selected_style : {}} onClick={() => this.setState({ level: level })}>Sec {level} {renderCount(level)}</span>
                    </div>
                }
            })
            }
        </div>
    }

    showTopics = (level) => {
        if (this.state.topics && level && this.props.secondary) {
            let selectArray = [];
            this.state.selected_topics.forEach(function (topic) {
                selectArray.push(topic._id);
            });
            return (
                <div className="row">
                    {this.state.topics[Number(level)].map(topic => {
                        if (selectArray.includes(topic._id)) {
                            return (
                                <div className="col-md-6">
                                    <div onClick={() => this.selectTopic(topic)} key={topic._id} className="cursor-pointer alert alert-info">
                                        {topic.title}
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="col-md-6">
                                    <div onClick={() => this.selectTopic(topic)} key={topic._id} className="cursor-pointer alert alert-dark">
                                        {topic.title}
                                    </div>
                                </div>
                            );
                        }
                    })
                    }
                </div>
            );
        } else {
            return <small>Loading</small>
        }
    }

    renderTopicSelection = () => {
        if (this.state.topics && this.state.level) {
            if (this.state.topics[1].length == 0 || this.state.topics[2].length == 0 || this.state.topics[3].length == 0) {
                return <div>
                    {this.showTopics(this.state.level)}
                </div>
            } else {
                return <div>
                    {this.renderSelector()}
                    <br />
                    {this.showTopics(this.state.level)}
                </div>
            }
        } else {
            return <></>
        }
    }

    render() {
        let savedText;
        if (this.state.saved) {
            savedText = <><br/><div className="alert alert-light" role="alert">
                {this.state.save_message}
            </div></> 
        }

        if (this.state.loading) {
            return <div className="container">
                {savedText}
                <br />
                <div align="center">
                    <br />
                    <div className="spinner-border text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <br />   <br />
                </div>
                </div>
        } else {
            return <div className="container">
                {savedText}
                <br />
                <button onClick={this.updateTopics} className="btn btn-info btn-block">Update</button>
                <br />
                <div className='header-font' align="center">
                    <h4>Select E-Maths Topics For Free Practice</h4>
                </div>
                <br />
                {this.renderTopicSelection()}
                <br />
                <button onClick={this.updateTopics} className="btn btn-info btn-block">Update</button>

                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>;
        }
    }
}



const mapStateToProps = state => {
    return {
        secondary: state.auth.secondary,
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { signOut })(Topics);