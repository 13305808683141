import React from 'react'
import { ShakeHorizontal } from 'reshake'
import Typing from 'react-typing-animation';

const ComplexLoading = (props) => {
    return <div class="padding-top-large" align="center">
        {/* <ShakeHorizontal fixed h={10} v={2} r={10}> */}
        <img style={{maxWidth: "40vw"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1566352225/joni-thinking.png" class="img img-fluid" />
        {/* </ShakeHorizontal> */}
        <br/><br/>       
            <p className="lead">Finding questions that are <br/> <strong>just right</strong> for you...</p>
    </div>

}

export default ComplexLoading