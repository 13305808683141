import React from 'react';

const SolutionView = (props) => {
    
        return <ul className="nav justify-content-center">
           <li className="nav-link nav-item" onClick={() => props.selectView("Question")}>
                <button className={`btn  ${props.solution_view === "Question" ? 'btn-part' : 'btn-light'} btn-sm btn-xs`}><strong>Question</strong></button>
            </li>
            <li className="nav-link nav-item" onClick={() => props.selectView("Solution")}>
                <button className={`btn ${props.solution_view === "Solution" ? 'btn-part' : 'btn-light'} btn-sm btn-xs`}><strong>Solution</strong></button>
            </li>
        </ul>

}

export default SolutionView;
