import React from 'react';
import { connect } from 'react-redux';
import 'whatwg-fetch';

/* CacheBuster component */
import packageJson from '../../../package.json';
global.appVersion = packageJson.version;

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
  
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
  
      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  };

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: async () => {
        // console.log('Clearing cache and hard reloading...')
        // if (caches) {
        //   // Service worker cache should be cleared with caches.delete()
        //  let names = await caches.keys()
        //  await this.asyncForEach(names, caches.delete)
        // }

        if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function(names) {
              return Promise.all(names.forEach(name => caches.delete(name)))
            }).then(window.location.reload(true))
          }
        // delete browser cache and hard reload
      }
    };
  }

    // asyncForEach = async (array, callback) => {
    // for (let index = 0; index < array.length; index++) {
    //     await callback(array[index], index, array);
    // }
    // };

  componentDidMount() {
    fetch('/meta.json')
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
  };
  
export default connect(mapStateToProps)(CacheBuster);