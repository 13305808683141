import React from 'react';
import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history'; 

import {SortableContainer, SortableElement} from 'react-sortable-hoc';

import arrayMove from 'array-move';

const SortableItem = SortableElement(({value}) =>  <div class="alert alert-light cursor-pointer" role="alert">
{value.title}
</div>);

const SortableList = SortableContainer(({items}) => {
  return (
    <ul className="unstyle-list">
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

class SortTopics extends React.Component {
    state = {sorted_topics: []}

    componentDidMount = () => {
      this.props.scrollToTop();
      this.setState({sorted_topics: this.props.sorted_topics})
    }

    onSortEnd = ({oldIndex, newIndex}) => {
      this.setState(({sorted_topics}) => ({
        sorted_topics: arrayMove(sorted_topics, oldIndex, newIndex),
      }));
    };

    renderTopicOrder(){
        return <div className="container padding-top">
          <i className="fas fa-chevron-left fa-2x cursor-pointer" aria-hidden="true" style={{color:"#354B80"}} onClick={this.props.onBack}></i> 
        <style></style>  
            <h3 className="dark-txt">Awesome! Now, <strong>drag and drop </strong> these topics in the order you would like to master them.</h3>
            <br/>
            <div class="row">
              <div class="col-10">
                <SortableList items={this.state.sorted_topics} onSortEnd={this.onSortEnd} />
              </div>
              <div class="col-2">
              </div>
            </div>
        </div>
    }

    renderSubmit(){
        return <nav class="navbar fixed-bottom navbar-light bg-white">
        <button onClick={()=>this.props.onSubmit(this.state.sorted_topics)} className="btn btn-block btn-blue">Generate Study Plan</button>
      </nav>
    }

    render(){
        return <>
         {this.renderTopicOrder()}
         {this.renderSubmit()}
         </>
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(SortTopics);