import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';
import history from '../../../history';
import Switch from 'react-switch';
import SkyLight from 'react-skylight';
import { myFirestore, myStorage } from '../../../apis/MyFirebase'
import imageCompression from 'browser-image-compression';

class UploadWorking extends React.Component {
    // window.location.reload(true)  
    constructor(props) {
        super(props)
        this.currentPhotoFile = null
    }

    componentDidMount = async () => {
    }

    uploadWorking = () => {
        this.working_upload.show();
    }

    notUploadWorking = () => {
        this.working_upload.hide();
    }
    onChoosePhoto = event => {
        if (event.target.files && event.target.files[0]) {
            this.setState({ loading: true })
            this.currentPhotoFile = event.target.files[0]
            // Check this file is an image?
            const prefixFiletype = event.target.files[0].type.toString()
            if (prefixFiletype.indexOf('image/') === 0) {
                this.uploadPhoto()
            } else {
                this.setState({ loading: false })
                window.alert('This file is not an image')
            }
        } else {
            this.setState({ loading: false })
        }
    }

    uploadPhoto = async () => {
        if (this.currentPhotoFile) {
            const timestamp = Date.now().toString()

            var options = {
                maxSizeMB: 1,
            }

            const compressedFile = await imageCompression(this.currentPhotoFile, options);

            const uploadTask = myStorage
                .ref()
                .child(timestamp)
                .put(compressedFile)

            uploadTask.on(
                'state_changed',
                null,
                err => {
                    this.setState({ loading: false })
                    window.alert(err.message)
                },
                () => {
                    uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
                        this.setState({ loading: false })
                        this.sendChat(downloadURL, 'image')
                        this.scrollToBottom()
                    })
                }
            )
        } else {
            this.setState({ loading: false })
            window.alert('File is null')
        }
    }

    render() {
        return <></>
        if (!this.props.ask_for_working) return <></>
        return <div class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">


                <div class="modal-content" style={{ overflow: "auto" }}>
                    {/* <div align="center">
                    <h4 onClick={() => { this.setState({ render: !this.state.render }) }} class="modal-title dark-blue-text"><strong>{this.props.title}</strong></h4>
                    <small class="dark-blue-text">{this.props.subtitle}</small>

                </div> */}
                    
                    <div class="modal-body">
                        <i onClick={() => this.refInput.click()} style={{ width: '10%', padding: '1vmax' }} className="fas fa-camera"></i>
                        <input
                            ref={el => {
                                this.refInput = el
                            }}
                            accept="image/*"
                            className='viewInputGallery'
                            type="file"
                            onChange={this.onChoosePhoto}
                        />
                    </div>
                </div>
            </div>
        </div>



    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(UploadWorking);


