import React from 'react';
import OnboardCards from './OnboardCards';
import history from '../../../history'
import {connect} from 'react-redux';



class NewOnboard extends React.Component{

    componentDidMount = () => {
      if (!this.props.isSignedIn || !this.props.currentUser){
        return history.push('/')
      }
    }

    render(){
      return <div>
        <OnboardCards/>
      </div>
    }
}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(NewOnboard);
