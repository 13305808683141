
import React from 'react';
import { connect } from 'react-redux';
// import kopi from '../../../../apis/kopi';

import GoalDetails from './GoalDetails';

import { signOut } from '../../../../actions';


const Goal = (props) => {

    const alertClass = (goal) => {
        return goal.has_concluded ? "alert-dark" : "alert-light"
    }

    return <div onClick={()=>{props.selectGoal(props.goal)}} className={`alert cursor-pointer ${alertClass(props.goal)}`}>
            <div className="row">
                <div className="col-5">
                    <div>
                        {props.goal.has_concluded ? "" : <><b>ACTIVE</b><br/></>}
                        <small>End Date</small>
                        <strong><h3 className="no-bottom-margin">{new Date(props.goal.end_date).getDate()}/{new Date(props.goal.end_date).getMonth()+1}</h3></strong>
                    </div>
                </div>
                <div className="col-7">
                    <div align="right">
                        <small> Topics Left: {props.goal.topics_left.length} 
                        </small><br/>
                        <small>Topics Practicing: {props.goal.topics_practicing.length} </small>
                    </div>
                </div>
            </div>
        </div>

}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
  };
      
export default connect(mapStateToProps, {signOut})(Goal);