import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/firestore";
import "firebase/storage";


const config = ({   
    apiKey: "AIzaSyBhYTGTydIWbmKikWV9gs3vZQFs-lWrW6I",
    authDomain: "joni-student-web-app.firebaseapp.com",
    databaseURL: "https://joni-student-web-app.firebaseio.com",
    projectId: "joni-student-web-app",
    storageBucket: "joni-student-web-app.appspot.com",
    messagingSenderId: "97720955491",
    appId: "1:97720955491:web:ab119bd112417e2926fe56",
    measurementId: "G-Z5CC54G5Q9"
})

// This must run before any other firebase functions
firebase.initializeApp(config)
firebase.firestore().settings({})
firebase.analytics();

export const myFirebase = firebase
export const myFirestore = firebase.firestore()
export const myStorage = firebase.storage()