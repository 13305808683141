import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history';

import kopi from '../../../apis/kopi';


import {Link} from 'react-router-dom';

import socketIOClient from "socket.io-client";

import QuestionCard from './QuestionCard'
import MasteriesUpdate from './JoniModal/MasteriesUpdate'
import JoniModal from './JoniModal'; 

let socket;

class Session extends React.Component {

    state = {
        // endpoint: "https://k8sapi.joni.ai/api",
        // endpoint: "http://localhost:3000", 
        endpoint: "https://k8sapi.joni.ai",

        question: null,
        attempted_questions: [],
        my_attempts: [],
        current_part_index: 0,

        answer_feedback: null,
        updated_masteries: null,
        marked_question: null,
        error: false,
        concluded: false,
        pause: false,
        loading: false
    };  

    timer;

    takeAttendance = () => {
        socket.emit("takeAttendance", {session_id: this.props.session_id, user_id: this.props.currentUser});
    }

    updateQuestion = async () => {
        if (!this.state.loading) this.setState({loading: true})
        let response = await kopi.get('/api/mentor_practice/get_next_question', {
            params: {
                user_id: this.props.currentUser,
                session_id: this.props.session_id
            }
        })

        if (!response || response.data.error) {
            this.setState({loading: false, pause: true})
            return;
        };

        if (this.state.question) {
            if (response.data.question[0].id === this.state.question[0].id) {
                if (response.data.question.length == response.data.current_part_index){
                    this.setState({loading: false, pause: true})
                    return;
                }
            }
        }

        this.setState({loading: false, question: response.data.question, current_part_index: response.data.current_part_index, error: false, answer_feedback: null, updated_masteries: null, marked_question: null, concluded: false, pause: false})
    }

    UNSAFE_componentWillMount = async () => {
        const { endpoint } = this.state;
        socket = socketIOClient(endpoint, {path: '/apisocket/socket.io'});
        window.addEventListener('beforeunload', this.leavePractice, false);

        socket.on("takeAttendance", data=>this.takeAttendance())
        socket.on("newQuestion", data=>this.updateQuestion(data))
        socket.on("endSession", (data)=>{this.setState({concluded: data})})

        let response = await kopi.get('/api/mentor_practice/get_next_question', {
            params: {
                user_id: this.props.currentUser,
                session_id: this.props.session_id
            }
        })
        if (response.data.error) {
            this.setState({error: true}); 
            return;
        }

        this.setState({question: response.data.question, current_part_index: response.data.current_part_index, error: false})
    }

    componentDidMount = async () => {
        this.timer = setInterval(
             () => socket.emit("joinPractice", this.props.session_id),
             30000
           );
        socket.emit("takeAttendance", {session_id: this.props.session_id, user_id: this.props.currentUser});
    }

    leavePractice = () => {
        socket.emit("leavePractice", {session_id: this.props.session_id, user_id: this.props.currentUser});
    }

    componentWillUnmount = () => {
        this.leavePractice();
        clearInterval(this.timer);
        socket.close();
    }

    renderAnswerFeedbackModal = () => {
        if (this.state.updated_masteries && this.state.answer_feedback) {
            let content = <MasteriesUpdate updated_masteries={this.state.updated_masteries} />;
            let title = this.state.answer_feedback.is_correct ? "Great Job!" : "Oh Dear!"
            let subtitle = this.state.answer_feedback.is_correct ? "Joni is proud of you!" : "You can do better!"
            return <JoniModal is_happy={this.state.answer_feedback.is_correct} title={title} subtitle={subtitle} content={content} />
        } else {
            return <></>
        }
    }

    renderPracticeComponents = () => {
            return <>
                {this.renderAnswerFeedbackModal()}
                <div className="padding-top">
                    {this.renderQuestionCard()}
                </div>
            </>
        }

    renderQuestionCard = () => {
        if (this.state.current_part_index !== null && this.state.question && this.state.current_part_index < this.state.question.length && !this.state.pause) {
            return <>
            <QuestionCard 
                loading={this.state.loading} 
                key={this.state.question[0].id + this.state.current_part_index} 
                question={this.state.question} 
                current_part_index={this.state.current_part_index} 
                marked_question={this.state.marked_question} 
                showing_modal={this.state.updated_masteries ? true : false} 
                onSubmit={this.onSubmit} all_parts={this.state.all_parts} 
                answer_feedback={this.state.answer_feedback} />
            </>
        } else {
            return <>
                Please wait for your teacher to proceed.
                <button class="btn btn-info btn-lg" onClick={this.updateQuestion}> Fetch Again </button>
            </>
        }
    }
    

    onSubmit = async (action, value) => {
        
        switch(action){
            case "attempt_current_question":
                    this.setState({loading: true})
                    let response1 = await kopi.post('/api/mentor_practice/submit', {
                    qn_id: this.state.question[this.state.current_part_index].id,
                    user_id: this.props.currentUser,
                    session_id: this.props.session_id,
                    ans: value,
                    });

                    if (response1.data.show_solution) {
                        this.setState({loading: false, answer_feedback: response1.data.answer_feedback, updated_masteries: response1.data.updated_masteries, marked_question: response1.data.marked_question});            
                    } else {
                        this.updateQuestion()
                    }

                    break; 

            case "view_current_solution":
                this.setState({loading: false, updated_masteries: null});
                break; 

            case "fetch_next_question":
                this.setState({loading: true})
                this.updateQuestion()
                break;
            
        }


    }

    renderError =() => {
        if (this.state.current_part_index !== null && this.state.question && this.state.current_part_index < this.state.question.length && !this.state.pause) {
            return <>
             return <div class="alert-danger"> Please wait for your teacher to proceed.
            <button class="btn btn-light btn-lg" onClick={this.updateQuestion}> Fetch Again </button></div>
            <QuestionCard loading={this.state.loading} key={this.state.question[0].id + this.state.current_part_index} question={this.state.question} current_part_index={this.state.current_part_index} marked_question={this.state.marked_question} showing_modal={this.state.updated_masteries ? true : false} onSubmit={this.onSubmit} all_parts={this.state.all_parts} answer_feedback={this.state.answer_feedback} />
            </>
        }
        return <div> Please wait for your teacher to proceed.
        <button class="btn btn-info btn-lg" onClick={this.updateQuestion}> Fetch Again </button></div>
    }

    render() {
        if (this.state.concluded) {
            return <div className="" align="center" style={{marginTop: '5vh'}}>
                <h1>Session has ended!</h1> 
                <button class="btn btn-lg btn-main" onClick={()=>{history.push('/')}}> End Session </button>
            </div>

        } else if (!this.state.error && !this.state.pause) {
            return <div align="center">
            {this.renderPracticeComponents()}  
            </div>
        } else {
            return this.renderError()
        }
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(Session);


//
// 