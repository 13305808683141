import React, { useState } from 'react'; 

import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

const SolutionField = props => {

    const renderAnswerContext = () => {
        let seperator = "\${1,2}";
        if (props.selected_part.prefix || props.selected_part.suffix){
          let prefix = ""
          let suffix = ""

          if (props.selected_part.prefix) prefix = props.selected_part.prefix;
          if (props.selected_part.suffix) suffix = props.selected_part.suffix;

          return  <>
          <InlineTex texSeperator={seperator} texContent={prefix  + " _____ " + suffix} />     
          <br/>
          </>
        } else {
          return <></>
        }
      }

    const renderWrongStudentAttempt = () => {
        let student_attempt = props.attempts.find(attempt=>attempt.question._id == props.selected_part.id)
        if (!student_attempt) {
            return <div className="alert alert-danger">
                Not Attempted
            </div>
        }
        if (student_attempt && !student_attempt.correct) {
            return <div className="alert alert-danger">
                {renderAnswerLatex(student_attempt.answer)}
            </div>
        }
        return <></>
    }

    const renderAnswerLatex = (answer) => {
        let seperator = "\${1,2}";
        let content1 =  props.selected_part.units == '\$' ? "$" : ""
        let content2 = answer
        let content3 = props.selected_part.units && props.selected_part.units !== '\$' ? props.selected_part.units : ""
        return <InlineTex texSeperator={seperator} texContent={content1 + content2 + content3} />     
    }

    const renderSolutionLatex = (text) => {
        let seperator = "\${1,2}";
        if (text && text.startsWith("\\begin")) {
            return <div align="left" className="" style={{overflowX: "auto", overflowY:"auto"}}>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Tex texContent={text} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        } else {
            return <div align="left"><InlineTex texSeperator={seperator} texContent={text} /></div>
        }
    }

    const renderSolution = () => {
                return <>
                    <br/>
                        <div className="alert alert-success">
                            { renderAnswerLatex(props.selected_part.ans) }
                        </div>
                        {renderWrongStudentAttempt()}
                        <img src={props.selected_part.sol_img} className="img-fluid" />
                        {renderSolutionLatex(props.selected_part.sol_txt)} 
                </>
           
    }
    
   
            return <div>
                {renderAnswerContext()}
                {renderSolution()}
            </div>
   
    
}

export default SolutionField;


  