import React from 'react'; 
import MCQField from './MCQField';
import TextField from './TextField';
import Self from './Self';

class AnswerField extends React.Component {


    renderAnswerForm = () => {
        if (this.props.current_part.qn_type === 'mcq'){
            return <MCQField dark_mode={this.props.dark_mode} attempt={this.props.attempt} updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        } else if (this.props.current_part.qn_type === 'self'){
            return <Self dark_mode={this.props.dark_mode} attempt={this.props.attempt} updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        } else {
            return <TextField dark_mode={this.props.dark_mode} attempt={this.props.attempt}  updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        }   
    }
  
    render() {
        return <>
        {this.renderAnswerForm()}
        </>
    }

}

export default AnswerField;

