import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../../apis/kopi';

import { signOut } from '../../../../actions';

import Topics from './Topics';
import Goals from './Goals';
import Config from './Config';

import Footer from '../../../Footer';

class Settings extends React.Component{

    state = {
        mode: "topics", // "topics" , "goals"
        key: true
    }

    whenClicked=()=>{
        this.setState({mode: "config", key: !this.state.key});
        this.props.toggleConfig();
    }
    
    whenClicked2=()=>{
        this.setState({mode: "topics", key: !this.state.key});
        this.props.goTopics();
    }

    renderModeSelector = () => {
        return <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
                <span onClick={() => this.whenClicked2()} className={`nav-link ${this.state.mode === "topics" ? "target" : ""}`}>Topics</span>
            </li>
            <li className="nav-item">
                <span onClick={() => this.setState({mode: "goals", key: !this.state.key})} className={`nav-link ${this.state.mode === "goals" ? "target" : ""}`}>Goals</span>
            </li>
            <li className="nav-item">
                <span onClick={() => this.whenClicked()} className={`nav-link ${this.state.mode === "config" ? "target" : ""}`}>Config</span>
            </li>
        </ul>
    }

    renderMode = () => {
        switch (this.state.mode) {
            case 'topics':
                return <Topics key={this.state.key} toggleTopics={this.props.toggleTopics}/>
            case 'goals':
                return <Goals key={this.state.key} />
            case 'config':
                return <Config key={this.state.key} learningTopics={this.props.learningTopics} continueTour={this.props.continueTour}/>

            default: 
                return <></>
        }
    }

    render(){
       return <>
       {this.renderModeSelector()}
       {this.renderMode()}
       <Footer />
       </>
    }
}



const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, {signOut})(Settings);