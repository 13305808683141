import React, { useState } from 'react';
import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history'; 


const SelectTopics = (props) => {

    let num_of_days_from_now = Math.ceil((new Date(props.endDate).getTime()-new Date(Date.now()).getTime())/(3600000 * 24))

    const [warning, triggerWarning] = useState(false);

    const renderWarning = () => {
        if ((num_of_days_from_now/7)/(props.selected_topics.length) < 1.5) {
            if (!warning){
                props.scrollToTop();
                triggerWarning(true);
            } 
            return  <div class="alert alert-danger" role="alert">
                Wow, hold on! Loving the enthusiasm, but selecting this many topics within this timeframe may result in a very hectic schedule!
          </div>
        } else {
            return <></>
        }
    }

    const renderTopicPicker = () => {
        return <div className="container">
            <style></style>
            <i className="fas fa-chevron-left fa-2x cursor-pointer" aria-hidden="true" style={{color:"#354B80"}} onClick={props.onBack}></i> 
            <h3 className="dark-txt">Select <strong>topics</strong> you would like to master in these <strong>{num_of_days_from_now}</strong> days.</h3>
            <br/>
            {renderWarning()}
            <br/>
            {props.topics.map(topic=>{
                  if(props.selected_topics.includes(topic)){
                    return <div onClick={()=>{props.selectTopic(topic)}} class="alert alert-info cursor-pointer" role="alert">
                    {topic.title}
                  </div>
                  } else {
                 return <div onClick={()=>{props.selectTopic(topic)}} class="alert alert-light cursor-pointer" role="alert">
                    {topic.title}
                  </div>
                  }
                })
            }
        </div>
    };

    const renderSubmit = () => {
        return <nav class="navbar fixed-bottom navbar-light bg-white">
        <button onClick={props.onSubmit} className="btn btn-block btn-blue">Continue</button>
      </nav>
    }

    return <div className="padding-top">
        {renderTopicPicker()}
        {renderSubmit()}
        </div>
    
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(SelectTopics);


//
// 