import React from 'react'; 
import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

const QuestionPart = props => {
    const renderQuestionImage = () => {
        if (props.selected_part.qn_img){
            return <div>
                    <img className="img-fluid" src={props.selected_part.qn_img} /><br/><br/>
                </div>
        } else {
            return <></>
        }

    }

    const renderQuestionLatex = () => {
        let seperator = "\${1,2}";
        let qn_txt;
        if (props.selected_part.qn_txt && props.selected_part.qn_txt.startsWith("\\begin")){
            qn_txt = <div className="" style={props.dark_mode ? {color:"#E1E1E1", overflowX: "auto"} : {overflowX: "auto"}}>
                        <Tex texContent={props.selected_part.qn_txt} />
                    </div>    
        } else {
            qn_txt = <div style={props.dark_mode ? {color:'#E1E1E1'} : {}}><InlineTex texSeperator={seperator} texContent={props.selected_part.qn_txt} /></div>
        }
        return qn_txt;
    }

    if (props.marked_question && props.solution_view === "Solution") {
        return <></>
    } else {
        return <div>
        {renderQuestionImage()}
        {renderQuestionLatex()}
    </div> 
    }
}

export default QuestionPart;


  