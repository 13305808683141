import React from 'react'; 
import MCQField from './MCQField';
import TextField from './TextField';
import Self from './Self';
import SkyLight from 'react-skylight';


import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

class AnswerField extends React.Component {


    renderAnswerForm = () => {
        if (this.props.current_part.qn_type === 'mcq'){
            return <MCQField attempt={this.props.attempt} updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        } else if (this.props.current_part.qn_type === 'self') {
            return <Self attempt={this.props.attempt} updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        } else {
            return <TextField attempt={this.props.attempt}  updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        }   
    }

    renderSubmitButton = () => {
        if (this.props.loading) {
            return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button className="btn btn-block btn-blue btn-not-rounded">Joni is thinking...</button>
            </nav>
        } else if (this.props.answered){
            return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
            <button onClick={()=>this.props.onSubmit("view_current_solution" , this.props.attempt)} className="btn btn-block btn-blue btn-not-rounded">View Solution</button>
        </nav>
        } else {
            if(this.props.attempt===""){
                return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button onClick={()=>{}} className="btn btn-block btn-blue btn-not-rounded">Enter Answer</button>
            </nav>
            } else {
                const checkRegexAndSubmit = (value) => {
                    if (this.props.current_part.qn_type === 'text') {
                        const re = new RegExp('^[-+]?[0-9]+(\.[0-9]+)?$') 
                        let match = value.match(re) 
                        match = match ? !value.match('/') : false 
                        if (!match && this.props.current_part.qn_type === 'text'){
                            this.regexError.show()
                            return;
                        } else {
                            value = value.replace(/^(-)?0+(?=\d)/, '$1')
                        }
                    }
                   
                    this.props.onSubmit("attempt_current_question" , value)
                }

                return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button onClick={()=>checkRegexAndSubmit(this.props.attempt)} className="btn btn-block btn-orange btn-not-rounded">Confirm Answer</button>
            </nav>
            }
            
        }
    }

    renderPreviousAttempts = () => {
        const nextChar = (c, index) => {
            return String.fromCharCode(c.charCodeAt(0) + index);
        }

        const renderAnswerLatex = (part, answer) => {
            let seperator = "\${1,2}";
            let prefix = part.prefix ? part.prefix : ""
            let content1 = part.units == '\$' ? "$" : ""
            let content2 = answer
            let content3 = part.units && part.units !== '\$' ? part.units : ""
            let suffix = part.suffix ? part.suffix : ""

            return <InlineTex texSeperator={seperator} texContent={prefix + " " + content1 + content2 + content3 + " " + suffix} />     
        }
        
        if (this.props.all_parts[0].correct_ans || this.props.all_parts[0].student_ans) {

            let flavor_text = this.props.all_parts[0].correct_ans ? "SOLUTIONS TO PREVIOUS PARTS" : "YOUR ATTEMPTS AT PREVIOUS PARTS"
            return <div className="card">
            <div className="card-body" style={{paddingTop: '5px'}}>
            <small style={{fontSize: '0.6em'}}>{flavor_text}</small>
            <br/>
            {this.props.all_parts.map((part,index)=>{
                if (part.correct_ans) {
                    return <div>
                        ({nextChar('a', index)}) 
                        {renderAnswerLatex(part, part.correct_ans)}
                    </div>
                } else if (part.student_ans) {
                    return <div>
                        ({nextChar('a', index)}) 
                        {renderAnswerLatex(part, part.student_ans)}
                    </div>
                } else {
                    return <></>
                }
            })}
            </div>
        </div>
        } else {
            return <></>
        }
        
       

    }

    render() {

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return <>
        {this.renderAnswerForm()}
        {this.renderPreviousAttempts()}
        {this.renderSubmitButton()}
        <SkyLight dialogStyles={style} hideOnOverlayClicked ref={ref => this.regexError = ref} >
            <div align="center">
                <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006990/Joni%20Assets/answer_wrong.png" className="img-fluid" />
                <h4>Oops. You can only enter integers and decimals!</h4>
            </div>
        </SkyLight>
        </>
    }

}

export default AnswerField;

