import React from 'react';
import PartSelector from './PartSelector';
import SolutionView from './SolutionView';

const SelectToggle = props => {

    if (props.marked_question){
        return <SolutionView solution_view={props.solution_view} marked_question={props.marked_question} selectView={props.selectView} selected_part={props.selected_part} all_parts={props.all_parts} current_part={props.current_part} />
    } else {
        return <PartSelector marked_question={props.marked_question} selectPart={props.selectPart} selected_part={props.selected_part} all_parts={props.all_parts} current_part={props.current_part} />
    }
}
export default SelectToggle;
