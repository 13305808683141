import React from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Flipper, Flipped } from "react-flip-toolkit";
import JoniModal from '../../Training/JoniModal'; 
import QuestionCard from './QuestionCard'; 
import SkyLight from 'react-skylight';
import PauseMenu from './PauseMenu';




class OnboardQuestions extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      run: true,
      steps: [
        {
          content: "Welcome to the Question Page!",
          placement: 'center',
          target: 'body',
          disableOverlayClose: true,
          hideCloseButton: true,

        },
        {
          content: 'You can see the question and answers here.',
          placement: 'bottom',
          spotlightPadding: 30,
          target: '.answer-form',
          disableOverlayClose: true,
          hideCloseButton: true,

        },
        {
          content: "This button allows you to pause.",
          target: '.fa-pause',
          placement: 'right',
          disableOverlayClose: true,
          hideCloseButton: true,

        },
        {
          content: "And you can submit your answer using this button!",
          placement: 'top',
          target: '.btn-blue',
          hideCloseButton: true,
          disableOverlayClose: true,
        },
        {
          content: "For a start, let us try doing this question! Select this option.",
          placement: 'top',
          target: '.alert:first-of-type',
          spotlightClicks: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          disableBeacon: true,
          hideFooter:true,
        },
        {
          content: "And submit your answer!",
          placement: 'top',
          target: '.btn-not-rounded',
          styles:{
            options:{
              spotlightShadow: '0 0 0px rgba(0, 0, 0, 0)',
            }
          },
          spotlightClicks: true,
          disableOverlayClose: true,
          hideCloseButton: true,
          hideFooter: true,
        },
        {
          content: "Great job!",
          placement: 'center',
          target: 'body',
          styles:{
            options:{
              spotlightShadow: '0 0 0px rgba(0, 0, 0, 0)',
            }
          },
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
          locale: {next:"Thanks!"}
        },
        {
          content: "You can see the solution for the question by clicking here.",
          placement: 'top',
          target: '.btn-not-rounded',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
          hideFooter: true,
          spotlightClicks: true,
          disableOverlay: true,
        },
        {
          content: "Awesome! Hope you have a better understanding of the question mechanics now!",
          placement: 'center',
          target: 'body',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
        },
        {
          content: "Let us go back to the home page. Click here.",
          placement: 'right',
          target: '.fa-home',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
          hideFooter: true,
          spotlightClicks: true,
        },
        {
          content: "Select 'End Session' to return.",
          placement: 'right',
          target: '.btn-danger',
          disableOverlayClose: true,
          hideCloseButton: true,
          hideBackButton: true,
          hideFooter: true,
          spotlightClicks: true,
        },
      ],
      current_part:{
        // "id": 1,
        "search_objectives": [
          "5cdc539d78c34fb863526255",
          "5cdc539d78c34fb86352625b",
          "5cdc539d78c34fb863526253"
        ],
        "search_prereq_objectives": [
          "5cdc539c78c34fb863526240"
        ],
        "search_topics": [
          "5cdc539c78c34fb863526252"
        ],
        "search_prereq_topics": [
          "5cdc539c78c34fb86352623a"
        ],
        "search_subobjectives": [
          "5cdc539d78c34fb863526256",
          "5cdc539d78c34fb86352625c",
          "5cdc539d78c34fb863526254"
        ],
        "search_prereq_subobjectives": [
          "5cdc539c78c34fb863526244"
        ],
        "topics": [
          "5cdc539c78c34fb863526252"
        ],
        "prereq_topics": [
          "5cdc539c78c34fb86352623a"
        ],
        "objectives": [
          "5cdc539d78c34fb863526255",
          "5cdc539d78c34fb86352625b",
          "5cdc539d78c34fb863526253"
        ],
        "prereq_objectives": [
          "5cdc539c78c34fb863526240"
        ],
        "subobjectives": [
          "5cdc539d78c34fb863526256",
          "5cdc539d78c34fb86352625c",
          "5cdc539d78c34fb863526254"
        ],
        "prereq_subobjectives": [
          "5cdc539c78c34fb863526244"
        ],
        "attempts": [
          "5d06ea3d3396e750c5a896fb",
          "5d0b65bcbb96ea2a3360566d",
          "5d17178fd61df03e45086c19"
        ],
        "tags": [
          
        ],
        "revenue": 0,
        "multiplier": 1,
        "flags": [
          
        ],
        "_id": "5cdf6d0203d63f146bf33d4f",
        "similar_questions": [
          
        ],
        "created_at": "2019-05-18T02:25:06.203Z",
        "status": "Published",
        "parts": 1,
        "ready": true,
        "author": "5cde0767f1a1203958228704",
        "__v": 8,
        "ready_number": 10,
        "name": "Sec 1 Basic Qn 1",
        "modified_at": "2019-05-18T02:34:38.691Z",
        "header_txt": "",
        "level": 1,
        "difficulty": 1,
        "qn_txt": "Subtract $2 a+3 b$ from $5 b-4 a$",
        "qn_type": "mcq",
        "has_sol": true,
        "sol_txt": "\\begin{array}{lcl}5 b-4 a-(2 a+3 b) \\\\=5 b-4 a-2 a-3b \\\\ =5b-3b-4a-2a\\\\=2b-6a\\end{array}",
        "ans": "$2b-6a$",
        "prefix": "",
        "suffix": "",
        "units": "",
        "ans_alt1": "$-2b+6a$",
        "ans_alt2": "$8b-2a$",
        "ans_alt3": "$2a-8b$",
        "sent_to_review_at": "2019-05-18T17:02:01.639Z",
        "published_at": "2019-05-18T17:09:52.369Z",
        "search_difficulty": 1,
        "first_published_at": "2019-05-18T04:04:06.700Z",
        "editor": "5cde0626f1a1203958228703",
        "author_data": "5ce22e5d743d7fa2c616c60b",
        "probono": false
      },
      answer_feedback: null,
      all_parts:[],
      is_correct: null,
      pause: false,
      cooldown_complete: true,
      created_at: Date.now(),
      want_similar_question: false,
      correct_answers: 0,
      wrong_answers: 0,
      marked_question: null,
      updated_masteries: null,
      showing_modal: null,
      stepIndex: 0,
      answerSelected: false,
      answerSubmitted: false,
      viewingSolution: false,
      sessionPaused: false,
    };
  }

  selectAnswer = () =>{
    const { run, answerSelected, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 4 ? false : run,
      answerSelected: !answerSelected,
      stepIndex: stepIndex === 4 ? 5 : stepIndex,
    });
  }

  submitAnswer=()=>{
    const { run, answerSubmitted, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 5 ? false : run,
      answerSubmitted: !answerSubmitted,
      stepIndex: stepIndex === 5 ? 6 : stepIndex,
    });
  }

  viewSolution = ()=>{
    const { run, viewingSolution, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 7 ? false : run,
      viewingSolution: !viewingSolution,
      stepIndex: stepIndex === 7 ? 8 : stepIndex,
    });
  }

  pauseSession2 = ()=>{
    const { run, sessionPaused, stepIndex } = this.state;
    this.setState({
      run: stepIndex === 9 ? false : run,
      sessionPaused: !sessionPaused,
      stepIndex: stepIndex === 9 ? 10 : stepIndex,
    });
  }
  handleJoyrideCallback = data => {
    const { answerSelected, answerSubmitted, viewingSolution, sessionPaused } = this.state;
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({ run: false });
    }
    else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      if (answerSelected && index==4){
        setTimeout(() => {
          this.setState({ run: true });
        }, 0);
      }
      else if (answerSelected && index==5) {
        this.setState({
          run: false,
          answerSelected: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else if (index === 5 && action === ACTIONS.PREV) {
        this.setState({
          run: false,
          answerSelected: true,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else if(answerSubmitted && index==5){
        setTimeout(() => {
          this.setState({ run: true });
        }, 200);
      }else if (answerSubmitted&&index==6){
        this.setState({
          run: false,
          answerSubmitted: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 200);
        });
      }else if(viewingSolution && index==7){
        setTimeout(() => {
          this.setState({ run: true });
        }, 500);
      }else if (viewingSolution &&index==8){
        this.setState({
          run: true,
          viewingSolution: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else if(sessionPaused && index==9){
        setTimeout(() => {
          this.setState({ run: true });
        }, 400);
      }else if (sessionPaused &&index==10){
        this.setState({
          run: true,
          sessionPaused: false,
          stepIndex,
        }, () => {
          setTimeout(() => {
            this.setState({ run: true });
          }, 400);
        });
      }else{
        this.setState({
          answerSelected: false,
          answerSubmitted: false,
          viewingSolution: false,
          sessionPaused: false,
          stepIndex,
        });
      }

      // console.log(this.state.stepIndex)
    }
    console.groupCollapsed(type);
    // console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };


  renderAnswerFeedbackModal = () => {
      if (this.state.showing_modal) {
          let title = this.state.is_correct? "Great Job!" : "Oh Dear!"
          let subtitle = this.state.is_correct ? "Joni is proud of you!" : "You can do better!"
          return <JoniModal is_happy={this.state.is_correct} title={title} subtitle={subtitle} content={null} />
      } else {
          return <></>
    }
  }
  renderQuestionCard = () => {
    if (this.state.current_part) {
        return <QuestionCard key={this.state.current_part.id + 5} selectAnswer={this.selectAnswer} want_similar_question={this.state.want_similar_question} toggleSimilarQuestion={this.toggleSimilarQuestion} correct_answers={this.state.correct_answers} wrong_answers={this.state.wrong_answers} pauseSession={this.pauseSession} marked_question={this.state.marked_question} showing_modal={this.state.showing_modal} onSubmit={this.onSubmit} all_parts={this.state.all_parts} current_part={this.state.current_part} answer_feedback={this.state.answer_feedback} />
    } else {
        return <></>
    }
  }

toggleSimilarQuestion = (want_similar_question) => {
    this.setState({want_similar_question});
  }
pauseSession = (setToPause) => {
    this.pauseSession2();
    setToPause ? this.simpleDialog.show() : this.simpleDialog.hide()
    this.setState({pause: !this.state.pause});
}
renderPauseMenu = () => {

  const style = {
    width: '80%',
    left: '35vw',
    minHeight: '0',
    zIndex: '9999'
  }
  return  <SkyLight dialogStyles={style} hideOnOverlayClicked ref={ref => this.simpleDialog = ref} >
   <PauseMenu changeParent={this.props.changeParent} no_more_questions={false} time_elapsed={parseInt((new Date(Date.now())-new Date(this.state.created_at))/60000.0)} correct_answers={this.state.correct_answers} wrong_answers={this.state.wrong_answers} pauseSession={this.pauseSession} current_part={this.state.current_part} /> 
</SkyLight>
}

onSubmit = async (action, value) => {
  let newCorrect;
  let newWrong;
  switch(action){
      case "attempt_current_question":
          let feedback = {
            sol_txt: "\\begin{array}{lcl}5 b-4 a-(2 a+3 b) \\\\=5 b-4 a-2 a-3b \\\\ =5b-3b-4a-2a\\\\=2b-6a\\end{array}",
            student_answer: "$2b-6a$",
            is_correct: true,
            attempt_id: 0,
            ans: "$2b-6a$",
          }
          if (this.state.current_part._id == "5cdf6d0203d63f146bf33d4f"){
            newCorrect = 1;
            newWrong = 0;
          } else{
            newCorrect = 0;
            newWrong = 1;
            feedback.is_correct = false;
            feedback.sol_txt = "\\begin{array}{l}\na^{3}-b^{3}\\\\\n=7^{3}-(-3)^{3}\\\\\n=343-(-27) \\\\ \n=343+27\\\\\n=370\n\\end{array}";
          }
          this.submitAnswer();

      this.setState({answerSelected: false, showing_modal: true, correct_answers: newCorrect, wrong_answers: newWrong, answer_feedback: feedback, marked_question: feedback, is_correct: feedback.is_correct});            
      break; 

      case "view_current_solution":
          this.setState({updated_masteries: null, showing_modal: false});
          this.viewSolution();
          break; 
      case "fetch_next_question":
          this.setState({answer_feedback: null, marked_question: null, current_part: {
            "search_objectives": [
              "5cdc539d78c34fb863526253",
              "5cdc539d78c34fb863526255",
              "5cdc539d78c34fb863526259",
              "5cdc539d78c34fb863526253",
              "5cdc539d78c34fb863526255",
              "5cdc539d78c34fb863526259"
            ],
            "search_prereq_objectives": [
              "5cdc539c78c34fb863526240",
              "5cdc539c78c34fb863526240"
            ],
            "search_topics": [
              "5cdc539c78c34fb863526252"
            ],
            "search_prereq_topics": [
              "5cdc539c78c34fb86352623a",
              "5cdc539c78c34fb86352623a",
              "5cdc539c78c34fb86352623a",
              "5cdc539c78c34fb86352623a",
              "5cdc539c78c34fb86352623a"
            ],
            "search_subobjectives": [
              "5cdc539d78c34fb863526254",
              "5cdc539d78c34fb863526256",
              "5cdc539d78c34fb86352625a",
              "5cdc539d78c34fb863526254",
              "5cdc539d78c34fb863526256",
              "5cdc539d78c34fb86352625a"
            ],
            "search_prereq_subobjectives": [
              "5cdc539c78c34fb863526241",
              "5cdc539c78c34fb863526244",
              "5cdc539c78c34fb863526243",
              "5cdc539c78c34fb863526241",
              "5cdc539c78c34fb863526244"
            ],
            "topics": [
              "5cdc539c78c34fb863526252"
            ],
            "prereq_topics": [
              "5cdc539c78c34fb86352623a",
              "5cdc539c78c34fb86352623a"
            ],
            "objectives": [
              "5cdc539d78c34fb863526253",
              "5cdc539d78c34fb863526255",
              "5cdc539d78c34fb863526259"
            ],
            "prereq_objectives": [
              "5cdc539c78c34fb863526240"
            ],
            "subobjectives": [
              "5cdc539d78c34fb863526254",
              "5cdc539d78c34fb863526256",
              "5cdc539d78c34fb86352625a"
            ],
            "prereq_subobjectives": [
              "5cdc539c78c34fb863526241",
              "5cdc539c78c34fb863526244"
            ],
            "attempts": [
              "5d17016bd61df03e45086bbf"
            ],
            "tags": [
              
            ],
            "revenue": 0,
            "multiplier": 1,
            "flags": [
              
            ],
            "_id": "5cdf71cb03d63f146bf33d51",
            "similar_questions": [
              
            ],
            "created_at": "2019-05-18T02:45:31.451Z",
            "status": "Published",
            "parts": 1,
            "ready": true,
            "author": "5cde0767f1a1203958228704",
            "__v": 9,
            "ready_number": 10,
            "name": "Sec 1 Basic Qn 2",
            "modified_at": "2019-05-24T10:12:17.924Z",
            "level": 1,
            "header_txt": "",
            "difficulty": 1,
            "qn_txt": "Given that $a=7$ and $b=-3$ , find the value of $a^{3}-b^{3}$",
            "qn_type": "text",
            "has_sol": true,
            "sol_txt": "\\begin{array}{l}\na^{3}-b^{3}\\\\\n=7^{3}-(-3)^{3}\\\\\n=343-(-27) \\\\ \n=343+27\\\\\n=370\n\\end{array}",
            "ans": "370",
            "sent_to_review_at": "2019-05-18T17:12:10.209Z",
            "published_at": "2019-05-18T17:17:14.423Z",
            "search_difficulty": 2,
            "first_published_at": "2019-05-18T04:04:04.745Z",
            "editor": "5cde0626f1a1203958228703",
            "author_data": "5ce22e5d743d7fa2c616c60b",
            "probono": false,
          }});
          break;
      default: 
          this.setState({show: !this.state.show});
  }


}

  render () {
    // const { breakpoint } = this.props;
    const {run, steps, stepIndex} = this.state;
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          steps={steps}
          continuous
          scrollToFirstStep
          showProgress = {false}
          showSkipButton={false}
          stepIndex={stepIndex}
          run={run}
          styles={{
            options: {
              primaryColor: '#354B80',
              zIndex: 99999999999999,
            }
          }}
        />
        <>
          <ul className="list"><li>
          <Flipped stagger="card">
            <div className="listItem" style={{ backgroundColor: "#FFD472" }}>
              <Flipped>
                <div className="listItemContent">
                  <Flipped stagger="card-content">
                    <div className="description" text-align="center" >
                      <Flipped>
                        <small style={{fontSize: "1em"}}><strong>You do not have a study plan yet.</strong></small>
                      </Flipped>
                    </div>
                  </Flipped>
                </div>
              </Flipped>
            </div>
          </Flipped>
          </li></ul>
            {this.renderPauseMenu()}
                {this.renderAnswerFeedbackModal()}
                <div className="padding-top">
                    {this.renderQuestionCard()}
                </div>
        </>
      </div>
    );
  };
}


export default OnboardQuestions;