import React from 'react'
import {InlineTex} from 'react-tex';

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const TopicBar = props => {

    const {rank, onTopicClick} = props

    let current_skill;
    let prev_skill;
    let nxt_skill;

    let current_rank;
    let prev_rank;
    let nxt_rank;

    let current_rank_img;
    let prev_rank_img;
    let nxt_rank_img;

    let progress
    let rank_color = '#FFFFFF';

    if (rank.skill < 25) {
        // start: 0
        // end: 30
        progress = 50 + (rank.skill)/25*50

        current_skill = rank.skill;
        current_rank = "Novice III"
        current_rank_img = "https://image.flaticon.com/icons/svg/340/340330.svg"

        prev_skill = null;
        prev_rank = null;
        prev_rank_img = null;
        
        nxt_skill = 25;
        nxt_rank = "Novice II";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340331.svg'

    } else if (rank.skill < 38) {

        progress = 50 + (rank.skill-25)/10*50

        current_skill = rank.skill
        current_rank = "Novice II"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340331.svg'

        prev_skill = 20
        prev_rank = "Novice III";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340330.svg'
        
        nxt_skill = 38;
        nxt_rank = "Novice I";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340332.svg'

    } else if (rank.skill < 42) {

        progress = 50 + (rank.skill-38)/10*50

        current_skill = rank.skill
        current_rank = "Novice I"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340332.svg'
        
        prev_skill = 25;
        prev_rank = "Novice II";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340331.svg'
        
        nxt_skill = 42;
        nxt_rank = "Apprentice";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340326.svg'

    } else if (rank.skill < 53) {

        progress = 50 + (rank.skill-42)/10*50

        rank_color = '#FAE65D'
        current_skill = rank.skill
        current_rank = "Apprentice"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340326.svg'

        prev_skill = 38
        prev_rank = "Novice I"
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340332.svg'
        
        nxt_skill = 53;
        nxt_rank = "Master";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340327.svg'

    } else if (rank.skill < 69) {

        progress = 50 + (rank.skill-53)/15*50

        rank_color = '#3076A3'
        current_skill = rank.skill
        current_rank = "Master"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340327.svg'

        prev_skill = 42
        prev_rank = "Apprentice";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340326.svg'
        
        nxt_skill = 69;
        nxt_rank = "Grandmaster";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340328.svg'

    } else if (rank.skill < 84) {

        progress = 50 + (rank.skill-69)/15*50
        
        rank_color = '#89C762'
        current_skill = rank.skill
        current_rank = "Grandmaster"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340328.svg'

        prev_skill = 53
        prev_rank = "Master";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340327.svg'
        
        nxt_skill = 84;
        nxt_rank = "Julian";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340329.svg'

    } else if (rank.skill < 95) {
        
        progress = 50 + (rank.skill-84)/10*50

        rank_color = '#e5e4e2'
        current_skill = rank.skill
        current_rank = "Julian"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340329.svg'

        prev_skill = 69
        prev_rank = "Grandmaster";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340328.svg'
        
        nxt_skill = 95;
        nxt_rank = "Destroyer of All Evil"
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340342.svg'

    } else {

        progress = 100

        current_skill = rank.skill
        current_rank = "Destroyer of All Evil"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340342.svg'

        prev_skill = 90
        prev_rank = "Julian";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340329.svg'
        
        nxt_skill = null;
        nxt_rank = null;
        nxt_rank_img = null;
    }

        let seperator = "\${1,2}";

        const renderImg = (src, accomplished) => {
            if (src) {
                return <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width="30"
                src={src}
              />
            } else {
                return <></>
            }
        }

        const renderCurrentRankImg = (src, accomplished) => {
            if (src) {
                return <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)`, align:"centre" }}
                width="80"
                src={src}
              />
            } else {
                return <></>
            }
        }

        return <div onClick={()=>onTopicClick(rank.topic)}>
        <hr/>
        <div align="center" style={{color:'#E1E1E1'}} className='header-font'>
            <InlineTex texSeperator={seperator} texContent={rank.topic.title} /><br/>
            <br/> 
        </div>
        <div class="col-12" align="center">
                <strong style={{color: rank_color}}>{current_rank}</strong>
                
            </div>
        <div style={{marginLeft: '-30px', marginRight: '-30px'}} class="row">
            <div class="col-6 float-left">
                <div class="float-left">
                    <small style={{color:'#E0E0E0'}}>{prev_rank}</small>
                </div>
                
            </div>
            
            <div class="col-6">
                <div class="float-right">
                    <small style={{color:'#E0E0E0'}}>{nxt_rank}</small>
                </div>
            </div>
        </div >
        <br/>
        <ProgressBar 
        percent={progress}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        >
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            renderImg(prev_rank_img,accomplished)
          )}
        </Step>
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            renderCurrentRankImg(current_rank_img,accomplished)
          )}
        </Step>
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            renderImg(nxt_rank_img,accomplished)
          )}
        </Step>
        </ProgressBar>
        <br/> <br/>
   
        
        </div>

}



export default TopicBar