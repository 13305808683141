import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../history';

import {Link} from 'react-router-dom';

class Kicked extends React.Component {

    state = {invite_code: null}

    componentDidMount = () => {
        let invite_code = window.location.pathname.split("/slip/kicked/").pop()
        this.setState({invite_code})
    }
    
    render() {
        return <div className="" align="center">
            <p class="lead">
                You got kicked out of your session! Reflect on your life choices, before joining again by <Link to={"/slip/" + this.state.invite_code}>clicking here</Link>
            </p>
        </div>
    }
}




export default Kicked;


