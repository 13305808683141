import React from 'react';
import {connect} from 'react-redux';
import history from '../../history'; 
import kopi from '../../apis/kopi';

class StudyPlanSummary extends React.Component {

    state = {
        bare_goal: 0,
        recommended_goal: 0,
        stretch_goal: 0,
        start_date: new Date(),
        end_date: new Date(),
        weeks: []
    };

    componentDidMount = async () => {
        
        if (!this.props.isSignedIn || !this.props.currentUser){
            return history.push('/')
        }
        
        let response = await kopi.get('/api/study_plans/summary', {
                params: {
                    userId: this.props.currentUser,
                }
        });
      if (response.data) {
    //    console.log(response.data)
       this.setState({
           bare_goal: response.data.study_plan.bare_goal,
           recommended_goal: response.data.study_plan.recommended_goal,
           stretch_goal: response.data.study_plan.stretch_goal,

           start_date: response.data.study_plan.start_date,
           end_date: response.data.study_plan.end_date,

           weeks: response.data.study_plan.study_weeks
       })
      }
    }

    renderDuration = () => {
        return <div>
            
        <h3>
            <b> 
                {new Date(this.state.start_date).toString().split(new Date().getFullYear()).length > 1 ? 
                    new Date(this.state.start_date).toString().split(new Date().getFullYear())[0] : 
                        new Date(this.state.start_date).toString().split(new Date().getFullYear()-1)[0]} - 
                {new Date(this.state.end_date).toString().split(new Date().getFullYear()).length > 1 ? 
                    new Date(this.state.end_date).toString().split(new Date().getFullYear())[0] : 
                        new Date(this.state.end_date).toString().split(new Date().getFullYear()-1)[0]
                } 
            </b>
        </h3>
            
        </div>
    }

    renderWeeks = () => {
        const renderCompletionStatus = (parts_done) => {
                if (parts_done >= this.state.bare_goal){
                    return "Bare Minimum 🥉"
                } else if (parts_done >= this.state.recommended_goal) {
                    return "Recommended 🥈"
                } else if (parts_done >= this.state.stretch_goal){
                    return "Stretch 🥇"
                } else {
                    return "No award"
                }
            }


        return this.state.weeks.map(week=>{
            return <div>
                <h5><b> 
                    {new Date(week.start_date).toString().split(new Date().getFullYear()).length > 1 ? 
                        new Date(week.start_date).toString().split(new Date().getFullYear())[0] : 
                            new Date(week.start_date).toString().split(new Date().getFullYear()-1)[0]} - 
                    {new Date(week.end_date).toString().split(new Date().getFullYear()).length > 1 ? 
                        new Date(week.end_date).toString().split(new Date().getFullYear())[0]: 
                            new Date(week.end_date).toString().split(new Date().getFullYear()-1)[0]
                    } 
                </b></h5>
                {week.parts_done} parts done ({renderCompletionStatus(week.parts_done)})

            </div>
        })
    }

    endPlan = async () => {
    
        await kopi.put('/api/study_plans/reviewed', {
            userId: this.props.currentUser,
        });

        history.push('/')
    }

//     const timeDiff  = (new Date(startDate)) - (new Date(endDate));
// const days      = timeDiff / (1000 * 60 * 60 * 24)

    render=()=>{
      return <div className="container">
         <div style={{paddingTop: "5vh"}} align="center">
          <img style={{height: '150px'}} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006990/Joni%20Assets/celebration.png" />
          <br/><br/>
          <p class="lead">
            Joni wishes you all the best!
          </p>
          {this.renderDuration()}
          <button className="btn btn-light btn-sm" onClick={()=>this.endPlan()}> 
              <strong>Back to Dashboard</strong> 
          </button>
          <p>
            
          </p>
          <hr/>
          {this.renderWeeks()}
         </div>
      </div>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(StudyPlanSummary);