import React from 'react'; 
import MCQField from './MCQField';
import TextField from './TextField';

class AnswerField extends React.Component {


    renderAnswerForm = () => {
        if (this.props.current_part.qn_type === 'mcq'){
            return <MCQField attempt={this.props.attempt} selectAnswer={this.props.selectAnswer} updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        } else {
            return <TextField attempt={this.props.attempt}  updateAttempt={this.props.updateAttempt} current_part={this.props.current_part} />
        }   
    }

    renderSubmitButton = () => {
        if (this.props.answered){
            return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
            <button onClick={()=>this.props.onSubmit("view_current_solution" , this.props.attempt)} className="btn btn-block btn-blue btn-not-rounded">View Solution</button>
        </nav>
        } else {
            if(this.props.attempt===""){
                return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button onClick={()=>{}} className="btn btn-block btn-blue btn-not-rounded">Enter Answer</button>
            </nav>
            } else {
                return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button onClick={()=>this.props.onSubmit("attempt_current_question" , this.props.attempt)} className="btn btn-block btn-orange btn-not-rounded">Confirm Answer</button>
            </nav>
            }
            
        }
    }

    render() {
        return <>
        {this.renderAnswerForm()}
        {this.renderSubmitButton()}
        </>
    }

}

export default AnswerField;

