import React from 'react';
import GoogleSlide from './GoogleSlide.js';
import YouTube from './YouTube.js';
import { InlineTex } from 'react-tex';

class Intervention extends React.Component {

    // THIS.PROPS.INTERVENTIONS //
    // [{topic: subobjective.topic_title,
    // subobjective: subobjective.title,
    // type: intervention.type,
    // format: intervention.format,
    // reference: intervention.reference,
    // intervention_id: intervention._id}]
    // has_answered

    state = { loading: false }

    renderInterventions = () => {

        let references = [];
        let topics = [];

        // topics = [{
        //     title: "",
        //     interventions: [{
        //         subobjectives: [],
        //         format: "",
        //         reference: ""
        //     }]
        // }]

        let existing_topic
        let existing_intervention
        this.props.interventions.forEach(intervention => {
            existing_topic = topics.findIndex(topic => topic.title == intervention.topic)

            if (existing_topic != -1) {  // found
                existing_intervention = references.findIndex(reference => {
                    // console.log('l')
                    // console.log('ll', reference)
                    // console.log('llll', intervention.reference)
                    return reference == intervention.reference
                })
                // console.log('findIndex', existing_intervention)
                // console.log(existing_intervention != -1 ? 'found ' + intervention.reference : 'not found' + intervention.reference)
                if (existing_intervention != -1) { // found
                    // console.log('added to existing intervention')

                    topics[existing_topic].interventions[existing_intervention].subobjectives.push(intervention.subobjective)
                } else { // not found
                    // console.log('added new intervention')

                    topics[existing_topic].interventions.push({
                        subobjectives: [intervention.subobjective],
                        format: intervention.format,
                        reference: intervention.reference
                    })
                    references.push(intervention.reference)

                }
            } else {
                topics.push({
                    title: intervention.topic,
                    interventions: [
                        {
                            subobjectives: [intervention.subobjective],
                            format: intervention.format,
                            reference: intervention.reference
                        }
                    ]
                })
                references.push(intervention.reference)
            }
        })

        const renderLatexSub = (sub) => {
            let seperator = "\${1,2}";
            return <div style={this.props.dark_mode ? {color:'#E1E1E1'} : {}}><small><InlineTex texSeperator={seperator} texContent={sub} /></small></div>
        }

        return topics.map(topic => {
            return <><h5 style={this.props.dark_mode ? {color:'#E1E1E1'} : {}}>{topic.title}</h5>
                {
                    topic.interventions.map(intervention => {
                        let format_reference;
                        // eslint-disable-next-line default-case
                        switch (intervention.format) {
                            case "Google Slides":
                                format_reference = intervention.reference.replace("/edit?usp=sharing", "")
                                format_reference = format_reference.replace("drive.google.com/open?id=", "docs.google.com/presentation/d/")
                                return <>{intervention.subobjectives.map(sub => renderLatexSub(sub))}
                                    <div class="google-slides-container">
                                        <GoogleSlide dark_mode={this.props.dark_mode} src={format_reference} loading_message={"Loading"} />
                                    </div>
                                    <a style={this.props.dark_mode ? {color:'#A0A0A0'} : {}} target="_blank" rel="noopener noreferrer" href={format_reference}>Open in Google Slide</a>
                                    <br /><br />
                                </>
                            case "YouTube":
                                format_reference = intervention.reference.replace("m.youtube.com/", "youtube.com/")
                                format_reference = format_reference.replace("m.youtu.be/", "youtube.com/")
                                format_reference = intervention.reference.replace("youtube.com/watch?", "youtube.com/")
                                format_reference = format_reference.replace("youtube.com/", "youtube.com/embed/")
                                format_reference = format_reference.replace("youtu.be/", "youtube.com/embed/")
                                format_reference = format_reference.replace("v=", "")

                                if (format_reference.includes('&t')) {
                                    let array_of_strings = format_reference.split('&t');
                                    let newStr = array_of_strings[1].substring(0, array_of_strings[1].length - 1);
                                    const final_string = array_of_strings[0] + '?start' + newStr;
                                    format_reference = final_string;
                                }

                                return <>
                                    {intervention.subobjectives.map(sub => renderLatexSub(sub))}
                                    <div class="google-slides-container">
                                        <YouTube dark_mode={this.props.dark_mode} src={format_reference} loading_message={"Loading"} />
                                    </div> <br /><br />
                                </>
                        }
                    })
                }
            </>
        })
    }

    renderBottomBar = () => {

        const onSubmit = () => {
            this.setState({ loading: true })
            this.props.onSubmit("fetch_next_question", this.props.want_similar_question)
        }

        if (this.props.is_self_assessment || !this.props.has_answered) {
            return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button onClick={() => this.props.toggleIntervention()}
                    style={this.props.dark_mode ? {backgroundColor:'#ffcc80', color:'black'} : { background: '#FED472', color: 'black' }}
                    className="btn btn-default btn-blue btn-block btn-not-rounded">Back
                    </button>
            </nav>
        } else {
            if (this.state.loading) return <nav class="customnav" style={{ height: "9vh" }}>
                <div className="row" style={{ height: "100%", marginLeft: "0", marginRight: "0" }} >
                    <div className="col" style={{ padding: '0' }}>
                        <button onClick={() => this.props.toggleIntervention()}
                            style={this.props.dark_mode ? {backgroundColor:'#ffcc80', color:'black'} : { background: '#FED472', color: 'black' }}
                            className="btn btn-default btn-blue btn-block btn-not-rounded">Back
                    </button>
                    </div>
                    <div className="col" style={{ padding: '0' }}>
                        <button style={this.props.dark_mode ? {backgroundColor:'#9fa8da', color:'black'} : {}} 
                                className="btn btn-default btn-blue btn-block btn-not-rounded">Loading</button>
                    </div>
                </div>
            </nav >

            return <nav class="customnav" style={{ height: "9vh" }}>
                <div className="row" style={{ height: "100%", marginLeft: "0", marginRight: "0" }} >
                    <div className="col" style={{ padding: '0' }}>
                        <button onClick={() => this.props.toggleIntervention()}
                            style={this.props.dark_mode ? {backgroundColor:'#ffcc80', color:'black'} : { background: '#FED472', color: 'black' }}
                            className="btn btn-default btn-blue btn-block btn-not-rounded">Back
                        </button>
                    </div>
                    <div className="col" style={{ padding: '0' }}>
                        <button
                            onClick={() => onSubmit()}
                            style={this.props.dark_mode ? {backgroundColor:'#9fa8da', color:'black', zIndex: ''} : {zIndex: ''}}
                            className="btn btn-default btn-blue btn-block btn-not-rounded">Next Question
                        </button>
                    </div>
                </div>
            </nav >
        }
    }
    render() {
        const componentStyle = 
            this.props.dark_mode ? 
            {
                paddingTop: "15px", 
                paddingBottom: "20vh",
                backgroundColor: '#121212',

            } :
            {
                paddingTop: "15px", 
                paddingBottom: "20vh",
            }
        return <>
            <div className="container" style={componentStyle}>
                {this.renderInterventions()}

            </div>
            {this.renderBottomBar()}
        </>
    }
}
export default Intervention;