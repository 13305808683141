import React from 'react'
import { ShakeHorizontal } from 'reshake'
import Typing from 'react-typing-animation';

const Loading = (props) => {
    return <div class="padding-top-large" align="center">
        {/* <ShakeHorizontal fixed h={10} v={2} r={10}> */}
            {/* <img style={{maxWidth: "40vw"}} src="512.png" class="img img-fluid" /> */}
        {/* </ShakeHorizontal> */}
        {/* <br/> */}
            <p className="lead">Loading...</p>
    </div>

}

export default Loading