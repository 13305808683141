import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../history';

import kopi from '../../apis/kopi';

import Loading from '../Loading'

import {Link} from 'react-router-dom';

import Session from './Session';

import socketIOClient from "socket.io-client";

let socket;

class Live extends React.Component {

    componentDidMount = () => {
        if (!this.props.isSignedIn || !this.props.currentUser){
            return history.push('/')
        }
    }

    state = {
        invite_code: '', 
        session_id: null,
        // endpoint: "https://k8sapi.joni.ai/api",
        // endpoint: "https://localhost:3000",
        endpoint: "https://k8sapi.joni.ai",
        
    }

    joinSession = async () => {
        let response = await kopi.put('/api/mentor_practice/join_session', {
            invite_code: this.state.invite_code,
            user_id: this.props.currentUser
        })
        if (response) {
            this.setState({session_id: response.data.session_id});
        }
    }

    renderSessionLogin = () => {
        return <div style={{paddingTop: '30vh'}}> <img style={{ maxWidth: "35vw" }} src='https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png' />
        <br /><br />
        {this.state.session_id}
        <input value={this.state.invite_code} onChange={e=>this.setState({invite_code: e.target.value})} placeholder="Enter Session Code" class="form-control" type="text" style={{ textAlign: "center", width: '70vw', fontSize: "1em" }} />
        <btn onClick={this.joinSession} style={{ width: "70vw" }} class="btn btn-main"> Let's Go! </btn><br/><br/>
        <Link to="/" >Back to Home</Link>
        </div>
    }

    renderSession = () => {
        return <Session session_id={this.state.session_id} />
    }

    render() {
        return <div className="" align="center">
                {this.state.session_id ? this.renderSession() : this.renderSessionLogin()}
        </div>
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(Live);


//
// 