import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';

import history from '../../../history';

import { signOut } from '../../../actions';

import SkyLight from 'react-skylight';

import Prereq from './Prereq.js';

import Switch from "react-switch";

class Config extends React.Component{

    state = {invite_key: "", mentor_connected: null, page: 0, access_all_topics: false, mentor_name: '', password:'', secondary: null, username: null}

    componentDidMount = async () => {
        kopi.get('/api/mentors/get-tutelage',{
            params: {user: this.props.currentUser}
         }).then(response=>{
             if (response.data) {
                this.setState({mentor_connected: true, mentor_name: response.data});
             } else {
                this.setState({mentor_connected: false});
             }
         })
         kopi.get('/api/topics/is_accessing_all_topics',{
            params: {user_id: this.props.currentUser}
         }).then(response=>{
            if (response.data) this.setState({
                                    access_all_topics: response.data.has_access, 
                                    secondary: response.data.student_secondary,
                                    username: response.data.username
                                    });
         })
    }

    connectToMentor = async () => {
        let response = await kopi.post('/api/mentors/join-tutelage',{
            user: this.props.currentUser,
            invite_key: this.state.invite_key
        });
        if (response.data){
            this.setState({mentor_connected: true, mentor_name: response.data});
            this.interact.hide();
        } else {
            this.setState({mentor_connected: false});
        }
    }

    disconnectFromMentor = () => {
        if (this.state.password === '12345'){
            kopi.delete('/api/mentors/leave-tutelage',{
                data: {user: this.props.currentUser}
            });
            this.interactDisconnect.hide();
            this.setState({mentor_connected: false, mentor_name:''});
        } else {
            this.interactDisconnect.hide();
            window.alert('Wrong code. Please contact info@joni.ai for assistance.')
        }
    }

    renderMentorButton = () => {
        switch (this.state.mentor_connected) {
            case null:
                return <button onClick={() => window.alert('Your parent may upgrade via the Joni Parent app. Thank you!')}type="submit" class="btn btn-block btn-light">Upgrade to Mentor plan to connect mentor</button>
            case true :
                return <button onClick={() => this.interactDisconnect.show()} type="submit" class="btn btn-block btn-main">Disconnnect</button>
            case false :
                return <button onClick={()=>this.interact.show()} className="btn btn-main btn-block">{this.state.mentor_connected ? "Connected To Mentor": "Connect To A Mentor"}</button>
        }
    }

    renderConnectToModal = () => {

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return <SkyLight
                dialogStyles = {style}
                closeButtonStyle = {{color: "transparent"}}
                hideOnOverlayClicked 
                ref={ref => this.interact = ref}
                >
                <div className="container" align="center">
                    <div class="form-group">
                        <input value={this.state.invite_key} type="text" class="form-control" id="password" placeholder="Mentor Invite Code" onChange={(e)=>this.setState({invite_key: e.target.value})} />
                    </div>
                    <button onClick={this.connectToMentor} type="submit" class="btn btn-block btn-main">Connect</button>
                </div>
            </SkyLight>
    }

    renderDisconnectModal = () => {

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return <SkyLight
                dialogStyles = {style}
                closeButtonStyle = {{color: "transparent"}}
                hideOnOverlayClicked 
                ref={ref => this.interactDisconnect = ref}
                >
                <div className="container" align="center">
                    <div class="form-group">
                        <input value={this.state.password} type="text" class="form-control" id="password" placeholder="Disconnect Code" onChange={(e)=>this.setState({password: e.target.value})} />
                    </div>
                    <button onClick={this.disconnectFromMentor} type="submit" class="btn btn-block btn-main">Disconnect</button>
                </div>
            </SkyLight>
    }

    renderJoinLiveButton = () => {
        if (this.state.mentor_connected) {
            return <button onClick={()=>history.push('/live')} type="submit" class="btn btn-block btn-info">Join Live Session</button>
        } else {
            return <></>
        }
    }

    renderMentorName = () => {
        return <>Your Mentor: <strong>{this.state.mentor_name}</strong><br/></>
    }

    renderMentor = () => {
        return <div className="col-md-4">  
        <h5 className='header-font'>Get supported in your learning</h5>
            <div className="card" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
                <div className="card-body" align="center">
                    {this.state.mentor_name !== '' ? this.renderMentorName() : ''}
                    <img className="img-fluid" style={{height: "20vh"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560546592/Joni%20Assets/pro_bono.png" />
                    <br/><br/>
                    {this.renderMentorButton()}
                    {this.renderJoinLiveButton()}
                </div>
            </div>
            <div className="padding-top" />
        </div>
    }


    renderPayment = () => {
        // return <div className="col-md-8">
        //             <h4>Sponsored Account</h4>
        //             <hr/>
        //             Next Payment Due: Tomorrow <br/><br/>
        //             <small>
        //             Questions Attempted: 0 <br/>
        //             Training Sets Completed: 0
        //             </small>
        
        
        // <div className="padding-top" />
        // </div>
    }

    renderEditAccount = () => {
        // return <div className="col-md-8">  
        //             <h4>Change Password</h4> 
        //             <hr/>
        //             <form>
        //                 <div class="form-group">
        //                     <input type="email" class="form-control" id="email"  placeholder="Old Password" />
        //                 </div>
        //                 <div class="form-group">
        //                     <input type="password" class="form-control" id="password" placeholder="New Password" />
        //                 </div>
        //                 <button type="submit" class="btn btn-main float-right">Confirm</button>
        //             </form>
        //         </div>
    }

    renderButtons = () => {
        return <div className="col-md-4">  
                            <h5 className='header-font'>Let Joni know you better</h5>
                            
            <div className="card" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
                
                <div className="card-body" align="center" style={{paddingTop: '8px'}}>
                {this.state.secondary < 6 ? <> You are in <strong>Secondary {this.state.secondary}</strong> <br/> </> :  <> You are an <strong>Admin</strong> <br/> </>}
                    
                    <button style={{marginTop: '8px'}} onClick={() => this.setState({page: 1})} className="btn btn-dark btn-block">Indicate Learnt Topics</button>
                    
                </div>
            </div>
            
        </div>
    }

    whateverMakesYouHappy = () => {
        const renderButton = () => {
            const toggleAccess = () => {
                kopi.put('/api/topics/toggle_access_all_topics',{
                    user_id: this.props.currentUser                    
                });
                this.setState({access_all_topics: !this.state.access_all_topics})
            }

           return <div>
            <h5>Allow access to all topics</h5>
           <Switch height={20} onColor={"#FFD472"} uncheckedIcon={false} checkedIcon={false} onChange={toggleAccess} checked={this.state.access_all_topics} />
            </div>
        }
        return <div className="col-md-4">  
                            <h5 className='header-font'>Whatever makes you happy</h5>
            <div className="card" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
                <div className="card-body" align="center">
                    {renderButton()}
                </div>
            </div>
            <div className="padding-top" />
        </div>
    }


    render(){
        if (this.state.page === 0) {
            return <div className="padding-top">
            {this.renderConnectToModal()}
            {this.renderDisconnectModal()}
            <div className="container">
                <div className="row">
                    {this.renderMentor()}
                    {this.renderPayment()}
                    {this.renderButtons()}
                    <div style={{marginTop: '150px'}} />
                    {this.whateverMakesYouHappy()}
                    {this.renderEditAccount()}
                </div>
                <button onClick={() => this.props.signOut()} className="btn btn-danger btn-block">Log out from <strong>{this.state.username}</strong></button>
                <small>Current App Version: {String(global.appVersion)}</small>
            </div>
            <div className="padding-top" />
            <div className="padding-top" />
            <div className="padding-top" />
    
            </div>
        } else {
            return <div class="container">
            <br/>
            <button onClick={()=>this.setState({page: 0})} className="btn btn-dark btn-block"><i class="fas fa-arrow-left"></i>&nbsp;Back</button>
            <Prereq/>
            </div>
        }
       
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, {signOut})(Config);