import React from 'react';
import kopi from '../../apis/kopi'
import { connect } from 'react-redux';
import { signIn } from '../../actions';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import history from '../../history.js';
import '../Joni.css';
import Sparkle from 'react-sparkle'

class ChangePassword extends React.Component {

    state = {
        username: "",
        password: "",
        changed_password: false,
        error: false
    }

    renderError = () => {
        if (this.state.error) {
            return "Either the email you provided is wrong, or the password reset link has expired."
        } else {
            return <></>
        }

    }

    renderusernameSignIn = () => {

        const changePassword = async () => {
            const { token } = this.props.match.params
            let response = await kopi.post('/api/auth/change_password', {
                username: this.state.username, reset_password_key: token, password: this.state.password
            })
            response.data.success ? this.setState({ changed_password: true }) : this.setState({ error: true })
        }

        if (this.state.changed_password) {
            return <div>Changed Password
                <br /><br />
                <button onClick={() => history.push('/')} className="btn btn-blue btn-block button-text-small"> Back to Login </button>
            </div >
        }
        return <> <small className="error-messages">{this.renderError()}</small>
            <br />
            <div class="form-group">
                <input autoComplete="off" placeholder="Your Username" onChange={(e) => { this.setState({ username: e.target.value }) }} value={this.state.username} className="form-control"  />
            </div>
            <div class="form-group">
                <input autoComplete="off" placeholder="Your New Password" onChange={(e) => { this.setState({ password: e.target.value }) }} value={this.state.password}  className="form-control" />
            </div>
            {this.state.changed_password ? <button onClick={() => history.push('/')} className="btn btn-blue btn-block button-text-small"> Back to Login </button> : <button onClick={changePassword} className="btn btn-blue btn-block button-text-small"> Change Password </button>}


            <br />
        </>
    }

    render() {
        return (
            <div style={{ height: '100%', overflow: "hidden" }}>
                <div className="login-centralise">

                    <div className="container padding-bottom" align="center">
                        {this.props.location && this.props.location.state && this.props.location.state.message ? <span>{this.props.location.state.message}</span> : ''}
                        <div style={{ position: 'relative' }}>
                            <br /><br />
                            {this.state.changed_password ? <h1>That's all!</h1> : <h1>Change Password</h1>}

                            <br />
                            <img src='https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png' className="img img-fluid" style={{ maxHeight: "25vh" }} />
                            <br />
                            {/* <Sparkle color={'random'} count={8}/> */}
                        </div>
                        <div>

                            <br />
                            {this.renderusernameSignIn()}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        secondary: state.auth.secondary
    };
};

export default connect(mapStateToProps, { signIn })(ChangePassword);
