
import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../../apis/kopi';

import history from '../../../../history';

import { signOut } from '../../../../actions';

import SkyLight from 'react-skylight';


class ProgressDetails extends React.Component {

    // this.props.homework
    // =======================
    // id: homework._id,
    // parts_done: homework.parts_done,
    // end_date: homework.end_date,
    // bare_goal: homework.bare_goal,
    // recommended_goal: homework.recommended_goal,
    // stretch_goal: homework.stretch_goal,
    // active: homework.active,
    // topics: homework.topics.map(t => t.title)

    state = { confirm_delete: false, active: this.props.homework.active }

    renderGold = () => {
        let gold_trophy_count = 0;
        if (this.props.homework.parts_done >= this.props.homework.stretch_goal) gold_trophy_count++;
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_gold.png" /><br />
            <strong>x{gold_trophy_count}</strong>
        </div>;
    }

    renderSilver = () => {
        let silver_trophy_count = 0;
        if (this.props.homework.parts_done >= this.props.homework.recommended_goal) silver_trophy_count++;

        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1566396152/Joni%20Assets/trophy_silver-removebg-preview.png" /> <br />
            <strong>x{silver_trophy_count}</strong>
        </div>;
    }

    renderBronze = () => {
        let bronze_trophy_count = 0;
        if (this.props.homework.parts_done >= this.props.homework.bare_goal) bronze_trophy_count++;

        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_bronze.png" /> <br />
            <strong>x{bronze_trophy_count}</strong>
        </div>;
    }

    toggleHomeworkMode = () => {
        kopi.put('/api/homework/resume_homework', {
            user_id: this.props.currentUser,
            homework_progress_id: this.props.homework.id
        });
        this.props.toggleHomeworkActive(this.props.homework.id)
        this.setState({
            active: !this.state.active
        })
    }

    renderTopics = () => {
        return this.props.homework.topics.map(t => {
            return <div className="alert alert-info">
                {t}
            </div>
        })
    }

    render() {
        return <div className="container padding-bottom">
            <div align="center">
                {new Date(this.props.homework.end_date) <= new Date(Date.now()) || !this.props.homework.homework_plan_active ? <></> : this.state.active ?
                    <button className="btn btn-block btn-light" onClick={this.toggleHomeworkMode}>Pause Homework Mode </button>
                    :
                    <button className="btn btn-block btn-info" onClick={this.toggleHomeworkMode}>Enter Homework Mode </button>
                }
                <br />
            </div>
            <div className="row">
                <div className="col" align="center">
                    {this.renderBronze()}
                </div>
                <div className="col" align="center">
                    {this.renderSilver()}
                </div>
                <div className="col" align="center">
                    {this.renderGold()}
                </div>
            </div>
            <br />
            <div align="center"><h3>Deadline: {new Date(this.props.homework.end_date).toString().split(new Date(this.props.homework.end_date).getYear() + 1900)[0]}</h3>
            Bare Goal: {this.props.homework.bare_goal} parts <br/>
            <strong>Recommended Goal: {this.props.homework.recommended_goal} parts</strong> <br/>
            Stretch Goal: {this.props.homework.stretch_goal} parts
            </div>
            <br />
            {this.renderTopics()}
        </div >

    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
}
export default connect(mapStateToProps, { signOut })(ProgressDetails);