import React from 'react';


import QuestionHeader from './QuestionHeader/index.js';
import QuestionPart from './QuestionPart/index.js';
import AnswerField from './AnswerField/index.js';
import SolutionField from './SolutionField/index.js';
import SelectToggle from './SelectToggle/index.js';
import SimilarQuestionsToggle from './SelectToggle/SimilarQuestionsToggle.js';
import Intervention from './Intervention/index.js';
import { myFirestore, myStorage } from '../../../apis/MyFirebase'
import imageCompression from 'browser-image-compression';

import { connect } from 'react-redux';
import { InlineTex } from 'react-tex';


import kopi from '../../../apis/kopi';

class QuestionCard extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      imageURL: "",
      attempt: "", selected_part: this.props.current_part, solution_view: "Solution",
      favorited: false, intervention: false, intervention_activated: false, interventions: []
    }
    this.currentPhotoFile = null
    this.loading = false;
  }

  toggleSimilarQuestion = (want_similar_question) => {
    this.props.toggleSimilarQuestion(want_similar_question);
  }

  selectPart = (selected_part) => {
    this.setState({ selected_part });
  }

  updateAttempt = (attempt) => {
    this.setState({ attempt });
  }

  selectView = (solution_view) => {
    this.setState({ solution_view });
  }

  saveQuestion = (qn_id) => {
    if (this.state.favorited) return;
    kopi.put('/api/questions/add_favorites', {
      userId: this.props.currentUser,
      questionId: qn_id
    });
    this.setState({ favorited: true });
  }

  renderQuestionNumberOrSave = () => {
    if (this.props.marked_question) {
      return <span className={`fav-${this.state.favorited}`} onClick={() => this.saveQuestion(this.props.marked_question.id)}></span>
    } else {
      return "Question " + (this.props.correct_answers + this.props.wrong_answers + 1)
    }
  }

  renderAnswerFieldOrSolutionField() {
    if (this.props.ask_for_working) {
      if (this.state.selected_part.id !== this.props.current_part.id) return <></>
      const renderAnswerLatex = (answer) => {
        let seperator = "\${1,2}";
        let content1 = this.state.selected_part.units == '\$' ? "$" : ""
        let content2 = answer
        let content3 = this.state.selected_part.units && this.state.selected_part.units !== '\$' ? this.state.selected_part.units : ""
        return <span style={this.props.dark_mode ? { color: '#121212' } : {}}><InlineTex texSeperator={seperator} texContent={content1 + content2 + ' ' + content3} /></span>
      }
      return <div>
        <br />
        <small> Your Attempt </small>
        <div style={this.props.dark_mode ? { backgroundColor: '#ef9a9a', borderColor: '#ef9a9a' } : {}} className="alert alert-info">
          {renderAnswerLatex(this.state.attempt)}
        </div>
        {this.state.imageURL ? <div><small> Your Working </small> <div align="center"> <br />
          <img src={this.state.imageURL} class="img-fluid" /> </div> </div> : <></>}</div>
    }

    if (!this.props.marked_question && this.state.selected_part && this.state.selected_part.id === this.props.current_part.id) {
      return <AnswerField dark_mode={this.props.dark_mode} attempt={this.state.attempt} updateAttempt={this.updateAttempt} onSubmit={this.props.onSubmit} selected_part={this.state.selected_part} current_part={this.props.current_part} />
    } else {
      return <SolutionField dark_mode={this.props.dark_mode} updateScore={this.props.updateScore} self_assessment_score={this.props.self_assessment_score} has_updated_self_assessment={this.props.has_updated_self_assessment} ask_for_self_assessment_again={this.props.ask_for_self_assessment_again} want_similar_question={this.props.want_similar_question} solution_view={this.state.solution_view} answer_feedback={this.props.answer_feedback} marked_question={this.props.marked_question} showing_modal={this.props.showing_modal} updated_masteries={this.props.updated_masteries} onSubmit={this.props.onSubmit} answered={this.props.answered} selected_part={this.state.selected_part} />
    }
  }

  toggleIntervention = async () => {
    if (!this.state.intervention_activated) {
      let response = await kopi.get('/api/interventions/fetch', {
        params: {
          user_id: this.props.currentUser,
          question_id: this.props.current_part.id
        }
      });
      this.setState({ intervention: !this.state.intervention, intervention_activated: true, interventions: response.data.final_interventions })
    } else {
      this.setState({ intervention: !this.state.intervention })
    }
  }

  renderSubmitButton = () => {
    if (this.props.ask_for_working) {
      const onChoosePhoto = event => {
        if (event.target.files && event.target.files[0]) {
          this.loading = true
          this.currentPhotoFile = event.target.files[0]
          // Check this file is an image?
          const prefixFiletype = event.target.files[0].type.toString()
          if (prefixFiletype.indexOf('image/') === 0) {
            uploadWorking()
          } else {
            this.loading = false
            window.alert('This file is not an image')
          }
        } else {
          this.loading = false
        }
      }

      const uploadWorking = async () => {
        if (this.currentPhotoFile) {
          const timestamp = Date.now().toString()

          var options = {
            maxSizeMB: 1,
          }

          const compressedFile = await imageCompression(this.currentPhotoFile, options);

          const uploadTask = myStorage
            .ref()
            .child(timestamp)
            .put(compressedFile)

          uploadTask.on(
            'state_changed',
            null,
            err => {
              this.loading = false
              window.alert(err.message)
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(imageURL => {
                this.loading = false
                this.setState({ imageURL })
              })
            }
          )
        } else {
          this.loading = false
          window.alert('File is null')
        }

      }
      return <nav className="customnav">
        <div style={{ height: "100%", marginLeft: "0", marginRight: "0" }} class="row">
          <div class="col" style={{ padding: '0' }}>
            <button style={this.props.dark_mode ? { backgroundColor: '#ffcc80', color: 'black' } : { background: '#FED472', color: 'black' }}
              onClick={() => this.loading ? null : this.props.onSubmit("upload_working", this.state.imageURL)}
              className="btn btn-block btn-blue btn-not-rounded">
              Continue</button>
          </div>
          <div class="col" style={{ padding: '0' }}>
            <button onClick={() => this.loading ? null : this.refInput.click()}
              className="btn btn-block btn-blue btn-not-rounded"
              style={this.props.dark_mode ? { backgroundColor: '#9fa8da', color: 'black' } : {}}
            >{this.state.imageURL ? "Reupload Working" : "Upload Working"}</button>
          </div>
        </div>
        <input
          ref={el => {
            this.refInput = el
          }}
          accept="image/*"
          className='viewInputGallery'
          type="file"
          onChange={onChoosePhoto}
        />
      </nav>
    } else if (this.props.loading) {
      return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
        <button onClick={() => { }} style={this.props.dark_mode ? { backgroundColor: '#9fa8da', color: 'black' } : {}} className="btn btn-block btn-blue btn-not-rounded">Joni is thinking...</button>
      </nav>
    } else if (!this.props.marked_question && this.state.selected_part && this.state.selected_part.id === this.props.current_part.id) {
      if (this.state.attempt === "") {
        if (this.props.is_a_noob) {
          return <nav className="customnav">
            <div style={{ height: "100%", marginLeft: "0", marginRight: "0" }} class="row">
              <div class="col" style={{ padding: '0' }}>
                <button style={this.props.dark_mode ? { backgroundColor: '#ffcc80', color: 'black' } : { background: '#FED472', color: 'black' }}
                  onClick={() => this.toggleIntervention()}
                  className="btn btn-block btn-blue btn-not-rounded">
                  Learn More</button>
              </div>
              <div class="col" style={{ padding: '0' }}>
                <button style={this.props.dark_mode ? { backgroundColor: 'grey', color: 'black' } : { backgroundColor: 'grey', color: 'black' } }
                  className="btn btn-block btn-blue btn-not-rounded">
                  Enter Answer</button>
              </div>
            </div>
          </nav>

        }
        return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
          <button onClick={() => { }} style={this.props.dark_mode ? { backgroundColor: '#9fa8da', color: 'black' } : {}} className="btn btn-block btn-blue btn-not-rounded">Enter Answer</button>
        </nav>
      } else {
        if (this.props.is_a_noob) {
          const onSubmit = () => {
            if (this.state.intervention_activated) { // viewed interventions
              this.props.onSubmit("attempt_current_question", this.state.attempt, this.state.selected_part.qn_type)
            } else {
              kopi.get('/api/interventions/ignore', {
                params: {
                  user_id: this.props.currentUser,
                  question_id: this.props.current_part.id
                }
              });
              this.props.onSubmit("attempt_current_question", this.state.attempt, this.state.selected_part.qn_type)
            }
          }
          return <nav className="customnav">
            <div style={{ height: "100%", marginLeft: "0", marginRight: "0" }} class="row">
              <div class="col" style={{ padding: '0' }}>
                <button style={this.props.dark_mode ? { backgroundColor: '#ffcc80', color: 'black' } : { background: '#FED472', color: 'black' }}
                  onClick={() => this.toggleIntervention()}
                  className="btn btn-block btn-blue btn-not-rounded">
                  Learn More</button>
              </div>
              <div class="col" style={{ padding: '0' }}>
                <button style={this.props.dark_mode ? { backgroundColor: '#9fa8da', color: 'black' } : {}}
                  onClick={() => this.loading ? null : onSubmit()}
                  className="btn btn-block btn-blue btn-not-rounded">
                  Confirm Answer</button>
              </div>
            </div>
          </nav>
        }
        return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
          <button onClick={() => this.props.onSubmit("attempt_current_question", this.state.attempt, this.state.selected_part.qn_type)}
            className="btn btn-block btn-orange btn-not-rounded"
            style={this.props.dark_mode ? { backgroundColor: '#ffcc80', color: 'black' } : {}}
          >
            Confirm Answer
          </button>
        </nav>
      }
    } else {
      if (this.props.showing_modal) {
        return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
          <button onClick={() => this.props.onSubmit("view_current_solution", this.state.attempt)}
            className="btn btn-block btn-blue btn-not-rounded"
            style={this.props.dark_mode ? { backgroundColor: '#ffcc80', color: 'black' } : {}}
          >
            View Solution</button>
        </nav>
      } else if (!this.props.has_updated_self_assessment && this.props.ask_for_self_assessment_again) {

        if (this.props.answer_feedback && !this.props.answer_feedback.is_correct) {
          const onSubmit = () => {
            if (this.state.interventions.length > 0) { // viewed interventions
              this.props.onSubmit("update_self_assessment", this.props.self_assessment_score)
            } else {
              kopi.get('/api/interventions/ignore', {
                params: {
                  user_id: this.props.currentUser,
                  question_id: this.props.marked_question.id
                }
              });
              this.props.onSubmit("update_self_assessment", this.props.self_assessment_score)
            }
          }

          return <nav className="customnav">
            <div style={{ height: "100%", marginLeft: "0", marginRight: "0" }} class="row">
              <div class="col" style={{ padding: '0' }}>
                <button style={this.props.dark_mode ? { backgroundColor: '#ffcc80', color: 'black' } : { background: '#FED472', color: 'black' }}
                  onClick={() => this.toggleIntervention()}
                  className="btn btn-block btn-blue btn-not-rounded">
                  Learn More</button>
              </div>
              <div class="col" style={{ padding: '0' }}>
                <button onClick={() => onSubmit()}
                  className="btn btn-block btn-blue btn-not-rounded"
                  style={this.props.dark_mode ? { backgroundColor: '#9fa8da', color: 'black' } : {}}
                >Update Self Assessment</button>
              </div>
            </div>
          </nav>
        } else {
          return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
            <button onClick={() => this.props.onSubmit("update_self_assessment", this.props.self_assessment_score)} className="btn btn-block btn-blue btn-not-rounded">Update Self Assessment</button>
          </nav>
        }
      } else if (this.state.selected_part.id === this.props.current_part.id && !this.props.answer_feedback.is_correct) {

        const onSubmit = () => {
          if (this.state.intervention_activated) { // viewed interventions
            this.props.onSubmit("fetch_next_question", this.props.want_similar_question)
          } else {
            kopi.get('/api/interventions/ignore', {
              params: {
                user_id: this.props.currentUser,
                question_id: this.props.marked_question.id
              }
            });
            this.props.onSubmit("fetch_next_question", this.props.want_similar_question)
          }
        }

        return <nav className="customnav" >
          <div style={{ height: "100%", marginLeft: "0", marginRight: "0" }} class="row">
            <div class="col" style={{ padding: '0' }}>
              <button style={this.props.dark_mode ? { backgroundColor: '#ffcc80', color: 'black' } : { background: '#FED472', color: 'black' }} onClick={() => this.toggleIntervention()} className="btn btn-block btn-blue btn-not-rounded">
                Learn More</button>
            </div>
            <div class="col" style={{ padding: '0' }}>
              <button onClick={() => onSubmit()}
                className="btn btn-block btn-blue btn-not-rounded"
                style={this.props.dark_mode ? { backgroundColor: '#9fa8da', color: 'black' } : {}}>
                Next Question
              </button>
            </div>
          </div>
        </nav>

      } else if (this.state.selected_part.id === this.props.current_part.id) {
        return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
          <button onClick={() => this.props.onSubmit("fetch_next_question", this.props.want_similar_question)}
            className="btn btn-block btn-blue btn-not-rounded"
            style={this.props.dark_mode ? { backgroundColor: '#9fa8da', color: 'black' } : {}}>
            Next Question
          </button>
        </nav>
      } else if (this.props.dark_mode === true) {
        return <nav className="nav fixed-bottom navbar-light bg-white nav-submit dark-layer-0">
        </nav>
      } else {
        return <></>
      }
    }
  }

  renderQuestionContent = () => {
    return <div
      className="card-body question-card-content dark-blue-text" style={{ overflowY: "auto" }}>
      <div className={this.props.dark_mode ? "top-bar-menu-dark" : "top-bar-menu"}>
        <span>
          <i onClick={() => this.props.pauseSession(true)} style={this.props.dark_mode ? { color: '#A0A0A0' } : {}} className="fas fa-home cursor-pointer"></i>
          <>&nbsp; &nbsp;</>
          <i onClick={this.props.toggleDarkMode} style={this.props.dark_mode ? { color: '#fff59d' } : {}} class="fas fa-moon cursor-pointer"></i>
        </span>

        <small style={this.props.dark_mode ? { color: '#6C6C6C' } : {}} className="question-num">{this.renderQuestionNumberOrSave()}</small>

      </div>
      <div className={this.props.dark_mode ? "top-bar-parts-dark" : "top-bar-parts"}>
        <div align="center" style={{ width: "100%" }}>
          <SelectToggle dark_mode={this.props.dark_mode} solution_view={this.state.solution_view} selectView={this.selectView} marked_question={this.props.marked_question} selectPart={this.selectPart} selected_part={this.state.selected_part} all_parts={this.props.all_parts} current_part={this.props.current_part} />
        </div>
      </div>
      <SimilarQuestionsToggle dark_mode={this.props.dark_mode} answer_feedback={this.props.answer_feedback} toggleSimilarQuestion={this.toggleSimilarQuestion} marked_question={this.props.marked_question} />
      <div className={this.props.marked_question ? '' : 'row'} style={{ width: "100%", marginLeft: 0, marginRight: 0 }}>
        <div className={this.props.marked_question ? '' : 'col-md-8'} style={{ paddingLeft: 0, }}>
          <QuestionHeader dark_mode={this.props.dark_mode} marked_question={this.props.marked_question} solution_view={this.state.solution_view} current_part={this.props.current_part} all_parts={this.props.all_parts} />
          <QuestionPart dark_mode={this.props.dark_mode} marked_question={this.props.marked_question} solution_view={this.state.solution_view} selected_part={this.state.selected_part} />
        </div>
        <div className={this.props.marked_question ? '' : 'col-md-4'} style={{ paddingLeft: 0, paddingRight: 0 }}>
          {this.renderAnswerFieldOrSolutionField()}
        </div>
      </div>
      <br />
    </div>
  }

  render() {
    if (this.state.intervention && !this.props.answer_feedback) {
      return <Intervention has_answered={Boolean(this.props.answer_feedback)} dark_mode={this.props.dark_mode} interventions={this.state.interventions}
        is_self_assessment={!this.props.has_updated_self_assessment && this.props.ask_for_self_assessment_again}
        onSubmit={this.props.onSubmit} toggleIntervention={this.toggleIntervention} />
    }

    if (this.state.intervention && this.props.answer_feedback && !this.props.answer_feedback.is_correct) {
      return <Intervention has_answered={Boolean(this.props.answer_feedback)} dark_mode={this.props.dark_mode} interventions={this.state.interventions}
        is_self_assessment={!this.props.has_updated_self_assessment && this.props.ask_for_self_assessment_again}
        onSubmit={this.props.onSubmit} toggleIntervention={this.toggleIntervention} />
    }

    const dark_mode = {
      color: '#252525',
    }

    return <> <div className={this.props.dark_mode ? "card question-card shadow-sm p-3 mb-5 bg-white rounded dark-layer-1" : "card question-card shadow-sm p-3 mb-5 bg-white rounded"}>
      {this.renderQuestionContent()}
    </div>
      {this.renderSubmitButton()}
    </>
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(QuestionCard);

