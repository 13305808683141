import React from 'react';
import { connect } from 'react-redux';

const Legal = (props) => {
  return (
    <div className="container-fluid">

<br/>
<h1> Joni.AI Full Terms and Conditions of Use</h1> <br/>

<h2>1. Introduction</h2>

<p>These Website Standard Terms and Conditions (these “Terms” or these “Website Standard Terms and Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). This website is under the ownership of Joni.ai Pte Ltd (hereinafter referred by/as “Joni.ai”, “Joni”, “we”, “us” or “our”). Services offered by the Website such as Joni.Mentors and Joni are collectively referred to as “Services”. These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website if you have any objection to any of the following Terms.</p>
<p>Please read these Terms attentively. Your access to and usage of our Services is conditioned upon your acceptance of and compliance with these Terms. These Terms applies to all users, visitors and others who access or use this Website. </p>

<h2>2. Ability to accept Terms</h2>
<p>The use of our Website and Services may require compatible devices, Internet access, and periodic updates. The latest version of software, programs and/or applications (collectively “Software”) is recommended and may be required to access certain features. You acknowledge that you are responsible to obtain and maintain at your own expense all equipment, systems, software, and/or services needed to access our Service and updating the Software.
</p>

<p>Usage of the Site may affect your enjoyment and/or the operation of the broadband or wireless services provided by the third-party network and we shall not in any event be liable in respect thereof.</p>

<p>If you are at least eighteen (18) years of age or older, you agree to enter into an agreement to accept the Terms. If you are between thirteen (13) and eighteen (18), you must possess legal parental or guardian consent and have your parent/guardian read and agree to the Terms and Privacy Policy for you. If a parent/guardian fails to read the stated agreements and agree to the Terms and Privacy Policy for you, then you are not permitted to create an account with Joni.ai and our Services. We encourage parents to discuss the usage of the Internet with his/her child, and monitor personal information that a child may provide on the Internet.</p>

<h2>3. Registration, Member Account, Security, Username, And Password.</h2>

<p>Every visitor of our Website, whether registered or not, shall be considered as a “User” of the contained Services provided for this Terms. Once an individual registers for these Services, through the process of creating an account, the user shall then be considered a “Member.” There are two types of Members; “Student” – a customer, who uses Joni.ai for the purpose of learning and acquiring knowledge; “Mentor” – a customer, who uses Joni.ai for the purpose of teaching and imparting knowledge to a Student or more. </p>

<p>To register and become a “member” of the Website, you have to be in good standing and not a person who has been previously barred from receiving our services.</p>

<p>When you register, Joni.ai may collect information such as your name, birth date, gender, race, occupation and personal interests. It is due to client’s preferences. There is no violation of Article 12 of the Constitution of the Republic of Singapore. </p>

<p>Users who wish to register with Joni.ai are required to provide accurate, valid, complete and current information about themselves as prompted by registration forms provided. When you create an account, you are the sole authorised user of your account. You agree to update your information should there be any changes, in order to keep registered information actual, complete, true and accurate. If you provide information that is not accurate or updated, we may terminate your access to the Website and our Services. We are not responsible for any failure in providing the Services, which result from information that is not true, accurate, current and complete. </p>

<p>You are responsible for all access to the Website using your internet connection, even if the access is by another person and any person who uses our Website and the Content under your user account, regardless whether approved by you, shall be deemed to be your use of our Website and Content. If you have reason to believe that someone has unauthorized use of your password or user account or has committed any other breach of security, please report to us immediately at info@joni.ai for our immediate suspension of your user account or to take other appropriate actions. Joni.ai shall not be held liable for any loss and damage arising from any failure to comply with this term of use. </p>

<h2>4. Website Services and Usage</h2>
<p>Through our Services, we enable students to learn and mentors to teach. This may involve usage of our platform during classroom lessons.</p>

<p>The User or Member acknowledges and agrees that Services provided and made available through our Website are the sole property of Joni.ai. At its discretion, we may offer additional website Services or renew, change or verify any actual content and Services, and these Terms shall apply to any and all additional, updated, altered or revised Services unless otherwise specified. Joni.ai hereby reserve the right to cancel and cease offering any of the Services mentioned above. You acknowledge, accept and agree that Joni.ai shall not be liable for any such renewals, changes, revisions or terminations of any of Our Services and products. Should you not agree to the, updated, overviewed or changed terms, you must stop using the provided Services immediately. </p>

<p>You may access Content for your information and personal, non-commercial use solely as intended through the provided functionality of our Website and as permitted under these Terms. We and the respective contributors of Content reserve all rights not expressly granted in and to our Website and the Content.</p>

<p>Though we provide rules for user conduct, we do not control or direct Users’ actions on our Website and are not responsible for the content or information users transmit or share on our Website. You understand that you will be exposed to Content when using our Service. Content may be derived from many different sources, and we will not take responsibility for the accuracy, suitability, practicality, safety, or intellectual property rights of or relating to such Content. You further understand and recognise that you may be exposed to Content that is incorrect, offensive, indecent, or unpleasant, and you agree to waive, and therefore do waive, any legal or equitable rights or remedies you have or may have against us with respect to any applicable law, and to agree to indemnify us and hold us harmless to the fullest extent allowed by law regarding all matters related to your use of our Site.</p>

<p>We make no promise that the materials on the Site are appropriate or available for use in locations outside Singapore. Accessing the Site from territories where its contents are illegal or unlawful is prohibited. If you choose to access the Site from elsewhere, you do so on your own initiative and are responsible for compliance with local laws.</p>

<p>This section “Website Content and Usage” will endure the cessation or expiry of this Terms of Service and/or your use of our Site.</p>

<h2>5. Communication Services and Usage </h2>
<p>The Website may contain chat areas (blog), news groups, communities, forums, calendars, personal web pages, and/or other message or communication facilities created to enable you to communicate with the public at large or with a group (communication services). You adhere to use these communication services only to send, receive and post material and messages and that are proper and related to the particular communication service.</p>

<p>By way of example, and not as a limitation, you agree that when using a communication service, you will not: harass, abuse, stalk, threaten, defame or otherwise violate the legal rights (such as rights of privacy and publicity) of others; post, distribute, upload, publish any inappropriate, defamatory, infringing, obscene, indecent or unlawful material, information, name or topic; upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents; advertise or offer to sell or buy any goods or services for any business purpose, unless communication service specifically allows such messages. </p>

<p>Joni.ai has no obligation to monitor the communication services. We do not control or endorse the content, messages or information found in any communication service and, therefore, Joni.ai specifically disclaims any liability in the matter of the communication services and any actions resulting from your joining in any communication service. Managers and hosts have not authorized spokespersons of Joni.ai, and their views do not necessarily reflect those of Joni.ai. </p>

<p>Joni.ai reserves the right to review materials posted to a communication service and to remove any materials in its sole discretion. We have the right to terminate your access to any of the communication services at any time without notice for any reason whatsoever. </p>

<h2>6. Rates, Commission, and Payment </h2>
<p>All currency references are in Singapore dollars (SGD). You shall be responsible for payment of all levies, duties or taxes that are imposed by taxing rules for any payment or fees you may incur while using our services. </p>

<h2>7. Rules and Conduct </h2>
<p>The Website is available only for your own personal, non-commercial use. You shall not use the Service for any purpose that is prohibited by these Terms of Use, including: </p>

<p>You shall not (and shall not permit any third party to) either (a) take any action or (b) post, submit, link, upload, download, to or otherwise distribute or facilitate distribution of any content on or through the Service, that:</p>
<ul>
<li>infringes any patent, trademark, trade secret, copyright, a right of publicity or another right of any other person or entity;</li>
<li>is unlawful, abusive, threatening, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, offensive, or profane;</li>
<li>involves commercial activities and sales without Joni.ai’s prior written consent such as contests, sweepstakes, barter, advertising, or pyramid schemes;</li>
<li> impersonates any person or entity, including any employee or representative of Joni.ai. </li>
</ul>

<p>You also shall not (directly or indirectly):</p>
<ul>
<li>take any action that may impose or imposes (as determined by Joni.ai in its sole discretion) an unreasonable or disproportionately large load on Joni.ai (or its third-party providers’) infrastructure;</li>

<li>interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service;</li>

<li>bypass any measures Joni.ai may use to restrict or prevent access to the Service (or other accounts, networks or computer systems connected to the Service);</li>

<li>reverse engineering the Service;</li>

<li>decompile, reverse, decipher, disassemble engineer or otherwise attempt to derive any software code or underlying algorithms of any part of the Website, except to the limited extent Singapore or other applicable laws specifically prohibit such restriction;</li>

<li>translate, modify or otherwise create derivative works of any part of the Service; or</li>

<li>distribute, lease, copy, rent or otherwise transfer any or all of the rights that you receive hereunder.</li>
</ul>

<p>Joni.ai reserves the right to remove any content from the Website or another feature of the Website at any time, for any reason or no reason at all.</p>

<p>You shall abide by all copyright notices, information, and restrictions contained in any Content accessed through the Service. You shall not license, rent, publicly display, adapt modify, sell, create, distribute, copy, reproduce, transmit, publicly perform, publish, edit derivative works from, or otherwise exploit any content or third-party submissions or other proprietary rights not owned by you:</p>

<ul>
    <li>without the consent of the respective owners or other valid right and</li>
    <li>in any way that violates any third party right. </li>
</ul>

<p>You can, to the extent the Website expressly authorizes you to do so, copy or download Content, and other items displayed on the service for download, for personal use only, provided that you maintain all copyright and other similar notices contained in such Content. </p>

<p>Deleting your Account affects all data associated with that account and any services you use. You understand that all your notes and information will be deleted. </p>

<p>Please be aware that the service or other features of the Website may contain, or direct you to services with content that some people may find offensive or inappropriate. </p>

<h2>8. Restrictions and Obligations </h2>

<p>Some areas of this service are restricted from being access by you and may further restrict access by you to any areas of this service at absolute discretion. Any user ID and password you may have for this Website are confidential, and you must respect confidentiality as well. You warrant that you will follow, without limitation, all applicable international, national and local laws and regulations concerning your usage of the Service and not inhibit the usage and enjoyment of the Service by other users or with the operation and management of the Website. </p>

<p>You shall provide accurate, authorized, complete, and true information when providing materials or information on the service, including, without limitation, information required to be provided through the Website’s registration form. This includes any certificates, documents, or photos that you upload to our website or send to us or any of our coordinators via various channels. If you submit any inaccurate, false, unauthorized, incomplete or untrue information or you do not meet our criteria as a Member, Joni.ai reserves the right to terminate your use and access to the Website temporarily or permanently. You warrant that you will not impersonate any others, whether actual or fictitious, when using the service, or defame or otherwise harm any party through your use of our service. </p>

<p>Joni.ai also reserves the right not to provide services for Users and Members without reason. Accessing the Website is forbidden from territories where the Service or any Content is illegal; you are responsible for compliance with local laws.</p>
<ul>
    <li>fraud or fraudulent misrepresentation; or </li>
    <li>the tort of deceit; or</li>
    <li>any other liability which may not be limited or excluded by law. </li>
</ul>

<h2>9. Termination </h2>
<p>Joni.ai expects all Users and Members to conduct themselves appropriately in a professional and ethical manner. As a member, you agree that Joni.ai may, without any prior written notice, immediately suspend, terminate, discontinue and/or limit your account, any email associated with your account, and access to any of our Services. The cause for such termination, discontinuance, suspension and/or limitation of access shall include, but is not limited to:</p>
<ul>
    <li>any breach or violation of our Terms and Conditions or any other incorporated agreement, regulation and/or guideline;</li>
    <li>by way of request from law enforcement or any other governmental agencies;</li>
    <li>unexpected technical or security issues and/or problems;</li>
    <li>any extended periods of inactivity;</li>
    <li>any engagement by you in any fraudulent or illegal activities</li>
    <li>the non-payment of any associated fees that may be owed by you</li>
    <li>unethical conduct</li>
</ul>

<p>In addition, you agree that any and all terminations, suspensions, discontinuances, and/or limitations of access for cause is made at our sole discretion and that we are not responsible to you or any other third parties with regards to the termination of your profile, associated email address and/or access to any of our Website. Furthermore, you agree that Joni.ai may cease operations at any time. </p>

<p>The termination of your account may contain any and/or all of the following:</p>
<ul>
    <li>the removal of any access to all or part of the Services offered within Joni.ai;</li>
    <li>the password deletion and any and all related information, files, and any such content that can be associated with or inside your account, or any part of thereof;</li>
    <li>the barring of any further use of all or part of our Website. </li>
</ul>

<p>As a member, you may also terminate or cancel your profile, associated email address and/or access to our Website by submitting a cancellation or termination request to us via email 7 days prior to termination date. </p>

<h2>10. Limitation Of Liability And Indemnification </h2>

<p>Joni.ai does its best to ensure that all information on the Website is accurate. If you find any inaccurate information on the Website, please let us know by sending an email and we will correct it, where we agree, as soon as practicable. </p>

<p>Joni.AI relies on data analytics and handles a large quantity of information each day. In the process, there are instances of manual data entry. Since no one is secured from human error, we cannot guarantee the information accuracy on our site. Joni.ai does its best to maintain our information accuracy at the highest standard.</p>

<p>Joni.ai gives no warranty or assurance about the content of the Website. As the Website is under constant development, its contents may be incorrect or out-of-date and are subject to change without notice. While we make every effort to ensure that the content of the Website is accurate, we cannot accept liability for the accuracy of all the content at any given point in time. </p>

<p>Joni.ai makes every effort to ensure that its computer infrastructure is a virus- and error-free but does not warrant that any content that is available for downloading from the Website does not contain viruses, infection or other code that has destructive or contaminating properties. You are responsible for carrying out sufficient procedures and virus checks (including anti-virus and other security verifications) to gratify your particular requirements for the accuracy of information output and input. </p>

<p>Neither Join.ai nor any of its employees, sub-contractors and agents shall be liable to you or any other party for any loss, demand, damages or claim whatsoever (whether such loss, demand, damages or claims were known, foreseeable, or otherwise) arising in connection with or out of the usage of the Website or information, materials or content included on the Website. </p>

<p>In no event shall Joni.ai or any of its employees, sub-contractors or agents be liable for any indirect or consequential damage or loss including, without limitation, any;</p>
<ul>
    <li>loss of life or limb, or any accidental damage or injury arising from usage of Services;</li>
    <li>loss of actual or anticipated profits (including loss of profits on contracts);</li>
    <li>loss of revenue;</li>
    <li>loss of business;</li>
    <li>loss of opportunity;</li>
    <li>loss of anticipated savings;</li>
    <li>loss of goodwill;</li>
    <li>loss of use of money or otherwise</li>
    <li>loss of reputation;</li>
    <li>loss or damage to or corruption of data,</li>
</ul>
<p>and whether or not advised of the possibility of such demand, claim, loss, damages or loss and arising in tort (including negligence), contract or otherwise, to the fullest extent that is allowed by law. </p>

<p>Should the Website provides hypertext links to other sites operated by other services, the usage of such a links means you are leaving the Website. Joni.ai takes no responsibility for and gives no warranties, endorsements, guarantees or representations in respect of, linked sites. We are not liable for the privacy practices, nor do we accept any responsibility in connection with the information of, such websites, including those of our group entities, which will in some cases have their privacy policies tailored to the particular business practices and educational sectors in which they operate. </p>

<p>We are not a publisher of content supplied by third parties and users of the internet. Any offers, services, statements, advice, opinions, or other content that is available by third parties, including information of providers, or users, are those of the authors or distributors and not of us. We do not necessarily endorse nor are we liable for the accuracy or reliability of any content made on the Website. </p>

<p>The information on the Website is not directed to address your particular demands. Such information does not constitute any form of recommendation or advice by our service and is not intended to be relied upon by you in creation (or refraining from creation) any specific educational, or other, decisions. You should take your advice and make specific inquiries and independently verify any information before relying upon it. </p>

<p>You agree to defend, hold and indemnify harmless Joni.ai, its directors, agents, officers, employees, and third parties, for any costs, losses, liabilities and expenses (including reasonable fees) relating to or arising out of users use of or inability to use the Website or services, any user postings made by you, your violation of any terms of this contract or your breach of any third parties rights, or your violation of any applicable laws, rules or regulations. Joni.ai reserves the right of assuming the exclusive defense and control of any matter otherwise subject to indemnification by a user, in which event the user will fully cooperate with Joni.ai in asserting any available defenses. </p>

<h2>11. Intellectual Property Rights </h2>
<p>Other than content you own, which you may have opted to include on this Website, under these Terms, Joni.ai and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved.</p>

<p>All content that is a part of the Website, such as images, logos, graphics, text as well as any software used on the Website, is the property of Joni.ai and is protected by copyright and other applicable laws. You agree to follow all copyright and other proprietary notices or other limitations that are in any content and will not make any changes thereto. </p>

<p>You will not publish, modify, transmit, reverse engineer, participate in the transfer or sale, or in other way use any of the content, in whole or in part, found on the Website. You are not entitled to make any unauthorized use of any protected content, and in particular, you will not delete or change any proprietary rights or attribution notices in any content. You have to use protected content solely for your personal use and do not let any other persons use the described content without the prior written permission of Joni.ai or the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, implied or expressed, to the intellectual property of Joni.ai or its licensors except as expressly authorized by these Terms. </p>

<h2>12. Arbitration</h2>

<p>If any party is not able to overcome any dispute of any kind arising out of or concerning our Terms, or any provisions hereof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such conflict shall be overcome only by final and binding arbitration or a similar arbitration service selected by the parties, in Singapore. The arbitrator’s award is final, and judgment is to be entered upon it in any court having jurisdiction. If any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms, the prevailing party shall be entitled to recover its costs and reasonable fees. User agrees to arbitrate all disputes and claims in regards to these Terms or any conflicts arising as a result of these Terms, whether directly or indirectly, including Tort claims that are a result of these Terms.</p>

<h2>13. Modification of Terms</h2>

<p>Joni.ai reserves the right, at its sole discretion, to change or substitute the Terms and Conditions. It is your responsibility to scan the Terms periodically for modifications. Your continued usage of the Services following the posting of any modifications to the Terms will be subject to the new Terms. </p>

<h2>14. Severability, Waiver and Interpretation</h2>

<p>Unless otherwise specified herein, this contract constitutes the entire agreement between you and Joni.ai concerning the Website, and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and Joni.ai with respect to the Website. A printed version of this agreement and any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or be relating to this agreement to the same extent and subject to the same conditions as other documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

<h2>15. Contact Us</h2>
<p>
If you have any questions concerning the Joni.AI Service or the Agreements, please contact Joni.AI Customer Service by visiting the About Us section of https://www.joni.ai.
Thank you for reading our terms, we hope you enjoy Joni.AI! 
</p>
<br/>
<br/>
    </div>
    
  );
}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Legal);