

import React from 'react'; 

import SkyLight from 'react-skylight';

import kopi from '../../../../apis/kopi';

import {connect} from 'react-redux';

import Dock from 'react-dock';

const ChangeDifficulty = (props) => {


    const renderText = () => {
        if (props.increase_difficulty){
            return <div>
                Would you like to increase the level of difficulty?
            </div>
        } else {
            return <div>
                Would you like to decrease the level of difficulty?
            </div>
        }
    }

    const renderOptions = () => {
        return <div>
            <button className="btn btn-block btn-orange" onClick={APIcallToChangeDifficulty}>Yes Please!</button>
            <button className="btn btn-danger btn-block" onClick={()=>APIcallToChangeDifficulty(true)}>No no!</button>
            </div>
    }

    const APIcallToChangeDifficulty = (no_change) => {
        kopi.put('/api/interactions/change_difficulty', {
            user_id: props.currentUser,
            increase_difficulty: props.increase_difficulty,
            no_change
        });
        props.endInteraction();
    }

    return <div className="dark-blue-text">
        {renderText()}
        <hr/>
        {renderOptions()}
    </div>
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(ChangeDifficulty);