import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../apis/kopi';
import Footer from '../Footer';
import AttemptedQuestions from './AttemptedQuestions';

class TrainingSets extends React.Component{

    // type: 0 == training sets index, 1 == questions index

    state = {
        type: 0, 
        pageNumber: 1, 
        training_sets: [], 
        selected_set: null, 
        loading: false,
        Yscroll_position: 0
    }

    componentDidMount = async () => {
        this.setState({ loading: true})
        const response = await kopi.get('/api/library/history/training_sets', {
            params: {userId: this.props.currentUser, pageNumber: this.state.pageNumber}
        });    
        // console.log(response.data);
        this.setState({training_sets: response.data, loading: false})
        
    }

    componentDidUpdate = () => {
        window.scrollTo(0, this.state.Yscroll_position)
    }

    selectTrainingSet = (training_set) => {
        this.props.resetClose();
        this.setState({type: 1,selected_set: training_set, Yscroll_position: window.pageYOffset});
    }

    renderList = () => {

        if (this.state.training_sets.length == 0) {
            return <div align="center"><img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540597/Joni%20Assets/klo1.png" class="img-fluid" style={{marginTop: "5vh", marginBottom: "5vh"}}/>
                <h4>Completed practice sets would appear here.</h4>
            </div>        
        }

        return this.state.training_sets.map(training_set => {
            let created_at = new Date(training_set.created_at);
            let completed_at = new Date(training_set.completed_at);
            let raw_minutes_elapsed = parseInt((completed_at-created_at)/60000.0);
            let session_h = parseInt(raw_minutes_elapsed/60);
            let session_min = parseInt(raw_minutes_elapsed%60);
            let session_timing;
            if (session_h >= 1) {
                session_timing = <span>{session_h}h {session_min}min</span>
            } else {
                session_timing = <span>{session_min} min</span>
            }

            let month = new Array();
            month[0] = "January";
            month[1] = "February";
            month[2] = "March";
            month[3] = "April";
            month[4] = "May";
            month[5] = "June";
            month[6] = "July";
            month[7] = "August";
            month[8] = "September";
            month[9] = "October";
            month[10] = "November";
            month[11] = "December";

            let formattedDay = completed_at.getDate();
            let formattedMonth = month[completed_at.getMonth()].slice(0,3);

            return (
                <div onClick={() => this.selectTrainingSet(training_set)} key={training_set._id} className="row alert alert-light cursor-pointer">
                    <div className="col-4">
                        <div>
                            <strong><h1 className="no-bottom-margin">{formattedDay} <br/>
                            {formattedMonth}
                            </h1></strong>
                        </div>
                    </div>
                    <div className="col-8">
                        <div align="right">
                            <small><strong>{training_set.attempts.filter(attempt => {
                                    return attempt.correct }).length}/{training_set.questions.length}</strong> correct
                            </small><br/>
                            <small>Duration: <strong>{session_timing}</strong></small>
                        </div>
                    </div>
                </div>
                ); 
        });
    }

    goBack = () => {
        this.setState({type: 0})
    }

    render(){
        if (this.state.loading) {
            return <div align="center"> 
                    <br/><br/><br/><br/>           
                    <div className="spinner-border text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        } else {
            if (this.state.type===0 || this.props.close) {
                return <div ref="trainingSets" className="container">
                    <br/>
                    {this.renderList()}
                    <br/><br/><br/><br/><br/>
                </div>;
            } else {
                return <div className="container">
                    <br/>
                    <button class="btn btn-block btn-dark" onClick={()=>this.goBack()}><i class="fas fa-arrow-left"></i> &nbsp; All Free Practice Sessions</button>
                    <br/>
                    <AttemptedQuestions training_set={this.state.selected_set} />
                </div>;
            }
        }
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(TrainingSets);