import React from 'react';
import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../history'; 
import SelectDate from './SelectDate';
import SelectTopics from './SelectTopics';
import SelectPrereqTopics from './SelectPrereqTopics/index.js';
import SortTopics from './SortTopics';
import ConfirmStudyPlan from './ConfirmStudyPlan';
import kopi from '../../../apis/kopi';

import Loading from '../../Loading'

var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll; 

class CreatePlan extends React.Component {

    state = {
        page: 1, 
        completion_date: null, 
        topics: [],
        prereq_topics: [],
        selected_topics: [],
        selected_prereq_topics: [],
        sorted_topics: [],
        selected_date: null,
        submit: false
    }

    componentDidMount = async () => {

        if (!this.props.isSignedIn || !this.props.currentUser){
            return history.push('/')
        }
        
        let response1 = await kopi.get('/api/study_plans/fetch-topics',{
            params: {userId: this.props.currentUser}
        });
        let response2 = await kopi.get('/api/study_plans/fetch-prereqtopics',{
            params: {userId: this.props.currentUser}
        });
        this.setState({
            topics: response1.data,
            prereq_topics: response2.data
        });
    }

    scrollToTop = () => {
        scroll.scrollToTop();
    }

    selectDate = (selected_date) => {
        this.setState({selected_date});
    }

    selectTopic = async (topic) => {

        let new_selected_topics;

        if (!this.state.selected_topics.includes(topic)){
            new_selected_topics = [...this.state.selected_topics, topic];
        } else {
            let newArray = this.state.selected_topics.filter(each_topic=>each_topic!==topic)
            new_selected_topics = [...newArray];
        }

        this.setState({
            selected_topics: new_selected_topics,
            sorted_topics: new_selected_topics
        })

    }

    selectPrereqTopic = async (topic) => {

            let new_selected_topics;
            let new_selected_prereq_topics;

            if (!this.state.selected_topics.includes(topic)){
                if (this.state.selected_prereq_topics.length <= 2) {
                    new_selected_topics = [...this.state.selected_topics, topic];
                    new_selected_prereq_topics = [...this.state.selected_prereq_topics, topic];
                } else {
                    window.alert("Hey! You can only select up to 3 prereq topics!")
                    return;
                }
            } else {
                let newArray = this.state.selected_topics.filter(each_topic=>each_topic!==topic)
                let newPrereqArray = this.state.selected_prereq_topics.filter(each_topic=>each_topic!==topic)
                new_selected_topics = [...newArray];
                new_selected_prereq_topics = [...newPrereqArray];
            }

            this.setState({
                selected_topics: new_selected_topics,
                selected_prereq_topics: new_selected_prereq_topics
            })
        
    }

    sortTopics = (sorted_topics) => {
        this.setState({sorted_topics});
    }

    onBack = () => {
       
        if(this.state.selected_topics.length > 0 && this.state.page === 4){
            if (this.state.prereq_topics.length === 0){
                this.setState((prevState) => ({page : prevState.page - 1}))    
            }
        }
        this.setState((prevState) => ({page : prevState.page - 1}))
    }

    onSubmit = async (sorted_topics) => {
        switch(this.state.page) {               
            case 1: // to select topics
                this.state.selected_date ? this.setState({page: this.state.page+1}) : window.alert("Hey! You need to select a date!");
                break;

            case 2: // to select topics
                if(this.state.selected_topics.length > 0){
                    if (this.state.prereq_topics.length !== 0) {
                        this.setState({page: this.state.page+1});
                    } else {
                        this.setState({page: this.state.page+2});
                    }
                } else {
                    window.alert("Hey! You need to select at least a topic!");
                } 
                break;
            case 3: // to sort topics
                let response = await kopi.get('/api/study_plans/fetch-topics-to-sort',{
                    params: {userId: this.props.currentUser, topicIds: this.state.sorted_topics.map(topic=>topic.id)}
                });   
                this.state.selected_topics.length > 0 ? this.setState({page: this.state.page+1, sorted_topics: response.data}) : window.alert("Hey! You need to select at least a topic!");
                break;
            case 4: // to confirm topics
                if (sorted_topics) {
                    this.setState({page: this.state.page+1, sorted_topics: sorted_topics});
                }
                break;
            default: 
                await this.setState({submit: true});
                await kopi.post('/api/study_plans/create-study-plan',{
                    userId: this.props.currentUser,
                    sorted_topic_ids: this.state.sorted_topics.map(topic=>topic.id),
                    selected_topic_ids: this.state.selected_topics.map(topic=>topic.id),
                    selected_date: this.state.selected_date
                });
                history.push('/dashboard');
        }
    }

    renderComponent = () => {
        if (!this.state.submit){
            switch(this.state.page) {
                case 1: 
                    return <SelectDate scrollToTop={this.scrollToTop} endDate={this.state.selected_date} selectDate={this.selectDate} onSubmit={this.onSubmit} onBack={this.onBack}/>
                case 2: 
                    return <SelectTopics scrollToTop={this.scrollToTop} endDate={this.state.selected_date} selectTopic={this.selectTopic} selected_topics={this.state.selected_topics} topics={this.state.topics} onSubmit={this.onSubmit} onBack={this.onBack}/>
                case 3: 
                    return <SelectPrereqTopics scrollToTop={this.scrollToTop} topics={this.state.topics} endDate={this.state.selected_date} selectTopic={this.selectPrereqTopic} selected_topics={this.state.selected_topics} prereq_topics={this.state.prereq_topics} onSubmit={this.onSubmit} onBack={this.onBack}/>
                case 4:
                    return <SortTopics scrollToTop={this.scrollToTop} sortTopics={this.sortTopics} sorted_topics={this.state.sorted_topics} onSubmit={this.onSubmit} onBack={this.onBack}/>
                default: 
                    return <ConfirmStudyPlan scrollToTop={this.scrollToTop} topics={this.state.topics} endDate={this.state.selected_date} selected_topics={this.state.selected_topics} sorted_topics={this.state.sorted_topics} onSubmit={this.onSubmit}/>
            }
        } else {
            return <Loading />
        }
    }

    render(){
        return <div className="container-padding-bottom">
           {this.renderComponent()}
         </div>
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(CreatePlan);


//
// 