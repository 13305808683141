
import React from 'react';
import { connect } from 'react-redux';
// import kopi from '../../../../apis/kopi';

import ProgressDetails from './ProgressDetails';

import { signOut } from '../../../../actions';


const Progress = (props) => {

    const alertClass = (goal) => {
        return goal.has_concluded || new Date(goal.end_date) <= new Date(Date.now()) || !goal.homework_plan_active  ? "alert-dark" : "alert-light"
    }

    const renderStatus = () => {
        if (new Date(props.homework.end_date) <= new Date(Date.now())) {
            return <><b>EXPIRED</b><br /></>
        } else if(!props.homework.homework_plan_active) {
            return <><b>STOPPED</b><br /></>
        } else if(!props.homework.active) {
            return <><b>PAUSED</b><br /></>
        } else {
            return <><b>ACTIVE</b><br /></>
        }
    }

    return <div onClick={() => { props.selectHomework(props.homework) }} className={`cursor-pointer alert ${alertClass(props.homework)}`}>
        <div className="row">
            <div className="col-5">
                <div> 
                    {renderStatus()}
                    <small>End Date</small>
                    <strong><h3 className="no-bottom-margin">{new Date(props.homework.end_date).getDate()}/{new Date(props.homework.end_date).getMonth() + 1}</h3></strong>
                </div>
            </div>
            <div className="col-7">
                <div align="right">
                    <small> Mentor: {props.homework.mentor} </small>
                </div>
            </div>
        </div>
    </div>

}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { signOut })(Progress);