import React from 'react'
import {InlineTex} from 'react-tex';

import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const LOBar = props => {

    const {title, skill} = props

    let current_skill;
    let prev_skill;
    let nxt_skill;

    let current_rank;
    let prev_rank;
    let nxt_rank;

    let current_rank_img;
    let prev_rank_img;
    let nxt_rank_img;

    let progress

    if (skill < 30) {
        // start: 0
        // end: 30
        progress = 50 + (skill)/30*50

        current_skill = skill;
        current_rank = "Novice III"
        current_rank_img = "https://image.flaticon.com/icons/svg/340/340330.svg"

        prev_skill = null;
        prev_rank = null;
        prev_rank_img = null;
        
        nxt_skill = 30;
        nxt_rank = "Novice II";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340331.svg'

    } else if (skill < 40) {

        progress = 50 + (skill-30)/10*50

        current_skill = skill
        current_rank = "Novice II"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340331.svg'

        prev_skill = 20
        prev_rank = "Novice III";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340330.svg'
        
        nxt_skill = 40;
        nxt_rank = "Novice I";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340332.svg'

    } else if (skill < 50) {

        progress = 50 + (skill-40)/10*50

        current_skill = skill
        current_rank = "Novice I"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340332.svg'
        
        prev_skill = 30
        prev_rank = "Novice II";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340331.svg'
        
        nxt_skill = 50;
        nxt_rank = "Apprentice";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340326.svg'

    } else if (skill < 60) {

        progress = 50 + (skill-50)/10*50

        current_skill = skill
        current_rank = "Apprentice"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340326.svg'

        prev_skill = 40
        prev_rank = "Novice I"
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340332.svg'
        
        nxt_skill = 60;
        nxt_rank = "Master";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340327.svg'

    } else if (skill < 75) {

        progress = 50 + (skill-60)/15*50

        current_skill = skill
        current_rank = "Master"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340327.svg'

        prev_skill = 50
        prev_rank = "Apprentice";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340326.svg'
        
        nxt_skill = 75;
        nxt_rank = "Grandmaster";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340328.svg'

    } else if (skill < 90) {

        progress = 50 + (skill-75)/15*50
        
        current_skill = skill
        current_rank = "Grandmaster"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340328.svg'

        prev_skill = 60
        prev_rank = "Master";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340327.svg'
        
        nxt_skill = 90;
        nxt_rank = "Julian";
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340329.svg'

    } else if (skill < 100) {
        
        progress = 50 + (skill-90)/10*50

        current_skill = skill
        current_rank = "Julian"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340329.svg'

        prev_skill =  75
        prev_rank = "Grandmaster";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340328.svg'
        
        nxt_skill = 100;
        nxt_rank = "Destroyer of All Evil"
        nxt_rank_img = 'https://image.flaticon.com/icons/svg/340/340342.svg'

    } else {

        progress = 100

        current_skill = skill
        current_rank = "Destroyer of All Evil"
        current_rank_img = 'https://image.flaticon.com/icons/svg/340/340342.svg'

        prev_skill = 90
        prev_rank = "Julian";
        prev_rank_img = 'https://image.flaticon.com/icons/svg/340/340329.svg'
        
        nxt_skill = null;
        nxt_rank = null;
        nxt_rank_img = null;
    }

        let seperator = "\${1,2}";

        const renderImg = (src, accomplished) => {
            if (src) {
                return <img
                style={{ filter: `grayscale(${accomplished ? 0 : 80}%)` }}
                width="30"
                src={src}
              />
            } else {
                return <></>
            }
        }
        
        return <>
        <hr/>
        <div align="center">
            <small><strong><InlineTex texSeperator={seperator} texContent={title} /></strong></small> <br/>
            <br/> 
        </div>
        <div style={{marginLeft: '-30px', marginRight: '-30px'}} class="row">
            <div class="col-4 float-left">
                <div class="float-left">
                    <small>{prev_rank}</small>
                </div>
                
            </div>
            <div class="col-4" align="center">
                <small><strong>{current_rank}</strong></small>
            </div>
            <div class="col-4">
                <div class="float-right">
                    <small>{nxt_rank}</small>
                </div>
            </div>
        </div>
        <br/>
        <ProgressBar
        percent={progress}
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        >
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            renderImg(prev_rank_img,accomplished)
          )}
        </Step>
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            renderImg(current_rank_img,accomplished)
          )}
        </Step>
        <Step transition="scale" transitionDuration="2000">
          {({ accomplished }) => (
            renderImg(nxt_rank_img,accomplished)
          )}
        </Step>
        </ProgressBar>
        <br/> 
        </>

}



export default LOBar