import React from 'react'; 

import {InlineTex} from 'react-tex';

import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css'

class Self extends React.Component {

    componentDidMount=()=>{
        this.props.updateAttempt(66);
    }

    updateAttempt = (value) => {
        this.props.updateAttempt(value);
    }

    renderInputRange = () => {
        return <div style={{paddingLeft: '4vw', paddingRight: '4vw', paddingTop: '0vw', paddingBottom: '2vw'}}> <InputRange
        formatLabel={value => ''}
        step={33}
        maxValue={99}
        minValue={0}
        value={this.props.attempt}
        onChange={value => this.updateAttempt(value)} /> </div>
    }
    
    renderMarkers = () => {
        return <div> 
            <div class="float-left">
                <span>😢</span>
            </div>
            <div class="float-right">
                <span>😀</span>
            </div>
        </div>
    }

    renderText = () => {
        const renderEmoji = (value) => {
            if (value == 0) {
                return '😢'
            } else if (value == 33) {
                return '😪'
            } else if (value == 66) {
                return '🙂'
            } else {
                return '😀'
            }
        }

        const renderStatus = (value) => {
            if (value == 0) {
                return "I don't know where to begin"
            } else if (value == 33) {
                return 'I am able to make some progress'
            } else if (value == 66) {
                return 'I almost got it'
            } else {
                return "It's perfect!"
            }
        }

        return <div align="center">
                <h1>{renderEmoji(this.props.attempt)}</h1>                
                <p>{renderStatus(this.props.attempt)}</p>
            </div>


       
    } 

    render () {
        return <> <div style={{background: "#e7f6ff" , marginTop: "2vh", paddingTop: "1vh", paddingBottom: '5vh', boxShadow: "0 3px 6px rgba(255,212,114,0.05), 0 1.5px 3px rgba(255,212,114,0.05)"
    }} class='card'>
            <div class="card-content">
            <div align="center" style={{paddingBottom: '3vh'}}><small><strong>DID YOU MANAGE TO DO IT?</strong></small></div>
            {this.renderText()}
            <div align="left">
                {this.renderInputRange()}
            </div>
            </div>
        </div>
        <br/>
        </>
    }


  
}

export default Self;
