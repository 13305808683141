import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';
import Footer from '../../Footer';
import AttemptedQuestions from './AttemptedQuestions';
import history from '../../../history'

class Mentor extends React.Component{

    // type: 0 == training sets index, 1 == questions index

    state = {type: 0, pageNumber: 1, training_sets: [], student_session_id: null, loading: false, Yscroll_position: 0}

    componentDidMount = async () => {
        this.setState({ loading: true})
        const response = await kopi.get('/api/library/history/mentor_sets', {
            params: {userId: this.props.currentUser, pageNumber: this.state.pageNumber}
        });    
        this.setState({training_sets: response.data, loading: false})
    }

    componentDidUpdate = () => {
        window.scrollTo(0, this.state.Yscroll_position)
    }

    selectTrainingSet = (training_set) => {
        this.props.resetClose();
        this.setState({type: 1,student_session_id: training_set._id, Yscroll_position: window.pageYOffset});
    }

    renderList = () => {


        if (this.state.training_sets.length == 0) {
            return <div align="center"><img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540597/Joni%20Assets/klo1.png" class="img-fluid" style={{marginTop: "5vh", marginBottom: "5vh"}}/>
                <h5>Practice sessions conducted by your mentor would appear here.</h5>
                <p>Want to connect to a mentor? Go to <btn 
                    onClick={()=>history.push({
                        pathname:'/settings',
                        state: {mode : 'config'}
                    })
                } class="btn btn-sm btn-light">Settings</btn></p>
            </div>
        }

        return this.state.training_sets.map(training_set => {
            let created_at = new Date(training_set.created_at);

            let month = new Array();
            month[0] = "January";
            month[1] = "February";
            month[2] = "March";
            month[3] = "April";
            month[4] = "May";
            month[5] = "June";
            month[6] = "July";
            month[7] = "August";
            month[8] = "September";
            month[9] = "October";
            month[10] = "November";
            month[11] = "December";

            let formattedDay = created_at.getDate();
            let formattedMonth = month[created_at.getMonth()].slice(0,3);

            return (
                <div onClick={() => this.selectTrainingSet(training_set)} key={training_set._id} className="row alert alert-light cursor-pointer">
                    <div className="col-4">
                        <div>
                            <strong><h1 className="no-bottom-margin">{formattedDay} <br/>
                            {formattedMonth}
                            </h1></strong>
                        </div>
                    </div>
                    <div className="col-8">
                        <div align="right">
                            <small><strong>{training_set.attempts.filter(attempt => {
                                    return attempt.correct }).length}/{training_set.attempts.length}</strong> correct
                            </small><br/>
                        </div>
                    </div>
                </div>
                ); 
        });
    }

    render(){
        if (this.state.loading) {
            return <div align="center"> 
                    <br/><br/><br/><br/>           
                    <div className="spinner-border text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
        } else {
            if (this.state.type===0 || this.props.close) {
                return <div className="container">
                    <br/>
                    {this.renderList()}
                    <br/><br/><br/><br/><br/>
                </div>;
            } else {
                return <div className="container">
                    <br/>
                    <button class="btn btn-block btn-dark" onClick={()=>this.setState({type: 0})}><i class="fas fa-arrow-left"></i> &nbsp; All Mentor-led Sessions</button>
                    <br/>
                    <AttemptedQuestions student_session_id={this.state.student_session_id} />
                </div>;
            }
        }
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Mentor);