import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../history';
import kopi from '../../apis/kopi';

import { Flipper, Flipped } from "react-flip-toolkit";

import StudyPlanComplete from './StudyPlanComplete';
import NewHomework from './NewHomework';


const listData = [0];
const colors = ["#FFD472"];

const shouldFlip = index => (prev, current) =>
  index === prev || index === current;

const ListItem = ({ dark_mode, index, color, onClick, content, isSmallScreen }) => {

  const progressBarStatusHomework = () => {
    if (dark_mode){
      return <><small className={isSmallScreen ? 'vw-font' : 'vh-font'}><strong>{pause} {homework_message} </strong>: {homework_parts_done}/{homework_upcoming_goal} Parts Completed</small>
      <div className="progress progress-dark btn-not-rounded" style={{ maxHeight: "7px", marginTop: "5px" }}>
        <div className="progress-bar progress-bar-dark btn-not-rounded" style={{ width: `${bar_percentage}%`, maxHeight: "7px", minWidth: "5%" }}></div>
      </div></>
    } else {
      return <><small className={isSmallScreen ? 'vw-font' : 'vh-font'}><strong>{pause} {homework_message} </strong>: {homework_parts_done}/{homework_upcoming_goal} Parts Completed</small>
      <div className="progress btn-not-rounded" style={{ maxHeight: "7px", marginTop: "5px" }}>
        <div className="progress-bar btn-not-rounded" style={{ width: `${bar_percentage}%`, maxHeight: "7px", minWidth: "5%" }}></div>
      </div></>
    }
  }

  const progressBarStatusGoal = () => {
    if (dark_mode){
      return <><small className={isSmallScreen ? 'vw-font' : 'vh-font'}><strong>{pause} {weekly_progress_msg}</strong>: {parts_done}/{upcoming_goal} Parts Completed</small>
        <div className={"progress-dark progress btn-not-rounded"} style={{ maxHeight: "7px", marginTop: "5px" }}>
          <div className={dark_mode ? "progress-bar-dark progress-bar btn-not-rounded" :"progress-bar btn-not-rounded"} style={{ width: `${bar_percentage}%`, maxHeight: "7px", minWidth: "5%" }}></div>
        </div></>
    } else {
      return <><small className={isSmallScreen ? 'vw-font' : 'vh-font'}><strong>{pause} {weekly_progress_msg}</strong>: {parts_done}/{upcoming_goal} Parts Completed</small>
        <div className={"progress btn-not-rounded"} style={{ maxHeight: "7px", marginTop: "5px" }}>
          <div className={dark_mode ? "progress-bar btn-not-rounded" :"progress-bar btn-not-rounded"} style={{ width: `${bar_percentage}%`, maxHeight: "7px", minWidth: "5%" }}></div>
        </div></>
    }
  }

  if (!content) return <></>;

  const { weekly_progress_msg, parts_done, upcoming_goal, active, response_type,
    homework_parts_done,
    homework_bare_goal,
    homework_recommended_goal,
    homework_stretch_goal,
    homework_end_date,
    homework_active
  } = content;

  let message;
  let bar_percentage
  let pause;
  let homework_upcoming_goal
  let homework_message
  if (homework_active) {
    if (homework_parts_done >= homework_stretch_goal) {
      bar_percentage = 100
      homework_message = "You smashed it!"
      homework_upcoming_goal = homework_stretch_goal
    } else if (homework_parts_done >= homework_recommended_goal) {
      bar_percentage = homework_parts_done / homework_stretch_goal * 100
      homework_upcoming_goal = homework_stretch_goal
      homework_message = "Joni approves"
    } else if (homework_parts_done >= homework_bare_goal) {
      bar_percentage = homework_parts_done / homework_recommended_goal * 100
      homework_upcoming_goal = homework_recommended_goal
      homework_message = "Not bad"
    } else {
      bar_percentage = homework_parts_done / homework_bare_goal * 100
      homework_upcoming_goal = homework_bare_goal
      homework_message = "Homework is life!"
    }
    pause = <i style={dark_mode ? {color: '#9fa8da'}: { color: "#3076A3" }} class="fas fa-play-circle"></i>;
    if (!homework_active) pause = <i style={dark_mode ? {color: '#ef9a9a'} : { color: "#ff6b81" }} class="fas fa-pause-circle"></i>;
    message = progressBarStatusHomework()

  } else {
    bar_percentage = parts_done < upcoming_goal ? parts_done / upcoming_goal * 100 : 100

    pause = <i style={dark_mode ? {color: '#9fa8da'}: { color: "#3076A3" }} class="fas fa-play-circle"></i>;

    if (!active) pause = <i style={dark_mode ? {color: '#ef9a9a'} : { color: "#ff6b81" }} class="fas fa-pause-circle"></i>;

    if (response_type !== "No Plan" && response_type !== "Has Concluded") {
      message = progressBarStatusGoal()
    } else if (response_type === "Has Concluded") {
      message = <small style={{ fontSize: "0.8em" }}><strong>Your study plan has concluded!</strong></small>

    } else {
      message = <small style={{ fontSize: "0.8em" }}><strong>Click here to create a forward study plan</strong></small> 
    }
  }


  // console.log({
  //   homework_parts_done,
  //   homework_bare_goal,
  //   homework_recommended_goal,
  //   homework_stretch_goal,
  //   homework_end_date,
  //   homework_active
  // })

  return (
    <Flipped
      inverseFlipId={`listItem-${index}`}
      // flipId={`listItem-${index}`}
      stagger="card"
      shouldInvert={shouldFlip(index)}
    >
      <div
        className="listItem"
        style={dark_mode ? {backgroundColor: "#ffcc80"} : { backgroundColor: color }}
        onClick={() => onClick(index)}
      >
        <Flipped inverseFlipId={`listItem-${index}`}>
          <div className="listItemContent">
            <Flipped
              flipId={`listItemContent-${index}`}
              stagger="card-content"
              shouldFlip={shouldFlip(index)}
            >
              <div className="description" text-align="center" >
                <Flipped
                  flipId={`description-${index}`}
                >
                  {message}

                </Flipped>
              </div>
            </Flipped>

          </div>
        </Flipped>
      </div>
    </Flipped>
  );
};

const ExpandedListItem = ({ dark_mode, resumeHomework, resumeStudyPlan, index, color, onClick, content }) => {

  const progressBarExpandedStatusHomework = () => {
    if (dark_mode){
      return <> <h5>{homework_pause} Complete {`${homework_upcoming_goal}`} Parts ({`${homework_parts_done}`}/{`${homework_upcoming_goal}`})</h5>
      <div className="progress-dark progress" style={{ minHeight: "4vh", marginTop: "5px", borderRadius: "50px" }}>
        <div className="progress-bar-dark progress-bar" style={{ width: `${homework_bar_percentage}%`, minWidth: "5%"}}></div>
      </div></>
    } else {
      return <> <h5>{homework_pause} Complete {`${homework_upcoming_goal}`} Parts ({`${homework_parts_done}`}/{`${homework_upcoming_goal}`})</h5>
      <div className="progress" style={{ minHeight: "4vh", marginTop: "5px", borderRadius: "50px" }}>
        <div className="progress-bar" style={{ width: `${homework_bar_percentage}%`, minWidth: "5%"}}></div>
      </div></>
    }
  }

  const progressBarExpandedStatusGoal = () => {
    if (dark_mode){
      return <> <h5>{pause} Complete {`${upcoming_goal}`} Parts ({`${parts_done}`}/{`${upcoming_goal}`})</h5>
      <div className="progress progress-dark" style={{ minHeight: "4vh", marginTop: "5px", borderRadius: "50px" }}>
        <div className="progress-bar progress-bar-dark" style={{ width: `${bar_percentage}%`, minWidth: "5%"}}></div>
      </div></>
    } else {
      return <> <h5>{pause} Complete {`${upcoming_goal}`} Parts ({`${parts_done}`}/{`${upcoming_goal}`})</h5>
      <div className="progress" style={{ minHeight: "4vh", marginTop: "5px", borderRadius: "50px" }}>
        <div className="progress-bar" style={{ width: `${bar_percentage}%`, minWidth: "5%"}}></div>
      </div></>
    }
  }

  let { weekly_progress_msg, additional_msg, parts_done, upcoming_goal, end_date, active, response_type,
    homework_parts_done,
    homework_bare_goal,
    homework_recommended_goal,
    homework_stretch_goal,
    homework_end_date,
    homework_active } = content;

  let message;
  let hero_message;

  let mode;

  let bar_percentage = parts_done < upcoming_goal ? parts_done / upcoming_goal * 100 : 100

  let pause = <i style={dark_mode ? {color: '#9fa8da'}: { color: "#3076A3" }} class="fas fa-play-circle"></i>;

  if (!active && response_type !== "No Plan") {
    pause = <i style={dark_mode ? {color: '#ef9a9a'} : { color: "#ff6b81" }} class="fas fa-pause-circle"></i>;
    weekly_progress_msg = <div>You have paused your study plan! <br /></div>
  }

  let homework_bar_percentage
  let homework_pause = <i style={dark_mode ? {color: '#9fa8da'} : { color: "#3076A3" }} class="fas fa-play-circle"></i>;
  if (!homework_active) homework_pause = <i style={dark_mode ? {color: '#ef9a9a'} : { color: "#ff6b81" }} class="fas fa-pause-circle"></i>;
  let homework_upcoming_goal
  let homework_message
  if (homework_active) {
    if (homework_parts_done >= homework_stretch_goal) {
      homework_bar_percentage = 100
      homework_upcoming_goal = homework_stretch_goal
      homework_message = "You smashed it!"
    } else if (homework_parts_done >= homework_recommended_goal) {
      homework_bar_percentage = homework_parts_done / homework_stretch_goal * 100
      homework_upcoming_goal = homework_stretch_goal
      homework_message = "Joni nods in approval."
    } else if (homework_parts_done >= homework_bare_goal) {
      homework_bar_percentage = homework_parts_done / homework_recommended_goal * 100
      homework_upcoming_goal = homework_recommended_goal
      homework_message = "Not bad."
    } else {
      homework_bar_percentage = homework_parts_done / homework_bare_goal * 100
      homework_upcoming_goal = homework_bare_goal
      homework_message = "Homework is life!"
    }
  }

  let resume_homework_button;

  if (content) {
    if (homework_active) {
      mode = 'Homework'
      hero_message = progressBarExpandedStatusHomework()
      if (!active && response_type !== "No Plan" && response_type !== "Has Concluded") additional_msg = <div>
        <button className="btn btn-blue" style={dark_mode ? {backgroundColor:'#9fa8da', color:'black'} : {}} onClick={resumeStudyPlan}> Click here to resume your study plan</button>
      </div>
      weekly_progress_msg = <div>{homework_message}<br /></div>
    } else {
      mode = 'Study Plan'
      if (response_type === "No Plan") {
        hero_message = <> <button className="btn btn-blue" onClick={() => { history.push('/createplan') }}>Create a Study Plan now</button></>
      } else if (response_type === "Has Concluded") {
        hero_message = <> <button className="btn btn-blue" onClick={() => { history.push('/studyplan/summary') }}>Let's see how you did!</button></>
        weekly_progress_msg = <div>Joni claps for you! <br /></div>
      } else {
        hero_message = progressBarExpandedStatusGoal()
      }

      if (!active && response_type !== "No Plan" && response_type !== "Has Concluded") additional_msg = <div>
        <button className="btn btn-blue" style={dark_mode ? {backgroundColor:'#9fa8da', color:'black'} : {}}   onClick={resumeStudyPlan}> Click here to resume your study plan</button>
      </div>

      resume_homework_button = !homework_active && homework_recommended_goal ? <div>
        <br/>
        <button className="btn btn-light btn-tiny" style={dark_mode ? {backgroundColor:'#b0bec5', borderColor:'#b0bec5'} : {}} onClick={resumeHomework}> Click here to resume your homework</button>
        <br />
      </div> : ""


    }

    message = <div style={{ width: "100%" }} className="ticker-text" align='center'>
      <small><strong>{mode}</strong></small> <br />
      {hero_message}
      <hr />
      {weekly_progress_msg} <br />
      <small>{additional_msg}</small>
      <small>{resume_homework_button}</small>
    </div>

  }


  return (
    <Flipped
      flipId={`listItem-${index}`}
      stagger="card"
      onStart={el => {
        setTimeout(() => {
          el.classList.add("animated-in");
        }, 400);
      }}
    >
      <div
        className="expandedListItem"
        style={dark_mode ? {backgroundColor: "#ffcc80"} : { backgroundColor: color }}
        onClick={() => onClick(index)}
      >
        <Flipped inverseFlipId={`listItem-${index}`}>
          <div className="expandedListItemContent">
            <div className="description">
              <Flipped
                flipId={`description-${index}`}
                stagger="card-content"
                shouldFlip={shouldFlip(index)}
              >
                {message}
              </Flipped>
            </div>
          </div>
        </Flipped>
      </div>
    </Flipped>
  );
};

class Ticker extends React.Component {

  state = {
    new_homework: false,
    homework: null,
    focused: null,
    study_week: null,
    open: false,
    update_marker: null,
    trigger_animation: false,
    last_rewarded_study_plan: 0,
    last_rewarded_homework: 0,
    animation_for: null, // Study Plan , Homework
    homeworkProgressId: '',
  };

  componentDidMount = async () => {
    let response = kopi.get('/api/study_plans/weekly-goal-status', {
      params: { userId: this.props.currentUser }
    });

    let response2 = kopi.get('/api/homework/fetch_homework', {
      params: { user_id: this.props.currentUser }
    })

    Promise.all([response, response2]).then((responses) => {
      if (!responses[1].data.no_plan && !responses[1].data.homework_progress.accepted) {
        this.setState({
          new_homework: responses[1].data.homework_progress ? !responses[1].data.homework_progress.accepted : false,
          study_week: responses[0].data, homework: responses[1].data.homework_progress,
          update_marker: this.props.update_marker,
          trigger_animation: true,
          homeworkProgressId: responses[1].data.homework_progress ? String(responses[1].data.homework_progress._id) : '',
        });
      } else {
        this.setState({
          new_homework: responses[1].data.homework_progress ? !responses[1].data.homework_progress.accepted : false,
          study_week: responses[0].data, homework: responses[1].data.homework_progress,
          update_marker: this.props.update_marker,
          homeworkProgressId: responses[1].data.homework_progress ? String(responses[1].data.homework_progress._id) : '',
        });
      }
    })
  }

  componentDidUpdate = async () => {
    if (!this.props.update_marker || this.props.update_marker === this.state.update_marker) return;

    let response = kopi.get('/api/study_plans/weekly-goal-status', {
      params: { userId: this.props.currentUser }
    });

    let response2 = kopi.get('/api/homework/fetch_homework', {
      params: { user_id: this.props.currentUser }
    })

    Promise.all([response, response2]).then((responses) => {
      const { response_type, parts_done, bare_goal, recommended_goal, stretch_goal, end_date, active } = responses[0].data;
      if (active && this.props.attempted_since_refresh !== 0 && parts_done !== this.state.last_rewarded_study_plan && (parts_done == bare_goal || parts_done == recommended_goal || parts_done == stretch_goal) && parts_done <= stretch_goal) {
        this.setState({
          new_homework: responses[1].data.homework_progress ? !responses[1].data.homework_progress.accepted : false,
          homework: { ...responses[1].data.homework_progress, active: false },
          last_rewarded_study_plan: parts_done,
          study_week: { ...responses[0].data, active: true },
          update_marker: this.props.update_marker,
          trigger_animation: true,
          animation_for: "Study Plan"
        });
      } else if (responses[1].data.homework_progress && responses[1].data.homework_progress.active && this.props.attempted_since_refresh !== 0 && responses[1].data.homework_progress.parts_done !== this.state.last_rewarded_homework && (responses[1].data.homework_progress.parts_done == responses[1].data.homework_progress.bare_goal || responses[1].data.homework_progress.parts_done == responses[1].data.homework_progress.recommended_goal || responses[1].data.homework_progress.parts_done == responses[1].data.homework_progress.stretch_goal) && responses[1].data.homework_progress.parts_done <= responses[1].data.homework_progress.stretch_goal) {
        this.setState({
          new_homework: responses[1].data.homework_progress ? !responses[1].data.homework_progress.accepted : false,
          homework: { ...responses[1].data.homework_progress, active: true },
          last_rewarded_homework: responses[1].data.homework_progress.parts_done,
          study_week: { ...responses[0].data, active: false },
          update_marker: this.props.update_marker,
          trigger_animation: true,
          animation_for: "Homework"
        });
      } else {
        this.setState({
          new_homework: responses[1].data.homework_progress ? !responses[1].data.homework_progress.accepted : false,
          homework: responses[1].data.homework_progress,
          study_week: responses[0].data,
          update_marker: this.props.update_marker,
          trigger_animation: false
        });
      }
    });
  }


  onClick = index =>
    this.setState({
      focused: this.state.focused === index ? null : index,
      open: this.state.open ? false : true
    });

  resumeStudyPlan = () => {
    kopi.put('/api/study_plans/resume-studyplan', {
      userId: this.props.currentUser
    });
    this.setState({ study_week: { ...this.state.study_week, active: true }, homework: { ...this.state.homework, active: false } });
  }

  resumeHomework = () => {
    kopi.put('/api/homework/resume_homework', {
      user_id: this.props.currentUser,
      homework_progress_id: this.state.homeworkProgressId
    });
    this.setState({ homework: { ...this.state.homework, active: true }, study_week: { ...this.state.study_week, active: false } });
  }

  createContentObject = (isCollapsed) => {

    if (!this.state.study_week && !this.state.homework) return;

    const { response_type, parts_done, bare_goal, recommended_goal, stretch_goal, end_date, active } = this.state.study_week;

    let homework_parts_done;
    let homework_bare_goal;
    let homework_recommended_goal;
    let homework_stretch_goal;
    let homework_end_date;
    let homework_active;

    if (this.state.homework) {
      homework_parts_done = this.state.homework.parts_done;
      homework_bare_goal = this.state.homework.bare_goal;
      homework_recommended_goal = this.state.homework.recommended_goal;
      homework_stretch_goal = this.state.homework.stretch_goal;
      homework_end_date = this.state.homework.end_date;
      homework_active = this.state.homework.active;
    }

    if (isCollapsed) {
      switch (response_type) {
        case 'In Progress':
          if (parts_done < bare_goal) {
            return {
              response_type,
              weekly_progress_msg: "Hitting Bare Minimum",
              parts_done: parts_done,
              upcoming_goal: bare_goal,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          } else if (parts_done < recommended_goal) {
            return {
              response_type,
              weekly_progress_msg: "Doing The Joni Workout",
              parts_done: parts_done,
              upcoming_goa: recommended_goal,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          } else if (parts_done < stretch_goal) {
            return {
              response_type,
              weekly_progress_msg: "Joni is Proud of You!",
              parts_done: parts_done,
              upcoming_goal: stretch_goal,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          } else {
            return {
              response_type,
              weekly_progress_msg: "Wow Joni is Amazed!",
              parts_done: parts_done,
              upcoming_goal: stretch_goal,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          }
          break;
        case 'Has Concluded':
          return {
            response_type,
            weekly_progress_msg: "Study plan has concluded!",
            parts_done: null,
            upcoming_goal: null,
            active,
            homework_parts_done,
            homework_bare_goal,
            homework_recommended_goal,
            homework_stretch_goal,
            homework_end_date,
            homework_active
          }
          break;
        case 'No Plan':
          return {
            response_type,
            weekly_progress_msg: "Click here to create a forward study plan",
            parts_done: null,
            upcoming_goal: null,
            active,
            homework_parts_done,
            homework_bare_goal,
            homework_recommended_goal,
            homework_stretch_goal,
            homework_end_date,
            homework_active
          }
          break;
        default:
      }
    } else {
      switch (response_type) {
        case 'In Progress':
          let status;
          if (parts_done < bare_goal) {
            return {
              response_type,
              weekly_progress_msg: "Hitting Bare Minimum",
              additional_msg: "It does not matter how slowly you go, as long as you do not stop.",
              parts_done: parts_done,
              upcoming_goal: bare_goal,
              end_date,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          } else if (parts_done < recommended_goal) {
            return {
              response_type,
              weekly_progress_msg: "Doing The Joni Workout",
              additional_msg: "You're doing well! Keep it up!",
              parts_done: parts_done,
              upcoming_goal: recommended_goal,
              end_date,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          } else if (parts_done < stretch_goal) {
            return {
              response_type,
              weekly_progress_msg: "Joni is Proud of You!",
              additional_msg: "You're doing well! Keep it up!",
              parts_done: parts_done,
              upcoming_goal: stretch_goal,
              end_date,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          } else {
            return {
              response_type,
              weekly_progress_msg: "Wow Joni is Amazed!",
              additional_msg: "You have exceeded Joni's expectations!",
              parts_done: parts_done,
              upcoming_goal: stretch_goal,
              end_date,
              active,
              homework_parts_done,
              homework_bare_goal,
              homework_recommended_goal,
              homework_stretch_goal,
              homework_end_date,
              homework_active
            }
          }
          break;
        case 'Has Concluded':
          return {
            response_type,
            weekly_progress_msg: "Study plan has concluded!",
            additional_msg: "Yay you made it!",
            parts_done: null,
            upcoming_goal: null,
            end_date,
            active,
            homework_parts_done,
            homework_bare_goal,
            homework_recommended_goal,
            homework_stretch_goal,
            homework_end_date,
            homework_active
          }
          break;
        case 'No Plan':
          return {
            response_type,
            weekly_progress_msg: "Learn new topics with a forward study plan",
            additional_msg: "Chart your path towards mastery now!",
            parts_done: null,
            upcoming_goal: null,
            end_date: null,
            active: false,
            homework_parts_done,
            homework_bare_goal,
            homework_recommended_goal,
            homework_stretch_goal,
            homework_end_date,
            homework_active
          }
          break;
        default:
      }
    }
  }

  renderStudyPlanCompletion = () => {

    const continueStudyPlan = () => {
      this.setState({ trigger_animation: false, animation_for: null, animation: false })
    }

    const renderContent = () => {
      if (this.state.trigger_animation && !this.state.new_homework && this.state.animation_for == "Study Plan") {
        const { response_type, parts_done, bare_goal, recommended_goal, stretch_goal, end_date, active } = this.state.study_week;
        let goal_type;
        if (parts_done == bare_goal) {
          goal_type = "Bare Goal"
        } else if (parts_done == recommended_goal) {
          goal_type = "Recommended Goal"
        } else if (parts_done == stretch_goal) {
          goal_type = "Stretch Goal"
        } else {
          goal_type = "Stretch Goal"
        }
        return <StudyPlanComplete continueStudyPlan={continueStudyPlan} goal_type={goal_type} />
      } else {
        return <></>
      }
    }
    return <><div id={this.state.trigger_animation && !this.state.new_homework && this.state.animation_for == "Study Plan" ? "overlay-darker-darker" : ""} onClick={() => { this.setState({ open: false, focused: null }) }}>
    </div>{renderContent()}</>

  }

  renderHomeworkCompletion = () => {

    const continueStudyPlan = () => {
      this.setState({ trigger_animation: false, animation_for: null, animation: false })
    }

    const renderContent = () => {
      if (this.state.trigger_animation && !this.state.new_homework && this.state.animation_for == "Homework") {
        const { parts_done, bare_goal, recommended_goal, stretch_goal } = this.state.homework;
        let goal_type;
        if (parts_done == bare_goal) {
          goal_type = "Bare Goal"
        } else if (parts_done == recommended_goal) {
          goal_type = "Recommended Goal"
        } else if (parts_done == stretch_goal) {
          goal_type = "Stretch Goal"
        } else {
          goal_type = "Stretch Goal"
        }
        return <StudyPlanComplete continueStudyPlan={continueStudyPlan} goal_type={goal_type} />
      } else {
        return <></>
      }
    }

    return <><div id={this.state.trigger_animation && !this.state.new_homework && this.state.animation_for == "Homework" ? "overlay-darker-darker" : ""} onClick={() => { this.setState({ open: false, focused: null }) }}>
    </div>{renderContent()}</>

  }

  renderNewHomework = () => {
    const continueStudyPlan = () => {
      kopi.put('/api/homework/accept_homework', {
        user_id: this.props.currentUser,
        homework_progress_id: this.state.homeworkProgressId
      });
      this.setState({ new_homework: false, trigger_animation: false, animation: false, homework: { ...this.state.homework, active: true }, study_week: { ...this.state.study_week, active: false } })
    }
    if (this.state.new_homework && this.state.trigger_animation == true) {
      return <><div id="overlay-darker-darker"></div><NewHomework continueStudyPlan={continueStudyPlan} goal_type={"New Homework"} /></>
    } else {
      return <></>
    }
  }

  render() {
    let overlay = this.state.open ? "overlay" : ""

    return (
      <>
        <div id={overlay} onClick={() => { this.setState({ open: false, focused: null }) }}></div>

        {this.renderStudyPlanCompletion()}
        {this.renderHomeworkCompletion()}
        {this.renderNewHomework()}

        <div className="ticker cursor-pointer" style={{ position: "fixed" }}>

          <Flipper
            flipKey={this.state.focused}
            className="staggered-list-content"
            spring="gentle"
            staggerConfig={{
              card: {
                reverse: this.state.focused !== null,
                speed: 0.5
              }
            }}
            decisionData={this.state.focused}
          >
            <ul className="list">
              {listData.map(index => {
                return (
                  <li key={index}>
                    {index === this.state.focused ? (
                      <ExpandedListItem
                        index={this.state.focused}
                        color={colors[this.state.focused % colors.length]}
                        onClick={this.onClick}
                        content={this.createContentObject()}
                        resumeStudyPlan={this.resumeStudyPlan}
                        resumeHomework={this.resumeHomework}
                        dark_mode={this.props.dark_mode}
                      />
                    ) : (
                        <ListItem
                          index={index}
                          key={index}
                          color={colors[index % colors.length]}
                          onClick={this.onClick}
                          content={this.createContentObject()}
                          isSmallScreen={window.innerWidth <= 350}
                          dark_mode={this.props.dark_mode}
                        />
                      )}
                  </li>
                );
              })}
            </ul>
          </Flipper>
        </div>
      </>
    );
  }

}


const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Ticker);