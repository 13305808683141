import React from 'react'; 

import {InlineTex} from 'react-tex';

import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css'

class Self extends React.Component {

    renderInputRange = () => {
        return <div style={{paddingLeft: '4vw', paddingRight: '4vw', paddingTop: '0vw', paddingBottom: '2vw', overflow:'None'}}> <InputRange
        formatLabel={value => ''}
        step={33}
        maxValue={99}
        minValue={0}
        value={this.props.self_assessment_score}
        onChange={value => this.props.updateScore(value)} /> </div>
    }
    
    renderMarkers = () => {
        return <div> 
            <div class="float-left">
                <span>😢</span>
            </div>
            <div class="float-right">
                <span>😀</span>
            </div>
        </div>
    }

    renderText = (value) => {
        const renderEmoji = (value) => {
            if (value == 0) {
                return '😢'
            } else if (value == 33) {
                return '😪'
            } else if (value == 66) {
                return '🙂'
            } else {
                return '😀'
            }
        }

        const renderStatus = (value) => {
            if (value == 0) {
                return "I don't know where to begin"
            } else if (value == 33) {
                return 'I am able to make some progress'
            } else if (value == 66) {
                return 'I almost got it'
            } else {
                return "It's perfect!"
            }
        }

        return <div align="center">
                <h1>{renderEmoji(this.props.self_assessment_score)}</h1>                
                <p style={this.props.dark_mode ? {color:'#E1E1E1'} : {}}>{renderStatus(this.props.self_assessment_score)}</p>
            </div>


       
    } 

    render () {

        const cardStyle = this.props.dark_mode ? {
            background: "#2E2E2E" , 
            marginTop: "2vh", 
            paddingTop: "1vh", 
            paddingBottom: '5vh',
            borderRadius: '8px'
        } : {
            background: "#e7f6ff" , 
            marginTop: "2vh", 
            paddingTop: "1vh", 
            paddingBottom: '5vh', 
            boxShadow: "0 3px 6px rgba(255,212,114,0.05), 0 1.5px 3px rgba(255,212,114,0.05)"
        }

        return <div style={cardStyle} class='card'>
            <div class="card-content">
            <div align="center" style={{paddingBottom: '3vh'}}><small style={this.props.dark_mode ? {color:'#E1E1E1'} : {}}><strong>DID YOU ASSESS YOURSELF CORRECTLY? CHANGE IT IF YOU NEED TO!</strong></small></div>
            {this.renderText()}
            {this.props.has_updated_self_assessment ? <></> : this.renderInputRange()}
            </div>
        </div>
    }


  
}

export default Self;
