import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Offline, Online } from "react-detect-offline";


import Auth from './Auth';
import Login from './Auth/Login.js';

import SignUp from './Auth/SignUp'
import VerifyEmail from './Auth/VerifyEmail'

import ProfileShow from './Profile/ProfileShow';

import Dashboard from './Dashboard';
import DailyMood from './Dashboard/DailyMood';

import Training from './Training';

import Library from './Library';

import Legal from './Legal';

import Privacy from './Privacy';

import Live from './Live';

import Settings from './Settings';

import history from '../history';

import CreatePlan from './StudyPlan/CreatePlan';
import StudyPlanSummary from './StudyPlan/StudyPlanSummary.js';

import Loading from './Loading';
import HttpsRedirect from 'react-https-redirect';
import NewOnboard from './Onboard/OnboardCards';
// import Google from './Auth/Google';
// import Setup from './Auth/Setup';
import OnboardStart from './Onboard/OnboardDashboard';
import AvatarCustomization from './Onboard/Avatar/AvatarCustomization';
import Slip from './Slip';
import ResultSlip from './Slip/ResultSlip/index.js';
import Kicked from './Slip/Kicked';
import Activate from './Auth/Activate.js';
import ChangePassword from './Auth/ChangePassword.js';
import ActivateAccount from './Auth/ActivateAccount.js';

import Trial from './Auth/Trial.js'
import Reactivate from './Auth/Reactivate.js';
import LiveChat from './LiveChat';

import CacheBuster from './CacheBuster';

import test from './Onboard/Avatar/test';

import Prompt from './Prompt';

// import { validateVersion } from '../actions';
import { connect } from 'react-redux';

import kopi from '../apis/kopi';
import packageJson from '../../package.json';

global.appVersion = packageJson.version;

class App extends React.Component {

    state = {
        isOffline: false
    }

    // componentDidMount()
    // {
    //     PullToRefresh.init({
    //         mainElement: 'body',
    //         onRefresh() {
    //             window.location.reload();
    //         },
    //         iconArrow: ReactDOMServer.renderToString(
    //             <i class="fas fa-sync-alt"></i>
    //         ),
    //         iconRefreshing: ReactDOMServer.renderToString(
    //             <i class="fas fa-sync-alt" spin={true}></i>
    //         ),
    //     });
    // }

    // componentWillUnmount() 
    // {
    //     // Don't forget to destroy all instances on unmout
    //     // or you will get some glitches.
    //     PullToRefresh.destroyAll();
    // }

    showiOSPrompt = () => {
        const isIos = () => {
            const userAgent = window.navigator.userAgent.toLowerCase();
            return /iphone|ipad|ipod/.test(userAgent);
        }

        // insert code to check if user has dismissed the prompt before in the past 3 days

        // Detects if device is in standalone mode
        const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

        const isOnExitSlipPage = window.location.href.includes('/slip')
        const isOnOnboarding = window.location.href.includes('/onboardstart')

        if (isIos() && !isInStandaloneMode() && !isOnExitSlipPage && !isOnOnboarding) {
            return <Prompt />;
        }
    }

    refreshUponConnected = () => {
        if (this.state.isOffline) {
            window.location.reload();
        }
        this.setState((prevState, props) => ({
            isOffline: !prevState.isOffline
        }))
    }

    render() {
        return <>

            <HttpsRedirect>
                <CacheBuster>
                    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                        if (loading) return <Loading />;
                        if (!loading && !isLatestVersion) {
                            // You can decide how and when you want to force reload
                            refreshCacheAndReload();
                        }
                        return (
                            <>

                                <Offline onChange={this.refreshUponConnected}>
                                    <div className="alert alert-info alert-dismissible fade show"
                                        role="alert"
                                        style={{
                                            background: '#2D3437',
                                            borderColor: '#2D3437',
                                            borderBottomColor: '#1C8AC8',
                                            borderBottomWidth: '5px',
                                            color: 'white',
                                            zIndex: '999999999999999999999',
                                            marginBottom: '0px',
                                            position: 'fixed'
                                        }}>
                                        Uh-oh, you seem to be offline. Check your internet connection.
                        </div>
                                </Offline>
                                <Router history={history}>
                                    <Switch>
                                        {/* Settings */}
                                        <Route path="/settings" exact component={Settings} />
                                        {/* avatar */}
                                        <Route path="/avatar" exact component={AvatarCustomization} />
                                        {/*test*/}
                                        <Route path="/test" exact component={test} />
                                        {/* Training */}
                                        <Route path="/training" exact component={Training} />
                                        {/* Training */}
                                        <Route path="/library" exact component={Library} />
                                        <Route path="/library/:id" exact component={Library} />
                                        {/* Landing */}
                                        <Route path="/" exact render={(props) => <Auth {...props} showiOSPrompt={this.showiOSPrompt} />} />
                                        <Route path="/login" exact component={Login} />

                                        <Route path="/loading" exact component={Loading} />
                                        {/* Joni Live */}
                                        <Route path="/live" exact component={Live} />

                                        {/* New Account Creation */}
                                        <Route path="/onboarding" exact component={SignUp} />
                                        <Route path="/verifyemail" exact component={VerifyEmail} />
                                        {/* Onboarding Process*/}
                                        <Route path="/onboard" exact component={NewOnboard} />
                                        {/* <Route path="/google" exact component={Google} /> */}
                                        {/* <Route path="/setup" exact component={Setup} /> */}
                                        {/* <Route path='/reload' exact component={Reload} /> */}
                                        {/* Profile */}
                                        <Route path="/dashboard" exact component={Dashboard} />
                                        <Route path="/daily" exact render={(props) => <DailyMood {...props} showiOSPrompt={this.showiOSPrompt} />} />
                                        <Route path="/brain" exact component={ProfileShow} />

                                        {/* Study Plan */}
                                        <Route path="/createplan" exact component={CreatePlan} />
                                        <Route path="/studyplan/summary" component={StudyPlanSummary} />

                                        {/* Legal */}
                                        <Route path="/legal" exact component={Legal} />
                                        {/* Privacy */}
                                        <Route path="/privacy" exact component={Privacy} />

                                        <Route path="/onboardstart" exact component={OnboardStart} />
                                        <Route path="/onboardstart:stageKey" exact component={OnboardStart} />

                                        {/* ExitSlip */}
                                        <Route path="/slip/results" component={ResultSlip} />
                                        <Route path="/slip/kicked" component={Kicked} />
                                        <Route path="/slip" component={Slip} />

                                        <Route path="/activate" component={Activate} />
                                        <Route path="/trial" component={Trial} />
                                        <Route path="/reactivate" component={Reactivate} />
                                        <Route path="/reset_password/:token" component={ChangePassword} />
                                        <Route path="/activate_account/:student_population/:user_id" component={ActivateAccount} />

                                        {/* LiveChat */}
                                        <Route path="/live-chat" component={LiveChat} />


                                    </Switch>
                                </Router>
                            </>
                        )
                    }}
                </CacheBuster>
            </HttpsRedirect></>;
    }

};

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        // version: state.version.version
    };
};

export default connect(mapStateToProps)(App);
