import React from 'react';
import { connect } from 'react-redux';
import SingleQuestion from './SingleQuestion';
import MultiPartQuestion from './MultiPartQuestion';

const Question = props => {

    if (props.question.nxt_qn || props.question.prev_qn ){
        return <span>
        <MultiPartQuestion  seed_question={props.question} />
        </span>
    } else {
        return <span>
        <SingleQuestion question={props.question} />
        </span>
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Question);