import React, { useState, useEffect } from 'react';

import { InlineTex } from 'react-tex';
import { Tex } from 'react-tex';

import Self from './Self.js';

const SolutionField = props => {

    const renderAnswerContext = () => {
        let seperator = "\${1,2}";
        if (props.selected_part.prefix || props.selected_part.suffix) {
            let prefix = ""
            let suffix = ""

            if (props.selected_part.prefix) prefix = props.selected_part.prefix;
            if (props.selected_part.suffix) suffix = props.selected_part.suffix;

            return <div style={props.dark_mode ? {color:'#E1E1E1'} : {}}>
                <InlineTex texSeperator={seperator} texContent={prefix + " _____ " + suffix} />
                <br />
            </div>
        } else {
            return <></>
        }
    }

    const renderSubmitButton = (action, button_text, want_similar_question) => {
        let colorClass;
        switch (action) {
            case "view_current_solution":
                colorClass = "btn-blue"
                break;
            case "fetch_next_question":
                colorClass = "btn-orange"
                break;
        }
        return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
            <button 
                onClick={() => props.onSubmit(action, want_similar_question)} 
                className={`btn btn-block ${colorClass} btn-not-rounded`}>{button_text}
            </button>
        </nav>
    }

    const renderWrongStudentAttempt = () => {
        if (props.answer_feedback) {
            if (props.answer_feedback.is_correct) {
                return <></>
            } else {
                return <div style={props.dark_mode ? {backgroundColor:'#ef9a9a', borderColor:'#ef9a9a'} : {}} className="alert alert-danger">
                    {renderAnswerLatex(props.answer_feedback.student_answer)}
                </div>
            }
        } else {
            if (props.selected_part.correct_ans === props.selected_part.student_ans) {
                return <></>
            } else {
                return <div style={props.dark_mode ? {backgroundColor:'#ef9a9a', borderColor:'#ef9a9a'} : {}} className="alert alert-danger">
                    {renderAnswerLatex(props.selected_part.student_ans)}
                </div>
            }
        }
    }

    const renderAnswerLatex = (answer) => {
        let seperator = "\${1,2}";
        let content1 = props.selected_part.units == '\$' ? "$" : ""
        let content2 = answer
        let content3 = props.selected_part.units && props.selected_part.units !== '\$' ? props.selected_part.units : ""
        return <span style={props.dark_mode ? {color:'#121212'} : {}}><InlineTex texSeperator={seperator} texContent={content1 + content2 + ' ' + content3} /></span>
    }

    const renderSolutionLatex = (text) => {
        let seperator = "\${1,2}";
        if (text && text.startsWith("\\begin")) {
            return <div className="" style={props.dark_mode ? {color:'#E1E1E1', overflowX: "auto"} : {overflowX: "auto"}}>
                <Tex texContent={text} />
            </div>
        } else {
            return <div style={props.dark_mode ? {color:'#E1E1E1'} : {}}><InlineTex texSeperator={seperator} texContent={text} /></div>
        }
    }

    const renderAnswers = () => {
        if (props.marked_question) {
            return <>
                <br />
                <div style={props.dark_mode ? {backgroundColor:'#a5d6a7', borderColor:'#a5d6a7'} : {}} className="alert alert-success">
                    {renderAnswerLatex(props.marked_question.ans)}
                </div>
                {renderWrongStudentAttempt()}

            </>
        } else {
            return <>
                <br />
                <div style={props.dark_mode ? {backgroundColor:'#a5d6a7', borderColor:'#a5d6a7'} : {}} className="alert alert-success">
                    {renderAnswerLatex(props.selected_part.correct_ans)}
                </div>
                {renderWrongStudentAttempt()}

            </>
        }
    }

    const renderSolution = () => {
        // console.log(props.marked_question)
        if (props.marked_question) {
            return <div>
                <small style={props.dark_mode ? {color:'#A0A0A0'} : {}}>Suggested Solution</small>
                {props.solution_view === "Solution" ? <>{props.marked_question.sol_img ? <div>
                    <img src={props.marked_question.sol_img} className="img-fluid" />
                </div>:<></>} {renderSolutionLatex(props.marked_question.sol_txt)} </> : <></>}
            </div>
        } else {
            return <div>
                <small style={props.dark_mode ? {color:'#A0A0A0'} : {}}>Suggested Solution</small>
                {props.solution_view === "Solution" ? <>{props.selected_part.sol_img ? <div>
                    <img src={props.selected_part.sol_img} className="img-fluid" />
                </div>:<></>} {renderSolutionLatex(props.selected_part.sol_txt)} </> : <></>}
            </div>
        }

    }


    const renderSelfAssessmentModule = () => {
        return <Self dark_mode={props.dark_mode} self_assessment_score={props.self_assessment_score} updateScore={props.updateScore} has_updated_self_assessment={props.has_updated_self_assessment} />
    }

    const renderSelfAssessmentButton = () => {
        if (props.has_updated_self_assessment) {
            return renderSubmitButton("fetch_next_question", "Next Question", props.want_similar_question)
        } else {
            return renderSubmitButton("update_self_assessment", "Update Self Assessment", props.self_assessment_score)
        }
    }

    if (props.solution_view === "Solution") {
        if (props.showing_modal) {
            return <div>
                {renderAnswerContext()}
                {renderAnswers()}
                {renderSolution()}
            </div>
        } else if (props.marked_question) {
            if (props.ask_for_self_assessment_again) {
                return <>
                    {renderAnswerContext()}
                    {renderSolution()}
                    {renderSelfAssessmentModule()}
                    {/* {renderSelfAssessmentButton()} */}
                </>
            } else {
                return <div>
                    {renderAnswerContext()}
                    {renderAnswers()}
                    {renderSolution()}
                </div>
            }
        } else if (props.selected_part.student_ans) {
            return <div>
                {renderAnswerContext()}
                {renderAnswers()}
                {renderSolution()}
            </div>
        } else {
            return <div align="center">
                <br /><br />
                <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540597/Joni%20Assets/klo1.png" style={{ display: "inline", maxHeight: "120px" }} /> <br />
                <small style={props.dark_mode ? {color: "#A0A0A0"} : {}}><strong>Attempt the previous part to proceed!</strong></small>
            </div>
        }
    } else {
        if (props.marked_question) {
            return <div>
            </div>
        } else {
            return <></>
        }
    }


}

export default SolutionField;


