import React from 'react';
import ReactDOM from 'react-dom';

class GoogleSlide extends React.Component {

    state = { loading: true }

    myRef = React.createRef();

    componentDidMount = () => {
        this.myRef.current.addEventListener('load', this.hiderSpinner);
        // this.refs.iframe.getDOMNode().addEventListener('load', this.hiderSpinner);
    }

    hiderSpinner = () => {
        this.setState({ loading: false });
    }

    render() {
        return <div align="center">
            <span style={this.props.dark_mode ? {color:'#A0A0A0'} : {}}>{this.state.loading ? "Loading" : ""}</span>
            <iframe title='Google Slide' ref={this.myRef} className=""
            src={`${this.props.src}/embed?start=false&loop=false&delayms=3000`}
            frameBorder="0" allowFullScreen="true"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"></iframe></div>
    }
}

export default GoogleSlide;

