import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import kopi from '../../apis/kopi';

class StudyPlanComplete extends React.Component {

    state = {
        animation: false,
        animation_complete: false
    };

    timer;

    componentDidMount = async () => {
        this.timer = setTimeout(() => {
            this.timer = setTimeout(() => {
                this.setState({ animation_complete: true })
            },
                3000);
            this.setState({ animation: true })
        },
            1000);
    }

    componentWillUnmount = () => {
        clearTimeout(this.timer);
    }

    renderContent = () => {
        if (!this.state.animation) {
            return <></>
        }

        let img_url;
        switch (this.props.goal_type) {
            case "Stretch Goal":
                img_url = "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_gold.png"
                break;

            case "Bare Goal":
                img_url = "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_bronze.png"
                break;

            case "Recommended Goal":
                img_url = "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1566396152/Joni%20Assets/trophy_silver-removebg-preview.png"
                break;
        }

        return <><div className="slide-up">
            <div className="slide-up content">
                <img className="img-fluid" style={{ height: "20vh" }} src={img_url} />
            </div>
        </div>
            <div className="text-fade">
                <h3> {this.props.goal_type} </h3> <h5>completed! </h5>
            </div>
            <br />
            <div className="text-fade">
                {this.state.animation_complete ? <button onClick={this.props.continueStudyPlan} class="btn btn-sm btn-orange btn-rounded">Keep up the good work!</button> : ""}
            </div>
        </>
    }

    render = () => {

        const style = {
            color: 'white',
            position: "fixed", /* Stay in place */
            paddingTop: "20vh",
            zIndex: 9999999999999999999, /* Sit on top */
            left: 0,
            top: 0,
            width: '100%', /* Full width */
            height: '100%', /* Full height */
            overflow: "auto" /* Enable scroll if needed */
        }
        return <div style={style}><div align="center" className="container">
            {this.renderContent()}
        </div></div>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(StudyPlanComplete);