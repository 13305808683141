
import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../../apis/kopi';

import history from '../../../../history';

import { signOut } from '../../../../actions';

import SkyLight from 'react-skylight';


class GoalDetails extends React.Component {

    state = { confirm_delete: false }

    deletePlan = () => {
        this.deleteMenu.show()
    }

    renderDeleteModal = () => {
        const deleteAPIcall = async () => {
            await kopi.delete('/api/study_plans/delete_plan', {
                data: { study_plan_id: this.props.goal.id }
            })
            this.props.deletePlan();
        }

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return <SkyLight dialogStyles = {style} hideOnOverlayClicked ref={ref => this.deleteMenu = ref} >
            <h3>Are you sure?</h3>
            <p>All goal activity will be lost.</p>
            <button on className="btn btn-block btn-danger" onClick={deleteAPIcall}>Confirm Delete</button>
        </SkyLight>

    }

    renderGold = () => {
        let gold_trophy_count = 0;
        this.props.goal.study_weeks.forEach(study_week => {
            if (study_week.parts_done >= this.props.goal.stretch_goal) gold_trophy_count++;
        })
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_gold.png" /><br />
            <strong>x{gold_trophy_count}</strong>
        </div>;
    }

    renderSilver = () => {
        let silver_trophy_count = 0;
        this.props.goal.study_weeks.forEach(study_week => {
            if (study_week.parts_done >= this.props.goal.recommended_goal) silver_trophy_count++;
        })


        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1566396152/Joni%20Assets/trophy_silver-removebg-preview.png" /> <br />
            <strong>x{silver_trophy_count}</strong>
        </div>;
    }

    renderBronze = () => {
        let bronze_trophy_count = 0;
        this.props.goal.study_weeks.forEach(study_week => {
            if (study_week.parts_done >= this.props.goal.bare_goal) bronze_trophy_count++;
        })
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_bronze.png" /> <br />
            <strong>x{bronze_trophy_count}</strong>
        </div>;
    }

    render() {
        return <div className="container padding-bottom">
            <div className="row">
                <div className="col">
                    {this.renderBronze()}
                </div>
                <div className="col">
                    {this.renderSilver()}
                </div>
                <div className="col">
                    {this.renderGold()}
                </div>
            </div>
            <br/>
            <div>
                {this.props.goal.topics_practicing.map(topic => {
                    return <div className="alert alert-info"> {topic} </div>
                })}
            </div>
            <div>
                {this.props.goal.topics_left.map(topic => {
                    return <div className="alert alert-dark"> {topic} </div>
                })}
            </div>
            <div align="center">
                <button className="btn btn-sm btn-danger" onClick={this.deletePlan}>Delete Study Plan</button>
            </div>
            {this.renderDeleteModal()}
        </div>

    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
}
export default connect(mapStateToProps, { signOut })(GoalDetails);