import React from 'react';
import { connect } from 'react-redux';

import { InlineTex } from 'react-tex';
import { Tex } from 'react-tex';

const SingleQuestion = props => {
    let seperator = "\${1,2}";
    let header_img, header_txt, qn_txt, qn_img, qn_ans, sol_img, sol_txt;

    if (props.question.header_txt) {
        if (props.question.header_txt.startsWith("\\begin")) {
            header_txt = <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Tex texContent={props.question.header_txt} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        } else {
            header_txt = <div><InlineTex texSeperator={seperator} texContent={props.question.header_txt} /></div>
        }
    }

    if (props.question.qn_txt) {
        if (props.question.qn_txt.startsWith("\\begin")) {
            qn_txt = <div className="table-responsive">
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Tex texContent={props.question.qn_txt} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        } else {
            qn_txt = <div><InlineTex texSeperator={seperator} texContent={props.question.qn_txt} /></div>
        }
    }

    if (!props.question.header_img) {
        header_img = <span></span>
    } else {
        header_img = <div><img className="img-fluid" src={`${props.question.header_img}`} alt="lol" /></div>
    }


    if (!props.question.qn_img) {
        qn_img = <span></span>
    } else {
        qn_img = <div><img className="img-fluid" src={`${props.question.qn_img}`} alt="lol" /></div>
    }

    if (!props.question.sol_img) {
        sol_img = <span></span>
    } else {
        sol_img = <div><img className="img-fluid" src={`${props.question.sol_img}`} alt="lol" /></div>
    }

    const renderAnswerLatex = () => {
        let seperator = "\${1,2}";
        let content1 = props.question.units == '\$' ? "$" : ""
        let content2 = props.question.ans
        let content3 = props.question.units && props.question.units !== '\$' ? props.question.units : ""
        return <InlineTex texSeperator={seperator} texContent={content1 + content2 + content3} />
    }

    const renderAnswerContext = () => {
        let seperator = "\${1,2}";
        if (props.question.prefix || props.question.suffix) {
            let prefix = props.question.prefix ? props.question.prefix : ""
            let suffix = props.question.suffix ? props.question.suffix : ""
            return <div>
                <InlineTex texSeperator={seperator} texContent={prefix + " _____ " + suffix} />
                <br />
            </div>
        } else {
            return <></>
        }
    }

    const renderText = (value) => {
        const renderEmoji = (value) => {
            if (value == 0) {
                return '😢'
            } else if (value == 33) {
                return '😪'
            } else if (value == 66) {
                return '🙂'
            } else {
                return '😀'
            }
        }

        const renderStatus = (value) => {
            if (value == 0) {
                return "I don't know where to begin"
            } else if (value == 33) {
                return 'I am able to make some progress'
            } else if (value == 66) {
                return 'I almost got it'
            } else {
                return "It's perfect!"
            }
        }

        return <div align="center">
            <h1>{renderEmoji(value)}</h1>
            <p>{renderStatus(value)}</p>
        </div>

    }


    if (props.question.units === "\$") {
        qn_ans = <div className="alert alert-success">
            {renderAnswerLatex()}
        </div>
    } else {
        qn_ans = <div className="alert alert-success">
            {renderAnswerLatex()}
        </div>
    }

    if (props.question.sol_txt && props.question.sol_txt.startsWith("\\begin")) {
        sol_txt = <div className="table-responsive">
            <table className="table">
                <tbody>
                    <tr>
                        <td>
                            <Tex texContent={props.question.sol_txt} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    } else {
        sol_txt = <div><InlineTex texSeperator={seperator} texContent={props.question.sol_txt} /></div>
    }

    // let student_ans;

    // if (props.attempt) {
    //     if (props.attempt.correct) {
    //         student_ans = <div className="alert alert-success">
    //             <InlineTex texSeperator={seperator} texContent={props.attempt.student_ans} />
    //         </div>
    //     } else {
    //         student_ans = <div className="alert alert-danger">
    //             <InlineTex texSeperator={seperator} texContent={props.attempt.student_ans} />
    //         </div>
    //     }
    // }

    // if (props.question.qn_type == "self") {
    //     if (props.question.qn_type == "self") {
    //         if (props.attempt) {
    //             student_ans = <div style={{
    //                 background: "#e7f6ff", marginTop: "2vh", paddingTop: "1vh", paddingBottom: '5vh', boxShadow: "0 3px 6px rgba(255,212,114,0.05), 0 1.5px 3px rgba(255,212,114,0.05)"
    //             }} class='card'>
    //                 <div class="card-content">
    //                     <div align="center" style={{ paddingBottom: '3vh' }}></div>
    //                     {renderText(props.attempt.student_ans)}
    //                 </div>
    //             </div>

    //             qn_ans = ""
    //         }


    //     }
    // }



    return (
        <span>
            {header_img}
            {header_txt}
            <hr />
            {qn_img}
            {qn_txt}
            <br />
            {renderAnswerContext()}
            <br />
            {qn_ans}
            {/* {student_ans} */}
            <br />
            {sol_img}
            {sol_txt}
        </span>
    )
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(SingleQuestion);
