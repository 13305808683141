import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../apis/kopi';
import history from '../../history';

import { signOut } from '../../actions';

import Topics from './Topics';
import Goals from './Goals';
import Config from './Config';
import Homework from './Homework';

import Footer from '../Footer';

class Settings extends React.Component {

    componentDidMount = () => {

        if (!this.props.isSignedIn || !this.props.currentUser) {
            return history.push('/')
        }

        if (this.props.location && this.props.location.state && this.props.location.state.mode) {
            this.setState({ mode: this.props.location.state.mode })
        }
    }

    state = {
        mode: "topics", // "topics" , "goals"
        key: true
    }

    setModeToMentor = () => {
        this.setState({mode: 'config'})
    }

    renderModeSelector = () => {
        return <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
                <span onClick={() => this.setState({ mode: "topics", key: !this.state.key })}
                    style={{paddingTop:'1px', paddingBottom:'1px'}} 
                    className={`nav-link cursor-pointer ${this.state.mode === "topics" ? "target" : ""}`}>
                        <i class="fas fa-book"></i>
                        <div className='header-font' style={{fontSize:'9px'}}>Topics</div>
                </span>
            </li>
            <li className="nav-item">
                <span 
                    onClick={() => this.setState({ mode: "goals", key: !this.state.key })} 
                    style={{paddingTop:'1px', paddingBottom:'1px'}} 
                    className={`nav-link cursor-pointer ${this.state.mode === "goals" ? "target" : ""}`}>
                        <i class="fas fa-bullseye"></i>
                        <div className='header-font' style={{fontSize:'9px'}}>Study Plans</div>
                    </span>
            </li>
            <li className="nav-item">
                <span 
                    onClick={() => this.setState({ mode: "homework", key: !this.state.key })} 
                    style={{paddingTop:'1px', paddingBottom:'1px'}} 
                    className={`nav-link cursor-pointer ${this.state.mode === "homework" ? "target" : ""}`}>
                        <i class="fas fa-chalkboard-teacher"></i>
                        <div className='header-font' style={{fontSize:'9px'}}>Homework</div>
                </span>
            </li>
            <li className="nav-item">
                <span 
                    onClick={() => this.setState({ mode: "config", key: !this.state.key })}
                    style={{paddingTop:'1px', paddingBottom:'1px'}}  
                    className={`nav-link cursor-pointer ${this.state.mode === "config" ? "target" : ""}`}>
                        <i class="fas fa-cogs"></i>
                        <div className='header-font' style={{fontSize:'9px'}}>Settings</div>
                </span>
            </li>
        </ul>
    }

    renderMode = () => {
        switch (this.state.mode) {
            case 'topics':
                return <Topics key={this.state.key} />
            case 'goals':
                return <Goals key={this.state.key} />
            case 'config':
                return <Config key={this.state.key} />
            case 'homework':
                return <Homework setModeToMentor = {this.setModeToMentor} key={this.state.key} />

            default:
                return <></>
        }
    }

    render() {
        return <div style={{ paddingTop: "6vh" }}>
            <div style={{ background: 'white' }} align="center" class="fixed-top">
                {this.renderModeSelector()}
            </div>
            {this.renderMode()}
            <Footer />
        </div>
    }
}



const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { signOut })(Settings);