

import React from 'react'; 

import SkyLight from 'react-skylight';

import kopi from '../../../../apis/kopi';

import {connect} from 'react-redux';

class TellQuote extends React.Component {
    
    state={loading: false, prompt_text: "", feedbacks: [], interactive_prompt_id: null, interaction_type: null}
    
    componentDidMount = async () => {
        let response = await kopi.get('/api/interactions/get_interactive_prompt', {
            params: {prompt_type: "Quote"}
        });
        if (!response) return;

        // console.log(response.data)
        this.setState({prompt_text: response.data.prompt_text, feedbacks: response.data.feedbacks, interactive_prompt_id: response.data.id,
                        interaction_type: response.data.interaction_type});
    }

    renderContext = () => {
        let context;
        let image;

        switch (this.state.interaction_type) {
            case "Motivation":
                image = "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540449/Joni%20Assets/start-your-free-trial.png"
                context = "Nuggets of Wisdom"
                break;
            case "Joke":
                image = "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540450/Joni%20Assets/to-the-future.png"
                context = "Knock Knock"
                break;
            case "Fact":
                image = "https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540449/Joni%20Assets/core-values-icon.png"
                context = "Facts of Life"
                break;

        }
        // console.log(context)
        return <div>
        <img src={image} className='img-fluid' style={{maxWidth: "15vw"}} /> <br/>
        <small> {context}</small>
        </div>
    }

  

    renderTextPrompt = () => <h5> {this.state.prompt_text}</h5>

    renderFeedbackInputPanel = () => {
            return <div className="">
            { this.state.feedbacks.map(feedback=>{
                return <div key={feedback} className="cursor-pointer" onClick={()=>this.selectFeedbackResponse(feedback)}>
                <button className="btn btn-sm">{feedback}</button>
                <br/><br/>
                </div>
            }) }
            </div>
        }

    selectFeedbackResponse = (selected_feedback) => {

            kopi.post('/api/interactions/submit_interaction', {
                userId: this.props.currentUser,
                interaction_type: this.state.interaction_type,
                interactive_prompt_id: this.state.interactive_prompt_id,
                selected_feedback
            });
    
            this.endInteraction();
    } 


    endInteraction = () => {
        this.props.endInteraction();
    }

    render(){
        return <div align="center" className="dark-blue-text">
            {this.renderContext()}
            {this.renderTextPrompt()}
            <hr/>
            {this.renderFeedbackInputPanel()}
            </div>
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(TellQuote);