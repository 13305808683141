import React from 'react';

const PartSelector = props => {

        const nextChar = (c, index) => {
            return String.fromCharCode(c.charCodeAt(0) + index);
        }

        let navigation = props.all_parts.map((qn, index) => {
            if (qn.id === props.selected_part.id) {
                return <li className="nav-link nav-item" key={index + 1} onClick={() => props.selectPart(qn)}>
                    <button className="btn btn-part btn-sm btn-xs"><strong>({nextChar('a',index)})</strong></button>
                </li>
            } else {
                return <li className="nav-link nav-item" key={index + 1} onClick={() => props.selectPart(qn)}>
                    <button className="btn btn-light btn-sm btn-xs">
                        <strong style={{color: '#c7c8c9'}}>({nextChar('a',index)})</strong>
                    </button>
                </li>
            }
        })

        return <ul className="nav justify-content-center">
                    {navigation}
                </ul>
         
    }

    export default PartSelector;
