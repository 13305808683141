import React from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Flipper, Flipped } from "react-flip-toolkit";


class OnboardDashboard2 extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      run: true,
      steps: [
        {
          title: "It's almost time for farewell...",
          content: "You're almost done with the tutorial! We just need one last thing from you.",
          placement: 'center',
          target: 'body',
          disableOverlayClose: true,
          hideCloseButton: true,

        },
        {
          content: "We will need you to update the topics for your questions. Click the Profile settings button to choose your topics.",
          target: '.fa-user-circle',
          placement: 'top',
          styles:{
            options:{
              spotlightShadow: '0 0 0px rgba(0, 0, 0, 0)',
            }
          },
          disableOverlayClose: true,
          hideCloseButton: true,
          spotlightClicks: true,
          hideFooter: true,
        },
      ],
    };
  }


  handleJoyrideCallback = data => {
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({ run: false });
    }
    console.groupCollapsed(type);
    // console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };


  renderBackground = () =>{
      return <div>
      <ul className="list"><li>
      <Flipped
      stagger="card"
    >
      <div
        className="listItem"
        style={{ backgroundColor: "#FFD472" }}
      >
        <Flipped>
          <div className="listItemContent">
            <Flipped
              stagger="card-content"
            >
                <div className="description" text-align="center" >
                <Flipped
                >
                  <small style={{fontSize: "1em"}}><strong>You do not have a study plan yet!</strong></small>
                </Flipped>
            </div>
            </Flipped>
   
          </div>
        </Flipped>
      </div>
    </Flipped>
      </li></ul>
      <div className="container padding-top" align="center">
            <br/>
            <img className="img-fluid big-joni" src={"https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540509/Joni%20Assets/joni-cute.png"} />
            <br/>
            <p className='lead'>{"Awesome! Let's get started, shall we?"}</p>
            <br/>
            <span 
              onClick={()=>this.questionClick()} 
              style={{marginBottom:"10px"}}
              className="btn btn-lg btn-blue btn-rounded">Start Now 
            </span>
            <div className="placeholder-position"></div>
            <nav className="navbar fixed-bottom navbar-light navbar-padding-bottom bg-light">
              <i className="nav-link  nav-active fas fa-home"></i>
              <i className="nav-link fas fa-book-open"></i>
              <i className="nav-link fas fa-brain"></i>
              <i className="nav-link fas fa-user-circle" onClick={()=>this.props.changeParent(3)}></i>
            </nav>
        </div>
      </div>
  }
  
  render () {
    const { run, steps } = this.state;
    // const { breakpoint } = this.props;
    return (
      <div className="app">
        <Joyride
          callback={this.handleJoyrideCallback}
          steps={steps}
          continuous
          scrollToFirstStep
          showProgress = {false}
          showSkipButton={false}
          run={run}
          styles={{
            options: {
              primaryColor: '#354B80',
              zIndex: 99999999999999,
            }
          }}
        />
      
      {this.renderBackground()}
      </div>
    );
  }
}

export default OnboardDashboard2;