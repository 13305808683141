import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../history';

import kopi from '../../apis/kopi';

import Loading from '../Loading'

import {Link} from 'react-router-dom';

import Session from './Session';

import socketIOClient from "socket.io-client";

let socket;

class Slip extends React.Component {
    // window.location.reload(true)
    state = {
        name: '',
        invite_code: '', 
        secret: null,
        session_id: null,
        show_secret_field: false,
        secret_name: "",
        secret_number: ""
    }

    componentDidMount = () => {
        let invite_code = window.location.pathname.split("/slip/").pop()
        let secret = Math.floor(Math.random() * 1000);     // returns a random integer from 0 to 9
        this.setState({secret, invite_code})
    }

    joinSession = async () => {
        if (this.state.name=="") return;
        let response = await kopi.post('/api/exit_slip/join', {
            invite_key: this.state.invite_code,
            name: this.state.name,
            secret: this.state.secret
        })
        if (response) {
            if (response.data.msg == "Name Exists") {
                window.alert("Name has already been taken! Pick another!");
                return;
            } else if (response.data.session_id) {
                this.setState({session_id: response.data.session_id});
            }
        }
    }

    resumeSession = async () => {
        let response = await kopi.get('/api/exit_slip/resume', {
            params: {
            invite_key: this.state.invite_code,
            name: this.state.secret_name,
            secret: this.state.secret_number}
        })
        if (response.data.session_id) {
            this.setState({secret: this.state.secret_number, session_id: response.data.session_id, name: this.state.secret_name});
        }
    }

    renderSecretField = () => {
        if (!this.state.show_secret_field) return <></>
        return <div class="container">
            <input value={this.state.secret_name} onChange={e=>this.setState({secret_name: e.target.value})} placeholder="Enter the same name you used." class="form-control" type="text" style={{ textAlign: "center", width: '70vw', fontSize: "1em" }} />
            <input value={this.state.secret_number} onChange={e=>this.setState({secret_number: e.target.value})} placeholder="Enter your secret number." class="form-control" type="text" style={{ textAlign: "center", width: '70vw', fontSize: "1em" }} />
            <button style={{width: '70vw'}} onClick={this.resumeSession} class="btn btn-dark btn-block">Resume Session</button>
            <br/> <br/>
        </div>
    }

    renderField = () => {
        if(this.state.show_secret_field) return <></>
        return  <div><input value={this.state.name} onChange={e=>this.setState({name: e.target.value})} placeholder="What's Your Name?" class="form-control" type="text" style={{ textAlign: "center", width: '70vw', fontSize: "1em" }} />
        <btn onClick={this.joinSession} style={{ width: "70vw" }} class="btn btn-main"> Let's Go! </btn><br/></div>

    }

    renderSessionLogin = () => {
        return <div style={{paddingTop: '10vh'}}> <img style={{ maxWidth: "35vw" }} src='https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png' />
        <br /><br />
        {this.state.session_id}
        {this.renderField()}
              <br/> 
        <button class="btn btn-sm btn-info" onClick={()=>this.setState({show_secret_field: !this.state.show_secret_field})}>{!this.state.show_secret_field ? "Disconnected? Enter secret number." : "Back"}</button> <br/> <br/>
        {this.renderSecretField()}
        <Link to="/" >Back to Home</Link><br/> <br/>
        </div>
    }

    renderSession = () => {
        return <Session name={this.state.name} secret={this.state.secret} session_id={this.state.session_id} invite_code={this.state.invite_code} />
    }

    render() {
        return <div className="" align="center">
                {this.state.session_id ? this.renderSession() : this.renderSessionLogin()}
        </div>
    }
}




export default Slip;


