import React from 'react';
import kopi from '../../apis/kopi'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import history from '../../history.js';
import queryString from "query-string";
import { signOut } from '../../actions';


class Reactivate extends React.Component {

    state = { loading: false, code: "", error: false}

    componentDidMount = async () => {
        if (this.props.tier == 1 && this.props.subscription_tier !== 'No Active Subscription'){
            history.push('/')
        }
    }

    onSubmit = async () => {
        this.setState({ loading: true });
        const parent_id = this.state.code.split('&')[0]
        const prototype_id = this.state.code.split('&')[1]
        let response;
        if (parent_id && prototype_id) {
            response = await kopi.post('/reactivate', {
                user_id: this.props.currentUser,
                parent_id: parent_id,
                prototype_id: prototype_id,
            })
            this.setState({ loading: false, error: response.data ? response.data.error : false});
        } else {
            this.setState({ loading: false, error: true});
        }

        if(response.data && !response.data.error){
            this.props.signOut('Activation successful. Please login again.');
        }
    }

    renderSubmit = () => {
        if (this.state.loading){ return <>
            <button className="btn btn-dark btn-block button-text-small"> Activating Account </button>
        </>} else {
        return <button onClick={this.onSubmit} className="btn btn-blue btn-block button-text-small"> Activate Account </button>
        }
    }

    render() {

        if(this.props.tier == 0){
            return <div className="login-centralise">
            <div className="container padding-bottom" align="center">
                <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" className="img img-fluid" style={{ maxHeight: "20vh" }} />
                <br /><br />
                <h1>Your Free Trial has ended.</h1>
                <h4>To continue using Joni.AI, please proceed to <a href='https://parent.joni.ai'>https://parent.joni.ai</a> to purchase a subscription.</h4>
                <br />
                <div class="form-group">
                    <input id="code" onChange={(e) => { this.setState({ code: e.target.value }) }} value={this.state.code} type="text" className="form-control" placeholder="Activation Code" />
                    {this.state.error ? <><small style={{color: 'red'}}>Invalid Code</small></> : ''}
                </div>
                
                {this.renderSubmit()}
                <br/>
                <span onClick={() => this.props.signOut()}>Back to Login</span>
            </div>
        </div>
        } else if (this.props.subscription_tier === 'No Active Subscription'){
            return <div className="login-centralise">
            <div className="container padding-bottom" align="center">
                <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" className="img img-fluid" style={{ maxHeight: "20vh" }} />
                <br /><br />
                <h1>Your Subscription has ended.</h1>
                <h4>To continue using Joni.AI, please proceed to <a href='https://parent.joni.ai'>https://parent.joni.ai</a> to purchase a new subscription.</h4>
                <br/>
                <span onClick={() => this.props.signOut()}>Back to Login</span>
            </div>
        </div>
        }
    }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        tier: state.auth.tier,
        dateJoined: state.auth.dateJoined,
        subscription_tier: state.auth.subscription_tier,
    };
};

export default connect(mapStateToProps, {signOut})(Reactivate);

