import React from 'react';

class test extends React.Component {

    /*currentState:
    index 0 = current mastery
    index 1 = prev attempt correct = 0 wrong = 1
    index 2 = prev option shown
    index 3 = array of probability for action*/
    state = {page:0, currentState:[35, 0, 0, [0.5, 0.5]]}

    updateProb=(currAttempt, prevOp, prob)=>{
        var newProb = prob.map(x => x * 100)
        if (currAttempt == 1 && newProb[(prevOp+1)%2] < 100){
            newProb[prevOp] -= 10
            newProb[(prevOp+1)%2] += 10
        }
        else if (currAttempt == 0 && newProb[prevOp] < 100){
            newProb[prevOp] += 10
            newProb[(prevOp+1)%2] -= 10
        }
        newProb = newProb.map(x => x / 100)
        return newProb
    }

    generateOption=(prob)=>{
        var option = Math.random()
        var sum = 0
        for (var i = 0; i < prob.length; i++){
            sum += prob[i];
            sum = +sum.toFixed(2);
            if (option <= sum) {
                return i;
            }
        }
    }

    togglePageForward=async()=>{
        this.setState(prevState => ({page: prevState.page+1}));
        let x = [...this.state.currentState]
        //if previous qn wrong update probability
        if (x[1] == 1){
            x[3] = this.updateProb(0, x[2], x[3])
            x[1] = 0
        }
        x[0]+= 1
        await this.setState({currentState: x})
        alert('you got it right ' + this.state.currentState)
    }

    togglePageBackward=async()=>{
        this.setState(prevState => ({page: prevState.page-1}));
        let x = [...this.state.currentState]
        //update probability if previous qn. wrong
        if (x[1] == 1){
            x[3] = this.updateProb(1, x[2], x[3])
        } else{
            x[1] = 1
        }
        //biased random to generate option
        //update option shown
        x[2] = this.generateOption(x[3])
        x[0]-= 1
        await this.setState({currentState: x})
        alert('recommended option ' + this.state.currentState[2])
        alert('you got it wrong ' + this.state.currentState)
    }

    leftButton=()=>{
        if (this.state.page==0){
            return <span className="lead text-dark" style={{fontSize:"0.9rem"}} >{this.state.currentState}</span>
        }
        else if(this.state.page>=1){
            return <i onClick={()=> this.togglePageBackward()} className="fas fa-angle-left">wrong</i>
        }else{
            return <i></i>
        }
    }

    rightButton=()=>{
        if (this.state.page==8){
            return <span className="lead text-dark" style={{fontSize:"0.9rem"}} >{this.state.currentState}</span>
        }
        else if(this.state.page<=20){
            return <i onClick={()=> this.togglePageForward()} className="fas fa-angle-right">correct</i>
        }
    }

    render() {
        return <div>
            <h4 align="center">test</h4>
            <nav className="navbar fixed-bottom navbar-light navbar-padding-bottom bg-none">
                {this.leftButton()}
                {this.rightButton()}
            </nav>
        </div>}

}

export default test;