import React from 'react';
import { connect } from 'react-redux';
// import {Link} from 'react-router-dom';
import kopi from '../../apis/kopi';
import history from '../../history'; 
import Footer from '../Footer';

import Switch from "react-switch";
import Ticker from '../StudyPlan/Ticker';

import SkyLight from 'react-skylight';

class DailyMood extends React.Component{
    
    state = {page: 0, image: null, message: null, mood: 0, mode: "Normal", mode_checked: false, practice_status: "Not Started"};
    // mood: -1, 0, 1

    componentDidMount = async () => {
        if (!this.props.isSignedIn || !this.props.currentUser){
            return history.push('/')
        } else if (this.props.isSignedIn && this.props.currentUser && this.props.tier == 0 && Math.floor((new Date(Date.now()).getTime() - new Date(this.props.dateJoined).getTime()) / (3600000 * 24)) >= 7 ){
            return history.push('/reactivate')
        } else if (this.props.isSignedIn && this.props.currentUser && this.props.subscription_tier === 'No Active Subscription'){
            return history.push('/reactivate')
        } else {
            let dailyMood = await kopi.get('/api/joni/has_daily_mood', {
                params: { userId: this.props.currentUser }
            })
        
            if (dailyMood.data[0]) {
                return history.push("/");
            }

        }

    }

    toggleQuestion = (num) => {
        this.setState({page: num})
    }

    sendRequest = async (score) => {
       const response = await kopi.post('/api/joni/daily_mood', {
            student_id: this.props.currentUser,
            score: score
        })
        this.interact.hide();
        this.setState({page: 3, message: response.data.message, image: response.data.image, mood: response.data.mood, practice_status: response.data.practice_status})
    }

    toggleEasyMode = () => {
        if (this.state.mode === "Normal"){
            this.setState({mode: "Easy", mode_checked: true});
        } else {
            this.setState({mode: "Normal", mode_checked: false});
        }
    }

    toggleHardMode = () => {
        if (this.state.mode === "Normal"){
            this.setState({mode: "Hard", mode_checked: true});
        } else {
            this.setState({mode: "Normal", mode_checked: false});
        }
    }

    renderMode = () => {
        if (this.state.mode === "Easy") {
            return <span>Easy Mode</span>
        } else if (this.state.mode === "Hard") {
            return <span>Hard Mode</span>
        } else {
            return <span>Normal Mode</span>
        }
    }

    renderModal = () => {

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return <SkyLight
        hideOnOverlayClicked 
        dialogStyles = {style}
        ref={ref => this.interact = ref}
        afterClose={()=>this.setState({tell_joni: false})}
        >
        <div className="container" align="center">
            <div align="center">
                <small className="grey-text"><b>DAILY QUESTION</b></small>
                <br/>
                <h2>How are you feeling today?</h2>
                <hr />
                <button onClick={()=> this.sendRequest(50)} className="btn btn-block btn-info">Feeling great!</button>
                <button onClick={()=> this.sendRequest(0)} className="btn btn-block btn-info">Meh.</button>
                <button onClick={()=> this.sendRequest(-50)} className="btn btn-block btn-info">Not so good...</button>
            </div>
        </div>
        </SkyLight>
    }


  renderContent(){

    if (this.state.page === 0){
        return <div className="container padding-top">
            <div align="center">
                <br/>
                <img alt="hi" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540509/Joni%20Assets/joni-cute.png" className="big-joni img-fluid"/>
                <br/>
                <h1 className="lead">Welcome back, my favourite disciple!</h1>
                <p><small className="">I have but one question for you. <br/>Would you like to answer it?</small></p>
                 <br/> 
                <span onClick={()=>this.interact.show()} className="btn btn-lg btn-blue btn-rounded"> Go ahead, I'm ready! </span> <br/>
                <br/><br/><br/><br/><br/><br/>
            </div>
            <Footer />
        </div>
    } else if (this.state.page === 1) {
        return <></>
    } else if (this.state.page === 3) {
        if (this.state.mood === -1){  // dude's sad
            return <div className="container padding-top" align="center">
                <br/>
                <img className="img-fluid big-joni" src={this.state.image} />
                <br/>
                <p className='lead'>{this.state.message}</p>
                <br/>
                <span onClick={()=> history.push(
                    {pathname: '/training',
                    state: {mode: this.state.mode}
                    })} className="btn btn-lg btn-blue btn-rounded"> {this.state.practice_status === 'Not Started' ? 'Start Now' : 'Resume'}
                </span>
                <br/>
                <label>
                <small>{this.renderMode()}</small><br/>
                    <Switch height={20} onColor={"#FFD472"} uncheckedIcon={false} checkedIcon={false} onChange={this.toggleEasyMode} checked={this.state.mode_checked} />
                   
                </label>
                <br/><br/><br/><br/><br/>
                <Footer />
            </div>
        } else if (this.state.mood === 1) { // dude's happy
            return <div className="container padding-top" align="center">
                <br/>
                <img className="img-fluid big-joni" src={this.state.image} />
                <br/>
                <p className='lead'>{this.state.message}</p>
                <br/>
                <span onClick={()=> history.push(
                {pathname: '/training',
                state: {mode: this.state.mode}
                })} className="btn btn-lg btn-blue btn-rounded"> {this.state.practice_status === 'Not Started' ? 'Start Now' : 'Resume'}
            </span>
            <br/>
            <label>
                <small>{this.renderMode()}</small><br/> 
                <Switch height={20} onColor={"#FFD472"} uncheckedIcon={false} checkedIcon={false} onChange={this.toggleHardMode} checked={this.state.mode_checked} />   
            </label>               
                <br/><br/><br/><br/><br/>
                <Footer />
            </div>
        } else {
            return <div className="container padding-top" align="center">
                <br/>
                <img className="img-fluid big-joni"  src={this.state.image} />
                <br/>
                <p className='lead'>{this.state.message}</p>
                <br/>
                <span onClick={()=> history.push(
                    {pathname: '/training',
                    state: {mode: this.state.mode}
                    })} className="btn btn-lg btn-blue btn-rounded"> {this.state.practice_status === 'Not Started' ? 'Start Now' : 'Resume'}
                </span> <br/>
                <br/><br/><br/><br/><br/>
                <Footer />
            </div>
        }
    }
  }

  render(){
      return <>
        <Ticker key={1} attempted_since_refresh={0} />
        {this.renderContent()}
        {this.renderModal()}
        {this.props.showiOSPrompt()}
      </>
  }
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        name: state.auth.name,
        isSignedIn: state.auth.isSignedIn,
        tier: state.auth.tier,
        dateJoined: state.auth.dateJoined,
        subscription_tier: state.auth.subscription_tier,
    };
  };
  
  export default connect(mapStateToProps, {})(DailyMood);