import React from "react";
import {Link} from 'react-router-dom';
import history from '../../../history';
import {connect} from 'react-redux';

import kopi from '../../../apis/kopi'

class OnboardCards extends React.Component{
  state = {page: 0};

  updateOnboarding = async () => {
    kopi.put('/api/joni/update_onboarding',{
      data: {user_id: this.props.currentUser}
    })
    history.push('../onboardstart');
  }

  togglePageForward=()=>{
    this.setState(prevState => ({page: prevState.page+1}));
  }

  togglePageBackward=()=>{
    this.setState(prevState => ({page: prevState.page-1}));
  }

  leftButton=()=>{
    if(this.state.page>=1){
      return <span onClick={()=> this.togglePageBackward()} className="lead text-white" style={{fontSize:"0.9rem"}}>PREV</span>
    }else{
      return <span className="lead text-white" style={{fontSize:"0.9rem", visibility:"hidden"}}>PREV</span>
    }
  }

  rightButton=()=>{
    if(this.state.page<=1){
      return <span onClick={()=> this.togglePageForward()} className="lead text-white " style={{fontSize:"0.9rem"}}>NEXT</span>
    }else if (this.state.page==2){
      return <span className="lead text-white" style={{fontSize:"0.9rem"}} onClick={this.updateOnboarding}>Get Started!</span>
    }
  }

  centralMenu=()=>{
    if(this.state.page===0){
      return <div style={{justifyContent:"center"}} className="central-menu">
        <i className="fas fa-circle text-white" style={{fontSize:"30%"}}></i>
        &nbsp;
        <i className="far fa-circle text-white" style={{fontSize:"30%"}}></i>
        &nbsp;
        <i className="far fa-circle text-white" style={{fontSize:"30%"}}></i>
      </div>
    }else if(this.state.page===1){
      return <div style={{justifyContent:"center"}} className="central-menu">
        <i className="far fa-circle text-white" style={{fontSize:"30%"}}></i>
        &nbsp;
        <i className="fas fa-circle text-white" style={{fontSize:"30%"}}></i>
        &nbsp;
        <i className="far fa-circle text-white" style={{fontSize:"30%"}}></i>
      </div>
    } else if(this.state.page===2){
      return <div style={{justifyContent:"center"}} className="central-menu">
        <i className="far fa-circle text-white" style={{fontSize:"30%"}}></i>
        &nbsp;
        <i className="far fa-circle text-white" style={{fontSize:"30%"}}></i>
        &nbsp;
        <i className="fas fa-circle text-white" style={{fontSize:"30%"}}></i>
      </div>
    }
  }

  // renderText=()=>{
  //   if (this.state.page == 0){
  //     return <span>Do questions that are just right for yourself!</span>
  //   }else if(this.state.page == 1){
  //     return <span>Set weekly and monthly goals to prepare for your exams!</span>
  //   }else if(this.state.page == 2){
  //     return <span>Track your own progress and watch yourself master Maths!</span>
  //   }
  // }

  // renderImage=()=>{
  //   if (this.state.page == 0){
  //     return <i class="fas fa-book-open"></i>
  //   }else if(this.state.page == 1){
  //     return <i class="fas fa-crosshairs"></i>
  //   }else if(this.state.page == 2){
  //     return <i class="fas fa-brain"></i>
  //   }
  // }

  renderCard=()=>{
    if (this.state.page === 0){
      return <div className="onboard-card card">
        <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1562340302/Joni%20Assets/RPGGameFinal.png" className="card-img-top"/>
        <div className="card-body">
          <br/>
          <br/>
          <br/>
          <h5 className="card-title">Personalise Your Learning</h5>
          <p className="card-text text-muted">Do questions that are just right for yourself, not too hard nor too easy!</p>
        </div>
        </div>
    }else if(this.state.page === 1){
      return <div className="onboard-card card">
        <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1562340302/Joni%20Assets/LevelDevelopmentFinal.png" className="card-img-top"/>
        <div className="card-body">
          <br/>
          <br/>
          <br/>
          <h5 className="card-title">Excel Effortlessly</h5>
          <p className="card-text text-muted">Set weekly and monthly goals to prepare for your exams!</p>
        </div>
        </div>
    }else if(this.state.page === 2){
      return <div className="onboard-card card">
        <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1562340302/Joni%20Assets/LeaderFinal.png" className="card-img-top"/>
        <div className="card-body">
          <br/>
          <br/>
          <br/>
          <h5 className="card-title">See Your Mastery</h5>
          <p className="card-text text-muted">Track your own progress and watch yourself master Maths!</p>
        </div>
        </div>
    }
  }

  renderContent(){
      return <div className="container login-centralise">
        <div className="card-group">
          {this.renderCard()}
          {/* {this.renderText()} */}
        </div>
      </div>
    
  }

  render(){
    return <>
      {this.renderContent()}
      <nav className="navbar fixed-bottom navbar-light bg-secondary pt-3 pb-3">
        {this.leftButton()}
        {this.centralMenu()}
        {this.rightButton()}
      </nav>
    </>
  }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, {})(OnboardCards);


