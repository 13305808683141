import React from 'react';
import history from '../../../history'

class AvatarCustomization extends React.Component{

    componentDidMount = () => {
        if (!this.props.isSignedIn || !this.props.currentUser){
            return history.push('/')
        }       
    }

//     state = {shade: 0, hair: 0, eyes: 0, eyebrows: 0,mouth: 0, top: 0, bottoms:0, shoes: 0, page: 0}
//     assets = {shade: ['./test/shade 1.svg', './test/shade 2.svg','./test/shade 3.svg',
//             './test/shade 4.svg', './test/shade 5.svg'],
//         hair: ['./test/for none.svg', './test/hair 2 - colour 1.svg', './test/hair 2 - colour 2.svg',
//             './test/hair 2 - colour 3.svg', './test/hair 3 - colour 1.svg', './test/hair 3 - colour 1.svg',
//             './test/hair 3 - colour 1.svg', './test/hair 4 - colour 1.svg', './test/hair 4 - colour 1.svg',
//             './test/hair 4 - colour 1.svg', './test/hair 5 - colour 1.svg', './test/hair 5 - colour 1.svg',
//             './test/hair 5 - colour 1.svg'],
//         eyes: ['./test/eyes 1.svg', './test/eyes 2.svg', './test/eyes 3.svg',
//             './test/eyes 4.svg', './test/eyes 5.svg'],
//         eyebrows: ['./test/eyebrows 1.svg', './test/eyebrows 2.svg', './test/eyebrows 3.svg',
//             './test/eyebrows 4.svg', './test/eyebrows 5.svg'],
//         mouth: ['./test/mouth 1.svg', './test/mouth 2.svg', './test/mouth 3.svg',
//             './test/mouth 4.svg', './test/mouth 5.svg'],
//         top: ['./test/tops 1.svg', './test/tops 2.svg', './test/tops 3.svg',
//             './test/tops 4.svg', './test/tops 5.svg', './test/tops 6.svg'],
//         bottoms: ['./test/bottoms 1.svg', './test/bottoms 2.svg', './test/bottoms 3.svg',
//             './test/bottoms 4.svg', './test/bottoms 5.svg', './test/bottoms 6.svg'],
//         shoes: ['./test/shoes 1.svg', './test/shoes 2.svg', './test/shoes 3.svg',
//             './test/shoes 4.svg', './test/shoes 5.svg']}


//     renderOptions = (type) => {
//         return this.assets[type].map((image, index)=>{
//             if (this.state[type] == index){
//                 return <label class="customization">
//                     <input type="radio" name={type} value={index} defaultChecked
//                            onChange={() => this.handleRadioButton(type, index)}/>
//                     <img src = {image} width="90" height="170"></img>
//                 </label>
//             }
//             else {
//                 return <label class="customization">
//                     <input type="radio" name={type} value={index}
//                            onChange={() => this.handleRadioButton(type, index)}/>
//                     <img src = {image} width="90" height="170"></img>
//                 </label>
//             }
//         })
//     }

//     togglePageForward=()=>{
//         this.setState(prevState => ({page: prevState.page+1}));
//     }

//     togglePageBackward=()=>{
//         this.setState(prevState => ({page: prevState.page-1}));
//     }

//     leftButton=()=>{
//         if(this.state.page>=1){
//             return <i onClick={()=> this.togglePageBackward()} className="fas fa-angle-left"></i>
//         }else{
//             return <i></i>
//         }
//     }

//     rightButton=()=>{
//         if (this.state.page==8){
//             return <span className="lead text-dark" style={{fontSize:"0.9rem"}} >Get Started!</span>
//         }
//         else if(this.state.page<=8){
//             return <i onClick={()=> this.togglePageForward()} className="fas fa-angle-right"></i>
//         }
//     }

//     renderPreview = () =>{
//         return <div>
//             {Object.keys(this.state).slice(0,-1).map(key =>
//                 <img class={key}
//                      src = {this.assets[key][this.state[key]]} width="90" height="170"></img>)}
//             </div>
//     }

//     handleRadioButton = (type, index) =>{
//         this.setState({[type]: index})
//     }

//     renderCard=()=>{
//         if (this.state.page === 0){
//             return <div>
//                 {this.renderOptions(Object.keys(this.state)[0])}
//             </div>
//         }else if(this.state.page === 1){
//             return<div>
//                 {this.renderOptions(Object.keys(this.state)[1])}
//                 </div>
//         }else if(this.state.page === 2){
//             return <div>
//                 {this.renderOptions(Object.keys(this.state)[2])}
//             </div>
//         }else if(this.state.page === 3){
//             return <div>
//                 {this.renderOptions(Object.keys(this.state)[3])}
//             </div>
//         }else if(this.state.page === 4){
//             return <div>
//                 {this.renderOptions(Object.keys(this.state)[4])}
//             </div>
//         }else if(this.state.page === 5){
//             return <div>
//                 {this.renderOptions(Object.keys(this.state)[5])}
//             </div>
//         }else if(this.state.page === 6){
//             return <div>
//                 {this.renderOptions(Object.keys(this.state)[6])}
//             </div>
//         }else if(this.state.page === 7){
//             return <div>
//                 {this.renderOptions(Object.keys(this.state)[7])}
//             </div>
//         }else if(this.state.page === 8){
//             return <div>
//                 {this.renderPreview()}
//             </div>
//         }
//     }

//     renderContent(){
//         return <div className="container">
//             <div className="card-group">
//                 {this.renderCard()}
//             </div>
//         </div>

//     }

//     render() {
//         return <div>
//             <h4 align="center">Avatar Customization</h4>
//             {this.renderContent()}
//             <nav className="navbar fixed-bottom navbar-light navbar-padding-bottom bg-none">
//                 {this.leftButton()}
//                 {this.rightButton()}
//             </nav>
//             </div>

// }


render() {
    return <></>
}

}

export default AvatarCustomization;