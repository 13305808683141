import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../apis/kopi';
import Footer from '../Footer';
import Question from './Question'

import {Tex, InlineTex} from 'react-tex';

class Favorites extends React.Component{
    // type: 0 => show list
    // type: 1 => show indiv qn
    state = { favorites: null, type: 0, selected_qn: null }

    closeQuestion = () => {
        this.setState({type: 0, selected_qn: null});
    }

    viewQuestion = (qn) => {
        this.setState({ type: 1, selected_qn: qn })
    }

    componentDidMount = async () => {
        const response = await kopi.get('/api/library/favorites', {
            params: {userId: this.props.currentUser}
        });
        // console.log(response.data)
        this.setState({ favorites: response.data })
    }

    renderList = () => {
        let seperator = "\${1,2}";
        let image = <span></span>;
        let header_txt;
        let qn_txt;
        let topics;
        let index;

        if (this.state.favorites) {
            if (this.state.favorites.length == 0) {
                return <div align="center"><img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540597/Joni%20Assets/klo1.png" class="img-fluid" style={{marginTop: "5vh", marginBottom: "5vh"}}/>
                    <h4>Your favorited questions would appear here.</h4>
                </div>        
            }
            
            return this.state.favorites.map(questionArray => questionArray.map(question => {
                image = <span></span>;
                topics = "";

                // formatting topic, image, header and question
                question.topics.forEach(function(topic){
                    topics += topic.title;
                });
                // console.log('qn_img:', question.qn_img)
                if (question.header_img !== " "){
                    image= <span><img src={question.header_img} className="img-fluid" /><br/></span>
                } 
                if (question.header_txt && question.header_txt.startsWith("\\begin")){
                    header_txt = <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={question.header_txt} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>    
                } else if (question.header_txt) {
                    header_txt = <div><InlineTex texSeperator={seperator} texContent={question.header_txt} /></div>
                }

                if (question.qn_txt && question.qn_txt.startsWith("\\begin")){
                    qn_txt = <div className="table-responsive">
                        <table className="table">
                            <tbody>
                            <tr>
                                <td>
                                    <Tex texContent={question.qn_txt} />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>    
                } else if (question.qn_txt) {
                    qn_txt = <div><InlineTex texSeperator={seperator} texContent={question.qn_txt} /></div>
                }

                function nextChar(c, index) {
                    return String.fromCharCode(c.charCodeAt(0) + index);
                }

                // rendering question cards
                if (!question.prev_qn){
                    if (question.nxt_qn){  // case 1: part (a) of multi-part question
                        index=0;
                        return (
                            <div key={question._id}>
                                <small className="topic-subtitle">{topics}</small>
                                {header_txt}<br/>
                                {image}
                                ({nextChar("a", index)})
                                {qn_txt}
                            </div>
                        ); 
                    } else {  // case 2: single part question
                        return (
                            <div key={question._id}>
                                <small className="topic-subtitle">{topics}</small>
                                {header_txt}<br/>
                                {image}
                                {qn_txt} <br/>
                                <button onClick={() => this.viewQuestion(question)} className="btn btn-block btn-orange">View Solution</button>
                                <hr/>
                            </div>
                        ); 
                    }
                } else {
                    if (question.nxt_qn) {  // case 3: subsequent middle parts of multi-part question
                        index++;
                        return (
                            <div key={question._id}>
                            ({nextChar("a", index)})
                                {image}
                                {qn_txt}
                            </div>
                        ); 
                    } else {  // case 4: last part of multi-part question
                        index++;
                        return (
                            <div key={question._id}>
                                ({nextChar("a", index)})
                                {image}
                                {qn_txt} <br/>
                                <button onClick={() => this.viewQuestion(question)} className="btn btn-block btn-orange">View Solution</button>
                                <hr/>
                            </div>
                        ); 
                    }
                }
            }))
                
        } else {  // no question to be rendered
            return <span></span>
        }
    }

    render(){
        if (this.state.type === 0){
            if (!this.state.favorites) {
                return <div align="center"> 
                        <br/><br/><br/><br/>           
                        <div className="spinner-border text-secondary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
            } else {
                if (this.state.favorites.length){
                    return <div className="container">
                        {this.renderList()}
                        <br/><br/><br/><br/><br/><br/>
                    </div>;
                } else {
                    return <div className="container">
                        <br/>
                        <p style={{ textAlign: "center" }}>Nothing in Joni's sleeve!</p>
                    </div>
                }
            }
        } else {
            return <div className="container">
                <Question attempts={this.state.selected_qn.attempts} closeQuestion={this.closeQuestion} question={this.state.selected_qn} />
                <br/><br/><br/><br/><br/><br/>
            </div>;
        }

    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Favorites);