import React from 'react'; 


import QuestionHeader from './QuestionHeader/index.js';
import QuestionPart from './QuestionPart/index.js';
import AnswerField from './AnswerField/index.js';
import SolutionField from './SolutionField/index.js';
import SelectToggle from './SelectToggle/index.js';
import SimilarQuestionsToggle from './SelectToggle/SimilarQuestionsToggle.js';

import {connect} from 'react-redux';

class QuestionCard extends React.Component {

    state = {attempt: "", selected_part: this.props.question[this.props.current_part_index], solution_view: "Solution"};

    selectPart = (selected_part) => { 
      this.setState({selected_part});
    }

    updateAttempt = (attempt) => {
        this.setState({attempt});
    }

    selectView = (solution_view) => { 
      this.setState({solution_view});
    }

    renderAnswerFieldOrSolutionField(){
        let current_part = this.props.question[this.props.current_part_index]
        if (!this.props.marked_question && this.state.selected_part && this.state.selected_part.id === current_part.id){
          return <AnswerField attempt={this.state.attempt} updateAttempt={this.updateAttempt} onSubmit={this.props.onSubmit} selected_part={this.state.selected_part} current_part={current_part} all_parts={this.props.question} />
        } else {
          return <SolutionField solution_view={this.state.solution_view} answer_feedback={this.props.answer_feedback} marked_question={this.props.marked_question} showing_modal = {this.props.showing_modal} updated_masteries={this.props.updated_masteries} onSubmit={this.props.onSubmit} answered={this.props.answered} selected_part={this.state.selected_part} />
        }
    }

    render(){
      let current_part = this.props.question[this.props.current_part_index]

      return <div className="card question-card shadow-sm p-3 mb-5 bg-white rounded">
          <div className="card-body question-card-content dark-blue-text" style={{overflowY:'scroll', fontSize: "1em"}}>
              <div class="top-bar-parts">
                <div align="center" style={{width: "100%"}}>
                  <SelectToggle solution_view={this.state.solution_view} selectView={this.selectView} marked_question={this.props.marked_question} selectPart={this.selectPart} selected_part={this.state.selected_part} all_parts={this.props.question} current_part={current_part} />
                </div>
              </div>
              <div align="left">
              <div className={this.props.marked_question ? '' : 'row'} style={{ width: "100%" }}>
              <div className={this.props.marked_question ? '': 'col-md-8'}>
                <QuestionHeader marked_question={this.props.marked_question} solution_view={this.state.solution_view}  current_part={current_part} all_parts={this.props.question} />
                <QuestionPart marked_question={this.props.marked_question} solution_view={this.state.solution_view} selected_part={this.state.selected_part} />
              </div>
              <div className={this.props.marked_question ? '': 'col-md-4'}>
              {this.renderAnswerFieldOrSolutionField()}
              </div>
              <br/>
              </div>
              </div>
          </div>
      </div>
      
    }

}

const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(QuestionCard);

