import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../apis/kopi';
import Footer from '../Footer';
import AttemptedQuestions from './AttemptedQuestions';
import Favorites from './Favorites';
import Mentor from './Mentor';

import TrainingSets from './TrainingSets';

import history from '../../history'; 

class Library extends React.Component{

    // type: 0 == sessions, 1 == favorites

    state = {type: 0, close: false};

    componentDidMount = () => {
        if (!this.props.isSignedIn || !this.props.currentUser){
            return history.push('/')
        }

    }

    onChangeLibraryType = (num) => {
        this.setState({type: num, close: true});
    }

    resetClose = () => {
        this.setState({close: false});
    }

    render(){
        let libraryType, navBar;
        if (this.state.type === 0){ 
            libraryType = <TrainingSets close={this.state.close} resetClose={this.resetClose} onChangeLibraryType={this.onChangeLibraryType}/>
        } else if (this.state.type === 2) {
            libraryType = <Mentor close={this.state.close} resetClose={this.resetClose} onChangeLibraryType={this.onChangeLibraryType}/>
        } else {
            libraryType = <Favorites/>
        }

        if (this.state.type === 0) {
            navBar =  <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(0)} className="nav-link target header-font cursor-pointer">Free Practice</span>
            </li>
            <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(2)} className="nav-link header-font cursor-pointer">Mentor</span>
            </li>
            {/* <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(1)} className="nav-link header-font cursor-pointer">Favorites</span>
            </li> */}
        </ul>
        } else if (this.state.type === 1) {
            navBar =  <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(0)} className="nav-link header-font cursor-pointer">Free Practice</span>
            </li>
            <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(2)} className="nav-link header-font cursor-pointer">Mentor</span>
            </li>
            {/* <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(1)} className="nav-link target header-font cursor-pointer">Favorites</span>
            </li> */}
        </ul>
        } else {
            navBar =  <ul className="nav nav-pills nav-fill">
            <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(0)} className="nav-link header-font cursor-pointer">Free Practice</span>
            </li>
            <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(2)} className="nav-link target header-font cursor-pointer">Mentor</span>
            </li>
            {/* <li className="nav-item">
                <span onClick={() => this.onChangeLibraryType(1)} className="nav-link header-font cursor-pointer">Favorites</span>
            </li> */}
        </ul>
        }
       
        return <div ref="library" className="container" style={{paddingTop: '7vh'}}>
        <div style={{background: 'white'}} align="center"className="fixed-top">
        {navBar}
        </div>
        {libraryType}
        <Footer/>
        </div>;
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps)(Library);