import React from 'react';

class Prompt extends React.Component {

  state = { showing: true };

  toggle = () => {
    this.setState({ showing: false });
    // insert code to not remind user for the next 3 days
  }

  render() {
    if (this.state.showing) {
      return (
        <div className="ios-prompt">
          <span onClick={() => this.toggle()} style={{ color: 'rgb(187, 187, 187)', float: 'right', marginTop: '-14px', marginRight: '-11px', fontSize: "20px" }}>&times;</span>
          <p style={{ textAlign: "center", lineHeight: '1.3rem' }}>For a better experience, install this Web App from <strong>Safari browser</strong> onto your iPhone/iPad simply by pressing <img src="share.png" style={{ display: 'inline-block', marginTop: '0px', marginBottom: '4px', height: '20px', width: 'auto' }} /> and then <strong>Add to Home Screen</strong> <img src="addtohomescreen.png" style={{ display:"inline-block", height: '20px', width: 'auto'}} />.</p>
        </div>
      )
    }else{
      return <></>
    }
    
  }
}

export default (Prompt);

