import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
import kopi from '../../apis/kopi';
import QuestionCard from './QuestionCard';
import Ticker from '../StudyPlan/Ticker';
import JoniModal from './JoniModal';
import MasteriesUpdate from './JoniModal/MasteriesUpdate';
import { VelocityTransitionGroup } from 'velocity-react';
import SkyLight from 'react-skylight';
import PauseMenu from './PauseMenu';
import Timer from './Timer';
import SweetAlert from 'sweetalert-react';
import ComplexLoading from '../ComplexLoading.js';
import AskJoni from './AskJoni'
import UploadWorking from './UploadWorking';
import JoniIntervention from './JoniIntervention';
import { Z_FIXED } from 'zlib';

// var Modal = require('boron/OutlineModal');
var Scroll = require('react-scroll');
var scroll = Scroll.animateScroll;


require('velocity-animate');
require('velocity-animate/velocity.ui');

class Training extends React.Component {

    state = {
        dark_mode: false,

        // topic_id: topicRank.topic,
        is_a_noob: false,
        loading: true,
        showLoading: true,
        show_cute_popup: false,
        cute_popup: "",
        cute_popup_title: "",

        will_joni_intervene: null,
        current_part: null,
        all_parts: [],

        no_more_questions: false,
        daily_limit_hit: false,

        answer_feedback: null,
        updated_masteries: null,
        marked_question: null,

        correct_answers: 0,
        wrong_answers: 0,
        created_at: null,
        pause: false,

        cooldown_complete: false,

        three_minutes_elapsed: false,

        attempted_since_refresh: 0,

        dev_button: false,

        want_similar_question: false,

        ask_for_self_assessment_again: false,
        self_assessment_score: null,
        has_updated_self_assessment: false,

        ask_for_working: false,
        delayed_response: null // for homework attempt
    };

    completeCooldown = () => {
        this.setState({ cooldown_complete: true });
    }

    toggleSimilarQuestion = (want_similar_question) => {
        this.setState({ want_similar_question });
    }

    showBenevolence = () => {
        this.setState({ three_minutes_elapsed: true });
    }

    componentDidMount = async () => {

        if (!this.props.isSignedIn || !this.props.currentUser) {
            return history.push('/')
        } else if (this.props.isSignedIn && this.props.currentUser && this.props.tier == 0 && Math.floor((new Date(Date.now()).getTime() - new Date(this.props.dateJoined).getTime()) / (3600000 * 24)) >= 7) {
            return history.push('/reactivate')
        } else if (this.props.isSignedIn && this.props.currentUser && this.props.subscription_tier === 'No Active Subscription') {
            return history.push('/reactivate')
        }

        let response1 = await kopi.get('/api/questions/next', {
            params: {
                userId: this.props.currentUser,
                nextQnId: this.state.current_part ? this.state.current_part.nxt_qn : null,
                mode: "Normal"
            }
        });

        if (!response1.data) {
            window.alert("Your session has expired. A new session will begin.")
            window.location.reload()
            return;
        } else if (response1.data === "no more questions") {
            this.setState({ no_more_questions: true, loading: false })
        } else if (response1.data === "daily limit hit") {
            this.setState({ daily_limit_hit: true, loading: false })
        } else {
            let will_joni_intervene = Math.random() >= 0.888
            this.setState({ is_a_noob: response1.data.is_a_noob, created_at: response1.data.training_status.created_at, correct_answers: response1.data.training_status.correct, wrong_answers: response1.data.training_status.wrong, all_parts: response1.data.all_parts, current_part: response1.data.current_part, will_joni_intervene, loading: false, showLoading: false });
        }
    }

    componentWillUnmount = () => {
        document.body.style.background = null
    }

    pauseSession = (setToPause) => {
        setToPause ? this.simpleDialog.show() : this.simpleDialog.hide()
        this.setState({ pause: !this.state.pause, loading: false });
    }

    toggleDarkMode = () => {
        document.body.style.background = this.state.dark_mode === false ? "#121212" : null
        this.setState({ dark_mode: !this.state.dark_mode, loading: false })
    }

    renderPauseMenu = () => {

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
        }

        return <SkyLight dialogStyles={style} hideOnOverlayClicked ref={ref => this.simpleDialog = ref} >
            <PauseMenu
                no_more_questions={this.state.no_more_questions}
                daily_limit_hit={this.state.daily_limit_hit}
                time_elapsed={parseInt((new Date(Date.now()) - new Date(this.state.created_at)) / 60000.0)}
                correct_answers={this.state.correct_answers}
                wrong_answers={this.state.wrong_answers}
                pauseSession={this.pauseSession}
                current_part={this.state.current_part}
                dark_mode={this.state.dark_mode}
            />
        </SkyLight>

    }

    renderAnswerFeedbackModal = () => {

        if (this.state.updated_masteries && this.state.answer_feedback) {
            let content
            let title
            let subtitle
            if (this.state.updated_topic_ranks.length !== 0) {
                content = ""
                title = "Topic Rank Up!"
                subtitle = "Congratulations!"
            } else {
                content = <MasteriesUpdate updated_masteries={this.state.updated_masteries} />;
                title = this.state.answer_feedback.is_correct ? "Great Job!" : "Oh Dear!"
                subtitle = this.state.answer_feedback.is_correct ? "Joni is proud of you!" : "You can do better!"
            }
            return <JoniModal
                updated_topic_ranks={this.state.updated_topic_ranks}
                is_happy={this.state.answer_feedback.is_correct}
                title={title}
                subtitle={subtitle}
                content={content}
                dark_mode={this.state.dark_mode}
            />
        } else {
            return <></>
        }
    }

    renderQuestionCard = () => {
        if (this.state.current_part) {
            return <QuestionCard
                key={this.state.current_part.id + 5}
                is_a_noob={this.state.is_a_noob}
                loading={this.state.loading}
                updateScore={this.updateScore}
                self_assessment_score={this.state.self_assessment_score}
                has_updated_self_assessment={this.state.has_updated_self_assessment}
                ask_for_self_assessment_again={this.state.ask_for_self_assessment_again}
                want_similar_question={this.state.want_similar_question}
                toggleSimilarQuestion={this.toggleSimilarQuestion}
                correct_answers={this.state.correct_answers}
                wrong_answers={this.state.wrong_answers}
                pauseSession={this.pauseSession}
                marked_question={this.state.marked_question}
                showing_modal={this.state.updated_masteries ? true : false}
                onSubmit={this.onSubmit} all_parts={this.state.all_parts}
                current_part={this.state.current_part}
                answer_feedback={this.state.answer_feedback}
                toggleDarkMode={this.toggleDarkMode}
                dark_mode={this.state.dark_mode}
                ask_for_working={this.state.ask_for_working}
            />
        } else {
            return <></>
        }
    }

    updateScore = (value) => {
        this.setState({ self_assessment_score: value })
    }

    onSubmit = async (action, value, question_type) => {

        let will_joni_intervene;

        switch (action) {
            case "attempt_current_question":
                if (question_type == 'text') {
                    const re = new RegExp('^[-+]?[0-9]+(\.[0-9]+)?$')
                    let match = value.match(re)
                    match = match ? !value.match('/') : false
                    if (!match && this.state.current_part.qn_type === 'text') {
                        this.regexError.show()
                        return;
                    } else {
                        value = value.replace(/^(-)?0+(?=\d)/, '$1')
                    }
                }

                this.setState({ loading: true })
                let response1 = await kopi.post('/api/attempts/submit', {
                    // req.body.qnid, req.body.studentid, req.body.ans, req.body.threeMin
                    qn_id: this.state.current_part.id,
                    student_id: this.props.currentUser,
                    ans: value,
                    threeMin: this.state.three_minutes_elapsed
                });
                if (!response1.data) {
                    window.alert("Your session has expired. A new session will begin.")
                    window.location.reload()
                    return;
                } else if (response1.data === 'Already Attempted') {
                    window.alert("You have already attempted this question!");
                    window.location.reload();
                    return;
                }

                if (response1.data.is_homework) {
                    this.setState({
                        ask_for_working: true,
                        delayed_response: response1.data,
                    });
                } else {
                    this.setState({ ask_for_working: false, updated_topic_ranks: response1.data.updated_topic_ranks, loading: false, self_assessment_score: response1.data.self_assessment_score, ask_for_self_assessment_again: response1.data.ask_for_self_assessment_again, will_joni_intervene, attempted_since_refresh: this.state.attempted_since_refresh + 1, created_at: response1.data.training_status.created_at, correct_answers: response1.data.training_status.correct, wrong_answers: response1.data.training_status.wrong, answer_feedback: response1.data.answer_feedback, updated_masteries: response1.data.updated_masteries, marked_question: response1.data.marked_question });
                }
                break;

            case "upload_working":
                if (value) {
                    kopi.put('/api/attempts/upload_working', {
                        data: {
                            userId: this.props.currentUser,
                            imageURL: value,
                            attempt_id: this.state.delayed_response.answer_feedback.attempt_id
                        }
                    });
                }
                this.setState({ ask_for_working: false, updated_topic_ranks: this.state.delayed_response.updated_topic_ranks, loading: false, self_assessment_score: this.state.delayed_response.self_assessment_score, ask_for_self_assessment_again: this.state.delayed_response.ask_for_self_assessment_again, will_joni_intervene, attempted_since_refresh: this.state.attempted_since_refresh + 1, created_at: this.state.delayed_response.training_status.created_at, correct_answers: this.state.delayed_response.training_status.correct, wrong_answers: this.state.delayed_response.training_status.wrong, answer_feedback: this.state.delayed_response.answer_feedback, updated_masteries: this.state.delayed_response.updated_masteries, marked_question: this.state.delayed_response.marked_question });

                break;

            case "update_self_assessment":
                this.setState({ loading: true })
                kopi.put('/api/attempts/update_self_assessment', {
                    data: {
                        userId: this.props.currentUser,
                        new_self_assessment_score: value,
                        attempt_id: this.state.answer_feedback.attempt_id
                    }
                });
                this.setState({ loading: false, has_updated_self_assessment: true });
                break;

            case "view_current_solution":
                this.setState({ updated_masteries: null, loading: false });
                break;

            case "fetch_next_question":
                this.setState({ loading: true })
                will_joni_intervene = Math.random() >= 0.888
                let response2 = await kopi.get('/api/questions/next', {
                    params: {
                        userId: this.props.currentUser,
                        nextQnId: this.state.current_part.nxt_qn,
                        want_similar_question: value,
                        previous_attempted_question_id: this.state.current_part.id
                    }
                });

                if (!response2.data) {
                    window.alert("Your session has expired. A new session will begin.")
                    window.location.reload()
                    return;
                } else if (response2.data === "no more questions") {
                    this.setState({ loading: false, self_assessment_score: null, has_updated_self_assessment: false, ask_for_self_assessment_again: false, will_joni_intervene: false, no_more_questions: true });
                } else if (response2.data === "daily limit hit") {
                    this.setState({ loading: false, self_assessment_score: null, has_updated_self_assessment: false, ask_for_self_assessment_again: false, will_joni_intervene: false, daily_limit_hit: true });
                } else {
                    let want_similar_question = this.state.want_similar_question;
                    if (!this.state.current_part.nxt_qn) want_similar_question = false;
                    this.setState({ is_a_noob: response2.data.is_a_noob, loading: false, self_assessment_score: null, has_updated_self_assessment: false, ask_for_self_assessment_again: false, want_similar_question, will_joni_intervene, three_minutes_elapsed: false, cooldown_complete: false, created_at: response2.data.training_status.created_at, correct_answers: response2.data.training_status.correct, wrong_answers: response2.data.training_status.wrong, answer_feedback: null, marked_question: null, all_parts: response2.data.all_parts, current_part: response2.data.current_part });

                }
                break;

            default:
                this.setState({ loading: false, show: !this.state.show });
        }


    }

    renderNoMoreQuestions = () => {
        return <div>
            <div className="padding-top">
                <div className="container">
                    <div className="card question-card shadow-sm p-3 mb-5 bg-white rounded">
                        <div className="card-body question-card-content dark-blue-text" align="center">
                            <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" style={{ paddingTop: "10vh", maxHeight: "40vh", maxWidth: "50vw" }} className="img-fluid" /> <br /><br />
                            <h3>Wow, great job! You've been doing lots of practice on these topics. How about trying some others to maximise your improvement?</h3> <br />
                        </div>
                    </div>
                </div>
                <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                    <button onClick={() => {
                        history.push('/settings')
                        // let response3 = await kopi.get('/api/joni/session_summary', {
                        //     params: {userId: this.props.currentUser}
                        // });
                        // this.pauseSession(true);
                        // this.setState({correct_answers: response3.data[0], wrong_answers: response3.data[1], created_at: response3.data[2]})
                    }
                    } className="btn btn-block btn-blue btn-not-rounded">Select Other Topics</button>
                </nav>

                {this.renderPauseMenu()}

            </div>
        </div>
    }

    renderDailyLimitHit = () => {
        return <div>
            <div className="padding-top">
                <div className="container">
                    <div className="card question-card shadow-sm p-3 mb-5 bg-white rounded">
                        <div className="card-body question-card-content dark-blue-text" align="center">
                            <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" style={{ paddingTop: "10vh", maxHeight: "40vh", maxWidth: "50vw" }} className="img-fluid" /> <br /><br />
                            <h3>Uh-oh, you are out of daily questions. Come back tomorrow for more! </h3> <br /> <small> Alternatively, your parent can <a className='underline' href='https://parent.joni.ai'><strong>upgrade your account</strong></a> so that you can enjoy all-day unlimited access.</small> <br />
                        </div>
                    </div>
                </div>
                <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                    <button onClick={() => {
                        history.push('/dashboard')
                    }
                    } className="btn btn-block btn-blue btn-not-rounded">Back to Home</button>
                </nav>

                {this.renderPauseMenu()}

            </div>
        </div>
    }

    renderPracticeComponents = () => {

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
        }

        if (this.state.no_more_questions) {
            return <>
                {this.renderNoMoreQuestions()}
            </>
        } else if (this.state.daily_limit_hit) {
            return <>
                {this.renderDailyLimitHit()}
            </>
        } else {
            return <>
                {this.renderPauseMenu()}
                {this.renderAnswerFeedbackModal()}
                <SkyLight dialogStyles={style} hideOnOverlayClicked ref={ref => this.regexError = ref} >
                    <div align="center">
                        <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006990/Joni%20Assets/answer_wrong.png" className="img-fluid" />
                        <h4>Oops. You can only enter integers and decimals!</h4>
                    </div>
                </SkyLight>
                <div className="padding-top">
                    {this.renderQuestionCard()}
                </div>
            </>
        }

    }

    onStudyPlanCompletion = () => {
        this.setState({ complete_study_plan: true })
    }


    render = () => {
        const dark_mode = {
            backgroundColor: '#121212',
            height: '91vh'
        }
        return <div style={this.state.dark_mode ? dark_mode : {}}>
            <Ticker
                key={10}
                update_marker={this.state.marked_question ? 7 : 8}
                attempted_since_refresh={this.state.attempted_since_refresh}
                dark_mode={this.state.dark_mode} />
            <JoniIntervention
                trigger={this.state.marked_question ? "After Attempt" : "Before Attempt"}
                answer_feedback={this.state.answer_feedback}
                correct_answers={this.state.correct_answers}
                wrong_answers={this.state.wrong_answers}
                key={this.state.marked_question ? 1 : 2}
                will_joni_intervene={this.state.will_joni_intervene}
                dark_mode={this.state.dark_mode}
            />
            {this.renderPracticeComponents()}
            {this.state.showLoading && !this.state.no_more_questions && !this.state.daily_limit_hit ? <ComplexLoading /> : <></>}
            <AskJoni
                question={this.state.current_part}
            />

            <Timer
                question_type={this.state.current_part ? this.state.current_part.qn_type : ""}
                no_more_questions={this.state.no_more_questions}
                daily_limit_hit={this.state.daily_limit_hit}
                key={this.state.current_part ? this.state.current_part.id : ""}
                completeCooldown={this.completeCooldown}
                showBenevolence={this.showBenevolence} />
        </div >
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        tier: state.auth.tier,
        dateJoined: state.auth.dateJoined,
        subscription_tier: state.auth.subscription_tier,
    };
};

export default connect(mapStateToProps)(Training);