import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../apis/kopi';

import Progress from './Progress';
import ProgressDetails from './Progress/ProgressDetails';

import history from '../../../history'
import { signOut } from '../../../actions';
import { ga } from 'react-ga';


class Homework extends React.Component {

    state = { homework_plans: [], selected_plan: null, loading: true }

    componentDidMount = () => {
        this.fetchHomework();
    }

    selectHomework = (selected_plan) => {
        this.setState({ selected_plan });
    }

    fetchHomework = async () => {
        let response = await kopi.get("/api/homework/all", {
            params: { userId: this.props.currentUser }
        })

        // id: homework._id,
        // parts_done: homework.parts_done,
        // end_date: homework.end_date,
        // bare_goal: homework.bare_goal,
        // recommended_goal: homework.recommended_goal,
        // stretch_goal: homework.stretch_goal,
        // active: homework.active,
        // topics: homework.topics.map(t => t.title)

        this.setState({ homework_plans: response.data, loading: false })
    }

    toggleHomeworkActive = (id) => {
        let new_homework_plans = this.state.homework_plans.map(hw => {
            if (hw.id == id) hw.active = !hw.active;
            return hw;
        })
        this.setState({ homework_plans: new_homework_plans })
    }

    renderGoals = () => {
        return this.state.homework_plans.map(homework => {
            return <Progress selectHomework={this.selectHomework} key={homework.id} homework={homework} />
        })
    }

    renderGold = () => {
        let gold_trophy_count = 0;
        this.state.homework_plans.forEach(homework => {
            if (homework.parts_done >= homework.stretch_goal) gold_trophy_count++;
        })
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_gold.png" /><br />
            <strong>x{gold_trophy_count}</strong>
        </div>;
    }

    renderSilver = () => {
        let silver_trophy_count = 0;
        this.state.homework_plans.forEach(homework => {
            if (homework.parts_done >= homework.recommended_goal) silver_trophy_count++;
        })
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1566396152/Joni%20Assets/trophy_silver-removebg-preview.png" /> <br />
            <strong>x{silver_trophy_count}</strong>
        </div>;
    }

    renderBronze = () => {
        let bronze_trophy_count = 0;
        this.state.homework_plans.forEach(homework => {
            if (homework.parts_done >= homework.bare_goal) bronze_trophy_count++;
        })
        return <div align="center">
            <img style={{ width: "50%" }} class="img-fluid" src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/trophy_bronze.png" /><br />
            <strong>x{bronze_trophy_count}</strong>
        </div>;
    }

    render() {
        if (this.state.loading) {
            return <div align="center"> 
                    <br/><br/><br/><br/>           
                    <div className="spinner-border text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    </div>
        } else if (this.state.selected_plan) {
            return <div>
                <br />
                <div class="container"><button onClick={() => this.setState({ selected_plan: null })} className="btn btn-dark btn-block"><i class="fas fa-arrow-left"></i>&nbsp;Back</button></div>
                <br />
                <ProgressDetails toggleHomeworkActive={this.toggleHomeworkActive} key={this.state.selected_plan.id} deletePlan={this.deletePlan} homework={this.state.selected_plan} />
            </div>
        } else if (this.state.homework_plans.length === 0){   
                return <div align="center"><img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540597/Joni%20Assets/klo1.png" class="img-fluid" style={{marginTop: "5vh", marginBottom: "5vh"}}/>
                    <h4>Homework given by your mentor would appear here.</h4>
                    <p>Want to connect to a mentor? Go to <btn 
                        onClick={this.props.setModeToMentor}
                    className="btn btn-sm btn-light">Settings</btn></p>
                </div>
        } else {
            // console.log(this.state.homework_plans)
            return <div className="container padding-bottom"><br />
            <div align="center">
            <h4 className='header-font'>My Homework</h4>
            </div>
            <br/>
                <div className="row">
                    <div className="col">
                        {this.renderBronze()}
                    </div>
                    <div className="col">
                        {this.renderSilver()}
                    </div>
                    <div className="col">
                        {this.renderGold()}
                    </div>
                </div>
                <br />
                {this.renderGoals()}
            </div>
        }
    }
}



const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, { signOut })(Homework);