import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';

import reduxThunk from'redux-thunk';

import App from './components/App';
import reducers from './reducers';

import * as serviceWorker from './serviceWorker';

Sentry.init({dsn: "https://3b1d844ee81f4dd99e72577f13acc9dd@sentry.io/1546706"});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(
                applyMiddleware(reduxThunk)
              ));

ReactDOM.render(
        <Provider store={store}>
                <App/>
        </Provider>,  document.querySelector('#root')
);
 
serviceWorker.register();
