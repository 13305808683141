import React from 'react';
import kopi from '../../apis/kopi'
import { connect } from 'react-redux';
import { signIn } from '../../actions';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import history from '../../history.js';
import '../Joni.css';
import Sparkle from 'react-sparkle'

class ActivateAccount extends React.Component {

    state = {
        username: "",
        password: "",
        password2: "",
        changed_password: false,
        error: false,
        password_dont_match: false,
        password_too_short: false,
    }

    renderError = () => {
        if (this.state.error) {
            return "Either the email you provided is wrong, or the password reset link has expired."
        } else {
            return <></>
        }
    }

    renderPasswordDontMatch = () => {
        if (this.state.password_dont_match) {
            return "Check that you have entered the same passwords."
        } else {
            return <></>
        }
    }

    renderPasswordTooShort = () => {
        if (this.state.password_too_short) {
            return "Your password should contain at least 8 characters and no spaces."
        } else {
            return <></>
        }
    }

    renderusernameSignIn = () => {

        const changePassword = async () => {

            if (this.state.password !== this.state.password2){
                this.setState({password_dont_match: true})
                return
            } else {
                this.setState({password_dont_match: false})
            }
            if (this.state.password.length < 8 || !/^\S*$/.test(this.state.password)){
                this.setState({password_too_short: true})
                return
            }
            const { student_population, user_id } = this.props.match.params
            // const { user_id, password, student_population_id } = req.body;
            this.setState({ loading: true })
            let response = await kopi.post('/mentor/admin/activate_account', {
                user_id, student_population_id: student_population, password: this.state.password
            })
            response.data.success ? this.setState({ loading: false, changed_password: true }) : this.setState({ loading: false, error: true })
        }
        if (this.state.loading) return <div>Activating Account
        <br /><br />
            <button className="btn btn-dark btn-block button-text-small"> Loading </button>
        </div >
        if (this.state.changed_password) {
            return <div>Activated Account. Login with your email and password.
                <br /><br />
                <button onClick={() => history.push('/')} className="btn btn-blue btn-block button-text-small"> Back to Login </button>
            </div >
        }
        return <> <small className="error-messages">{this.renderError()}</small>
        <small className="error-messages">{this.renderPasswordTooShort()}</small>
        <small className="error-messages">{this.renderPasswordDontMatch()}</small>
            <br />
            <div class="form-group">
                {/* <input disabled autoComplete="off" placeholder="Your Email" onChange={(e) => { this.setState({ username: e.target.value }) }} value={this.props.match.params.username} className="form-control" /> */}
            </div>
            <div class="form-group">
                <input type="password" autoComplete="off" placeholder="Your New Password" onChange={(e) => { this.setState({ password: e.target.value }) }} value={this.state.password} className="form-control" />
            </div>
            <div class="form-group">
                <input type="password" autoComplete="off" placeholder="Confirm Password" onChange={(e) => { this.setState({ password2: e.target.value }) }} value={this.state.password2} className="form-control" />
            </div>
            {this.state.changed_password ? <button onClick={() => history.push('/')} className="btn btn-blue btn-block button-text-small"> Back to Login </button> : <button onClick={changePassword} className="btn btn-blue btn-block button-text-small"> Save Password </button>}


            <br />
        </>
    }

    render() {
        return (
            <div style={{ height: '100%', overflow: "hidden" }}>
                <div className="login-centralise">

                    <div className="container padding-bottom" align="center">
                        {this.props.location && this.props.location.state && this.props.location.state.message ? <span>{this.props.location.state.message}</span> : ''}
                        <div style={{ position: 'relative' }}>
                            <br /><br />
                            {this.state.changed_password ? <h1>That's all!</h1> : <h1>Activate Your Account</h1>}

                            <br />
                            <img src='https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png' className="img img-fluid" style={{ maxHeight: "25vh" }} />
                            <br />
                            {/* <Sparkle color={'random'} count={8}/> */}
                        </div>
                        <div>

                            <br />
                            {this.renderusernameSignIn()}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn,
        secondary: state.auth.secondary
    };
};

export default connect(mapStateToProps, { signIn })(ActivateAccount);
