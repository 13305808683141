import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../../../apis/kopi';

import { signOut } from '../../../../../actions';

class Topics extends React.Component{

    state = {
        topics: [],
        selected_topics: [],
        saved: null,
        loading: false,
        save_message: ""
    }

    fetchAllTopics = async () => {
        this.setState({loading: true});
        const response = await kopi.get('/api/topics/settings', {
            params: {userId: this.props.currentUser}
        });    
        let levels = [1,2,3];
        let topics;
        levels.forEach(level=>{
            if (response.data[0][level].length != 0) {
                topics = response.data[0][level]
            } 
        })
        this.setState({loading: false, topics, selected_topics: response.data[1]});
    }

    selectTopic(topic) {
        let selectArray = [];
        this.state.selected_topics.forEach(function(t){
            selectArray.push(t._id);
        });
        if (selectArray.includes(topic._id)) {
            this.setState({selected_topics: this.state.selected_topics.filter((t, i) => t._id !== topic._id)});
        } else {
                this.setState({selected_topics: [...this.state.selected_topics, topic]});
            }
        }

    updateTopics = async () => {
        if (this.state.selected_topics.length !== 0) {
            this.setState({loading: true});
            const response2 = await kopi.put('/api/topics/update', {
                userId: this.props.currentUser,
                selected_topics: this.state.selected_topics
            });  
            let save_message  = response2.data[2] ? "Topics saved! Your study plan has been paused." : "Topics saved!" 
            await this.setState({loading: false, topics: response2.data[0], selected_topics: response2.data[1], saved: true, save_message});
            await kopi.put('/api/joni/update_onboarding', {
                data: {user_id: this.props.currentUser}
            })
            this.props.toggleTopics();
        } else {
            window.alert("You need to select at least one topic!")
        }
    }

    componentDidMount = async () => {
        await this.fetchAllTopics();
    }

    showTopics() {
        if (this.state.topics){
            let selectArray = [];
            this.state.selected_topics.forEach(function(topic){
                selectArray.push(topic._id);
            });
            return (
                <div className="row"> {this.state.topics.map(topic => {
                    if (selectArray.includes(topic._id)) {
                        return (
                            <div className="col-md-6">
                                <div onClick={() => this.selectTopic(topic)} key={topic._id} className="cursor-pointer alert alert-info">
                                    {topic.title}
                                </div>
                            </div>
                            ); 
                    } else {
                        return (
                            <div className="col-md-6">
                                <div onClick={() => this.selectTopic(topic)} key={topic._id} className="cursor-pointer alert alert-dark">
                                    {topic.title}
                                </div>
                            </div>
                        );
                    }
                })
            } </div>
            );
        } else {
            return <small>Loading</small>
        }
    }

    render(){
        let savedText;
        
        if(this.state.saved){
            savedText =  <div className="alert alert-light" role="alert">
                            {this.state.save_message}
                        </div>
        }

        if(this.state.loading){
           return <div className="container">
                    {savedText}
                    <br/>
                    <div align="center"> 
                    <br/> 
                    <div className="spinner-border text-secondary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    <br/>   <br/> 
                    </div>                    
                    <button className="btn btn-info btn-block" onClick={()=>this.props.toggleTopics()}>Update</button>
                    <hr/>
                    <button onClick={() => this.props.signOut()} className="btn btn-danger btn-block">Sign Out</button>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </div>;
        } else {
                return <div className="container" style={{marginBottom: '100px'}}>
                    {savedText}
                <br/>
                    <button onClick={this.updateTopics} className="btn btn-info btn-block wiggle-btn">Update</button>
                    <br/>
                    <h4>Select Topics To Practise</h4>
                    <br/>
                    {this.showTopics()}
                    <br/>
                    <button onClick={this.updateTopics} className="btn btn-info btn-block wiggle-btn">Update</button>
                  
                </div>;
            }
        }
    }



const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn,
  };
};

export default connect(mapStateToProps, {signOut})(Topics);