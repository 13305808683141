import React from 'react';
import kopi from '../../apis/kopi'
import {connect} from 'react-redux';
import { signUpFreeTrial } from '../../actions';
import {Link} from 'react-router-dom';
import ReactGA from 'react-ga';
import '../Joni.css';

class SignUp extends React.Component{

  state = {
        loading: false,
        email: "",
        password: "",
        password_check: "",
        level: "1",
        name: ""
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
   }

   signUp = () => {
        if (this.state.password === this.state.password_check) {
          this.props.signUpFreeTrial({
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            level: this.state.level,
            name: this.state.name
        });
        } else {
          window.alert("Password does not match!")
        }
    }
  
    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        this.props.signUp({
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            level: this.state.level,
            name: this.state.name
        });
      }
    }

    render() {
        return (
          <div className="login-centralise">
            <div className="container padding-bottom" align="center">
              <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006991/Joni%20Assets/joni_waves.png" className="img img-fluid" style={{maxHeight: "20vh"}}/>
              <br/><br/>
              <br/>
              <div class="form-group">
                <input id="email" onChange={(e)=>{this.setState({email: e.target.value})}} value={this.state.email} type="email" className="form-control" placeholder="Email"/> 
              </div>
              <div class="form-group">
                <input id="name" onChange={(e)=>{this.setState({name: e.target.value})}} value={this.state.name} type="name" className="form-control" placeholder="Name"/> 
              </div>
              <div class="form-group">
                <select onChange={(e)=>{this.setState({level: e.target.value})}} value={this.state.level} class="form-control" id="secondary">
                  <option value="1">Secondary 1</option>
                  <option value="2">Secondary 2</option>
                  <option value="3">Secondary 3</option>
                  <option value="4">Secondary 4</option>
                  <option value="5">Secondary 5</option>
                </select>
              </div>
              <div class="form-group">
                <input id="password" onChange={(e)=>{this.setState({password: e.target.value})}} value={this.state.password} type="password" className="form-control" placeholder="Password" /> 
              </div>
              <div class="form-group">
                <input id="repeat-password" onChange={(e)=>{this.setState({password_check: e.target.value})}} value={this.state.password_check} type="password" className="form-control" placeholder="Repeat Password" onKeyDown={this._handleKeyDown}/> 
              </div>
              {!this.state.loading ? <button onClick={this.signUp} className="btn btn-blue btn-block button-text-small"> Sign Up </button> : <button style={{backgroundColor:'grey', color: 'darkgrey'}} className="btn btn-blue btn-block button-text-small"> Sign Up </button>}
            </div>
          </div>
        );
      }
}

const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps, {signUpFreeTrial})(SignUp);
