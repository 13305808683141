import React from 'react';
import {connect} from 'react-redux';
// import {Link} from 'react-router-dom';
import history from '../../../../history'; 

import SelectByLevel from './SelectByLevel';

class SelectPrereqTopics extends React.Component {

    state = {level: 1}

    componentDidMount = () => {
        this.props.scrollToTop();
    }

    renderInstructions = () => {
        return <div className="container padding-top">
         <i className="fas fa-chevron-left fa-2x cursor-pointer" aria-hidden="true" style={{color:"#354B80"}} onClick={this.props.onBack}></i>   
         <h3 className="dark-txt">Select up to 3 prerequisite topics that you think you are weak at and would like to revise in.</h3>
         {this.renderMenu()} <br/>
        </div>
}

    renderMenu = () => {

        let options = [];

        for(let i = 1; i < this.props.topics[0].level; i++){
            let button = this.state.level === i ?  <li className="nav-item"><a className="nav-link active cursor-pointer" onClick={()=>this.setState({level: i})}><b>Secondary {i}</b> &nbsp; &nbsp;</a></li>:<li className="nav-item"> <span className="nav-link cursor-pointer" onClick={()=>this.setState({level: i})}>Secondary {i} &nbsp; &nbsp;</span></li>
            options.push(button)
        }
        
        return  <ul className="nav justify-content-center">
                   {options}
                </ul>
    }   

    renderSelectByLevel = (level) => {
        return <SelectByLevel selected_topics={this.props.selected_topics} prereq_topics={this.props.prereq_topics.filter(topic=>topic.level===level)} onSubmit={this.props.onSubmit} selectTopic={this.props.selectTopic} />
    }

    render(){
        return <>
            {this.renderInstructions()}
            {this.renderSelectByLevel(this.state.level)}
        </>
    }
    
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
};

export default connect(mapStateToProps)(SelectPrereqTopics);


