import React from 'react'
import history from '../../history'

const VerifyEmail = (props) => {
    return <div class="padding-top-large center" align="center">
        <img style={{maxWidth: "40vw"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1561006990/Joni%20Assets/celebration.png" class="img img-fluid" />
        <br/>
            <p className="lead">Please verify your email by clicking on the activation link sent to you.</p>
            <button onClick={()=>{history.push('/')}} className='btn btn-orange'>Return to Login page</button><br/>
            <small>Check your spam if you did not receive the email. For further assistance, contact us at info@joni.ai.</small><br/>

    </div>

}

export default VerifyEmail