import React from 'react'; 


import QuestionHeader from './QuestionHeader/index.js';
import QuestionPart from './QuestionPart/index.js';
import AnswerField from './AnswerField/index.js';
import SolutionField from './SolutionField/index.js';
import SelectToggle from './SelectToggle/index.js';
import SimilarQuestionsToggle from './SelectToggle/SimilarQuestionsToggle.js';

class QuestionCard extends React.Component {

    state = {attempt: "", selected_part: this.props.current_part, solution_view: "Solution", favorited: false};

    toggleSimilarQuestion = (want_similar_question) => {
      this.props.toggleSimilarQuestion(want_similar_question);
    }

    selectPart = (selected_part) => { 
      this.setState({selected_part});
    }

    updateAttempt = (attempt) => {
        this.setState({attempt});
        this.props.selectAnswer();
    }

    selectView = (solution_view) => { 
      this.setState({solution_view});
    }

    renderQuestionNumberOrSave = () => {
      if (this.props.marked_question){
        return <span className={`fav-${this.state.favorited}`} ></span>  
      } else {
        return "Question " + (this.props.correct_answers + this.props.wrong_answers + 1)
      }
    }

    renderAnswerFieldOrSolutionField(){
        if (!this.props.marked_question && this.state.selected_part && this.state.selected_part.id === this.props.current_part.id){
          return <AnswerField selectAnswer={this.props.selectAnswer} attempt={this.state.attempt} updateAttempt={this.updateAttempt} onSubmit={this.props.onSubmit} selected_part={this.state.selected_part} current_part={this.props.current_part} />
        } else {
          return <SolutionField want_similar_question={this.props.want_similar_question} solution_view={this.state.solution_view} answer_feedback={this.props.answer_feedback} marked_question={this.props.marked_question} showing_modal = {this.props.showing_modal} updated_masteries={this.props.updated_masteries} onSubmit={this.props.onSubmit} answered={this.props.answered} selected_part={this.state.selected_part} />
        }
    }

    render(){
      return <div className="card question-card shadow-sm p-3 mb-5 bg-white rounded">
          <div className="card-body question-card-content dark-blue-text" style={{overflowY: "auto"}}>
              <div className="top-bar-menu">
                  <i onClick={()=>this.props.pauseSession(true)} className="fas fa-home cursor-pointer"></i>
                  <small className="question-num"><b>{this.renderQuestionNumberOrSave()}</b></small>
              </div>
              <div className="top-bar-parts">
                <div align="center" style={{width: "100%"}}>
                  <SelectToggle solution_view={this.state.solution_view} selectView={this.selectView} marked_question={this.props.marked_question} selectPart={this.selectPart} selected_part={this.state.selected_part} all_parts={this.props.all_parts} current_part={this.props.current_part} />
                  </div>
              </div>
              <SimilarQuestionsToggle answer_feedback={this.props.answer_feedback} toggleSimilarQuestion={this.toggleSimilarQuestion} marked_question={this.props.marked_question}  />
              <div className={this.props.marked_question ? '' : 'row'} style={{ width: "100%" }}>
              <div className={this.props.marked_question ? '': 'col-md-8'}>
              <QuestionHeader marked_question={this.props.marked_question} solution_view={this.state.solution_view}  current_part={this.props.current_part} all_parts={this.props.all_parts} />
              <QuestionPart marked_question={this.props.marked_question} solution_view={this.state.solution_view} selected_part={this.state.selected_part} />
              </div>
              <div className={this.props.marked_question ? '': 'col-md-4'}>
              {this.renderAnswerFieldOrSolutionField()}
              </div>
              <br/>
              </div>
          </div>
      </div>
      
    }

}

export default QuestionCard;

