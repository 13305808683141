import React from 'react'; 

import {InlineTex} from 'react-tex';

const MCQField = (props) => {
    
    let seperator = "\${1,2}";

    const renderOptions = () => {

        let options = [];

        if (props.current_part.option1) options.push(props.current_part.option1);
        if (props.current_part.option2) options.push(props.current_part.option2);
        if (props.current_part.option3) options.push(props.current_part.option3);

        if (props.current_part.option3){
            options.push("None of the above");
        }

        return options.map((option,index)=>{
            if (props.attempt === option){
                return <div key={index} className={props.dark_mode ? "alert alert-light cursor-pointer" :"alert alert-info cursor-pointer"}>
                <InlineTex texSeperator={seperator} texContent={option} />
                </div>
            } else {
                return <div key={index} onClick={()=>props.updateAttempt(option)} className={props.dark_mode ? "alert alert-darkmode cursor-pointer" :"alert alert-light cursor-pointer"}>
                    <InlineTex texSeperator={seperator} texContent={option} />
                </div>
            }
            
        });

    }

    return <div className="padding-top answer-form">
        {renderOptions()}
    </div> 
  
}

export default MCQField;

