
import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../../../../apis/kopi';

import history from '../../../../../../history';

import { signOut } from '../../../../../../actions';

import SkyLight from 'react-skylight';


class GoalDetails extends React.Component {

    state = {confirm_delete: false}

    deletePlan = () => {
        this.deleteMenu.show() 
    }

    renderDeleteModal = () => {
        const deleteAPIcall = async () => {
            await kopi.delete('/api/study_plans/delete_plan', {
                 data: { study_plan_id: this.props.goal.id }
            })
        this.props.deletePlan();
        }

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return  <SkyLight dialogStyles={style} hideOnOverlayClicked ref={ref => this.deleteMenu = ref} >
            <h3>Are you sure?</h3>
            <p>All goal activity will be lost.</p>
            <button on className="btn btn-block btn-danger" onClick={deleteAPIcall}>Confirm Delete</button>
        </SkyLight>

    }

    render(){
        return <div className="container padding-bottom">
        <div>
            {this.props.goal.topics_practicing.map(topic=>{
                return <div className="alert alert-info"> {topic} </div>
            })}
        </div>
        <div>
            {this.props.goal.topics_left.map(topic=>{
                return <div className="alert alert-dark"> {topic} </div>
            })}
        </div>
        <br/>
        <div align="center">
            <button className="btn btn-sm btn-danger" onClick={this.deletePlan}>Delete Study Plan</button>
        </div>
        {this.renderDeleteModal()}
    </div>

    }
   
}


const mapStateToProps = state => {
    return {
        currentUser: state.auth.currentUser,
        isSignedIn: state.auth.isSignedIn
    };
  };
      
export default connect(mapStateToProps, {signOut})(GoalDetails);