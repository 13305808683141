import React, { useState } from 'react'; 

import {InlineTex} from 'react-tex';
import {Tex} from 'react-tex';

const SolutionField = props => {

    const renderAnswerContext = () => {
        let seperator = "\${1,2}";
        if (props.selected_part.prefix || props.selected_part.suffix){
          let prefix = ""
          let suffix = ""

          if (props.selected_part.prefix) prefix = props.selected_part.prefix;
          if (props.selected_part.suffix) suffix = props.selected_part.suffix;

          return  <>
          <InlineTex texSeperator={seperator} texContent={prefix  + " _____ " + suffix} />     
          <br/>
          </>
        } else {
          return <></>
        }
      }

    const renderSubmitButton = (action, button_text, want_similar_question) => {
        let colorClass;
        switch(action){
            case "view_current_solution":
                colorClass = "btn-blue"
                break;
            case "fetch_next_question":
                colorClass = "btn-orange"
                break;
        }

        if (this.props.loading){
            return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button className={`btn btn-block btn-blue btn-not-rounded`}>Joni is thinking...</button>
            </nav>
        } else{
            return <nav className="navbar fixed-bottom navbar-light bg-white nav-submit">
                <button onClick={()=>props.onSubmit(action, want_similar_question)} className={`btn btn-block ${colorClass} btn-not-rounded`}>{button_text}</button>
            </nav>
        }
    }

    const renderWrongStudentAttempt = () => {
        if (props.answer_feedback){
            if (props.answer_feedback.is_correct) {
                return <></>
            } else {
                return <div className="alert alert-danger">
                {renderAnswerLatex(props.answer_feedback.student_answer)}
                </div>
            }
        } else {
            if (!props.selected_part.correct_ans) { 
                return <div className="alert alert-light">
                    {renderAnswerLatex(props.selected_part.student_ans)}
                </div>
            } else if(props.selected_part.correct_ans === props.selected_part.student_ans) {
                return <></>
            } else {
                return <div className="alert alert-danger">
                {renderAnswerLatex(props.selected_part.student_ans)}
                </div>
            }
        }
    }

    const renderAnswerLatex = (answer) => {
        let seperator = "\${1,2}";
        let content1 =  props.selected_part.units == '\$' ? "$" : ""
        let content2 = answer
        let content3 = props.selected_part.units && props.selected_part.units !== '\$' ? props.selected_part.units : ""
        return <InlineTex texSeperator={seperator} texContent={content1 + content2 + content3} />     
    }

    const renderSolutionLatex = (text) => {
        let seperator = "\${1,2}";
        if (text && text.startsWith("\\begin")) {
            return <div align="left" className="" style={{overflowX: "auto", overflowY:"auto"}}>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <Tex texContent={text} />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        } else {
            return <div align="left"><InlineTex texSeperator={seperator} texContent={text} /></div>
        }
    }

    const renderSolution = () => {
            // console.log(props.marked_question)
            if (props.marked_question){
                return <>
                <br/>
                <div className="alert alert-success">
                {renderAnswerLatex(props.marked_question.ans)}
                 </div>
                {renderWrongStudentAttempt()}
                {props.solution_view === "Solution" ? <><div>
                    <img src={props.marked_question.sol_img} className="img-fluid" />
                </div> {renderSolutionLatex(props.marked_question.sol_txt)} </> : <></>}
            </>
            } else {
                return <>
                    <br/>
                    {props.selected_part.correct_ans ?
                        <div className="alert alert-success">
                            { renderAnswerLatex(props.selected_part.correct_ans) }
                        </div>
                        : ""
                    }
                    {renderWrongStudentAttempt()}
                    {props.solution_view === "Solution" && props.selected_part.correct_ans ? <><div>
                        <img src={props.selected_part.sol_img} className="img-fluid" />
                    </div>  
                    {renderSolutionLatex(props.selected_part.sol_txt)} </> : <></>}
                </>
            }
           
    }
    
    if(props.solution_view === "Solution"){
        if (props.showing_modal){
            return <div>
                {renderAnswerContext()}
                {renderSolution()}
                {renderSubmitButton("view_current_solution", "View Solution")}
            </div>
        } else if (props.marked_question){
            return  <div>
                {renderAnswerContext()}
                {renderSolution()}
                {renderSubmitButton("fetch_next_question", "Next Question", props.want_similar_question)}
            </div>
        } else if (props.selected_part.student_ans) {
            return  <div>
                {renderAnswerContext()}
                {renderSolution()}
            </div>
        } else {
            // console.log(props.selected_part)
            return <div align="center">
                <br/><br/>
                <img src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560540597/Joni%20Assets/klo1.png" style={{ display: "inline", maxHeight: "120px" }}/> <br/>
                <small><strong>Attempt the previous part to proceed!</strong></small>
            </div>
        }
    } else {
        if (props.marked_question){
            return  <div>
                {renderSubmitButton("fetch_next_question", "Next Question", props.want_similar_question)}
            </div>
        } else {
            return  <></>
        }
    }
    
    
}

export default SolutionField;


  