import React from 'react';
import { connect } from 'react-redux';
import kopi from '../../../../../apis/kopi';

import { signOut } from '../../../../../actions';

import SkyLight from 'react-skylight';

import Prereq from './Prereq.js';

class Config extends React.Component{

    state = {invite_key: "", mentor_connected: false, page: 0}

    componentDidMount = async () => {
        let response = await kopi.get('/api/mentors/get-tutelage',{
            params: {user: this.props.currentUser}
         });
        if (response.data){
            this.setState({mentor_connected: true});
        } else {
            this.setState({mentor_connected: false});
        }
    }

    connectToMentor = async () => {
        let response = await kopi.post('/api/mentors/join-tutelage',{
            user: this.props.currentUser,
            invite_key: this.state.invite_key
        });
        if (response.data){
            this.setState({mentor_connected: true});
            this.interact.hide();
        } else {
            this.setState({mentor_connected: false});
        }
    }

    renderConnectToModal = () => {

        const style = {
            width: '80%',
            left: '35vw',
            minHeight: '0',
            zIndex: '9999'
          }

        return <SkyLight
                dialogStyles = {style}
                closeButtonStyle = {{color: "transparent"}}
                hideOnOverlayClicked 
                ref={ref => this.interact = ref}
                >
                <div className="container" align="center">
                    <div class="form-group">
                        <input value={this.state.invite_key} type="text" class="form-control" id="password" placeholder="Mentor Invite Code" onChange={(e)=>this.setState({invite_key: e.target.value})} />
                    </div>
                    <button onClick={this.connectToMentor} type="submit" class="btn btn-block btn-main">Connect</button>
                </div>
            </SkyLight>
    }

    renderMentor = () => {
        return <div className="col-md-4">  
        <h5 className='header-font'>Get supported in your learning</h5>
            <div className="card" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
                <div className="card-body" align="center">
                    <img className="img-fluid" style={{height: "20vh"}} src="https://res.cloudinary.com/dwxuq2vjl/image/upload/q_auto:eco,f_auto/v1560546592/Joni%20Assets/pro_bono.png" />
                    <br/><br/>
                    <button onClick={()=>this.interact.show()} className="btn btn-main btn-block">{this.state.mentor_connected ? "Connected To Mentor": "Connect To A Mentor"}</button>
                </div>
            </div>
            <div className="padding-top" />
        </div>
    }


    renderPayment = () => {
        // return <div className="col-md-8">
        //             <h4>Sponsored Account</h4>
        //             <hr/>
        //             Next Payment Due: Tomorrow <br/><br/>
        //             <small>
        //             Questions Attempted: 0 <br/>
        //             Training Sets Completed: 0
        //             </small>
        
        
        // <div className="padding-top" />
        // </div>
    }

    renderEditAccount = () => {
        // return <div className="col-md-8">  
        //             <h4>Change Password</h4> 
        //             <hr/>
        //             <form>
        //                 <div class="form-group">
        //                     <input type="email" class="form-control" id="email"  placeholder="Old Password" />
        //                 </div>
        //                 <div class="form-group">
        //                     <input type="password" class="form-control" id="password" placeholder="New Password" />
        //                 </div>
        //                 <button type="submit" class="btn btn-main float-right">Confirm</button>
        //             </form>
        //         </div>
    }

    renderButtons = () => {
        return <div className="col-md-4">  
                            <h5 className='header-font'>Let Joni know you better</h5>
            <div className="card" style={{backgroundColor: "rgba(255,255,255,0.5)"}}>
                <div className="card-body" align="center">

                    
                    <button onClick={() => this.clicking()} className="btn btn-dark btn-block">Indicate Learnt Topics</button>
                    
                </div>
            </div>
            <br/>
            <button onClick={() => this.props.signOut()} className="btn btn-danger btn-block">Sign Out</button>
            <div className="padding-top" />
        </div>
    }

    clicking = () =>{
        this.setState({page: 1});
        this.props.learningTopics();
    }

    render(){
        if (this.state.page === 0) {
            return <div className="padding-top">
            {this.renderConnectToModal()}
            <div className="container">
                <div className="row">
                    {this.renderMentor()}
                    {this.renderPayment()}
                    {this.renderButtons()}
                    {this.renderEditAccount()}
                </div>
            </div>
            <div className="padding-top" />
            <div className="padding-top" />
            <div className="padding-top" />
    
            </div>
        } else {
            return <Prereq continueTour={this.props.continueTour}/>
        }
       
    }
}


const mapStateToProps = state => {
  return {
      currentUser: state.auth.currentUser,
      isSignedIn: state.auth.isSignedIn
  };
};

export default connect(mapStateToProps, {signOut})(Config);